import "appkit-react/style/appkit-react.default.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-resizable/css/styles.css";

import "./App.scss";
import "./appkit-overrides.scss";

import { getTenant } from "api/tenant/tenantApi";
import Footer from "components/Footer";
import FullPageLoader from "components/FullPageLoader";
import LoginManager from "components/LoginManager";
import { createContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNoTenant, upsertHostInfo } from "redux/actions/global";

import Header from "./components/Header";
import Menu from "./components/Menu";
import Routes from "./routes";

export const ScrollContext = createContext(null);

const App = () => {
  const ref = useRef(null);
  const currentUser = useSelector((state) => state.global.data.loginUser);
  const hostInfo = useSelector((state) => state.global.data.hostInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hostInfo === null)
      getTenant()
        .then((data) => {
          dispatch(upsertHostInfo(data));
        })
        .catch(() => {
          dispatch(setNoTenant());
          dispatch(upsertHostInfo({}));
        });
  }, [dispatch, hostInfo]);

  if (hostInfo === null) {
    return <FullPageLoader message="Loading application..." />;
  }

  return (
    <ScrollContext.Provider value={ref}>
      <div className="nav-template template-theme-one">
        <Header />
        <LoginManager />
        <div className="d-flex flex-fill">
          {currentUser ? <Menu className="a-menu" /> : null}
          <div className="a-right-content-wrapper">
            <div className="a-right-content-page" ref={ref}>
              <Routes />
            </div>
            <div id="fab-portal" />
            <div id="bottom-banner-portal" />
          </div>
        </div>
        <Footer />
      </div>
    </ScrollContext.Provider>
  );
};

export default App;
