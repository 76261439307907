import { ARTICLE_TYPES } from "../../pages/news/constants";
import { TABS } from "../../pages/news/constants/tabs";
import { byId } from "../../utils/byId";
import {
  ARTICLES_CHANGE_FILTERS,
  ARTICLES_CHANGE_TAB,
  ARTICLES_INITIALIZE,
  ARTICLES_INITIALIZE_SUCCESS,
  ARTICLES_MANUAL_CREATE_DOCUMENT_TYPE,
  ARTICLES_MANUAL_CREATE_ISSUER,
  ARTICLES_MANUAL_CREATE_TOPIC,
  ARTICLES_MANUAL_DELETE_DOCUMENT_TYPE,
  ARTICLES_MANUAL_DELETE_ISSUER,
  ARTICLES_MANUAL_DELETE_TOPIC,
  ARTICLES_MANUAL_UPDATE_DOCUMENT_TYPE,
  ARTICLES_MANUAL_UPDATE_ISSUER,
  ARTICLES_MANUAL_UPDATE_TOPIC,
  ARTICLES_RESET_FILTERS,
  ARTICLES_SET_ARTICLE,
  ARTICLES_SET_ARTICLE_FIELD,
  ARTICLES_SET_DOCUMENT_TYPES,
  ARTICLES_SET_ISSUERS,
  ARTICLES_SET_METADATA_TYPE,
  ARTICLES_SET_PERMISSIONS,
  ARTICLES_SET_TERRITORIES,
  ARTICLES_SET_TOPICS,
  CALENDAR_SET_FOCUS_DATE,
  NEWS_ADMIN_INITIALIZE,
  NEWS_ADMIN_INITIALIZE_SUCCESS,
  NEWS_CHANGE_PAGE,
  NEWS_CHANGE_PAGE_SUCCESS,
} from "../actions/articles";
import { INITIALIZE } from "../constants";

const defaultArticlesFilters = {
  published: true,
  type: null,
  territories: [],
  issuers: [],
  topics: [],
  documentTypes: [],
  issuedDateRange: {},
  freeTextSearch: "",
  textFilters: [],
  clientNoteFilter: {
    impact: null,
    ratings: [],
    assignedToMe: null,
  },
  pwcNoteFilter: {
    impact: null,
    ratings: [],
  },
};

const initialState = {
  data: {
    itemsByType: Object.values(ARTICLE_TYPES).reduce((acc, key) => {
      acc[key] = {
        territories: null,
        territoriesById: null,
        topics: null,
        topicsById: null,
        documentTypes: null,
        documentTypesById: null,
      };
      return acc;
    }, {}),
    territories: null,
    territoriesById: null,
    topics: null,
    topicsById: null,
    documentTypes: null,
    documentTypesById: null,
    issuers: null,
    issuersById: null,
    news: {
      items: null,
      pageNumber: 1,
      totalCount: null,
      loading: false,
      hasNext: true,
    },
    articles: {
      items: null,
      pageNumber: 1,
      totalCount: null,
      loading: false,
      hasNext: true,
    },
    initialized: INITIALIZE.FALSE,
    newsAdminInitialized: INITIALIZE.FALSE,
  },
  filters: defaultArticlesFilters,
  calendarSourceDate: new Date(),
  currentArticle: null,
  permissions: null,
  landingTab: TABS[0].value,
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    // ARTICLES REDUCERS
    case ARTICLES_INITIALIZE:
      return {
        ...state,
        data: {
          ...state.data,
          initialized: INITIALIZE.WIP,
        },
      };
    case ARTICLES_INITIALIZE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          initialized: INITIALIZE.TRUE,
        },
      };
    case NEWS_ADMIN_INITIALIZE:
      return {
        ...state,
        data: {
          ...state.data,
          newsAdminInitialized: INITIALIZE.WIP,
        },
      };
    case NEWS_ADMIN_INITIALIZE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          newsAdminInitialized: INITIALIZE.TRUE,
        },
      };
    case ARTICLES_CHANGE_FILTERS:
      return {
        ...state,
        filters: action.newFilters,
      };

    case ARTICLES_CHANGE_TAB:
      return {
        ...state,
        landingTab: action.tab,
      };

    case ARTICLES_RESET_FILTERS:
      let newFilters = { ...defaultArticlesFilters };
      if (action.selectedType) {
        newFilters.type = action.selectedType;
      }
      return {
        ...state,
        filters: newFilters,
      };
    case ARTICLES_SET_TERRITORIES:
      return {
        ...state,
        data: {
          ...state.data,
          territories: action.newData,
          territoriesById: byId(action.newData),
        },
      };
    case ARTICLES_SET_METADATA_TYPE:
      return {
        ...state,
        data: {
          ...state.data,
          itemsByType: {
            ...state.data.itemsByType,
            [action.payload.type]: {
              ...state.data.itemsByType[action.payload.type],
              documentTypes: action.payload.newData.documentTypes,
              documentTypesById: byId(action.payload.newData.documentTypes),
              issuers: action.payload.newData.issuers,
              issuersById: byId(action.payload.newData.issuers),
              territories: action.payload.newData.territories,
              territoriesById: byId(action.payload.newData.territories),
              topics: action.payload.newData.topics,
              topicsById: byId(action.payload.newData.topics),
              initialized: true,
            },
          },
        },
      };
    case ARTICLES_SET_ISSUERS:
      return {
        ...state,
        data: {
          ...state.data,
          issuers: action.newData,
          issuersById: byId(action.newData),
        },
      };
    case ARTICLES_MANUAL_CREATE_ISSUER:
      const issuersCreate = [...state.data.issuers, action.payload];
      return {
        ...state,
        data: {
          ...state.data,
          issuers: issuersCreate,
          issuersById: byId(issuersCreate),
        },
      };
    case ARTICLES_MANUAL_UPDATE_ISSUER:
      const issuersUpdate = state.data.issuers.map((issuer) =>
        issuer.id !== action.payload.id ? issuer : action.payload
      );

      return {
        ...state,
        data: {
          ...state.data,
          issuers: issuersUpdate,
          issuersById: byId(issuersUpdate),
        },
      };
    case ARTICLES_MANUAL_DELETE_ISSUER:
      const issuersDelete = state.data.issuers.filter(
        (issuer) => issuer.id !== action.payload.id
      );

      return {
        ...state,
        data: {
          ...state.data,
          issuers: issuersDelete,
          issuersById: byId(issuersDelete),
        },
      };
    case ARTICLES_SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.newData.access,
      };
    case ARTICLES_SET_ARTICLE:
      return {
        ...state,
        currentArticle: action.newData,
      };
    case ARTICLES_SET_ARTICLE_FIELD:
      let newState = {
        ...state,
        currentArticle: {
          ...state.currentArticle,
        },
      };
      newState.currentArticle[action.payload.field] = action.payload.newData;
      return newState;

    case NEWS_CHANGE_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          news: {
            ...state.data.news,
            pageNumber: action.newData.pageNumber,
            loading: true,
          },
        },
      };
    case NEWS_CHANGE_PAGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          news: {
            ...state.data.news,
            items: [...(state.data.news.items || []), ...action.newData.items],
            totalCount: action.newData.totalCount,
            hasNext: action.newData.hasNext,
            loading: false,
          },
        },
      };
    case ARTICLES_SET_TOPICS:
      return {
        ...state,
        data: {
          ...state.data,
          topics: action.newData,
          topicsById: byId(action.newData),
        },
      };
    case ARTICLES_MANUAL_CREATE_TOPIC:
      const topicsCreate = [...state.data.topics, action.payload];
      return {
        ...state,
        data: {
          ...state.data,
          topics: topicsCreate,
          topicsById: byId(topicsCreate),
        },
      };
    case ARTICLES_MANUAL_UPDATE_TOPIC:
      const topicsUpdate = state.data.topics.map((topic) =>
        topic.id !== action.payload.id ? topic : action.payload
      );

      return {
        ...state,
        data: {
          ...state.data,
          topics: topicsUpdate,
          topicsById: byId(topicsUpdate),
        },
      };
    case ARTICLES_MANUAL_DELETE_TOPIC:
      const topicsDelete = state.data.topics.filter(
        (topic) => topic.id !== action.payload.id
      );

      return {
        ...state,
        data: {
          ...state.data,
          topics: topicsDelete,
          topicsById: byId(topicsDelete),
        },
      };

    case ARTICLES_MANUAL_CREATE_DOCUMENT_TYPE:
      const documentTypesCreate = [...state.data.documentTypes, action.payload];
      return {
        ...state,
        data: {
          ...state.data,
          documentTypes: documentTypesCreate,
          documentTypesById: byId(documentTypesCreate),
        },
      };
    case ARTICLES_MANUAL_UPDATE_DOCUMENT_TYPE:
      const documentTypesUpdate = state.data.documentTypes.map((documenttype) =>
        documenttype.id !== action.payload.id ? documenttype : action.payload
      );

      return {
        ...state,
        data: {
          ...state.data,
          documentTypes: documentTypesUpdate,
          documentTypesById: byId(documentTypesUpdate),
        },
      };
    case ARTICLES_MANUAL_DELETE_DOCUMENT_TYPE:
      const documentTypesDelete = state.data.documentTypes.filter(
        (documenttype) => documenttype.id !== action.payload.id
      );

      return {
        ...state,
        data: {
          ...state.data,
          documentTypes: documentTypesDelete,
          documentTypesById: byId(documentTypesDelete),
        },
      };
    case ARTICLES_SET_DOCUMENT_TYPES:
      return {
        ...state,
        data: {
          ...state.data,
          documentTypes: action.newData,
          documentTypesById: byId(action.newData),
        },
      };

    case CALENDAR_SET_FOCUS_DATE:
      return {
        ...state,
        calendarSourceDate: action.payload,
      };
    // DEFAULT REDUCER
    default:
      return state;
  }
}

export default rootReducer;
