import "./contextsSearchbar.scss";

import { List, ListItem, Panel, Tag } from "appkit-react";
import classnames from "classnames";
import useClickOutside from "hooks/useClickOutside";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const Searchbar = ({ filters, onFiltersChange, columns }) => {
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  const addFilter = useCallback(
    (column, value) => {
      if (!column) {
        return;
      }

      onFiltersChange([
        ...filters,
        {
          id: column.id,
          Header: column.Header,
          value,
        },
      ]);

      setSearch("");
      focus();
    },
    [filters, onFiltersChange]
  );

  const deleteFilter = useCallback(
    (filterId) => {
      onFiltersChange(filters.filter((f) => f.id !== filterId));
    },
    [filters, onFiltersChange]
  );

  const possibleContexts = useMemo(() => {
    const usedContexts = filters.map((f) => f.id);
    return columns.filter((c) => !usedContexts.includes(c.id));
  }, [filters, columns]);

  const columnKeys = useMemo(() => {
    return columns.map((c) => c.id);
  }, [columns]);

  useEffect(() => {
    if (search.length === 0) {
      setSelectedOption(null);
    } else if (!selectedOption && possibleContexts.length > 0) {
      setSelectedOption(possibleContexts[0]);
    }
  }, [search, possibleContexts, selectedOption]);

  const focus = () => {
    if (inputRef) {
      inputRef.current.focus();
    }
  };

  const handleKeyUp = (event) => {
    const arrowUp = () => {
      const selectedIndex = possibleContexts.indexOf(selectedOption);
      const newIndex =
        selectedIndex > 0 ? selectedIndex - 1 : possibleContexts.length - 1;
      setSelectedOption(possibleContexts[newIndex]);
    };

    const arrowDown = () => {
      const selectedIndex = possibleContexts.indexOf(selectedOption);
      const newIndex =
        selectedIndex < possibleContexts.length - 1 ? selectedIndex + 1 : 0;
      setSelectedOption(possibleContexts[newIndex]);
    };

    switch (event.keyCode) {
      case 13: // Press Enter
        addFilter(selectedOption, search.trim());
        break;
      case 38: // Arrow Up
        arrowUp();
        break;
      case 40: // Arrow Down
        arrowDown();
        break;
      default:
        break;
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 8 && filters.length > 0 && search.length === 0) {
      // Backspace
      deleteFilter(filters[filters.length - 1].id);
    }
  };

  const open = !!search.trim().length;
  const resetSearchbar = useCallback(() => setSearch(""), []);
  const ref = useClickOutside(open, resetSearchbar);

  return (
    <div
      ref={ref}
      className={classnames(
        "contexts-searchbar search-bar",
        focused && "searchbar-focused"
      )}
      onClick={focus}
    >
      <span className="appkiticon icon-search-outline" />
      {filters.map((filter) => {
        const column = columns.find((column) => column.id === filter.id);

        return (
          <Tag
            className={classnames(
              !columnKeys.includes(filter.id) &&
                "contexts-searchbar-tag-disabled",
              column?.isValid &&
                !column.isValid(filter.value) &&
                "contexts-searchbar-tag-errored"
            )}
            key={filter.id}
            closeable={true}
            onClose={() => deleteFilter(filter.id)}
            size="sm"
          >
            {`${filter.Header}: ${filter.value}`}
          </Tag>
        );
      })}

      <div className="contexts-searchbar-input-wrapper">
        <input
          ref={inputRef}
          className="contexts-searchbar-input"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          placeholder="Search"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />

        <div className="contexts-searchbar-results">
          {open && (
            <Panel>
              {possibleContexts.length > 0 ? (
                <List className="contexts-searchbar-results-list">
                  {possibleContexts.map((column) => (
                    <ListItem
                      key={column.id}
                      className={classnames(
                        column.id === selectedOption?.id &&
                          "contexts-searchbar-results-selected"
                      )}
                      onClick={() => addFilter(column, search)}
                    >
                      <span className="contexts-searchbar-results-context">
                        {column.Header}: &nbsp;
                      </span>
                      <span className="contexts-searchbar-results-value">
                        {search}
                      </span>
                    </ListItem>
                  ))}
                </List>
              ) : (
                "No more columns to search."
              )}
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default Searchbar;
