import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const PageTitle = ({ title = null }) => {
  const hostInfoName = useSelector((state) => state.global.data.hostInfo?.name);
  if (!hostInfoName) return null;
  const suffix = `${hostInfoName}${
    process.env.REACT_APP_APP_NAME_SUFFIX
      ? ` ${process.env.REACT_APP_APP_NAME_SUFFIX}`
      : ""
  }`;

  return (
    <Helmet>
      <title>
        {title ? `${title} - ` : ""}
        {suffix}
      </title>
    </Helmet>
  );
};

export default PageTitle;
