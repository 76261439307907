const tooltipMap = (selectedIds, ids, values) => {
  if (
    !Array.isArray(selectedIds) ||
    !Array.isArray(ids) ||
    !Array.isArray(values)
  ) {
    return undefined;
  }
  return selectedIds
    .map((id) => values[ids.findIndex((item) => item === id)])
    .join(", ");
};

export default tooltipMap;
