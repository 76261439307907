import asFormData from "json-form-data";
import { toFormData } from "utils/toFormData";

import isIE from "../utils/isIE";
import { DEFAULT_OIDC_KEY } from "./auth/config";

export const RET_JSON = "RET_JSON";
export const RET_RAW = "RET_RAW";
export const RET_TEXT = "RET_TEXT";

export const CONTENT_TYPE_JSON = {
  "Content-Type": "application/json",
};
export const CONTENT_TYPE_TEXT = {
  "Content-Type": "text/plain",
};

export function apiFetch<R>(
  url: string,
  body: any = undefined,
  method = "GET",
  returnType = RET_JSON,
  headers: any = CONTENT_TYPE_JSON
): Promise<R> {
  if (typeof body === "object") {
    body = JSON.stringify(body);
  }
  return _apiFetch(url, body, method, returnType, headers);
}

export const apiFetchFormData = (
  url: string,
  body = {},
  method = "GET",
  returnType = RET_JSON,
  headers = {}
) => {
  return _apiFetch(
    url,
    asFormData(body, {
      showLeafArrayIndexes: false,

      // @ts-ignore
      mapping: function (value) {
        return value;
      },
    }),
    method,
    returnType,
    headers
  );
};

export const apiFetchFormDataCustom = (
  url: string,
  body = {},
  method = "GET",
  returnType = RET_JSON,
  headers = {}
) => {
  return _apiFetch(url, toFormData(body), method, returnType, headers);
};

export const appendIEHeaders = (headers = {}) => {
  if (isIE) {
    return {
      ...headers,
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
    };
  }
  return headers;
};

export const appendTokenHeaders = (headers = {}) => {
  // grab current state
  let authToken = null;
  try {
    const token = sessionStorage.getItem(DEFAULT_OIDC_KEY);
    if (token === null) {
      return headers;
    }

    const oidcJson = JSON.parse(sessionStorage.getItem(DEFAULT_OIDC_KEY) || "");
    authToken = oidcJson.access_token;
  } catch (error) {
    console.error("Error accessing JWT!", error);
  }

  return {
    ...headers,
    Authorization: `Bearer ${authToken}`,
  };
};

const appendAcceptHeader = (headers: any = {}, returnType: any) => {
  switch (returnType) {
    case RET_TEXT:
      headers.Accept = "text/plain";
      break;
    case RET_RAW:
      headers.Accept = "application/octet-stream";
      break;
    case RET_JSON:
    default:
      headers.Accept = "application/json";
      break;
  }
  return headers;
};

const _apiFetch = (
  url: string,
  body: any,
  method = "GET",
  returnType: any,
  headers = {}
) => {
  headers = appendAcceptHeader(headers, returnType);
  headers = appendTokenHeaders(headers);
  headers = appendIEHeaders(headers);

  return fetch(url, { method: method, headers: headers, body: body }).then(
    (data) => {
      if (!data.ok) {
        throw data;
      } else if (data.status === 204 && returnType !== RET_RAW) {
        // No-Content
        return null;
      } else {
        switch (returnType) {
          case RET_JSON:
            return data.json();
          case RET_TEXT:
            return data.text();
          case RET_RAW:
            return data;
          default:
            throw new Error(
              `Unrecognized "returnType" supplied (${returnType})`
            );
        }
      }
    }
  );
};
