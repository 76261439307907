import "./index.scss";

import DEFAULT_SRC from "../../img/illustrations/undraw_empty.svg";

const DEFAULT_TITLE = "So empty...";

const ImageTitle = ({
  title = DEFAULT_TITLE,
  subtitle,
  src = DEFAULT_SRC,
  alt = title,
}) => (
  <div className="image-title">
    <img src={src} alt={alt} />
    <div className="image-title-title">{title}</div>

    <div className="image-title-subtitle">
      {subtitle === undefined ? <SubTitle /> : subtitle}
    </div>
  </div>
);

const SubTitle = () => (
  <>
    <div>You're looking for something too specific,</div>
    <div>try broadening your criteria.</div>
  </>
);
export default ImageTitle;
