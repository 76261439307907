import { createContext } from "react";

import useSubscriptions, { UseSubscriptions } from "./hooks/useSubscriptions";

export const AdminEngagementContext = createContext(
  (undefined as any) as UseSubscriptions
);

const EngagementsTableContextWrapper = ({ children, filters }: any) => {
  const reducer = useSubscriptions(filters);
  return (
    <AdminEngagementContext.Provider value={reducer}>
      {children}
    </AdminEngagementContext.Provider>
  );
};

export default EngagementsTableContextWrapper;
