import { dataURItoBlob } from "api/helpers";

import { MIME_PNG } from "../../constants";

export const adaptDataToBackend = (data) => {
  return {
    ...data,
    image: data.image ? dataURItoBlob(data.image, MIME_PNG) : null,
  };
};
