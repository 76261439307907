import { downloadBlob } from "utils";

import { RET_RAW, apiFetch, apiFetchFormData } from "../api";
import {
  adaptNewsFromBackend,
  adaptNewsToBackEnd,
  toApiFilter,
} from "./newsDataManipulator";

const PREFIX = process.env.REACT_APP_URL_NEWS_ROOT + "News/";

export const getList = (
  filters,
  pagination = { pageNumber: 1, pageSize: 6 }
) => {
  return apiFetch(
    `${PREFIX}paged?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}`,
    { filters: toApiFilter(filters.filters), orderBy: filters.orderBy },
    "POST"
  ).then(adaptNewsFromBackend);
};

export const getDashboard = (filters) => {
  return apiFetch(
    `${process.env.REACT_APP_URL_NEWS_ROOT}Dashboard`,
    filters,
    "POST"
  );
};

export const getListByArticle = (articleId) => {
  return apiFetch(`${PREFIX}byArticle/${articleId}`);
};

export const getNews = (newsId) => {
  return apiFetch(`${PREFIX}${newsId}`).then(adaptNewsFromBackend);
};

export const getNewsTitlesFromIds = (articleIds) => {
  return apiFetch(`${PREFIX}titles`, articleIds, "POST");
};

export const addNews = (news) => {
  return apiFetch(PREFIX, adaptNewsToBackEnd(news), "POST");
};

export const updateNews = (news) => {
  return apiFetch(`${PREFIX}${news.id}`, adaptNewsToBackEnd(news), "PUT");
};

export const deleteNews = (newsId) => {
  return apiFetch(`${PREFIX}${newsId}`, undefined, "DELETE");
};

export const publishNews = (id, publish) => {
  return apiFetch(`${PREFIX}${id}/published`, publish.toString(), "PATCH");
};

export const setArticleId = (newsIds, articleId) => {
  return apiFetch(
    `${PREFIX}ArticleId`,
    {
      ids: newsIds,
      articleId,
    },
    "PATCH"
  );
};

export const isAlive = (newsId) => {
  return apiFetch(`${PREFIX}${newsId}/IsAlive`, undefined, "PATCH");
};

export const downloadTemplate = () => {
  return apiFetch(`${PREFIX}import`, undefined, "GET", RET_RAW)
    .then((res) => res.blob())
    .then((blob) => downloadBlob(blob, "import_news_template.xlsx"));
};

export const importNewsFromExcel = (file) => {
  return apiFetchFormData(`${PREFIX}import`, { importFile: file }, "POST");
};
