import "./index.scss";

import { MODULES, MODULE_VALUES } from "constants/module";

import { AuthenticationContext } from "@axa-fr/react-oidc-context";
import { DEFAULT_OIDC_KEY } from "api/auth/config";
import { Button } from "appkit-react";
import { Tooltip } from "appkit-react";
import classNames from "classnames";
import PwcLogo from "img/illustrations/pwc-logo-simple.svg";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getInitialsFromName } from "../../utils/index";
import AppLink from "../AppLink";
import AvatarWithAsyncPhoto from "../AvatarWithAsyncPhoto";

const outlineAction = {
  NOACTION: 0,
  CLICK: 1,
  CHANGE: 2,
  BLUR: 3,
  TAB: 4,
};

const getProfileData = (props) => {
  if (props.loginUser) {
    return {
      name: props.loginUser.name,
      initials: getInitialsFromName(props.loginUser.name),
      photoUri: props.loginUser?.photoUri,
    };
  }
  return null;
};

export const handleLogout = () => {
  let idToken = null;
  try {
    const oidcJson = JSON.parse(sessionStorage.getItem(DEFAULT_OIDC_KEY));
    idToken = oidcJson.id_token;
  } catch (error) {
    console.error("Error accessing JWT!", error);
  }
  sessionStorage.removeItem(DEFAULT_OIDC_KEY);
  window.location.replace(
    `${
      process.env.REACT_APP_OID_LOGOUT_ROOT
    }?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(
      `${window.location.origin}${process.env.REACT_APP_OID_POST_LOGOUT_REDIRECT_URI_SUFFIX}`
    )}`
  );
};

const LogoutTooltip = (props) => {
  const profileData = getProfileData(props);
  if (!profileData) {
    return null;
  }
  return (
    <div>
      <div className="a-list-title profile">
        <AvatarWithAsyncPhoto
          name={profileData.name}
          photoUri={profileData.photoUri}
        />
        <span>{profileData.name}</span>
      </div>
      <AppLink to={MODULES.PROFILE}>
        <div className="a-list-title a-list-button">
          <span className="appkiticon icon-person-fill" />
          &nbsp;My profile
        </div>
      </AppLink>
      <div
        className="a-list-title a-list-button logout-button"
        onClick={handleLogout}
      >
        <span className="appkiticon icon-logout-fill" />
        &nbsp;Logout
      </div>
      {process.env.REACT_APP_SHOW_VERSION === "true" && (
        <div className="version-row">
          version {process.env.REACT_APP_PACKAGE_VERSION}
          {process.env.REACT_APP_PACKAGE_HASH &&
            `-${process.env.REACT_APP_PACKAGE_HASH.substring(0, 8)}`}
        </div>
      )}
    </div>
  );
};

class HeaderContent extends Component {
  constructor() {
    super();
    this.state = {
      optionVal: "1",
      showValue: "2",
      hasOutLine: true,
      lastAction: outlineAction.NOACTION,
    };
    const self = this;
    this.keyEvent = function (e) {
      if (e.keyCode === 9) {
        // Tab key
        self.setState({
          lastAction: outlineAction.BLUR,
          hasOutLine: true,
        });
      }
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.keyEvent, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.keyEvent, false);
  }

  handleSelect(val) {
    this.setState({
      showValue: val,
    });
  }

  onMouseDown = () => {
    this.setState({
      hasOutLine: false,
      lastAction: outlineAction.CLICK,
    });
  };

  onBlur = () => {
    const { lastAction } = this.state;
    if (lastAction === outlineAction.CLICK) {
      this.setState({
        hasOutLine: false,
        lastAction: outlineAction.BLUR,
      });
    } else {
      this.setState({
        hasOutLine: true,
        lastAction: outlineAction.BLUR,
      });
    }
  };

  render() {
    const showSettings =
      process.env.REACT_APP_HIDE_MODULES_NON_LUX === "1"
        ? this.props.loginUser?.isLu
        : this.props.loginUser?.isInternal;

    const backgroundColor = process.env.REACT_APP_ENV_COLOR
      ? process.env.REACT_APP_ENV_COLOR
      : null;

    return (
      <>
        <nav
          onMouseDown={this.onMouseDown}
          onBlur={this.onBlur}
          className={`a-header-wrapper justify-content-between ${
            !this.state.hasOutLine ? "a-header-wrapper-no-outline" : ""
          }`}
          style={{
            zIndex: 100,
            ...(backgroundColor ? { backgroundColor } : {}),
          }}
        >
          <div className="a-brand-container">
            <AppLink to="/">
              <img
                src={PwcLogo}
                alt="pwc-logo"
                style={{
                  width: "2.5rem",
                  marginRight: "1rem",
                }}
              />
            </AppLink>
          </div>
          <span className="app-name font-weight-medium text-nowrap">
            {`${this.props.hostInfo.name}${
              process.env.REACT_APP_APP_NAME_SUFFIX
                ? ` ${process.env.REACT_APP_APP_NAME_SUFFIX}`
                : ""
            }`}
          </span>

          <div className="spacer" />
          <div className="a-actions-container">
            {showSettings && (
              <Tooltip
                content="Platform settings"
                placement="bottom"
                mouseEnterDelay={500}
              >
                <AppLink to={MODULES.ADMIN}>
                  <button
                    className={classNames(
                      "settings-icon a-icon-container d-none d-sm-flex a-btn-background-transparent",
                      { active: window.location.pathname === MODULES.ADMIN }
                    )}
                    aria-label="Administration"
                  >
                    <span
                      className={`appkiticon ${
                        MODULE_VALUES[MODULES.ADMIN].icon
                      }-outline`}
                    />
                  </button>
                </AppLink>
              </Tooltip>
            )}

            <Tooltip
              content={<LogoutTooltip {...this.props} />}
              trigger="click"
              refClassName="a-account-info"
              className="a-account-options"
              tooltipTheme="light"
              clickToClose
              style={{ zIndex: 100 }}
              containerid="a-actions-container"
              placement="bottom-end"
            >
              {(() => {
                const profileData = getProfileData(this.props);
                return profileData ? (
                  <AvatarWithAsyncPhoto
                    name={profileData.name}
                    photoUri={profileData.photoUri}
                  />
                ) : (
                  <Button onClick={() => this.props.login()}>Login</Button>
                );
              })()}
            </Tooltip>
          </div>
        </nav>
        <div id="a-actions-container" />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.global.data.loginUser,
    hostInfo: state.global.data.hostInfo,
  };
};

const ConnectedHeaderContent = connect(mapStateToProps, {})(HeaderContent);

const Header = (props) => {
  return (
    <div className="normal-header-container">
      <AuthenticationContext.Consumer>
        {(oidcProps) => <ConnectedHeaderContent {...props} {...oidcProps} />}
      </AuthenticationContext.Consumer>
    </div>
  );
};

export default withRouter(Header);
