import { PopBanner } from "appkit-react";
import { useContext } from "react";

import DeleteUserFromElementModal from "../../RemoveUserFromElementModal";
import { AdminEngagementContext } from "..";

const DeleteUserFromEngagementModal = ({ user, onClose, ...modalProps }) => {
  const { removeUserFromSubscription } = useContext(AdminEngagementContext);
  const submitDeleteUser = () => {
    return removeUserFromSubscription(user.parentId, user.ppid).then(() => {
      PopBanner({
        content: `The user ${user.name} has been successfully deleted from the subcription.`,
        duration: 4000,
        status: "success",
      });

      onClose();
    });
  };

  return (
    <DeleteUserFromElementModal
      visible={user !== null}
      userName={user?.name}
      elementType="subscription"
      onSubmit={submitDeleteUser}
      onCancel={onClose}
      {...modalProps}
    />
  );
};

export default DeleteUserFromEngagementModal;
