import "./index.scss";

import { Tooltip } from "@mui/material";
import { Button, Select } from "appkit-react";
import classnames from "classnames";
import { useState } from "react";
import tooltipMap from "utils/tooltipsMapper";

const getTooltipContent = (value, children) => {
  return tooltipMap(
    value,
    children?.map((child) => child?.props?.value),
    children?.map((child) => child?.props?.children)
  );
};

const ValidationSelectTooltipHoc = ({ ...selectProps }) => {
  const tooltip = getTooltipContent(selectProps?.value, selectProps?.children);
  const disabled =
    !tooltip || !selectProps.multiple || selectProps.value?.length < 2;
  return (
    <Tooltip title={disabled ? "" : tooltip} placement="top">
      <div>
        <ValidationSelect {...selectProps} />
      </div>
    </Tooltip>
  );
};

const ValidationSelect = ({
  value,
  errMsg = undefined,
  hasError = false,
  className = undefined,
  clearable = false,
  multiple = false,
  onSelect,
  ...selectProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const isEmptyValue = multiple ? (value?.length ?? 0) === 0 : !value;

  return (
    <div className="amds-select-wrapper">
      {clearable && !isEmptyValue && !isOpen && (
        <Button
          className="amds-select-clear-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            onSelect(multiple ? [] : null);
          }}
        >
          <span className="appkiticon icon-circle-delete-outline clear-button" />
        </Button>
      )}

      <Select
        value={value}
        onClick={() => setIsOpen(true)}
        multiple={multiple}
        onSelect={onSelect}
        onClose={() => setIsOpen(false)}
        className={classnames(hasError && "a-select-error", className)}
        {...selectProps}
      />
      {hasError && errMsg && typeof errMsg === "string" ? (
        <p className="a-form-error">{errMsg}</p>
      ) : null}
    </div>
  );
};

export default ValidationSelectTooltipHoc;
