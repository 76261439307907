import {
  ADMIN_ACCEPT_TERMS_AND_CONDITIONS,
  GLOBAL_INIT,
  GLOBAL_INIT_ERROR,
  GLOBAL_INIT_SUCCESS,
  UPSERT_HOSTINFO,
  UPSERT_LOGINUSER,
  UPSERT_TENANTS,
} from "../actions/global";
import { GLOBAL_ERRORS, INITIALIZE } from "../constants";

const initialState = {
  data: {
    loginUser: null,
    companies: {
      items: null,
      itemsById: {},
      loading: false,
    },
    hostInfo: null,
    tenants: null,
    hasError: GLOBAL_ERRORS.NO_ERROR,
  },
  initialized: INITIALIZE.FALSE,
};

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case GLOBAL_INIT:
      return {
        ...state,
        initialized: INITIALIZE.WIP,
      };
    case GLOBAL_INIT_SUCCESS:
      return {
        ...state,
        initialized: INITIALIZE.TRUE,
      };
    case GLOBAL_INIT_ERROR:
      return {
        ...state,
        hasError: action.error,
        initialized: INITIALIZE.TRUE,
      };
    case UPSERT_LOGINUSER:
      return {
        ...state,
        data: {
          ...state.data,
          loginUser: {
            ...(state.data.loginUser || {}),
            ...action.payload,
          },
        },
      };
    case UPSERT_HOSTINFO:
      return {
        ...state,
        data: {
          ...state.data,
          hostInfo: {
            ...action.payload,
          },
        },
      };

    case UPSERT_TENANTS:
      return {
        ...state,
        data: {
          ...state.data,
          tenants: [...action.payload],
        },
      };

    case ADMIN_ACCEPT_TERMS_AND_CONDITIONS:
      return {
        ...state,
        data: {
          ...state.data,
          loginUser: {
            ...state.data.loginUser,
            termsAndConditionsAccepted: true,
          },
        },
      };
    default:
      return state;
  }
}

export default usersReducer;
