import {
  LifecycleAllReadDTO,
  LifecycleReadDTO,
} from "api/catalog/__generated__/CatalogueAPI";
import { useLifeCyclesAll } from "api/catalog/queries/useLifeCycles";
import { SelectOption } from "appkit-react";
import Select from "components/Select";
import { sortBy } from "lodash";
import { useMemo } from "react";

interface ILifeCycleSelectProps {
  tenant: string;
  value: string[];
  onValueChange: (value: string[]) => void;
  hasError?: boolean;
  errMsg?: any;
  placeholder?: string;
}

const LifeCycleSelect = ({
  tenant,
  value,
  onValueChange,
  ...selectProps
}: ILifeCycleSelectProps) => {
  const { data: lifecyclesAll = {} } = useLifeCyclesAll();
  const lifecyclesAllSorted = useMemo(() => {
    return Object.entries(lifecyclesAll).reduce((acc, [key, value]) => {
      acc[key] = sortBy(value, (item) => item.order);
      return acc;
    }, {} as LifecycleAllReadDTO);
  }, [lifecyclesAll]);
  const lifecycles: LifecycleReadDTO[] = lifecyclesAllSorted[tenant] ?? [];
  return (
    <Select
      multiple
      placeholder="Select a Life cycle"
      showSearchOnToggle
      value={value}
      onSelect={(values: string[]) => {
        if (typeof values === "string") {
          onValueChange(values);
          return;
        }
        const isAdd = values.length > value.length;
        if (isAdd) {
          const [addedValue] = values.filter((item) => !value.includes(item));
          const valuesToCommit = values.filter((item) => {
            if (
              !lifecycles.map((lifecycle) => lifecycle.name).includes(item) &&
              item !== addedValue
            ) {
              return false;
            }
            return true;
          });
          onValueChange(valuesToCommit);
        } else {
          onValueChange(values);
        }
      }}
      {...selectProps}
    >
      {lifecycles?.map((lifecycle) => (
        <SelectOption value={lifecycle.name} key={lifecycle.id}>
          {lifecycle.name}
        </SelectOption>
      ))}
    </Select>
  );
};

export default LifeCycleSelect;
