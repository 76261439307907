/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CaptchaCreateDTO {
  /** @format uuid */
  resourceId: string | null;
}

export interface CaptchaReadDTO {
  /** @format uuid */
  id: string;

  /** @format date-time */
  expiredAt: string;
  captcha: string | null;

  /** @format int32 */
  width: number;

  /** @format int32 */
  height: number;
}

export interface SubIndustryAllReadDTO {
  [key: string]: SubIndustryReadDTO;
}

export interface SubIndustryReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;
}

export interface IndustryAllReadDTO {
  [key: string]: IndustryReadDTO[];
}

export interface IndustryReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;
  subIndustries: SubIndustryReadDTO[] | null;
}

export interface SubIndustryCreateDTO {
  name: string | null;
}

export interface IndustryCreateDTO {
  name: string | null;
  subIndustries: SubIndustryCreateDTO[] | null;
}

export interface IndustryUpdateDTO {
  name: string | null;
}

export interface SubIndustryUpdateDTO {
  name: string | null;
}

export interface LifecycleAllReadDTO {
  [key: string]: LifecycleReadDTO[];
}

export interface LifecycleReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;

  /** @format int32 */
  order: number;
  domain: string;
}

export interface LifecycleCreateDTO {
  name: string | null;

  /** @format int32 */
  order: number;
}

export interface LifecycleUpdateDTO {
  name: string | null;
}

export interface PageContactReadDTO {
  ppid: string | null;
  firstname: string | null;
  lastname: string | null;
  email: string | null;

  /** @format int32 */
  phoneCountryCode: number | null;
  phoneNumber: string | null;
  grade: string | null;
  photoUri: string | null;
}

export interface PageReadDTO {
  title: string | null;
  subtitle: string | null;
  description: string | null;
  value1Title: string | null;
  value1Description: string | null;
  value2Title: string | null;
  value2Description: string | null;
  contacts: PageContactReadDTO[] | null;
  mainFilter: string | null;
  secondaryFilter: string | null;
  tertiaryFilter: string | null;
}

export interface PageFieldsUpdateDTO {
  title: string | null;
  subtitle: string | null;
  description: string | null;
  value1Title: string | null;
  value1Description: string | null;
  value2Title: string | null;
  value2Description: string | null;
  contacts: string[] | null;
}

export interface PageOptionsUpdateDTO {
  mainFilter: string | null;
  secondaryFilter: string | null;
  tertiaryFilter: string | null;
}

export interface ServiceContactReadDTO {
  /** @format uuid */
  id: string;
  ppid: string | null;
  firstname: string | null;
  lastname: string | null;
  email: string | null;

  /** @format int32 */
  phoneCountryCode: number | null;
  phoneNumber: string | null;
  grade: string | null;
  photoUri: string | null;
}

export interface ApplicationReadDTO {
  name: string | null;
  link: string | null;
  showToClient: boolean;
  itContact: ServiceContactReadDTO | null;
}

export interface RelatedServicesDTO {
  /** @format uuid */
  id: string;
  name: string | null;
  smallDescription: string | null;
}

export interface ServiceIllustrationReadDTO {
  /** @format uuid */
  id: string;
  contentType: string | null;
  isVideo: boolean;
}

export interface ServiceReadDTO {
  team: ServiceContactReadDTO[] | null;
  responsibles: ServiceContactReadDTO[] | null;
  organizations: string[] | null;
  viewers: ServiceContactReadDTO[] | null;
  ukViewers: ServiceContactReadDTO[] | null;
  frViewers: ServiceContactReadDTO[] | null;
  applications: ApplicationReadDTO[] | null;
  industries: string[] | null;
  itContact: ServiceContactReadDTO | null;
  relatedServices: RelatedServicesDTO[] | null;
  illustrations: ServiceIllustrationReadDTO[] | null;
  domains: string[] | null;
  territories: { id: string; name: string }[] | null;

  /** @format uuid */
  id: string;
  name: string | null;
  description: string | null;
  videoLink: string | null;
  live: boolean;
  isHighlighted: boolean;
  isNew: boolean;
  isPSF: boolean;
  isPSA: boolean;
  lifeCycles: string[] | null;
  valueChains: string[] | null;
  smallDescription: string | null;
  geographicalInformation: string | null;
  targetProfile: string | null;
  availableApplication: string | null;
  deliverable: string | null;
  mainAddedValue: string | null;
  secondaryAddedValue: string | null;

  /** @format uuid */
  serviceGroupId: string | null;
  friendlyUrl: string | null;
  visibleInClientView: boolean | null;
}

export interface ApplicationCreateDTO {
  name: string | null;
  link: string | null;
  showToClient: boolean;
  itContactPpid: string | null;
}

export interface CaptchaValidationDTO {
  /** @format uuid */
  id: string;
  code: string | null;
}

export interface ServiceContactDTO {
  message: string | null;
  email: string | null;
  name: string | null;
  phoneNumber: string | null;
  jobTitle: string | null;
  captchaValidation: CaptchaValidationDTO | null;
}

export interface ServiceGroupReadListDTO {
  /** @format uuid */
  id: string;
  name: string | null;
  lifeCycle: string | null;
  valueChain: string | null;
  smallDescription: string | null;
  domain: string;
}

export interface ServiceGroupCreateDTO {
  name: string | null;
  lifeCycles: string[] | null;
  valueChains: string[] | null;
  smallDescription: string | null;
  services: string[] | null;
}

export interface ServiceGroupServiceReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;
}

export interface ServiceGroupReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;
  lifeCycles: string[] | null;
  valueChains: string[] | null;
  smallDescription: string | null;
  services: ServiceGroupServiceReadDTO[] | null;
}

export interface ServiceGroupUpdateDTO {
  name: string | null;
  lifeCycles: string[] | null;
  valueChains: string[] | null;
  smallDescription: string | null;
  services: string[] | null;
}

export interface ValueChainAllReadDTO {
  [key: string]: ValueChainReadDTO[];
}

export interface ValueChainReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;

  /** @format int32 */
  order: number;
  domain: string;
}

export interface ValueChainCreateDTO {
  name: string | null;

  /** @format int32 */
  order: number;
}

export interface ValueChainUpdateDTO {
  name: string | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`
    )}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${
        queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
          ...(requestParams.headers || {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Catalog Services v1
 * @version v1
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Captcha
     * @name V1CaptchaCreate
     * @summary Return a captcha challenge.
     * @request POST:/api/v1/Captcha
     * @secure
     */
    v1CaptchaCreate: (data: CaptchaCreateDTO, params: RequestParams = {}) =>
      this.request<CaptchaReadDTO, any>({
        path: `/api/v1/Captcha`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Industry
     * @name V1IndustryList
     * @summary Return all industries.
     * @request GET:/api/v1/Industry
     * @secure
     */
    v1IndustryList: (params: RequestParams = {}) =>
      this.request<IndustryReadDTO[], any>({
        path: `/api/v1/Industry/current`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    v1IndustryAllList: (params: RequestParams = {}) =>
      this.request<IndustryAllReadDTO, any>({
        path: `/api/v1/Industry`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Industry
     * @name V1IndustryCreate
     * @summary Create the industry.
     * @request POST:/api/v1/Industry
     * @secure
     */
    v1IndustryCreate: (data: IndustryCreateDTO, params: RequestParams = {}) =>
      this.request<IndustryReadDTO, any>({
        path: `/api/v1/Industry`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Industry
     * @name V1IndustryDetail
     * @summary Return industry.
     * @request GET:/api/v1/Industry/{industryId}
     * @secure
     */
    v1IndustryDetail: (industryId: string, params: RequestParams = {}) =>
      this.request<IndustryReadDTO, any>({
        path: `/api/v1/Industry/${industryId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Industry
     * @name V1IndustryCreate2
     * @summary Create the sub industry.
     * @request POST:/api/v1/Industry/{industryId}
     * @originalName v1IndustryCreate
     * @duplicate
     * @secure
     */
    v1IndustryCreate2: (
      industryId: string,
      data: SubIndustryCreateDTO,
      params: RequestParams = {}
    ) =>
      this.request<SubIndustryReadDTO, any>({
        path: `/api/v1/Industry/${industryId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Industry
     * @name V1IndustryUpdate
     * @summary Update the industry.
     * @request PUT:/api/v1/Industry/{industryId}
     * @secure
     */
    v1IndustryUpdate: (
      industryId: string,
      data: IndustryUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Industry/${industryId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Industry
     * @name V1IndustryDelete
     * @summary Delete the industry.
     * @request DELETE:/api/v1/Industry/{industryId}
     * @secure
     */
    v1IndustryDelete: (industryId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Industry/${industryId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Industry
     * @name V1IndustryDetail2
     * @summary Return sub industry.
     * @request GET:/api/v1/Industry/{industryId}/{subIndustryId}
     * @originalName v1IndustryDetail
     * @duplicate
     * @secure
     */
    v1IndustryDetail2: (
      industryId: string,
      subIndustryId: string,
      params: RequestParams = {}
    ) =>
      this.request<SubIndustryReadDTO, any>({
        path: `/api/v1/Industry/${industryId}/${subIndustryId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Industry
     * @name V1IndustryUpdate2
     * @summary Update the sub industry.
     * @request PUT:/api/v1/Industry/{industryId}/{subIndustryId}
     * @originalName v1IndustryUpdate
     * @duplicate
     * @secure
     */
    v1IndustryUpdate2: (
      industryId: string,
      subIndustryId: string,
      data: SubIndustryUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Industry/${industryId}/${subIndustryId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Industry
     * @name V1IndustryDelete2
     * @summary Delete the sub industry.
     * @request DELETE:/api/v1/Industry/{industryId}/{subIndustryId}
     * @originalName v1IndustryDelete
     * @duplicate
     * @secure
     */
    v1IndustryDelete2: (
      industryId: string,
      subIndustryId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Industry/${industryId}/${subIndustryId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lifecycle
     * @name V1LifecycleList
     * @summary Return all lifecycles.
     * @request GET:/api/v1/Lifecycle
     * @secure
     */
    v1LifecycleList: (params: RequestParams = {}) =>
      this.request<LifecycleReadDTO[], any>({
        path: `/api/v1/Lifecycle/current`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    v1LifecycleAllList: (params: RequestParams = {}) =>
      this.request<LifecycleAllReadDTO, any>({
        path: `/api/v1/Lifecycle`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lifecycle
     * @name V1LifecycleCreate
     * @summary ´Create the lifecycle.
     * @request POST:/api/v1/Lifecycle
     * @secure
     */
    v1LifecycleCreate: (data: LifecycleCreateDTO, params: RequestParams = {}) =>
      this.request<LifecycleReadDTO, any>({
        path: `/api/v1/Lifecycle`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lifecycle
     * @name V1LifecycleDetail
     * @summary Return lifecycle.
     * @request GET:/api/v1/Lifecycle/{lifecycleId}
     * @secure
     */
    v1LifecycleDetail: (lifecycleId: string, params: RequestParams = {}) =>
      this.request<LifecycleReadDTO, any>({
        path: `/api/v1/Lifecycle/${lifecycleId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lifecycle
     * @name V1LifecycleUpdate
     * @summary Update the lifecycle.
     * @request PUT:/api/v1/Lifecycle/{lifecycleId}
     * @secure
     */
    v1LifecycleUpdate: (
      lifecycleId: string,
      data: LifecycleUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Lifecycle/${lifecycleId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lifecycle
     * @name V1LifecycleDelete
     * @summary Delete the lifecycle.
     * @request DELETE:/api/v1/Lifecycle/{lifecycleId}
     * @secure
     */
    v1LifecycleDelete: (lifecycleId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Lifecycle/${lifecycleId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Lifecycle
     * @name V1LifecycleOrderUpdate
     * @summary Update the lifecycle order.
     * @request PUT:/api/v1/Lifecycle/{lifecycleId}/order/{order}
     * @secure
     */
    v1LifecycleOrderUpdate: (
      lifecycleId: string,
      order: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Lifecycle/${lifecycleId}/order/${order}`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Page
     * @name V1PageList
     * @summary Return main page.
     * @request GET:/api/v1/Page
     * @secure
     */
    v1PageList: (params: RequestParams = {}) =>
      this.request<PageReadDTO, any>({
        path: `/api/v1/Page`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Page
     * @name V1PageFieldsUpdate
     * @summary Update main page.
     * @request PUT:/api/v1/Page/fields
     * @secure
     */
    v1PageFieldsUpdate: (
      data: PageFieldsUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Page/fields`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Page
     * @name V1PageOptionsUpdate
     * @summary Update main page.
     * @request PUT:/api/v1/Page/options
     * @secure
     */
    v1PageOptionsUpdate: (
      data: PageOptionsUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Page/options`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceDetail
     * @summary Return the specified service.
     * @request GET:/api/v1/Service/{id}
     * @secure
     */
    v1ServiceDetail: (id: string, params: RequestParams = {}) =>
      this.request<ServiceReadDTO, any>({
        path: `/api/v1/Service/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceDetail
     * @summary Return the specified service.
     * @request GET:/api/v1/Service/{id}
     * @secure
     */
    v1ServiceDetailFriendly: (url: string, params: RequestParams = {}) =>
      this.request<ServiceReadDTO, any>({
        path: `/api/v1/Service/friendly/${url}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceUpdate
     * @summary Update the specified service.
     * @request PUT:/api/v1/Service/{id}
     * @secure
     */
    v1ServiceUpdate: (
      id: string,
      data: {
        TeamPpids?: string[];
        ResponsiblesPpids?: string[];
        Organizations?: string[];
        ViewersPpids?: string[];
        UkViewersPpids?: string[];
        FrViewersPpids?: string[];
        Applications?: ApplicationCreateDTO[];
        Industries?: string[];
        IllustrationsToAdd?: File[];
        IllustrationsToRemove?: string[];
        RelatedServices?: string[];
        Name?: string;
        Description?: string;
        VideoLink?: string;
        ItContactPpid?: string;
        Live?: boolean;
        IsHighlighted?: boolean;
        IsNew?: boolean;
        LifeCycles?: string;
        ValueChains?: string;
        SmallDescription?: string;
        GeographicalInformation?: string;
        TargetProfile?: string;
        AvailableApplication?: string;
        Deliverable?: string;
        MainAddedValue?: string;
        SecondaryAddedValue?: string;
        ServiceGroupId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Service/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceDelete
     * @summary Delete the specified service.
     * @request DELETE:/api/v1/Service/{id}
     * @secure
     */
    v1ServiceDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Service/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceList
     * @summary Return all services.
     * @request GET:/api/v1/Service
     * @secure
     */
    v1ServiceList: (params: RequestParams = {}) =>
      this.request<ServiceReadDTO[], any>({
        path: `/api/v1/Service`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceCreate
     * @summary Create a new service.
     * @request POST:/api/v1/Service
     * @secure
     */
    v1ServiceCreate: (
      data: {
        TeamPpids?: string[];
        ResponsiblesPpids?: string[];
        ViewersPpids?: string[];
        UkViewersPpids?: string[];
        FrViewersPpids?: string[];
        Organizations?: string[];
        Applications?: ApplicationCreateDTO[];
        Industries?: string[];
        RelatedServices?: string[];
        Illustrations?: File[];
        Name?: string;
        Description?: string;
        VideoLink?: string;
        ItContactPpid?: string;
        Live?: boolean;
        IsHighlighted?: boolean;
        IsNew?: boolean;
        LifeCycles?: string;
        ValueChain?: string;
        SmallDescription?: string;
        GeographicalInformation?: string;
        TargetProfile?: string;
        AvailableApplication?: string;
        Deliverable?: string;
        MainAddedValue?: string;
        SecondaryAddedValue?: string;
        ServiceGroupId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ServiceReadDTO, any>({
        path: `/api/v1/Service`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceLiveList
     * @summary Return all services with live property set to true.
     * @request GET:/api/v1/Service/live
     * @secure
     */
    v1ServiceLiveList: (params: RequestParams = {}) =>
      this.request<ServiceReadDTO[], any>({
        path: `/api/v1/Service/live`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceDetail2
     * @summary Get a service illustration.
     * @request GET:/api/v1/Service/{serviceId}/{illustrationId}
     * @originalName v1ServiceDetail
     * @duplicate
     * @secure
     */
    v1ServiceDetail2: (
      serviceId: string,
      illustrationId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Service/${serviceId}/${illustrationId}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceLivePartialUpdate
     * @summary Update the specified service live value.
     * @request PATCH:/api/v1/Service/{id}/live
     * @secure
     */
    v1ServiceLivePartialUpdate: (
      id: string,
      query?: { live?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Service/${id}/live`,
        method: "PATCH",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceNewPartialUpdate
     * @summary Update the specified service new value.
     * @request PATCH:/api/v1/Service/{id}/new
     * @secure
     */
    v1ServiceNewPartialUpdate: (
      id: string,
      query?: { isNew?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Service/${id}/new`,
        method: "PATCH",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceHighlightedPartialUpdate
     * @summary Update the specified service highlight value.
     * @request PATCH:/api/v1/Service/{id}/highlighted
     * @secure
     */
    v1ServiceHighlightedPartialUpdate: (
      id: string,
      query?: { isHighlighted?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Service/${id}/highlighted`,
        method: "PATCH",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceImportList
     * @summary Download the template for the import.
     * @request GET:/api/v1/Service/import
     * @secure
     */
    v1ServiceImportList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Service/import`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceImportCreate
     * @summary Import services from Excel file.
     * @request POST:/api/v1/Service/import
     * @secure
     */
    v1ServiceImportCreate: (
      data: { importFile?: File },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Service/import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceExportExcelCreate
     * @summary Export services.
     * @request POST:/api/v1/Service/export/excel
     * @secure
     */
    v1ServiceExportExcelCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Service/export/excel`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceContactCreate
     * @summary Send a message to the contacts of a service.
     * @request POST:/api/v1/Service/{serviceId}/contact
     * @secure
     */
    v1ServiceContactCreate: (
      serviceId: string,
      data: ServiceContactDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Service/${serviceId}/contact`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceGroup
     * @name V1ServiceGroupList
     * @summary Return all service Group.
     * @request GET:/api/v1/ServiceGroup
     * @secure
     */
    v1ServiceGroupList: (params: RequestParams = {}) =>
      this.request<ServiceGroupReadListDTO[], any>({
        path: `/api/v1/ServiceGroup`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    v1TerritoriesList: (params: RequestParams = {}) =>
      this.request<any[], any>({
        path: `/api/v1/Territory`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    v1TerritoryGet: (id: string, params: RequestParams = {}) =>
      this.request<any[], any>({
        path: `/api/v1/Territory/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    v1TerritoryCreate: (data: any, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/api/v1/Territory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    v1TerritoryUpdate: (id: string, data: any, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Territory/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    v1TerritoryDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Territory/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceByidsCreate
     * @request POST:/api/v1/Service/byids
     * @secure
     */
    v1ServiceByidsCreate: (data: string[], params: RequestParams = {}) =>
      this.request<ServiceReadDTO[], any>({
        path: `/api/v1/Service/byids`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceGroup
     * @name V1ServiceGroupCreate
     * @summary Create a new service group.
     * @request POST:/api/v1/ServiceGroup
     * @secure
     */
    v1ServiceGroupCreate: (
      data: ServiceGroupCreateDTO,
      params: RequestParams = {}
    ) =>
      this.request<ServiceGroupReadDTO, any>({
        path: `/api/v1/ServiceGroup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceGroup
     * @name V1ServiceGroupDetail
     * @summary Return the specified service Group.
     * @request GET:/api/v1/ServiceGroup/{id}
     * @secure
     */
    v1ServiceGroupDetail: (id: string, params: RequestParams = {}) =>
      this.request<ServiceGroupReadDTO, any>({
        path: `/api/v1/ServiceGroup/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceGroup
     * @name V1ServiceGroupUpdate
     * @summary Update a specified service group.
     * @request PUT:/api/v1/ServiceGroup/{id}
     * @secure
     */
    v1ServiceGroupUpdate: (
      id: string,
      data: ServiceGroupUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/ServiceGroup/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServiceGroup
     * @name V1ServiceGroupDelete
     * @summary Update a specified service group.
     * @request DELETE:/api/v1/ServiceGroup/{id}
     * @secure
     */
    v1ServiceGroupDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ServiceGroup/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ValueChain
     * @name V1ValueChainList
     * @summary Return all valueChains.
     * @request GET:/api/v1/ValueChain
     * @secure
     */
    v1ValueChainList: (params: RequestParams = {}) =>
      this.request<ValueChainReadDTO[], any>({
        path: `/api/v1/ValueChain/current`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    v1ValueChainAllList: (params: RequestParams = {}) =>
      this.request<ValueChainAllReadDTO[], any>({
        path: `/api/v1/ValueChain`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ValueChain
     * @name V1ValueChainCreate
     * @summary ´Create the valueChain.
     * @request POST:/api/v1/ValueChain
     * @secure
     */
    v1ValueChainCreate: (
      data: ValueChainCreateDTO,
      params: RequestParams = {}
    ) =>
      this.request<ValueChainReadDTO, any>({
        path: `/api/v1/ValueChain`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ValueChain
     * @name V1ValueChainDetail
     * @summary Return valueChain.
     * @request GET:/api/v1/ValueChain/{valueChainId}
     * @secure
     */
    v1ValueChainDetail: (valueChainId: string, params: RequestParams = {}) =>
      this.request<ValueChainReadDTO, any>({
        path: `/api/v1/ValueChain/${valueChainId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ValueChain
     * @name V1ValueChainUpdate
     * @summary Update the valueChain.
     * @request PUT:/api/v1/ValueChain/{valueChainId}
     * @secure
     */
    v1ValueChainUpdate: (
      valueChainId: string,
      data: ValueChainUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/ValueChain/${valueChainId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ValueChain
     * @name V1ValueChainDelete
     * @summary Delete the valueChain.
     * @request DELETE:/api/v1/ValueChain/{valueChainId}
     * @secure
     */
    v1ValueChainDelete: (valueChainId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/ValueChain/${valueChainId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ValueChain
     * @name V1ValueChainOrderUpdate
     * @summary Update the valueChain order.
     * @request PUT:/api/v1/ValueChain/{valueChainId}/order/{order}
     * @secure
     */
    v1ValueChainOrderUpdate: (
      valueChainId: string,
      order: number,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/ValueChain/${valueChainId}/order/${order}`,
        method: "PUT",
        secure: true,
        ...params,
      }),
  };
}
