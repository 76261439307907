import "./index.scss";

import { Switch } from "appkit-react";
import classNames from "classnames";

const SwitchWithLoader = ({
  loading,
  onText = "On",
  offText = "Off",
  ...props
}) => {
  const className = classNames("a-toggle-with-loader", props.className);
  if (loading) {
    const loader = <div className="a-loading a-default a-m-10"></div>;
    return (
      <Switch
        {...props}
        className={className}
        onText={loader}
        offText={loader}
        disabled={true}
      />
    );
  }
  return (
    <Switch
      onText={onText}
      offText={offText}
      {...props}
      className={className}
    />
  );
};

export default SwitchWithLoader;
