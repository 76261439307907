import "./index.scss";

import { Button, List, Tooltip } from "appkit-react";
import classNames from "classnames";
import useClickOutside from "hooks/useClickOutside";
import { useCallback, useState } from "react";

import CustomPanel from "./CustomPanel";

const MenuList = ({ className, children, tooltip = "Actions", ...props }) => {
  const [open, setOpen] = useState(false);
  const close = useCallback(() => setOpen(false), []);
  const node = useClickOutside(open, close);

  return (
    <div
      className={classNames(className, "menulist-wrapper")}
      ref={node}
      {...props}
    >
      <Tooltip content={tooltip} placement="top" mouseEnterDelay={1000}>
        <Button kind="transparent" onClick={() => setOpen(true)}>
          <span className="appkiticon icon-vertical-more-fill" />
        </Button>
      </Tooltip>
      <CustomPanel className={classNames("menulist", open && "menulist-open")}>
        <List onClick={() => setOpen(false)}>{children}</List>
      </CustomPanel>
    </div>
  );
};

export default MenuList;
