import { TABS } from "pages/newsAdmin/components/TableToggle";

import { NEWS_ADMIN_CHANGE_TAB } from "../actions/newsAdmin";

const initialState = {
  tab: TABS.NEWS,
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case NEWS_ADMIN_CHANGE_TAB:
      return {
        ...state,
        tab: action.payload,
      };

    // DEFAULT REDUCER
    default:
      return state;
  }
}

export default rootReducer;
