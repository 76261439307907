import { RET_RAW, apiFetch } from "../api";
import { toApiFilter } from "./exportNewsDataManipulator";

const PREFIX = process.env.REACT_APP_URL_EXPORT_NEWS_ROOT;

export const exportAllToExcel = (filters) => {
  return apiFetch(`${PREFIX}all/excel`, toApiFilter(filters), "POST", RET_RAW);
};

export const exportAllToWord = (filters) => {
  return apiFetch(`${PREFIX}all/word`, toApiFilter(filters), "POST", RET_RAW);
};

export const exportArticlesToExcel = (articleIds) => {
  return apiFetch(`${PREFIX}article/excel`, articleIds, "POST", RET_RAW);
};

export const exportSelectedNewsToExcel = (articleIds) => {
  return apiFetch(`${PREFIX}news/selected/excel`, articleIds, "POST", RET_RAW);
};

export const exportFilteredNewsToExcel = (filters) => {
  return apiFetch(`${PREFIX}news/filtered/excel`, filters, "POST", RET_RAW);
};

export const exportSelectedEventsToExcel = (eventIds) => {
  return apiFetch(`${PREFIX}event/selected/excel`, eventIds, "POST", RET_RAW);
};

export const exportFilteredEventsToExcel = (filters) => {
  return apiFetch(`${PREFIX}event/filtered/excel`, filters, "POST", RET_RAW);
};

export const exportType = (type) => {
  return apiFetch(`${PREFIX}right/excel/${type}`, null, "POST", RET_RAW);
};
