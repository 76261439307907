import { Item } from "./type";

export const ADMIN_FETCHLIST = "ADMIN_FETCHLIST";
type FetchListAction = {
  type: typeof ADMIN_FETCHLIST;
};
export const fetchList = (): FetchListAction => {
  return { type: ADMIN_FETCHLIST };
};

export const ADMIN_FETCHLIST_SUCCESS = "ADMIN_FETCHLIST_SUCCESS";
type FetchListSuccessAction<T extends Item> = {
  type: typeof ADMIN_FETCHLIST_SUCCESS;
  payload: T[];
};
export function fetchListSuccess<T extends Item>(
  payload: T[]
): FetchListSuccessAction<T> {
  return {
    type: ADMIN_FETCHLIST_SUCCESS,
    payload,
  };
}

export const ADMIN_ITEM_ADDED = "ADMIN_ITEM_ADDED";
type ItemAddedAction<T extends Item> = {
  type: typeof ADMIN_ITEM_ADDED;
  payload: T;
};
export function itemAdded<T extends Item>(payload: T): ItemAddedAction<T> {
  return {
    type: ADMIN_ITEM_ADDED,
    payload,
  };
}

export const ADMIN_ITEM_UPDATED = "ADMIN_ITEM_UPDATED";
type ItemUpdatedAction<T extends Item> = {
  type: typeof ADMIN_ITEM_UPDATED;
  payload: T;
};
export function itemUpdated<T extends Item>(payload: T): ItemUpdatedAction<T> {
  return {
    type: ADMIN_ITEM_UPDATED,
    payload,
  };
}

export const ADMIN_ITEM_DELETED = "ADMIN_ITEM_DELETED";
type ItemDeletedAction<T extends Item> = {
  type: typeof ADMIN_ITEM_DELETED;
  payload: T;
};

export function itemDeleted<T extends Item>(payload: T): ItemDeletedAction<T> {
  return {
    type: ADMIN_ITEM_DELETED,
    payload,
  };
}

export type Action<T extends Item> =
  | FetchListAction
  | FetchListSuccessAction<T>
  | ItemAddedAction<T>
  | ItemUpdatedAction<T>
  | ItemDeletedAction<T>;
