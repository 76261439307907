import { appendIEHeaders, appendTokenHeaders } from "api/api";

import {
  Api,
  NotificationSettingsReadDTO,
  NotificationTypeDTO,
} from "./__generated__/NotificationAPI";

const PREFIX = process.env.REACT_APP_URL_NOTIFICATION;

let _clientSingleton: Api<any> | null = null;
const client = (): Api<any> => {
  if (!_clientSingleton) {
    _clientSingleton = new Api({
      baseUrl: PREFIX,
      baseApiParams: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...appendTokenHeaders(),
          ...appendIEHeaders(),
        },
      },
    });
  }
  return _clientSingleton;
};

function toData<T>(res: { data: T }) {
  return res.data;
}

export function getNotificationsByPpids(
  ppids: string[]
): Promise<NotificationSettingsReadDTO[]> {
  return client().api.v1NotificationByPpidsCreate(ppids).then(toData);
}

export const getNotifications = (): Promise<NotificationSettingsReadDTO> => {
  return client().api.v1NotificationList().then(toData);
};

export const updateNotifications = (
  notifications: NotificationSettingsReadDTO
) => {
  return client().api.v1NotificationUpdate(notifications).then(toData);
};

export const getNotificationTypes = (): Promise<NotificationTypeDTO[]> => {
  return client().api.v1NotificationTypesList().then(toData);
};

export const unsubscribe = () => {
  return client().api.v1NotificationUnsubscribeList().then(toData);
};
