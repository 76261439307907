import DeleteModal from "components/DeleteModal";

const DeleteUserFromElementModal = ({
  visible,
  onCancel,
  userName,
  elementType,
  elementName,
  secondMessage = null,
  onSubmit,
}) => {
  return (
    <DeleteModal
      visible={visible}
      className="delete-user-from-engagement-modal"
      onClose={onCancel}
      onConfirm={onSubmit}
      title={`Remove user from ${elementType} ?`}
    >
      <p>
        You're about to remove the user {userName} from the {elementType}&nbsp;
        {elementName}. <strong>This action cannot be undone</strong>.
      </p>
      {secondMessage && <p>{secondMessage}</p>}
      <small>(This will not delete the user from Managed Services Hub)</small>
    </DeleteModal>
  );
};

export default DeleteUserFromElementModal;
