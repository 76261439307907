import "./index.scss";

import {
  downloadServiceImportTemplate,
  importServiceFromExcel,
} from "api/admin/adminApi";
import FloatingActionButtonPortal from "components/FloatingActionButton";
import UploadImport from "pages/newsAdmin/components/UploadImport";
import { useState } from "react";

import { ServiceItem } from "../type";
import AddServiceModal from "./AddServiceModal";
import DeleteServiceModal from "./DeleteServiceModal";
import ServicesTable from "./Table";

interface Props {
  filters: any;
  initializer?: any;
}

const EMPTY_SERVICE: ServiceItem = {
  canAddSubscription: false,
  domains: [],
  id: "",
  isHighlighted: false,
  isNew: false,
  live: false,
  name: "",
};

export default function AdminServices({ filters, initializer }: Props) {
  const [addingService, setAddingService] = useState<boolean>(false);
  const [serviceToUpdate, setServiceToUpdate] = useState<ServiceItem | null>(
    initializer?.id ? { ...EMPTY_SERVICE, id: initializer.id } : null
  );
  const [serviceToDelete, setServiceToDelete] = useState<ServiceItem | null>(
    null
  );

  return (
    <>
      <AddServiceModal
        visible={addingService || !!serviceToUpdate}
        serviceId={serviceToUpdate?.id || null}
        onClose={() => {
          setServiceToUpdate(null);
          setAddingService(false);
        }}
      />
      <DeleteServiceModal
        service={serviceToDelete}
        onClose={() => setServiceToDelete(null)}
      />
      <div className="service-admin">
        <UploadImport
          onImport={importServiceFromExcel}
          onDownloadTemplate={downloadServiceImportTemplate}
          type="Service"
        />
        <ServicesTable
          filters={filters}
          onEditService={setServiceToUpdate}
          onDeleteService={setServiceToDelete}
        />
      </div>
      <FloatingActionButtonPortal
        onClick={() => setAddingService(true)}
        name="icon-plus-fill"
        tooltip="Create a new service"
      />
    </>
  );
}
