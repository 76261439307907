import { GLOBAL_ERRORS } from "redux/constants";

export const UPSERT_LOGINUSER = "UPSERT_LOGINUSER";
export const upsertLoginUser = (userInfo) => {
  return {
    type: UPSERT_LOGINUSER,
    payload: userInfo,
  };
};

export const UPSERT_HOSTINFO = "UPSERT_HOSTINFO";
export const upsertHostInfo = (hostInfo) => {
  return {
    type: UPSERT_HOSTINFO,
    payload: hostInfo,
  };
};

const tenantColors = [
  "#415385",
  "#D04A02",
  "#26776D",
  "#D93954",
  "#E0301E",
  "#757575",
];

export const UPSERT_TENANTS = "UPSERT_TENANTS";
export const upsertTenants = (tenants) => {
  return {
    type: UPSERT_TENANTS,
    payload: tenants.map((tenant, index) => ({
      ...tenant,
      color: tenantColors[Math.min(tenantColors.length - 1, index)],
    })),
  };
};

export const setLoginUserNotFound = () => {
  return {
    type: GLOBAL_INIT_ERROR,
    error: GLOBAL_ERRORS.NO_USER,
  };
};

export const setSessionExpired = () => {
  return {
    type: GLOBAL_INIT_ERROR,
    error: GLOBAL_ERRORS.SESSION_EXPIRED,
  };
};

export const setNoTenant = () => {
  return {
    type: GLOBAL_INIT_ERROR,
    error: GLOBAL_ERRORS.NO_TENANT,
  };
};

export const GLOBAL_INIT = "GLOBAL_INIT";
export const GLOBAL_INIT_SUCCESS = "GLOBAL_INIT_SUCCESS";
export const GLOBAL_INIT_ERROR = "GLOBAL_INIT_ERROR";

// FIXME JGE how can we manage user permissions
export const globalInitialize = () => async (dispatch) => {
  dispatch({
    type: GLOBAL_INIT,
  });
  dispatch({
    type: GLOBAL_INIT_SUCCESS,
  });
};

export const ADMIN_ACCEPT_TERMS_AND_CONDITIONS =
  "ADMIN_ACCEPT_TERMS_AND_CONDITIONS";

export const adminAcceptTermsAndConditions = () => (dispatch) => {
  dispatch({ type: ADMIN_ACCEPT_TERMS_AND_CONDITIONS });
};
