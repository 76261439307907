import { FormErrors } from "hooks/useSubmitable";
import useMultilineForm from "pages/admin/hooks/useMultilineForm";

import { SubscriptionLinkForm } from "../../types/form";
import LinkForm from "./LinkForm";

export const DEFAULT_LINK: SubscriptionLinkForm = {
  name: "",
  link: "",
  showToClient: true,
};

interface Props {
  items: SubscriptionLinkForm[];
  errors?: FormErrors<SubscriptionLinkForm>;
  onItemsChange: (applications: SubscriptionLinkForm[]) => void;
}

export default function LinksForm({ items, errors, onItemsChange }: Props) {
  const { handleChange, handleDelete } = useMultilineForm(
    items,
    onItemsChange,
    DEFAULT_LINK
  );
  return (
    <>
      {items.map((link, index) => (
        <LinkForm
          key={index}
          value={link}
          // @ts-ignore
          errors={errors ? errors[index] : null}
          onValueChange={(link) => handleChange(link, index)}
          onDelete={() => handleDelete(index)}
        />
      ))}

      <div className="a-form-label">
        (Leave blank to avoid adding a new link)
      </div>
    </>
  );
}
