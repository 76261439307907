import React, { createContext } from "react";

import useServiceReducer from "../hooks/useServiceReducer";
import { ServiceReducer } from "../hooks/useServiceReducer";

export const AdminServiceContext = createContext(
  (undefined as any) as ServiceReducer
);

const AdminServiceContextHOC: React.FC<{}> = ({ children }) => {
  const reducer = useServiceReducer();
  return (
    <AdminServiceContext.Provider value={reducer}>
      {children}
    </AdminServiceContext.Provider>
  );
};

export default AdminServiceContextHOC;
