import "./MenuList.scss";

import { ListItem } from "appkit-react";
import MenuList from "components/MenuList";
import is3wService from "pages/admin/utils/is3wService";
import { SyntheticEvent } from "react";

interface Props {
  subscription: any;
  onAddUser: (subscription: any) => void;
  onEditSubscription: (subscription: any) => void;
  onDeleteSubscription: (subscription: any) => void;
  onNewsAccessRight: (subscription: any) => void;
  onDisplayDetails: (subscription: any) => void;
}

export default function EditList({
  subscription,
  onAddUser,
  onEditSubscription,
  onDeleteSubscription,
  onNewsAccessRight,
  onDisplayDetails,
}: Props) {
  return (
    <MenuList
      className="subscriptions-menu-list"
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      <ListItem
        onClick={(event: SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
          onDisplayDetails(subscription);
        }}
      >
        <span className="appkiticon icon-circle-more-fill" />
        Show details
      </ListItem>
      <ListItem
        onClick={(event: SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
          onAddUser(subscription);
        }}
      >
        <span className="appkiticon a-btn-icon icon-add-user-fill" />
        Add user to subscription
      </ListItem>
      <ListItem
        onClick={(event: SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
          onEditSubscription(subscription);
        }}
      >
        <span className="appkiticon a-btn-icon icon-edit-fill" />
        Edit
      </ListItem>
      {is3wService(subscription.serviceId) && (
        <ListItem
          onClick={(event: SyntheticEvent) => {
            event.preventDefault();
            event.stopPropagation();
            onNewsAccessRight(subscription);
          }}
        >
          <span className="appkiticon a-btn-icon icon-cogs-fill" />
          Manage news access rights
        </ListItem>
      )}

      <ListItem
        onClick={(event: SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
          onDeleteSubscription(subscription);
        }}
      >
        <span className="appkiticon a-btn-icon icon-delete-fill" />
        Delete
      </ListItem>
    </MenuList>
  );
}
