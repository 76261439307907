import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/es/array/from";
import "core-js/es/array/find";
import "core-js/es/array/includes";
import "core-js/es/array/find-index";
import "core-js/es/array/map";
import "core-js/es/object/assign";
import "core-js/es/promise";
import "core-js/es/map";
import "core-js/es/string/repeat";
import "core-js/es/string/pad-start";
import "core-js/es/string/pad-end";
import "core-js/es/string/starts-with";
import "whatwg-fetch";
import "blueimp-canvas-to-blob";
import "pwc-cookie/dist/pwc-cookie.css";

import "./index.scss";
import "./dark-theme.scss";

import {
  AuthenticationProvider,
  authenticateUser,
  getUserManager,
  oidcLog,
} from "@axa-fr/react-oidc-context";
import MatomoHOC from "components/MatomoHOC";
import PageTitle from "components/PageTitle";
import MuiTheme from "MuiTheme";
import * as PwcCookie from "pwc-cookie";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReactQueryProvider from "ReactQueryProvider";

import oidcConfig from "./api/auth/config";
// Please preserve the order of this import, changing this may cause style problems
import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";
import HTTPErrorBoundary from "./components/ErrorBoundary/httpErrorBoundary";
import OIDCMessage from "./components/OIDCMessage";
import store from "./redux/store/store";

//import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <ReactQueryProvider>
    <Provider store={store}>
      <AuthenticationProvider
        configuration={oidcConfig}
        loggerLevel={
          process.env.NODE_ENV === "development" ? oidcLog.WARN : oidcLog.NONE
        }
        authenticating={() => (
          <OIDCMessage
            title="Authentication in progress"
            subtitle="We're checking your login status..."
            timeoutButton={true}
          />
        )}
        sessionLostComponent={() => (
          <OIDCMessage
            title="Session expired"
            subtitle="Please sign in again"
            buttonLabel="Sign in"
            buttonAction={() =>
              authenticateUser(getUserManager(), window.location)
            }
          />
        )}
        callbackComponentOverride={() => {
          const oldOnPopState = window.onpopstate;
          window.onpopstate = () => {
            if (window.location.pathname === "/authentication/session-lost") {
              window.location.replace(
                window.location.search.substr(
                  window.location.search.indexOf("=") + 1
                )
              );
            } else if (
              window.location.pathname.indexOf("/authentication/") === 0
            ) {
              window.location.replace("/");
            }
            window.onpopstate = oldOnPopState;
          };
          return (
            <OIDCMessage
              title="Redirecting..."
              subtitle="Login successful. We're redirecting you to where you left off"
            />
          );
        }}
        notAuthenticated={() => (
          <OIDCMessage
            title="Access restricted"
            subtitle="You are not logged in"
            buttonLabel="Sign in"
            buttonAction={authenticateUser(getUserManager(), window.location)}
          />
        )}
        notAuthorized={() => (
          <OIDCMessage
            title="Access restricted"
            subtitle="You're not authorized to access this resource"
          />
        )}
      >
        <BrowserRouter>
          <MatomoHOC>
            <ErrorBoundary>
              <HTTPErrorBoundary>
                <MuiTheme>
                  <PageTitle />
                  <App />
                </MuiTheme>
              </HTTPErrorBoundary>
            </ErrorBoundary>
          </MatomoHOC>
        </BrowserRouter>
      </AuthenticationProvider>
    </Provider>
  </ReactQueryProvider>,
  document.getElementById("root")
);

window.cookieHandler = new PwcCookie.build();
window.cookieHandler.run();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
