import { ServiceGroupReadListDTO } from "api/catalog/__generated__/CatalogueAPI";
import DeleteModal from "components/DeleteModal";

interface Props {
  serviceGroup: ServiceGroupReadListDTO | null;
  onClose: () => void;
  onConfirmDelete: () => void;
}
export default function DeleteServiceGroupModal({
  serviceGroup,
  onClose,
  onConfirmDelete,
  ...modalProps
}: Props) {
  const submitDeleteServiceGroup = async () => {
    onConfirmDelete();
  };

  return (
    <DeleteModal
      visible={!!serviceGroup}
      className="delete-service-modal"
      onClose={onClose}
      title="Delete service group?"
      onConfirm={submitDeleteServiceGroup}
      {...modalProps}
    >
      <p>
        You're about to remove the service group {serviceGroup?.name}&nbsp;
        <strong>This action cannot be undone</strong>.
      </p>
    </DeleteModal>
  );
}
