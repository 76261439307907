import {
  ApplicationReadDTO,
  ContactReadDTO,
  SubscriptionDetailsReadDTO,
  SubscriptionReadDTO,
} from "api/admin/__generated__/UserManagementAPI";
import { CatalogueService } from "pages/catalogue/types";

export interface ECSModules {
  documents: boolean;
  tasks: boolean;
  links: boolean;
}

export interface ECModules {
  deliverables: boolean;
  documents: boolean;
}

export interface SubscriptionDetails {
  ec: string | null;
  ecs: string | null;
  clientContent: string | null;
  ecModules: ECModules | null;
  ecsModules: ECSModules | null;
  links: ApplicationReadDTO[];
  contacts: ContactReadDTO[];
  service?: CatalogueService;
  clientContacts: ContactReadDTO[];
}

export function toECModules(dto: Record<string, boolean> = {}): ECModules {
  if (dto === null) {
    return toECModules();
  }
  return {
    deliverables: !!dto.deliverables,
    documents: !!dto.documents,
  };
}

export function toECSModules(dto: Record<string, boolean> = {}): ECSModules {
  if (dto === null) {
    return toECSModules();
  }

  return {
    documents: !!dto.documents,
    links: !!dto.links,
    tasks: !!dto.tasks,
  };
}

export function toSubscriptionDetails(
  dto: SubscriptionDetailsReadDTO | SubscriptionReadDTO
): SubscriptionDetails {
  return {
    clientContent: dto.clientContent || null,
    ec: dto.ec,
    ecModules: toECModules(dto.ecModules),
    ecs: dto.ecs,
    ecsModules: toECSModules(dto.ecsModules),
    links: dto.applications || [],
    contacts: dto.contacts ?? [],
    clientContacts: [],
  };
}
