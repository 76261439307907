import "./index.scss";

import { FloatingActionButton, Tooltip } from "appkit-react";
import classNames from "classnames";
import ReactDOM from "react-dom";

const computeBottom = (position) => {
  return 18 + (45 + 18) * (position - 1);
};

const FAB_PORTAL_DIV = "fab-portal";

const FloatingActionButtonPortal = ({
  kind = "primary",
  disabled = false,
  loading = false,
  size = "sm",
  name,
  onClick,
  className = "",
  tooltipClassName = "",
  tooltip,
  position = 1,
  right = null,
}) => {
  const FAB = (
    <FloatingActionButton
      className={classNames(
        "a-fab-portal",
        tooltip ? "with-tooltip" : "",
        className ? className : ""
      )}
      style={{ bottom: computeBottom(position), right: right }}
      onClick={onClick}
      kind={kind}
      disabled={disabled}
      loading={loading.toString()}
      size={size}
      data-number={position}
    >
      <span className={classNames("appkiticon", "a-btn-icon", name)}></span>
    </FloatingActionButton>
  );

  if (tooltip) {
    return ReactDOM.createPortal(
      <Tooltip
        content={tooltip}
        placement="top"
        refClassName={classNames("a-fab-tooltip-portal", tooltipClassName)}
        mouseEnterDelay={500}
        refStyle={{ bottom: computeBottom(position), right: right }}
      >
        {FAB}
      </Tooltip>,
      document.getElementById(FAB_PORTAL_DIV)
    );
  } else {
    return ReactDOM.createPortal(FAB, document.getElementById(FAB_PORTAL_DIV));
  }
};

export default FloatingActionButtonPortal;
