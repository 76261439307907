import "./index.scss";

import { Button, ModalBody, ModalFooter, ModalHeader } from "appkit-react";
import classNames from "classnames";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import useSubmitable from "hooks/useSubmitable";
import { useCallback, useEffect, useRef } from "react";

const findErrors = (form) => {
  return {
    users: !form.users?.length,
  };
};

const DEFAULT_FORM = {
  users: [],
};

const AddUserToElementModal = ({
  visible,
  className = "",
  onCancel,
  onSubmit,
  Render,
}) => {
  const submitable = useSubmitable(DEFAULT_FORM, findErrors);
  const {
    values,
    handleValueChange,
    resetForm,
    submit,
    submitting,
    formErrors,
  } = submitable;

  const handleUserChange = useCallback(
    (value) => handleValueChange("users", value),
    [handleValueChange]
  );
  useEffect(() => {
    if (visible === false) {
      resetForm();
    }
  }, [visible, resetForm]);

  const handleCancel = () => {
    resetForm();
    onCancel();
  };

  const modalContainerRef = useRef();

  const handleSubmit = () =>
    submit((form) =>
      onSubmit({
        users: form.users,
      })
    );

  return (
    <ResizableAppkitModal
      backdropClosable={false}
      visible={visible}
      className={classNames("add-user-to-element-modal", className)}
      onCancel={handleCancel}
      ref={(_ref) => {
        modalContainerRef.current = _ref?.node;
      }}
    >
      <ModalHeader>
        <div className="modal-title">Please select user(s) to add</div>
      </ModalHeader>
      <ModalBody>
        <Render
          value={values.users}
          hasError={!!formErrors?.users}
          handleValueChange={handleUserChange}
          clickOutsideRef={modalContainerRef}
        />
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={handleCancel} disabled={submitting}>
          Cancel
        </Button>
        <Button isLoading={submitting} kind="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default AddUserToElementModal;
