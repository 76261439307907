import deepEqual from "deep-equal";
import { localeCompare } from "utils/localeCompare";

import { pollData, pollerCheck404, pollerCheckUndefined } from "../apiPoller";
import {
  CompanyCreateDTO,
  EntityCreateDTO,
  SubscriptionCreateDTO,
  SubscriptionDetailsReadDTO,
  SubscriptionFilters,
  SubscriptionOrderBy,
  SubscriptionUpdateDTO,
} from "./__generated__/UserManagementAPI";
import {
  addEngagement,
  addUserToEngagement,
  addUserToEntity,
  createCompany,
  createEntity,
  createUser,
  deleteEngagement,
  deleteEntity,
  deleteUser,
  deleteUserFromEngagement,
  getAllEntities,
  getCompanies,
  getSubscriptionDetails,
  getSubscriptionsUsersPaged,
  getUserDetails,
  removeUserFromEntity,
  updateCompany,
  updateEngagement,
  updateEntity,
  updateProfile,
  updateSuperUserStatus,
  updateUser,
  updateUserEngagement,
} from "./adminApi";
import { Company } from "./model/Company";
import { EntityUpdate } from "./model/Entity";
import { SubscriptionUpdate } from "./model/Subscription";

export const pollCheckCreatingUser = (ppid: string) => {
  return pollData(() => getUserDetails(ppid), pollerCheck404);
};

export const pollAddCompany = async (company: CompanyCreateDTO) => {
  const addedCompany = await createCompany(company);

  return await pollData(
    () =>
      new Promise((resolve, reject) => {
        return getCompanies().then((companies) => {
          companies.find((company) => company.id === addedCompany.id)
            ? resolve(addedCompany)
            : reject();
        });
      }),
    pollerCheckUndefined,
    0,
    addedCompany
  );
};

export const pollUpdateCompany = async (company: Company) => {
  await updateCompany(company);

  return await pollData(
    () =>
      new Promise((resolve, reject) => {
        return getCompanies().then((companies) => {
          companies.find((c) => c.id === company.id && c.name === company.name)
            ? resolve(company)
            : reject();
        });
      }),
    pollerCheckUndefined,
    0,
    company
  );
};

export const pollCreateUser = async (user: any) => {
  const createdUser = await createUser(user);
  return await pollData(
    () => getUserDetails(createdUser.ppid!),
    pollerCheck404,
    0,
    createdUser
  );
};

export const pollUpdateUser = async (user: any, initialUser: any) => {
  await updateUser(user);
  return await checkUpdateUserProfile(user, initialUser);
};

export const pollUpdateProfile = async (user: any, initialUser: any) => {
  await updateProfile(user);
  return await checkUpdateUserProfile(user, initialUser);
};

const checkUpdateUserProfile = async (user: any, initialUser: any) => {
  return await pollData(
    () =>
      new Promise((resolve, reject) => {
        getUserDetails(user.ppid)
          .then((userDetails) => {
            if (deepEqual(initialUser, userDetails)) {
              return reject();
            }
            resolve(userDetails);
          })
          .catch(reject);
      }),
    pollerCheckUndefined,
    0,
    initialUser
  );
};

export const pollDeleteUser = async (ppid: string) => {
  await deleteUser(ppid);

  await pollData(
    () =>
      new Promise<void>((resolve, reject) => {
        return getUserDetails(ppid)
          .then(() => reject())
          .catch((error) => {
            if (pollerCheck404(error)) {
              return resolve();
            }
            return reject(error);
          });
      }),
    pollerCheckUndefined
  );
};

export const pollUpdateSuperUserStatus = async (
  ppid: string,
  isSuperUser: boolean
) => {
  await updateSuperUserStatus(ppid, isSuperUser);
  return pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const userDetails = await getUserDetails(ppid);
        userDetails.isSuperUser === isSuperUser ? resolve(null) : reject();
      }),
    pollerCheckUndefined
  );
};

export const pollAddEngagement = async (engagement: SubscriptionCreateDTO) => {
  const newEngagement = await addEngagement(engagement);

  return await pollData(
    () => getSubscriptionDetails(newEngagement.id).then(() => newEngagement),
    pollerCheck404,
    0,
    newEngagement
  );
};

export const pollUpdateEngagement = async (
  subscription: SubscriptionUpdate
) => {
  await updateEngagement(subscription);

  return await pollData(
    () => {
      const { subscriptionId, ...data } = subscription;

      return new Promise(async (resolve, reject) => {
        const subscriptionDetails = await getSubscriptionDetails(
          subscriptionId
        );
        return isEqual(data, subscriptionDetails)
          ? resolve(subscriptionDetails)
          : reject();
      });
    },
    pollerCheckUndefined,
    0,
    subscription
  );
};

function isEqual(
  dto: SubscriptionUpdateDTO,
  subscription: SubscriptionDetailsReadDTO
) {
  return deepEqual(
    [
      dto.endDate ? new Date(dto.endDate).getTime() : null,
      dto.ec,
      dto.ecs,
      dto.clientContent,
      dto.ecModules,
      dto.ecsModules,
      dto.applications ? dto.applications.sort(localeCompare("name")) : null,
      dto.contacts?.sort(),
    ],
    [
      subscription.endDate ? new Date(subscription.endDate).getTime() : null,
      subscription.ec,
      subscription.ecs,
      subscription.clientContent,
      subscription.ecModules,
      subscription.ecsModules,
      subscription.applications
        ? subscription.applications.sort(localeCompare("name"))
        : null,
      subscription.contacts?.map((contact) => contact.ppid).sort(),
    ]
  );
}

export const pollDeleteEngagement = async (subscriptionId: string) => {
  await deleteEngagement(subscriptionId);

  return await pollData(
    () =>
      new Promise<void>((resolve, reject) => {
        getSubscriptionDetails(subscriptionId)
          .then(() => reject())
          .catch((error) => {
            if (pollerCheck404(error)) {
              return resolve();
            }
            return reject();
          });
      }),
    pollerCheckUndefined
  );
};

export const pollAddUserToEngagement = async (
  engagementId: string,
  ppids: string[]
) => {
  await addUserToEngagement(engagementId, ppids);

  return Promise.all(
    ppids.map((ppid) =>
      pollData(
        () =>
          new Promise(async (resolve, reject) => {
            const userInSubscription = await pollCheckUserInSubscription(
              ppid,
              engagementId
            );

            userInSubscription ? resolve(undefined) : reject();
          }),
        pollerCheckUndefined
      )
    )
  );
};

export const pollUpdateUserEngagement = async (
  engagementId: string,
  userPpid: string,
  isMainContact: boolean
) => {
  await updateUserEngagement(engagementId, userPpid, isMainContact);

  return pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const subscription = await getSubscriptionDetails(engagementId);
        subscription.users?.some(
          (user) =>
            user.ppid === userPpid && user.isMainContact === isMainContact
        )
          ? resolve(undefined)
          : reject();
      }),
    pollerCheckUndefined
  );
};

export const pollDeleteUserFromEngagement = async (
  engagementId: string,
  ppid: string
) => {
  await deleteUserFromEngagement(engagementId, ppid);

  return pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const userInSubscription = await pollCheckUserInSubscription(
          ppid,
          engagementId
        );

        userInSubscription ? reject() : resolve(undefined);
      }),
    pollerCheckUndefined
  );
};

async function pollCheckUserInSubscription(
  ppid: string,
  subscriptionId: string
): Promise<boolean> {
  const subscriptions = await getSubscriptionsUsersPaged(
    {
      filters: {
        memberPpid: ppid,
      } as SubscriptionFilters,
      orderBy: {} as SubscriptionOrderBy,
    },
    { pageNumber: 1, pageSize: 999 }
  );

  return !!subscriptions.find(
    (subscription) => subscription.id === subscriptionId
  );
}

export const pollAddEntity = async (entity: EntityCreateDTO) => {
  const newEntity = await createEntity(entity);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        return getAllEntities().then((entities) =>
          entities.find((e) => e.id === newEntity.id)
            ? resolve(newEntity)
            : reject()
        );
      }),
    pollerCheckUndefined,
    0,
    newEntity
  );
};

export const pollUpdateEntity = async (entity: EntityUpdate) => {
  await updateEntity(entity);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        return getAllEntities().then((entities) => {
          const updatedEntity = entities.find((e) => {
            const ppids = e.contacts?.map((c) => c.ppid!) ?? [];
            return (
              e.id === entity.entityId &&
              e.name?.toUpperCase() === entity.name?.toUpperCase() &&
              e.prid === entity.prid &&
              ppids?.every((p) => entity.contacts?.includes(p)) &&
              entity.contacts?.every((p) => ppids.includes(p)) &&
              e.viewInMyServices === entity.viewInMyServices
            );
          });

          updatedEntity ? resolve(updatedEntity) : reject();
        });
      }),
    pollerCheckUndefined,
    0,
    entity
  );
};

export const pollDeleteEntity = async (entityId: string) => {
  await deleteEntity(entityId);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        return getAllEntities().then((entities) => {
          const entityToDelete = entities.find((e) => e.id === entityId);
          entityToDelete ? reject() : resolve(undefined);
        });
      }),
    pollerCheckUndefined
  );
};

export const pollAddUserToEntity = async (
  entityId: string,
  ppids: string[]
) => {
  return addUserToEntity(entityId, ppids);
};

export const pollRemoveUserFromEntity = async (
  entityId: string,
  ppid: string
) => {
  return removeUserFromEntity(entityId, ppid);
};
