import "./index.scss";

import { getThreeWPermissions } from "api/news/engagementAccessApi";
import { pollUpdateThreeWPermissions } from "api/news/engagementAccessPoller";
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Switch,
} from "appkit-react";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import useNewsAdminInitialize from "pages/newsAdmin/hooks/useNewsAdminInitialize";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useSubmitable from "../../../../hooks/useSubmitable";
import MultiSelect from "../MultiSelect";

const defaultForm = {
  allTerritories: false,
  territories: [],
  allTopics: false,
  topics: [],
  deniedTopics: [],
  allDocumentTypes: false,
  documentTypes: [],
  deniedDocumentTypes: [],
};

const findFormErrors = (form) => {
  return {
    territories:
      !form.allTerritories &&
      form.territories.length === 0 &&
      "Please select at least one territory",
    topics:
      !form.allTopics &&
      form.topics.length === 0 &&
      "Please select at least one topic",
    documentTypes:
      !form.allDocumentTypes &&
      form.documentTypes.length === 0 &&
      "Please select at least one document type",
  };
};

const toPermissions = (form) => {
  return {
    territoryIds: form.allTerritories
      ? null
      : form.territories.map(({ id }) => id),
    topicIds: form.allTopics ? null : form.topics.map(({ id }) => id),
    deniedTopicIds: form.allTopics
      ? null
      : form.deniedTopics.map(({ id }) => id),
    documentTypeIds: form.allDocumentTypes
      ? null
      : form.documentTypes.map(({ id }) => id),
    deniedDocumentTypeIds: form.allDocumentTypes
      ? null
      : form.deniedDocumentTypes.map(({ id }) => id),
  };
};

const ThreeWPermissionsModal = ({ engagement = null, onClose }) => {
  const initialized = useNewsAdminInitialize();
  const {
    territories,
    territoriesById,
    topics,
    topicsById,
    documentTypes,
    documentTypesById,
  } = useSelector((state) => state.articles.data);

  const [oldPermissions, setOldPermissions] = useState();

  const visible = !!engagement;

  const {
    values: form,
    handleValueChange,
    resetForm,
    submitting,
    formErrors,
    submit,
  } = useSubmitable(defaultForm, findFormErrors);

  const handleSubmit = async () => {
    await submit(() => {
      return pollUpdateThreeWPermissions(
        engagement.id,
        { ...toPermissions(form), engagementId: engagement.id },
        oldPermissions
      ).then(onClose);
    });
  };

  useEffect(() => {
    if (!visible) {
      resetForm();
    }
  }, [visible, resetForm]);

  useEffect(() => {
    if (!(engagement && initialized)) {
      return;
    }

    getThreeWPermissions(engagement.id).then((permissions) => {
      setOldPermissions(permissions);

      const form = {
        allTerritories: permissions.territoryIds === null,
        territories: permissions.territoryIds
          ? permissions.territoryIds.map((id) => territoriesById[id])
          : defaultForm.territories,
        allTopics: permissions.topicIds === null,
        topics: permissions.topicIds
          ? permissions.topicIds.map((id) => topicsById[id])
          : defaultForm.topics,
        deniedTopics: permissions.deniedTopicIds
          ? permissions.deniedTopicIds.map((id) => topicsById[id])
          : defaultForm.deniedTopics,

        allDocumentTypes: permissions.documentTypeIds === null,
        documentTypes: permissions.documentTypeIds
          ? permissions.documentTypeIds.map((id) => documentTypesById[id])
          : defaultForm.documentTypes,
        deniedDocumentTypes: permissions.deniedDocumentTypeIds
          ? permissions.deniedDocumentTypeIds.map((id) => documentTypesById[id])
          : defaultForm.documentTypes,
      };

      resetForm(form);
    });
  }, [
    engagement,
    initialized,
    territoriesById,
    topicsById,
    documentTypesById,
    resetForm,
  ]);

  return (
    <ResizableAppkitModal
      backdropClosable={false}
      visible={visible}
      className="threew-permissions-modal"
      onCancel={onClose}
    >
      <ModalHeader>
        <div className="modal-title">Manage 3W Permissions</div>
      </ModalHeader>
      <ModalBody>
        {!!initialized && (
          <>
            <div>
              <div className="switch-label-wrapper">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Territories
                </label>
                <Switch
                  onText="On"
                  offText="Off"
                  checked={form.allTerritories}
                  onChange={(value) =>
                    handleValueChange("allTerritories", value)
                  }
                  label="All territories"
                />
              </div>
              <MultiSelect
                items={territories}
                selection={form.allTerritories ? territories : form.territories}
                onSelectionChange={(territories) =>
                  handleValueChange("territories", territories)
                }
                hasError={!!formErrors.territories}
                errorMsg={formErrors.territories}
                disabled={form.allTerritories}
              />
            </div>

            <div>
              <div className="switch-label-wrapper">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Topics
                </label>
                <Switch
                  onText="On"
                  offText="Off"
                  checked={form.allTopics}
                  onChange={(value) => handleValueChange("allTopics", value)}
                  label="All topics"
                />
              </div>
              <MultiSelect
                items={topics}
                selection={form.allTopics ? topics : form.topics}
                onSelectionChange={(topics) =>
                  handleValueChange("topics", topics)
                }
                denied={form.allTopics ? [] : form.deniedTopics}
                onDeniedChange={(deniedTopics) =>
                  handleValueChange("deniedTopics", deniedTopics)
                }
                hasError={!!formErrors.topics}
                errorMsg={formErrors.topics}
                disabled={form.allTopics}
              />
            </div>

            <div>
              <div className="switch-label-wrapper">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Document Types
                </label>

                <Switch
                  onText="On"
                  offText="Off"
                  checked={form.allDocumentTypes}
                  onChange={(value) =>
                    handleValueChange("allDocumentTypes", value)
                  }
                  label="All doc types"
                />
              </div>
              <MultiSelect
                items={documentTypes}
                selection={
                  form.allDocumentTypes ? documentTypes : form.documentTypes
                }
                onSelectionChange={(documentTypes) =>
                  handleValueChange("documentTypes", documentTypes)
                }
                denied={form.allDocumentTypes ? [] : form.deniedDocumentTypes}
                onDeniedChange={(deniedDocumentTypes) =>
                  handleValueChange("deniedDocumentTypes", deniedDocumentTypes)
                }
                hasError={!!formErrors.documentTypes}
                errorMsg={formErrors.documentTypes}
                disabled={form.allDocumentTypes}
              />
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onClose} disabled={submitting}>
          Cancel
        </Button>
        <Button kind="primary" isLoading={submitting} onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default ThreeWPermissionsModal;
