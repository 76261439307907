import { getAdminCatalogue } from "api/admin/adminApi";
import {
  pollCreateService,
  pollRemoveService,
  pollUpdateDisplayStatus,
  pollUpdateIsHighlighted,
  pollUpdateIsNew,
  pollUpdateService,
} from "api/catalog/catalogPoller";
import { adminGetTenants } from "api/tenant/tenantApi";
import {
  Action,
  fetchList,
  fetchListSuccess,
  itemAdded,
  itemDeleted,
  itemUpdated,
} from "pages/admin/actions";
import adminListReducer, { initialState } from "pages/admin/actions/reducer";
import { State } from "pages/admin/actions/type";
import { Reducer, useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upsertTenants } from "redux/actions/global";

import { ServiceItem, toServiceItem } from "../type";
import {
  ServiceForm,
  toServiceCreateDTO,
  toServiceUpdateDTO,
} from "../type/form";

type AdminServiceState = State<ServiceItem>;
type AdminServiceAction = Action<ServiceItem>;

export interface ServiceReducer extends State<ServiceItem> {
  upsertService: (form: ServiceForm) => Promise<ServiceItem>;
  deleteService: (service: ServiceItem) => Promise<void>;
  patchDisplayed: (service: ServiceItem, displayed: boolean) => Promise<void>;
  patchIsNew: (service: ServiceItem, isNew: boolean) => Promise<void>;
  patchIsHighlighted: (
    service: ServiceItem,
    isHightlighted: boolean
  ) => Promise<void>;
}

export default function useServiceReducer(): ServiceReducer {
  const tenants = useSelector((state) => (state as any).global.data.tenants);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (tenants === null) {
      adminGetTenants().then((data) => reduxDispatch(upsertTenants(data)));
    }
  }, [tenants, reduxDispatch]);

  const [state, dispatch] = useReducer<
    Reducer<AdminServiceState, AdminServiceAction>
  >(adminListReducer, {
    ...initialState,
  } as State<ServiceItem>);

  const fetchAdminCatalog = useCallback(async (): Promise<ServiceItem[]> => {
    dispatch(fetchList());

    const dtos = await getAdminCatalogue();
    const services = dtos.map(toServiceItem);
    dispatch(fetchListSuccess(services));

    return services;
  }, []);

  const upsertService = useCallback(
    async (service: ServiceForm): Promise<ServiceItem> => {
      let upsertedService;

      if (!service.id) {
        const addedDTO = await pollCreateService(toServiceCreateDTO(service));

        upsertedService = toServiceItem({
          ...addedDTO,
          canAddSubscription: true,
          domains: addedDTO.domains ?? [],
        });
        dispatch(itemAdded(upsertedService));
      } else {
        const updatedDTO = await pollUpdateService(
          toServiceUpdateDTO(service),
          service.id
        );

        upsertedService = toServiceItem({
          ...updatedDTO,
          canAddSubscription: true,
          domains: updatedDTO.domains ?? [],
        });
        dispatch(itemUpdated(upsertedService));
      }

      return upsertedService;
    },
    []
  );

  const deleteService = useCallback(async (service: ServiceItem) => {
    await pollRemoveService(service.id);
    dispatch(itemDeleted(service));
  }, []);

  const patchDisplayed = useCallback(
    async (service: ServiceItem, live: boolean) => {
      await pollUpdateDisplayStatus(service.id, live);
      dispatch(
        itemUpdated({
          ...service,
          live,
        })
      );
    },
    []
  );

  const patchIsNew = useCallback(
    async (service: ServiceItem, isNew: boolean) => {
      await pollUpdateIsNew(service.id, isNew);
      dispatch(
        itemUpdated({
          ...service,
          isNew,
        })
      );
    },
    []
  );

  const patchIsHighlighted = useCallback(
    async (service: ServiceItem, isHighlighted: boolean) => {
      await pollUpdateIsHighlighted(service.id, isHighlighted);
      dispatch(
        itemUpdated({
          ...service,
          isHighlighted,
        })
      );
    },
    []
  );

  useEffect(() => {
    fetchAdminCatalog();
  }, [fetchAdminCatalog]);

  return {
    ...state,
    upsertService,
    deleteService,
    patchDisplayed,
    patchIsNew,
    patchIsHighlighted,
  };
}
