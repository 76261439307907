import { imageFromBlob } from "api/utils";

import { RET_RAW, apiFetch, apiFetchFormData } from "../api";
import { adaptDataToBackend } from "./publicationsManipulators";

const PREFIX = process.env.REACT_APP_URL_NEWS_ROOT + "Publication/";

export const getList = (filters, { pageNumber = 1, pageSize = 20 }) => {
  return apiFetch(
    `${PREFIX}paged?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    filters,
    "POST"
  );
};

export const getPublication = (id) => {
  return apiFetch(`${PREFIX}${id}`);
};

export const deletePublication = (id) => {
  return apiFetch(`${PREFIX}${id}`, undefined, "DELETE");
};

export const updatePublication = async (publication) => {
  return apiFetchFormData(
    `${PREFIX}${publication.id}`,
    adaptDataToBackend(publication),
    "PUT"
  );
};

export const createPublication = (publication) => {
  return apiFetchFormData(`${PREFIX}`, adaptDataToBackend(publication), "POST");
};

export const publishPublication = async (id, publish) => {
  return apiFetch(`${PREFIX}${id}/publish`, publish.toString(), "PATCH");
};

export const getImage = (imageUrl) => {
  return apiFetch(`${PREFIX}${imageUrl}`, undefined, "GET", RET_RAW)
    .then((data) => data.blob())
    .then(imageFromBlob);
};
