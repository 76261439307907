import {
  ValueChainAllReadDTO,
  ValueChainReadDTO,
} from "api/catalog/__generated__/CatalogueAPI";
import { useValueChainsAll } from "api/catalog/queries/useValueChains";
import { SelectOption } from "appkit-react";
import Select from "components/Select";
import { sortBy } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";

interface IValueChainSelectProps {
  tenant?: string;
  value: string[];
  onValueChange: (value: string[]) => void;
  hasError?: boolean;
  errMsg?: any;
  placeholder?: string;
}

const ValueChainSelect = ({
  tenant,
  value,
  onValueChange,
  ...selectProps
}: IValueChainSelectProps) => {
  const tenants = useSelector((state) => (state as any).global.data.tenants);
  const { data: valueChainsAll = {} as ValueChainAllReadDTO } =
    useValueChainsAll();
  const valueChainsAllSorted = useMemo(() => {
    return Object.entries(valueChainsAll).reduce((acc, [key, value]) => {
      acc[key] = sortBy(value, (item) => item.order);
      return acc;
    }, {} as ValueChainAllReadDTO);
  }, [valueChainsAll]);

  const valueChains: ValueChainReadDTO[] = useMemo(() => {
    if (tenant !== undefined) {
      return valueChainsAllSorted[tenant] ?? [];
    }
    return Object.entries(valueChainsAllSorted).reduce((acc, [key, value]) => {
      return [...acc, ...value];
    }, [] as ValueChainReadDTO[]);
  }, [tenant, valueChainsAllSorted]);

  return (
    <Select
      multiple
      placeholder="Select a Value chain"
      showSearchOnToggle
      value={value}
      onSelect={(values: string[]) => {
        if (typeof values === "string") {
          onValueChange(values);
          return;
        }
        const isAdd = values.length > value.length;
        if (isAdd) {
          const [addedValue] = values.filter((item) => !value.includes(item));
          const valuesToCommit = values.filter((item) => {
            if (
              !valueChains
                .map((valuechain) => valuechain.name)
                .includes(item) &&
              item !== addedValue
            ) {
              return false;
            }
            return true;
          });
          onValueChange(valuesToCommit);
        } else {
          onValueChange(values);
        }
      }}
      {...selectProps}
    >
      {valueChains?.map((valueChain) => (
        <SelectOption value={valueChain.name} key={valueChain.id}>
          {valueChain.name}
          {tenant === undefined &&
            ` (${
              tenants.find(
                (t: any) => t.domain.toUpperCase() === valueChain.domain
              )?.name
            })`}
        </SelectOption>
      ))}
    </Select>
  );
};

export default ValueChainSelect;
