import "./index.scss";

import CalendarPicker from "appkit-react-datepicker/lib/CalendarPicker";
import classNames from "classnames";

const ClearableCalendarPicker = ({
  onClearValue,
  hasError = false,
  errMsg = undefined,
  ...props
}) => {
  return (
    <div className="a-clearable-calendar-picker">
      <div className="a-clearable-calendar-picker-input">
        <CalendarPicker
          {...props}
          className={classNames(props.className, hasError && "errored")}
        />
        {props.value && onClearValue && (
          <span
            onClick={onClearValue}
            className="appkiticon icon-circle-delete-outline clear-button"
          />
        )}
      </div>
      {hasError && errMsg && typeof errMsg === "string" ? (
        <p className="a-form-error">{errMsg}</p>
      ) : null}
    </div>
  );
};

export default ClearableCalendarPicker;
