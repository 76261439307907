import { EntityReadDTO } from "api/admin/__generated__/UserManagementAPI";
import { PopBanner } from "appkit-react";
import DeleteModal from "components/DeleteModal";
import { useContext } from "react";

import { AdminEntityContext } from "..";

interface Props {
  entity: EntityReadDTO | null;
  onClose: () => void;
}
export default function DeleteSerciceModal({
  entity,
  onClose,
  ...modalProps
}: Props) {
  const { deleteEntity } = useContext(AdminEntityContext);

  const submitDeleteEngagement = async () => {
    if (!entity) return;

    await deleteEntity(entity);

    PopBanner({
      content: `The entity ${entity.name} has been successfully deleted.`,
      duration: 4000,
      status: "success",
    });

    onClose();
  };

  return (
    <DeleteModal
      visible={!!entity}
      className="delete-entity-modal"
      onClose={onClose}
      title="Delete entity ?"
      onConfirm={submitDeleteEngagement}
      {...modalProps}
    >
      <p>
        You're about to remove the entity {entity?.name}&nbsp;
        <strong>This action cannot be undone</strong>.
      </p>
    </DeleteModal>
  );
}
