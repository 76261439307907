import "./searchbar.scss";

import { Input } from "appkit-react";

const SearchBar = ({ className = "", ...inputProps }) => (
  <Input
    className={`${className} search-bar`}
    prefix={
      <span className="appkiticon icon-search-outline baseline-search-icon" />
    }
    placeholder="Search"
    {...inputProps}
  />
);

export default SearchBar;
