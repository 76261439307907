import { refreshUser } from "api/admin/adminApi";
import {
  Badge,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopBanner,
} from "appkit-react";
import classnames from "classnames";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import { useState } from "react";

const RefreshUserModal = ({ user, className, onCancel, ...modalProps }) => {
  const [refreshing, setRefreshing] = useState(false);

  const onClose = () => {
    if (!refreshing) {
      onCancel();
    }
  };

  const refresh = () => {
    setRefreshing(true);
    refreshUser(user.id)
      .then(() => {
        PopBanner({
          content:
            "The user will be refreshed. Please check back in a couple of minutes.",
          closeable: true,
          className: "a-banner-success",
        });
        onCancel();
      })
      .finally(() => setRefreshing(false));
  };

  return (
    <ResizableAppkitModal
      className={classnames("delete-modal", className)}
      onCancel={onCancel}
      visible={!!user}
      {...modalProps}
    >
      <ModalHeader>
        <Badge type={"warning"}>INFORMATION</Badge>
        <span className="title">Refresh user data</span>
      </ModalHeader>
      <ModalBody>
        <p>
          You're about to refresh <strong>{user ? user.name : "a user"}</strong>
          . This will update the user's information such as his phone number and
          grade. The refresh could take a couple of minutes to appear in the
          application.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onClose} disabled={refreshing}>
          Cancel
        </Button>
        <Button onClick={refresh} isLoading={refreshing}>
          Refresh data
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default RefreshUserModal;
