import "./index.scss";

import {
  pollAddServiceGroup,
  pollEditServiceGroup,
} from "api/catalog/catalogPoller";
import useCatalogueHomepageDetails from "api/catalog/queries/useCatalogueHomepageDetails";
import useServiceGroupById from "api/catalog/queries/useServiceGroupById";
import {
  Button,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "appkit-react";
import { SelectOption } from "appkit-react";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import Select from "components/Select";
import ValidationTextarea from "components/Textarea";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

import useSubmitable from "../../../../../hooks/useSubmitable";
import ServiceSelect from "../../Engagement/AddEngagementModal/ServiceSelect";
import LifeCycleSelect from "../../Service/components/AddServiceModal/LifeCycleSelect";
import ValueChainSelect from "../../Service/components/AddServiceModal/ValueChainSelect/ValueChainSelect";

const DEFAULT_FORM = {
  name: "",
  domain: "",
  smallDescription: "",
  lifeCycle: "",
  valueChain: "",
  services: [],
};

const findFormErrors = (form) => {
  return {
    name: form.name.trim().length === 0,
  };
};

const AddServiceGroupModal = ({
  serviceGroupId,
  visible,
  onClose,
  onServiceGroupChanged,
}) => {
  const { data: homepage } = useCatalogueHomepageDetails();

  const { data: serviceGroup } = useServiceGroupById({
    serviceGroupId,
    queryOptions: {
      enabled: !!serviceGroupId && visible,
      select: (data) => ({
        ...data,
        services: data.services.map((service) => service.id),
      }),
    },
  });
  const { mutate: addServiceGroup, isLoading: submittingAddService } =
    useMutation(
      ({ form }) => {
        return pollAddServiceGroup(form);
      },
      {
        onSuccess: () => {
          onServiceGroupChanged();
        },
      }
    );
  const { mutate: editServiceGroup, isLoading: submittingEditService } =
    useMutation(
      ({ id, form }) => {
        return pollEditServiceGroup(id, form);
      },
      {
        onSuccess: () => {
          onServiceGroupChanged();
        },
      }
    );

  const submitting = submittingAddService || submittingEditService;

  const {
    values: form,
    handleValueChange,
    formErrors,
    resetForm,
  } = useSubmitable(DEFAULT_FORM, findFormErrors);

  useEffect(() => {
    resetForm(serviceGroup || DEFAULT_FORM);
  }, [serviceGroup, resetForm]);

  const handleSubmit = () => {
    if (serviceGroupId) {
      editServiceGroup({ id: serviceGroupId, form });
    } else {
      addServiceGroup({ form });
    }
  };

  useEffect(() => {
    if (!visible) {
      resetForm();
    }
  }, [visible, resetForm]);

  const tenants = useSelector((state) => state.global.data.tenants);

  return (
    <ResizableAppkitModal
      visible={visible}
      className="add-service-group-modal"
      onCancel={onClose}
      backdropClosable={false}
      width={500}
      height={500}
    >
      <ModalHeader>
        <div className="modal-title">
          {serviceGroup ? "Edit service group" : "Create service group"}
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-12">
            <label className="a-form-label">
              <span className="a-color-primary">*</span>&nbsp;Service group name
            </label>
            <Input
              value={form.name}
              onChange={(value) => handleValueChange("name", value)}
              placeholder="Company group name"
              hasError={!!formErrors.name}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="a-form-label">Service short description</label>
            <ValidationTextarea
              rows={4}
              value={form.smallDescription}
              onChange={(value) => handleValueChange("smallDescription", value)}
              placeholder="Service short description"
              hasError={!!formErrors.smallDescription}
              errMsg={
                typeof formErrors.smallDescription === "string"
                  ? formErrors.smallDescription
                  : undefined
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="a-form-label">
              <span className="a-color-primary">*</span>&nbsp;Domain
            </label>
            <Select
              placeholder="Select a domain"
              showSearchOnToggle
              value={form.domain}
              onSelect={(value) => {
                handleValueChange("domain", value);
                handleValueChange("lifeCycle", "");
                handleValueChange("valueChain", "");
              }}
            >
              {tenants.map((tenant) => (
                <SelectOption
                  key={tenant.id}
                  value={tenant.domain.toUpperCase()}
                >
                  {tenant.name}
                </SelectOption>
              ))}
            </Select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="a-form-label">
              <span className="a-color-primary">*</span>&nbsp;
              {homepage?.secondaryFilter}
            </label>
            <LifeCycleSelect
              multiple={false}
              tenant={form.domain}
              value={form.lifeCycle}
              onValueChange={(value) => handleValueChange("lifeCycle", value)}
              hasError={!!formErrors.lifeCycle}
              placeholder={"Select a " + homepage?.secondaryFilter}
              errMsg={
                typeof formErrors.lifeCycle === "string"
                  ? formErrors.lifeCycle
                  : undefined
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <label className="a-form-label">
              <span className="a-color-primary">*</span>&nbsp;
              {homepage?.tertiaryFilter}
            </label>
            <ValueChainSelect
              multiple={false}
              tenant={form.domain}
              value={form.valueChain}
              onValueChange={(value) => handleValueChange("valueChain", value)}
              placeholder={"Select a " + homepage?.tertiaryFilter}
              hasError={!!formErrors.valueChain}
              errMsg={
                typeof formErrors.valueChain === "string"
                  ? formErrors.valueChain
                  : undefined
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="a-form-label">Services</label>
            <ServiceSelect
              multiple
              value={form.services}
              onValueChange={(value) => handleValueChange("services", value)}
              placeholder={"Select some services"}
              hasError={!!formErrors.services}
              errMsg={
                typeof formErrors.services === "string"
                  ? formErrors.services
                  : undefined
              }
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onClose} disabled={submitting}>
          Cancel
        </Button>
        <Button kind="primary" isLoading={submitting} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default AddServiceGroupModal;
