import { PopBanner } from "appkit-react";
import DeleteModal from "components/DeleteModal";
import { useContext } from "react";

import { ServiceItem } from "../../type";
import { AdminServiceContext } from "../AdminServiceContextHOC";

interface Props {
  service: ServiceItem | null;
  onClose: () => void;
}
export default function DeleteSerciceModal({
  service,
  onClose,
  ...modalProps
}: Props) {
  const { deleteService } = useContext(AdminServiceContext);

  const submitDeleteEngagement = async () => {
    if (!service) return;

    await deleteService(service);

    PopBanner({
      content: `The service ${service.name} has been successfully deleted.`,
      duration: 4000,
      status: "success",
    });

    onClose();
  };

  return (
    <DeleteModal
      visible={!!service}
      className="delete-service-modal"
      onClose={onClose}
      title="Delete service ?"
      onConfirm={submitDeleteEngagement}
      {...modalProps}
    >
      <p>
        You're about to remove the service {service?.name}&nbsp;
        <strong>This action cannot be undone</strong>.
      </p>
    </DeleteModal>
  );
}
