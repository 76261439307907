import React from "react";

import useEntityReducer from "./hooks/useEntityReducer";

export const AdminEntityContext = React.createContext();

const AdminEntityContextHOC = ({ children }) => {
  const reducer = useEntityReducer();
  return (
    <AdminEntityContext.Provider value={reducer}>
      {children}
    </AdminEntityContext.Provider>
  );
};

export default AdminEntityContextHOC;
