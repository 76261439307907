import { UseTypedQueryOptions } from "api/UseTypedQueryOptions";
import { UseQueryResult, useQuery } from "react-query";

import { ServiceReadDTO } from "../__generated__/CatalogueAPI";
import { getCatalogue } from "../catalogApi";

export default function useLiveService(
  props?: UseTypedQueryOptions<ServiceReadDTO[]>
): UseQueryResult<ServiceReadDTO[]> {
  return useQuery("service/live", () => getCatalogue(), props);
}
