export const isValidUrl = (href) => {
  if (typeof href !== "string") {
    return false;
  }
  const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
  return href.match(new RegExp(expression)) !== null;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getInitialsFromName = (name) => {
  let initials = name
    .trim()
    .split(/ +/)
    .map((n) => n[0].toUpperCase())
    .join("");
  if (initials.length > 3) {
    initials = initials.substr(0, 2) + initials.substr(initials.length - 1, 1);
  }
  return initials;
};

export const downloadFromResponse = async (data, defaultFilename) => {
  const blob = await data.blob();

  let filename = defaultFilename;
  const contentDisposition = data.headers.get("Content-Disposition");
  if (!!contentDisposition) {
    const regexp = /filename=(.*);/;
    const matchers = contentDisposition.match(regexp);
    if (matchers[1]) {
      filename = matchers[1];
    }
  }

  downloadBlob(blob, filename);
};

export const downloadBlob = (blob, name) => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
};
