import React from "react";

import useCompanyReducer from "./hooks/useCompanyReducer";

export const AdminCompanyContext = React.createContext();

const AdminCompanyContextHOC = ({ children }) => {
  const reducer = useCompanyReducer();
  return (
    <AdminCompanyContext.Provider value={reducer}>
      {children}
    </AdminCompanyContext.Provider>
  );
};

export default AdminCompanyContextHOC;
