import HandledPromiseRejectionError from "exceptions/handledPromiseRejectionError";

export const imageFromBlob = (blob: any): any => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.onerror = function () {
      throw new HandledPromiseRejectionError("Corrupted image received");
    };
  });
};
