import { PopBanner } from "appkit-react";
import { useContext } from "react";

import DeleteUserFromElementModal from "../../RemoveUserFromElementModal";
import { AdminEntityContext } from "..";

const DeleteUserFromEntityModal = ({ user, onClose, ...modalProps }) => {
  const { removeUserFromEntity } = useContext(AdminEntityContext);
  const submitDeleteUser = () => {
    return removeUserFromEntity(user.parentId, user.ppid).then(() => {
      PopBanner({
        content: `The user ${user.name} has been successfully deleted from the entity.`,
        duration: 4000,
        status: "success",
      });

      onClose();
    });
  };

  return (
    <DeleteUserFromElementModal
      visible={user !== null}
      userName={user?.name}
      elementType="entity"
      onSubmit={submitDeleteUser}
      onCancel={onClose}
      secondMessage={
        <strong>
          Be careful that you will also remove the user from all his/her
          subscriptions
        </strong>
      }
      {...modalProps}
    />
  );
};

export default DeleteUserFromEntityModal;
