import "./table.scss";

import { Table } from "appkit-react";
import classnames from "classnames";
import { forwardRef } from "react";

import ImageTitle from "../ImageTitle";
import { Expanded, LoadingComponent } from "./";

export const computeExpandedRows = (expanded) => {
  return Object.keys(expanded)
    .filter((expandedIndex) => expanded[expandedIndex] !== false)
    .map(Number);
};

const PWCTable = forwardRef(
  (
    {
      className,
      expanded,
      onExpandedChange,
      getTrProps: trProps,
      SubComponent,
      pivotBy,
      loading = false,
      ...tableProps
    },
    ref
  ) => {
    return (
      <Table
        ref={ref}
        striped={false}
        className={classnames("pwc-table", className ? className : "")}
        NoDataComponent={() =>
          !loading ? <ImageTitle /> : <div className="no-data-spacer" />
        }
        LoadingComponent={() => loading && <LoadingComponent />}
        expanded={expanded}
        onExpandedChange={onExpandedChange}
        pivotBy={pivotBy}
        ExpanderComponent={({ isExpanded }) => (
          <Expanded isExpanded={isExpanded} />
        )}
        SubComponent={SubComponent}
        getTheadProps={() => {
          return {
            className: classnames(
              SubComponent && "thead-subcomponent",
              pivotBy && pivotBy.length && "expandable-header"
            ),
          };
        }}
        getTrProps={(state, rowInfo, column) => {
          const {
            onClick,
            className: trClassName,
            ...getTrProps
          } = trProps ? trProps(state, rowInfo, column) : {};
          return {
            onClick: ({ ...args }) => {
              if (!!onExpandedChange) {
                // Manage row expansion
                if (
                  SubComponent ||
                  (rowInfo.subRows && rowInfo.subRows.length)
                ) {
                  const expandedRows = computeExpandedRows(state.expanded);
                  const rowIsExpanded = expandedRows.includes(
                    rowInfo.nestingPath[0]
                  );

                  onExpandedChange({
                    ...state.expanded,
                    [rowInfo.nestingPath[0]]: rowIsExpanded ? false : {},
                  });
                }
              }

              onClick && onClick.apply(this, args);
            },
            className: classnames(
              (SubComponent ||
                (rowInfo.subRows && rowInfo.subRows.length > 0)) &&
                "expandable-row",
              (SubComponent ||
                (rowInfo.subRows && rowInfo.subRows.length > 0)) &&
                computeExpandedRows(expanded).includes(
                  rowInfo.nestingPath[0]
                ) &&
                "expanded-row",
              trClassName
            ),
            ...getTrProps,
          };
        }}
        {...tableProps}
      />
    );
  }
);

export default PWCTable;
