import { getCompanies } from "api/admin/adminApi";
import { pollAddCompany, pollUpdateCompany } from "api/admin/adminPoller";
import {
  fetchList,
  fetchListSuccess,
  itemAdded,
  itemUpdated,
} from "pages/admin/actions";
import adminReducer, { initialState } from "pages/admin/actions/reducer";
import { useCallback, useEffect, useReducer } from "react";

export default function useCompanyReducer() {
  const [state, dispatch] = useReducer(adminReducer, initialState);

  const fetchCompanyList = useCallback(async () => {
    dispatch(fetchList());

    const companies = await getCompanies();
    dispatch(fetchListSuccess(companies));
  }, []);

  const setCompanyList = useCallback((companies) => {
    dispatch(fetchListSuccess(companies));
  }, []);

  const addCompany = useCallback((form) => {
    return pollAddCompany(form).then((company) => {
      dispatch(itemAdded(company));
      return company;
    });
  }, []);

  const updateCompany = useCallback((form) => {
    return pollUpdateCompany(form).then((company) => {
      dispatch(itemUpdated(company));
      return company;
    });
  }, []);

  useEffect(() => {
    fetchCompanyList();
  }, [fetchCompanyList]);

  return {
    ...state,
    fetchCompanyList,
    setCompanyList,
    addCompany,
    updateCompany,
  };
}
