import { pollDeleteUser } from "api/admin/adminPoller";
import classnames from "classnames";
import DeleteModal from "components/DeleteModal";
const DeleteUserModal = ({
  user,
  className,
  onCancel,
  onDelete,
  ...modalProps
}) => {
  const deleteUser = async () => {
    await pollDeleteUser(user.ppid);
    onDelete(user.ppid);
  };

  return (
    <DeleteModal
      className={classnames("delete-user-modal", className)}
      visible={!!user}
      onClose={onCancel}
      onConfirm={deleteUser}
      title="Delete User ?"
      {...modalProps}
    >
      <p>
        You're about to remove <strong>{user ? user.name : "a user"}</strong>
        .&nbsp;
        <strong>This action cannot be undone</strong>.
      </p>
    </DeleteModal>
  );
};

export default DeleteUserModal;
