import {
  AssignedUserReadDTO,
  ContactReadDTO,
  SubscriptionCreateDTO,
  SubscriptionDetailsReadDTO,
} from "api/admin/__generated__/UserManagementAPI";
import { toUTCISODateString } from "api/helpers";
import { FormErrors } from "hooks/useSubmitable";
import { isValidGuid } from "utils/random";

import { findApplicationErrors } from "../../Service/type/form";
import { ECModules, ECSModules, toECModules, toECSModules } from "./";

export interface SubscriptionLinkForm {
  name: string;
  link: string;
  showToClient: boolean;
}

export interface SubscriptionForm {
  id?: string | null;
  companyId: string | null;
  entityId: string | null;
  serviceId: string | null;
  endDate: Date | null;
  clientContent: string;
  hasECLink: boolean;
  ec: string;
  ecModules: ECModules;
  hasECSLink: boolean;
  ecs: string;
  ecsModules: ECSModules;
  links: SubscriptionLinkForm[];
  contacts: ContactReadDTO[];
  users: AssignedUserReadDTO[];
}

type SubscriptionFormError = {
  [Property in keyof SubscriptionForm]?:
    | FormErrors<any>
    | string
    | boolean
    | null;
};

export const findFormErrors = (
  form: SubscriptionForm,
  isEditMode: boolean
): SubscriptionFormError => {
  return {
    companyId: form.companyId === null,
    entityId: form.entityId === null,
    serviceId: form.serviceId === null,
    ec:
      form.hasECLink && !isValidECLink(form.ec)
        ? "Please provide a valid EC id or URL"
        : null,
    ecs:
      form.hasECSLink && !isValidECSLink(form.ecs)
        ? "Please provide a valide Ecs site name or URL"
        : null,
    // @ts-ignore
    links: findApplicationErrors(form.links),
    contacts: form.contacts?.length < 1 ? "Please choose a contact." : null,
    users:
      !isEditMode && form.users?.length < 1 ? "Please choose a contact." : null,
  };
};

function tokenizer(str: string, prefix: string) {
  const splited = str.trim().split(prefix);
  if (!splited[1]) {
    return null;
  }
  return splited[1].split("/")[0];
}

function extractor(
  str: string,
  baseUri: string,
  validator: (str: string) => boolean
) {
  let res = null;

  const trimed = str.trim();
  if (validator(trimed)) {
    res = trimed;
  } else {
    const extracted = tokenizer(str, baseUri);
    res = extracted && validator(extracted) ? extracted.trim() : null;
  }

  return res && res.length > 0 ? res : null;
}

function extractECLink(link: string) {
  return extractor(link, process.env.REACT_APP_EC_BASEURI!, isValidGuid);
}

function isValidECLink(link: string) {
  return extractECLink(link) !== null;
}

function isValidECSSite(site: string) {
  return /^[^\\/:]+$/.test(site);
}

function extractECSLink(link: string) {
  return extractor(link, process.env.REACT_APP_ECS_BASEURI!, isValidECSSite);
}

function isValidECSLink(link: string) {
  return extractECSLink(link) !== null;
}

export function toSubscriptionForm(
  dto: SubscriptionDetailsReadDTO
): SubscriptionForm {
  return {
    id: dto.id,
    companyId: dto.companyId,
    entityId: dto.entityId,
    serviceId: dto.serviceId,
    endDate: dto.endDate ? new Date(dto.endDate) : null,
    clientContent: dto.clientContent || "",
    hasECLink: !!dto.ec,
    ec: dto.ec || "",
    ecModules: toECModules(dto.ecModules),
    hasECSLink: !!dto.ecs,
    ecs: dto.ecs || "",
    ecsModules: toECSModules(dto.ecsModules),
    links: dto.applications
      ? dto.applications.map((app) => ({
          name: app.name || "",
          link: app.link || "",
          showToClient: app.showToClient,
        }))
      : [],
    contacts: dto.contacts ?? [],
    users: dto.users ?? [],
  };
}

export function toSubscriptionCreateDTO(
  form: SubscriptionForm
): SubscriptionCreateDTO {
  const clientContent = form.clientContent.trim();
  const ec = form.ec.trim();
  const ecs = form.ecs.trim();

  return {
    companyId: form.companyId!,
    entityId: form.entityId!,
    serviceId: form.serviceId!,
    endDate: form.endDate ? toUTCISODateString(form.endDate) : null,
    clientContent: clientContent.length > 0 ? clientContent : null,
    ec: form.hasECLink ? extractECLink(ec) : null,
    ecs: form.hasECSLink ? extractECSLink(ecs) : null,
    ecModules: form.hasECLink ? (form.ecModules as any) : null,
    ecsModules: form.hasECSLink ? (form.ecsModules as any) : null,
    applications: form.links.filter((link) => link.name.trim().length > 0),
    contacts: form.contacts.map((contact) => contact.ppid!),
    users: form.users,
  };
}
