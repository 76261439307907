import "./index.scss";

import oidcConfig from "api/auth/config";
import { Button } from "appkit-react";
import { useEffect, useState } from "react";

const OIDCMessage = ({
  title = "Default title",
  subtitle = "Default subtitle",
  illustration = null,
  buttonLabel = "Default Button",
  buttonAction = null,
  timeoutButton = false,
}) => {
  const [viewTimeout, setViewTimeout] = useState(false);

  const [storedTimeout, setStoredTimeout] = useState(null);

  if (timeoutButton && !viewTimeout && storedTimeout === null) {
    const timeout = setTimeout(() => {
      setViewTimeout(true);
    }, 5000);
    setStoredTimeout(timeout);
  }

  useEffect(() => {
    return () => {
      clearTimeout(storedTimeout);
    };
  }, [storedTimeout]);

  return (
    <div className="oidc-message">
      <h1>{title}</h1>
      <p>{subtitle}</p>
      {buttonAction ? (
        <Button kind="primary" onClick={buttonAction}>
          {buttonLabel}
        </Button>
      ) : null}
      {viewTimeout ? (
        <div className="timeout">
          <p>
            This is taking longer than usual. A page refresh could solve the
            problem.
          </p>
          <Button
            kind="primary"
            onClick={() => {
              sessionStorage.removeItem(
                `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
              );
              window.location.reload();
            }}
          >
            Refresh page
          </Button>
        </div>
      ) : null}
      {illustration ? (
        <div
          className="illustration"
          style={{ backgroundImage: illustration }}
        />
      ) : null}
    </div>
  );
};

export default OIDCMessage;
