import useStickyHeader, {
  ADMIN_TABLE_OFFSET,
} from "components/Table/hooks/useStickyHeader";
import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { ScrollContext } from "../../../App";

const useMainTableStickyHeader = (
  loading,
  tableRef,
  offset = ADMIN_TABLE_OFFSET
) => {
  const scrollRef = useContext(ScrollContext);
  const [theadRef, setTheadRef] = useState(null);
  useEffect(() => {
    if (tableRef.current) {
      setTheadRef(
        ReactDOM.findDOMNode(tableRef.current).querySelector(
          ".rt-thead.-header"
        )
      );
    }
  }, [loading, tableRef]);
  useStickyHeader(scrollRef?.current, theadRef, offset);
};

export default useMainTableStickyHeader;
