import { UseTypedQueryOptions } from "api/UseTypedQueryOptions";
import { UseQueryResult, useQuery } from "react-query";

import { PageReadDTO } from "../__generated__/CatalogueAPI";
import { getCatalogueHomepageDetails } from "../catalogApi";

export default function useCatalogueHomepageDetails(
  props?: UseTypedQueryOptions<PageReadDTO>
): UseQueryResult<PageReadDTO> {
  return useQuery(
    "catalogue/homepage",
    () => getCatalogueHomepageDetails(),
    props
  );
}
