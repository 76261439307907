import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { ScrollContext } from "../../App";
import { scrollTo } from "../../utils/scrollTo";

export const useRedirectWithScrollSave = () => {
  const history = useHistory();
  const appRef = useContext(ScrollContext);

  return (path, state = {}) => {
    history.replace(history.location.pathname, {
      ...state,
      scrollTop: appRef.current.scrollTop,
    });
    history.push(path);
  };
};

const RestoreScroll = ({ children }) => {
  const history = useHistory();
  const appRef = useContext(ScrollContext);

  useEffect(() => {
    const scrollRef = appRef.current;
    scrollTo(scrollRef, { top: history.location.state?.scrollTop || 0 });
  }, [appRef, history.location]);

  return <>{children}</>;
};

export default RestoreScroll;
