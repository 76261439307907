import { PopBanner } from "appkit-react";
import { Component } from "react";

import HandledPromiseRejectionError from "../../exceptions/handledPromiseRejectionError";

const DEFAULT_MESSAGE =
  "Something went wrong. Please try again later. If the problem persists, please contact the support team.";
const FORBIDDEN_MESSAGE =
  "It seems like you haven't the right permission for this action.";
const NOTFOUND_MESSAGE =
  "You're looking for something that doesn't exist, or have been removed.";
const UNKNOWN_ERROR_MESSAGE =
  "No response received from the server. Please try again later. If the problem persists, please contact the support team.";

const parseErrors = (errors) => {
  return (
    <div>
      <p>An error occured while processing your request :</p>
      <ul>
        {errors.map((error) => (
          <li key={`${error.itemId}-${error.type}`}>{error.message}</li>
        ))}
      </ul>
    </div>
  );
};

export default class HTTPErrorBoundary extends Component {
  componentDidMount() {
    window.onunhandledrejection = (data) => {
      const error = data.reason;

      if (error instanceof Response && error.status === 400) {
        try {
          const promise = error.error
            ? Promise.resolve(error.error)
            : error.json();

          promise.then((errorMessages) => {
            PopBanner({
              content: parseErrors(errorMessages),
              closeable: true,
              className: "a-banner-error",
            });
          });
        } catch {
          PopBanner({
            content: <span>{DEFAULT_MESSAGE}</span>,
            closeable: true,
            status: "negative",
          });
        }
      } else {
        let message;
        if (error instanceof Response) {
          switch (error.status) {
            case 401:
            case 403:
              message = FORBIDDEN_MESSAGE;
              break;
            case 404:
              message = NOTFOUND_MESSAGE;
              break;
            case 500:
            default:
              message = DEFAULT_MESSAGE;
          }
        } else if (error instanceof HandledPromiseRejectionError) {
          message = error.message;
        } else {
          message = UNKNOWN_ERROR_MESSAGE;
        }

        PopBanner({
          content: <span>{message}</span>,
          closeable: true,
          status: "negative",
        });
      }
    };
  }

  render() {
    return this.props.children;
  }
}
