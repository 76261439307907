import { ServiceReadForAdminDTO } from "api/admin/__generated__/UserManagementAPI";
import { ServiceTag, ServiceType } from "api/catalog/type";

export interface ServiceItem {
  id: string;
  domains: string[];
  name: string;
  live: boolean;
  isNew: boolean;
  isHighlighted: boolean;
  canAddSubscription: boolean;
}

export interface ServiceTenantItem {
  id: string;
  name: string;
  url: string;
  domain: string;
}

export interface ServiceDetails extends ServiceItem {
  description: string;
  type: ServiceType;
  tags: ServiceTag[];
}

export function toServiceItem(dto: ServiceReadForAdminDTO): ServiceItem {
  return {
    id: dto.id,
    name: dto.name!,
    live: dto.live || false,
    canAddSubscription: dto.canAddSubscription,
    isNew: dto.isNew,
    isHighlighted: dto.isHighlighted,
    domains: dto.domains,
  };
}
