import { Select, SelectOption } from "appkit-react";
import { useEffect } from "react";

import { USERS_CONTEXTS } from "../../constants/index";

const LABELS = {
  [USERS_CONTEXTS.ALL]: "All",
  [USERS_CONTEXTS.SUPERADMIN]: "Admin",
  [USERS_CONTEXTS.PWC]: "PwC",
  [USERS_CONTEXTS.CLIENT]: "Client",
  [USERS_CONTEXTS.LUXEMBOURG_STAFF]: "Luxembourg staff",
};

const ContextToggle = ({ context, onContextChange, superAdmin = false }) => {
  useEffect(() => {
    if (!superAdmin && context === USERS_CONTEXTS.SUPERADMIN) {
      onContextChange(USERS_CONTEXTS.ALL);
    }
  }, [context, onContextChange, superAdmin]);

  const tabKeys = Object.keys(LABELS).filter(
    (userContext) => superAdmin || userContext !== USERS_CONTEXTS.SUPERADMIN
  );
  return (
    <Select
      className="search-bar-style"
      onSelect={onContextChange}
      value={context}
    >
      {tabKeys.map((key) => {
        return (
          <SelectOption value={key} key={key}>
            {LABELS[key]}
          </SelectOption>
        );
      })}
    </Select>
  );
};

export default ContextToggle;
