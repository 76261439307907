import "./index.scss";

import { ServiceGroupReadListDTO } from "api/catalog/__generated__/CatalogueAPI";
import { pollDeleteServiceGroup } from "api/catalog/catalogPoller";
import useServiceGroups from "api/catalog/queries/useServiceGroups";
import { PopBanner } from "appkit-react";
import FloatingActionButtonPortal from "components/FloatingActionButton";
import FullPageLoader from "components/FullPageLoader";
import { useState } from "react";
import { useMutation } from "react-query";

import AddServiceGroupModal from "./AddServiceGroupModal";
import DeleteServiceGroupModal from "./DeleteServiceGroupModal";
import ServiceGroupsTable from "./Table";

interface Props {
  filters: any;
}
export default function AdminServiceGroups({ filters }: Props) {
  const [showCreateServiceGroupModal, setShowCreateServiceGroupModal] =
    useState(false);
  const [serviceGroupToDelete, setServiceGroupToDelete] =
    useState<ServiceGroupReadListDTO | null>(null);
  const [editedServiceGroupId, setEditedServiceGroupId] = useState<
    string | null
  >(null);
  const {
    data: serviceGroups,
    isFetching,
    isLoading,
    refetch: refreshServiceGroups,
  } = useServiceGroups();

  const { mutate: deleteServiceGroup, isLoading: deleteServiceGroupLoading } =
    useMutation(
      ({ id }: { id: string }) => {
        return pollDeleteServiceGroup(id);
      },
      {
        onSuccess: () => {
          refreshServiceGroups();
          PopBanner({
            content: `The service ${
              serviceGroupToDelete!.name
            } has been successfully deleted.`,
            duration: 4000,
            status: "success",
          });
          setServiceGroupToDelete(null);
        },
      }
    );

  return (
    <>
      {deleteServiceGroupLoading && <FullPageLoader />}
      <AddServiceGroupModal
        visible={showCreateServiceGroupModal}
        onClose={() => {
          setEditedServiceGroupId(null);
          setShowCreateServiceGroupModal(false);
        }}
        onServiceGroupChanged={() => {
          setShowCreateServiceGroupModal(false);
          setEditedServiceGroupId(null);
          refreshServiceGroups();
        }}
        serviceGroupId={editedServiceGroupId}
      />
      <DeleteServiceGroupModal
        serviceGroup={serviceGroupToDelete}
        onClose={() => setServiceGroupToDelete(null)}
        onConfirmDelete={() => {
          deleteServiceGroup({ id: serviceGroupToDelete!.id });
        }}
      />

      <div className="service-groups-admin">
        <ServiceGroupsTable
          filters={filters}
          serviceGroups={serviceGroups}
          loading={isFetching || isLoading}
          onEdit={(serviceGroup: ServiceGroupReadListDTO) => {
            setShowCreateServiceGroupModal(true);
            setEditedServiceGroupId(serviceGroup.id);
          }}
          onDelete={(serviceGroup: ServiceGroupReadListDTO) => {
            setServiceGroupToDelete(serviceGroup);
          }}
        />
      </div>
      <FloatingActionButtonPortal
        onClick={() => setShowCreateServiceGroupModal(true)}
        name="icon-plus-fill"
        tooltip="Create a new service"
      />
    </>
  );
}
