import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

import articlesReducer from "../reducers/articles";
import globalReducer from "../reducers/global";
import newsAdminReducer from "../reducers/newsAdmin";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/*window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true, // (action) => { return ‘trace as string’; }
    traceLimit: 25,
  })*/

export default createStore(
  combineReducers({
    global: globalReducer,
    articles: articlesReducer,
    newsAdmin: newsAdminReducer,
  }),
  composeEnhancers(applyMiddleware(thunk))
);
