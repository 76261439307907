import "./index.scss";

import {
  MODULES,
  MODULE_VALUES,
  NEWS_MODULE_IS_ENABLED,
} from "constants/module";
import { findCurrentModuleKey } from "constants/module";

import { List, ListItem } from "appkit-react";
import KeyCode from "appkit-react-utils/keyCode";
import classNames from "classnames";
import GlobeIconOutline from "img/icones/Icons-Outline-Globe-White.svg";
import NewsIconFill from "img/icones/icons-outline-start-tour-fill.svg";
import NewsIconOutline from "img/icones/icons-outline-start-tour.svg";
import useUserServices from "pages/myServices/hooks/useUserServices";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import AppLink from "../AppLink";

let outlineAction = {
  NOACTION: 0,
  CLICK: 1,
  CHANGE: 2,
  BLUR: 3,
  TAB: 4,
};

const MODULE_MENUS = [
  MODULES.MYSERVICES,
  MODULES.MYCLIENTS,
  MODULES.CLIENT_VIEW,
  MODULES.CATALOGUE,
  MODULES.NEWS,
];

class LeftNav extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasOutLine: true,
      lastAction: outlineAction.CLICK,
    };
    const self = this;
    this.keyEvent = function (e) {
      if (e.keyCode === 9) {
        // Tab key
        self.setState({
          lastAction: outlineAction.BLUR,
        });
      }
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.keyEvent, false);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.keyEvent, false);
  }

  handleKeyDown(evt) {
    if (evt.keyCode === KeyCode.ENTER) {
      const listItemNode = document.querySelector(
        ".a-left-navigation .a-list-item:focus"
      );
      listItemNode.click();
    }
  }
  onMouseDown = () => {
    this.setState({
      hasOutLine: false,
      lastAction: outlineAction.CLICK,
    });
  };

  onBlur = () => {
    const { lastAction } = this.state;
    if (lastAction === outlineAction.CLICK) {
      this.setState({
        hasOutLine: false,
        lastAction: outlineAction.BLUR,
      });
    } else {
      this.setState({
        hasOutLine: true,
        lastAction: outlineAction.BLUR,
      });
    }
  };
  render() {
    const userLogged = !!this.props.loginUser;
    let displayedModules = MODULE_MENUS.filter((menu) => {
      return userLogged || !MODULE_VALUES[menu].requireConnexion;
    })
      .filter(
        (menu) =>
          this.props.loginUser?.isInternal ||
          !MODULE_VALUES[menu].isInternalOnly
      )
      .filter((menu) => {
        // hide myServices (client) menu enty if no userUservices are available (only for external user)
        return menu === "/myServices" && !this.props.loginUser?.isInternal
          ? !!this.props.userServices?.length
          : true;
      });

    if (process.env.REACT_APP_HIDE_MODULES_NON_LUX === "1") {
      displayedModules = displayedModules.filter(
        (menu) => this.props.loginUser?.isLu || !MODULE_VALUES[menu].isLuxOnly
      );
    }

    if (displayedModules.length <= 1) {
      return null;
    }

    if (!NEWS_MODULE_IS_ENABLED) {
      displayedModules = displayedModules.filter(
        (module) => module !== MODULES.NEWS
      );
    }

    const selectedMenu = findCurrentModuleKey(this.props.location.pathname);

    return (
      <div className="a-left-navigation a-shadow-xs dark">
        <List
          className={`a-nav-menu ${
            !this.state.hasOutLine ? "a-nav-menu-no-outline" : ""
          }`}
          onMouseDown={this.onMouseDown}
          onBlur={this.onBlur}
        >
          <>
            {displayedModules.map((rootPath) => {
              const listitemClasses = classNames("a-nav-menu-item", {
                active: selectedMenu && selectedMenu === rootPath,
              });
              const item = MODULE_VALUES[rootPath];

              return (
                <AppLink to={rootPath} key={rootPath}>
                  <ListItem
                    className={listitemClasses}
                    onKeyDown={this.handleKeyDown.bind(this)}
                    tabIndex="0"
                  >
                    {item.icon ? (
                      <i
                        key={item.index}
                        className={`appkiticon ${item.icon}-${
                          selectedMenu === rootPath ? "fill" : "outline"
                        }`}
                      />
                    ) : (
                      <img
                        src={
                          selectedMenu === rootPath && item.fillIcon
                            ? item.fillIcon
                            : item.outlineIcon
                        }
                        className="appkiticon"
                        alt=""
                      />
                    )}
                    <span className="a-nav-item-text">{item.label}</span>
                  </ListItem>
                </AppLink>
              );
            })}
          </>
          <>
            {this.props.userServices
              ?.filter((service) =>
                service?.links?.some((link) => link?.showToClient === true)
              )
              .map((service) => {
                return service.links
                  .filter((link) => link.showToClient)
                  .map((link) => (
                    <AppLink href={link.link} key={link.link} external>
                      <ListItem className="a-nav-menu-item">
                        <img
                          src={GlobeIconOutline}
                          className="appkiticon"
                          alt={link.name}
                        />
                        <span className="a-nav-item-text"> {link.name}</span>
                      </ListItem>
                    </AppLink>
                  ));
              })}
          </>
          <>
            {process.env.REACT_APP_ENABLE_ECS_MENU_ITEM === "true" && (
              <AppLink to={"/ecs"} key={"ecs"}>
                <ListItem
                  className={classNames(
                    "a-nav-menu-item",
                    window.location.pathname.startsWith("/ecs")
                  )}
                >
                  <img
                    src={
                      window.location.pathname.startsWith("/ecs")
                        ? NewsIconFill
                        : NewsIconOutline
                    }
                    className="appkiticon"
                    alt=""
                  />
                  <span className="a-nav-item-text">ECS</span>
                </ListItem>
              </AppLink>
            )}
          </>
        </List>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUser: state.global.data.loginUser,
  };
};

function withUserServices(Component) {
  return function WrappedComponent(props) {
    const userServices = useUserServices();
    return <Component {...props} userServices={userServices} />;
  };
}

export default withRouter(withUserServices(connect(mapStateToProps)(LeftNav)));
