import { toUTCISODateString } from "api/helpers";
import { toDateRangeFilter, toTextFilter } from "api/helpers/filters";
import { isArray, isObject } from "lodash";

export const toApiFilter = (filters) => {
  const {
    type,
    territories,
    issuers,
    topics,
    documentTypes,
    published,
    mustRead,
    issuedDateRange,
    freeTextSearch,
    repositoryItem,
    isAlive,
  } = filters;
  return {
    type,
    territories,
    issuers,
    topics,
    documentTypes,
    published,
    mustRead,
    repositoryItem,
    isAlive,
    issuedDateRange: toDateRangeFilter(issuedDateRange),
    freeTextSearch: toTextFilter(freeTextSearch),
  };
};

export function adaptNewsToBackEnd(oldNews) {
  const news = { ...oldNews };

  if (oldNews.issueDate) {
    news.issueDate = toUTCISODateString(oldNews.issueDate);
  }
  news.repositoryText = isObject(oldNews.repositoryText)
    ? JSON.stringify(oldNews.repositoryText)
    : oldNews.repositoryText;

  return news;
}

export function adaptNewsFromBackend(news) {
  function _adaptNewsFromBackend(_new) {
    return {
      ..._new,
      repositoryText: _new.repositoryText
        ? JSON.parse(_new.repositoryText)
        : _new.repositoryText,
    };
  }
  return isArray(news)
    ? news.map((_new) => _adaptNewsFromBackend(_new))
    : _adaptNewsFromBackend(news);
}
