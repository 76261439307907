import "./index.scss";

import { Modal } from "appkit-react";
import classNames from "classnames";
import { CSSProperties, useState } from "react";
import { ResizableBox } from "react-resizable";

interface Props {
  // Appkit Modal Props
  style?: CSSProperties;
  className?: string;
  visible?: boolean;
  backdropClosable?: boolean;
  zIndex?: number;
  onCancel: () => void;
  // Resizable Props
  width?: number;
}

const ResizableAppkitModal: React.FC<Props> = ({
  width: propsWidth = 500,
  children,
  ...modalProps
}) => {
  const [width, setWidth] = useState(propsWidth);
  return (
    <Modal
      {...modalProps}
      className={classNames(modalProps.className, "resizable-appkit-modal")}
      style={{
        ...(modalProps.style ?? {}),
        width: width,
      }}
    >
      <ResizableBox
        axis="x"
        resizeHandles={["se", "sw"]}
        width={width}
        minConstraints={[propsWidth, 0]}
        onResize={(_event, data) => {
          setWidth(data.size.width);
        }}
      >
        <div
          tabIndex={0}
          className="appkiticon appkit-pure-goodness icon-close-fill a-close-btn a-keyboard-hover-only-div"
          onClick={modalProps.onCancel}
        />
        {children}
      </ResizableBox>
    </Modal>
  );
};

export default ResizableAppkitModal;
