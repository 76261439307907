import "./index.scss";

import { Input, Select, SelectOption } from "appkit-react";

import codes from "./codes";

const DEFAULT_VALUE = {
  code: null,
  number: "",
};

export function validatePhone(value) {
  if (value.code === null) {
    return "Please provide a valid dialing code";
  }

  const valueNumber = value?.number?.replaceAll(" ", "") || "";

  if (!/^[0-9]{1,15}$/g.test(valueNumber)) {
    return "Please provide a valid phone number (without dialing code number)";
  }

  return null;
}

const PhoneInput = ({ value = DEFAULT_VALUE, onChange, ...props }) => {
  const handleDialingCodeChange = (code) => onChange({ ...value, code });
  const handlePhoneNumberChange = (number) => onChange({ ...value, number });

  return (
    <div className="phone-input">
      <Select
        showSearchOnList
        value={value.code}
        onSelect={handleDialingCodeChange}
        {...props}
      >
        {codes.map(({ code, country }) => (
          <SelectOption key={`${code}-${country}`} value={code}>
            <span className="country">{country}</span>
            <span className="code">(+{code})</span>
          </SelectOption>
        ))}
      </Select>

      <Input
        value={value.number}
        onChange={handlePhoneNumberChange}
        placeholder="6 12 34 56 78"
        {...props}
      />
    </div>
  );
};

export default PhoneInput;
