import "react-image-crop/lib/ReactCrop.scss";

import "./index.scss";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "appkit-react";
import { useEffect, useState } from "react";
import ReactCrop from "react-image-crop";

const ImageCropperModal = ({
  image,
  visible,
  onValidate,
  onCancel,
  aspect = null,
}) => {
  const [crop, setCrop] = useState({
    aspect: aspect,
    unit: "%",
    width: 100,
  });

  const [imageRef, setImageRef] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setCrop({
        aspect: aspect,
        unit: "%",
        width: 100,
      });
    }, 300);
  }, [image, aspect]);

  const onSubmit = async () => {
    if (!(crop.width && crop.height)) {
      onValidate(image);
      return;
    }
    onValidate(await makeClientCrop());
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          resolve(reader.result);
        };
      }, "image/png");
    });
  };

  const makeClientCrop = async () => {
    if (crop.width && crop.height) {
      return await getCroppedImg(imageRef, crop);
    }
  };

  const originalIsValidRatio = () => {
    if (aspect !== null && imageRef !== null) {
      return (
        Math.abs(imageRef.naturalWidth / imageRef.naturalHeight - aspect) <=
        0.000000001
      );
    }
  };

  return (
    <Modal
      backdropClosable={false}
      visible={visible}
      className="crop-image-modal"
      onCancel={onCancel}
    >
      <ModalHeader>
        <div className="modal-title">Crop Image</div>
      </ModalHeader>
      <ModalBody>
        <ReactCrop
          src={image}
          crop={crop}
          ruleOfThirds
          onChange={setCrop}
          onImageLoaded={setImageRef}
        />
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          kind="primary"
          onClick={onSubmit}
          disabled={
            aspect !== null &&
            !originalIsValidRatio() &&
            !(crop.width && crop.height)
          }
        >
          Validate
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImageCropperModal;
