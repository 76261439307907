import "./index.scss";

import { MODULES } from "constants/module";

import { Panel, Tab, Tabs } from "appkit-react";
import CategoryPitch from "components/CategoryPitch";
import PageTitle from "components/PageTitle";
import EngagementsTableContextWrapper from "pages/admin/components/Engagement";
import SubscriptionAdmin from "pages/admin/components/Engagement/SubscriptionAdmin";
import EntityAdmin from "pages/admin/components/Entity/EntityAdmin";
import UserAdmin from "pages/admin/components/User/UserAdmin";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { SERVICE_STEPS } from "../catalogue/constants";
import AdminCompanyContextHOC from "./components/Company";
import CompaniesTable, {
  COLUMNS as CompaniesTableColumns,
} from "./components/Company/CompaniesTable";
import { COLUMNS as EngagementsTableColumns } from "./components/Engagement/SubscriptionTable";
import AdminEntityContextHOC from "./components/Entity";
import { COLUMNS as EntitiesTableColumns } from "./components/Entity/EntitiesTable";
import Filters from "./components/Filters";
import AdminServices from "./components/Service/components";
import AdminServiceContextHOC from "./components/Service/components/AdminServiceContextHOC";
import { COLUMNS as ServicesTableColumns } from "./components/Service/components/Table";
import AdminServiceGroups from "./components/ServiceGroup";
import { COLUMNS as ServiceGroupTableColumns } from "./components/ServiceGroup/Table";
import AdminTerritories from "./components/Territories";
import { COLUMNS as TerritoriesColumns } from "./components/Territories/Table";
import { COLUMNS as UsersTableColumns } from "./components/User/UsersTable";
import { USERS_CONTEXTS } from "./constants";
import backgroundImage from "./img/header.png";

export const ADMIN_TABS = {
  USERS: {
    id: "USERS",
    columns: UsersTableColumns,
    label: "Users",
    icon: "icon-person",
  },

  COMPANIES: {
    id: "COMPANIES",
    columns: CompaniesTableColumns,
    label: "Company groups",
    icon: "icon-building",
  },

  ENTITIES: {
    id: "ENTITIES",
    columns: EntitiesTableColumns,
    label: "Companies",
    icon: "icon-visitor",
  },

  ENGAGEMENTS: {
    id: "ENGAGEMENTS",
    columns: EngagementsTableColumns,
    label: "Subscriptions",
    icon: "icon-growth",
  },

  SERVICES: {
    id: "SERVICES",
    columns: ServicesTableColumns,
    label: "Services",
    icon: "icon-management",
  },

  SERVICES_GROUPS: {
    id: "SERVICE_GROUPS",
    columns: ServiceGroupTableColumns,
    label: "Service groups",
    icon: "icon-bubble-plot",
  },
};
if (process.env.REACT_APP_ENABLE_SERVICE_TERRITORIES === "true") {
  ADMIN_TABS["TERRITORIES"] = {
    id: "TERRITORIES",
    columns: TerritoriesColumns,
    label: "Territories",
    icon: "icon-globe-map",
  };
}

const DEFAULT_FILTERS = {
  context: USERS_CONTEXTS.ALL,
  rowFilters: [],
  steps: Object.values(SERVICE_STEPS),
  myServices: true,
  trial: 0,
};

export const AdminWithInitializer = () => {
  const { service, id } = useParams();
  return (
    <Admin
      initializer={{
        service: Object.entries(ADMIN_TABS).find(
          ([key]) => key.toLowerCase() === service
        )?.[1],
        id,
      }}
    />
  );
};

const Admin = ({ initializer }) => {
  const [activeTab, setActiveTab] = useState(
    initializer?.service ?? ADMIN_TABS.USERS
  );
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  useEffect(() => {
    if (initializer) {
      window.history.replaceState(undefined, "", MODULES.ADMIN);
    }
  }, [initializer]);

  const history = useHistory();
  const hash = history.location.hash;
  useEffect(() => {
    if (hash) {
      const hashId = hash.substring(1).toUpperCase();
      const hashTab = Object.values(ADMIN_TABS).find(
        (tab) => tab.id.toUpperCase() === hashId
      );
      if (hashTab) {
        setActiveTab(hashTab);
      }
    }
  }, [hash]);

  const loginUser = useSelector((state) => state.global.data.loginUser);
  useEffect(() => {
    // If loginUser is null, that means page is still loading,
    // So we don't want to redirect user in such case
    // (Otherwise page refreshing would redirect to dashboard for example)
    if (loginUser !== null && !loginUser.isInternal) {
      history.replace("/");
    }
  }, [loginUser, history]);

  return (
    <main className="page-admin">
      <PageTitle title="Administration" />
      <CategoryPitch
        title="Take control"
        image={backgroundImage}
        titleMarginBottom={30}
      >
        <Filters
          filters={filters}
          onFiltersChange={setFilters}
          activeTab={activeTab}
        />
        <div className="row">
          <div className="col-md-12">
            <Tabs
              className="a-category-tabs"
              size="lg"
              value={activeTab.id}
              onChange={(_, selectedId) =>
                setActiveTab(
                  Object.values(ADMIN_TABS).find((tab) => tab.id === selectedId)
                )
              }
            >
              {Object.values(ADMIN_TABS).map((tab) => (
                <Tab
                  key={tab.id}
                  value={tab.id}
                  icon={
                    <>
                      <span
                        className={`appkiticon ${tab.icon}-${
                          activeTab.id === tab.id ? "fill" : "outline"
                        }`}
                      />
                      &nbsp;{tab.label}
                    </>
                  }
                />
              ))}
            </Tabs>
          </div>
        </div>
      </CategoryPitch>

      <div className="container with-margin">
        <div className="row">
          <div className="col-12">
            <Panel className="admin-table-panel">
              <AdminServiceContextHOC>
                <AdminCompanyContextHOC>
                  <AdminEntityContextHOC>
                    {activeTab.id === ADMIN_TABS.USERS.id && (
                      <UserAdmin filters={filters} />
                    )}
                    {activeTab.id === ADMIN_TABS.COMPANIES.id && (
                      <CompaniesTable filters={filters} />
                    )}
                    {activeTab.id === ADMIN_TABS.ENGAGEMENTS.id && (
                      <EngagementsTableContextWrapper filters={filters}>
                        <SubscriptionAdmin />
                      </EngagementsTableContextWrapper>
                    )}
                    {activeTab.id === ADMIN_TABS.ENTITIES.id && (
                      <EntityAdmin filters={filters} />
                    )}
                    {activeTab.id === ADMIN_TABS.SERVICES.id && (
                      <AdminServices
                        initializer={initializer}
                        filters={filters}
                      />
                    )}
                    {activeTab.id === ADMIN_TABS.SERVICES_GROUPS.id && (
                      <AdminServiceGroups filters={filters} />
                    )}
                    {process.env.REACT_APP_ENABLE_SERVICE_TERRITORIES ===
                      "true" && (
                      <>
                        {activeTab.id === ADMIN_TABS.TERRITORIES.id && (
                          <AdminTerritories filters={filters} />
                        )}
                      </>
                    )}
                  </AdminEntityContextHOC>
                </AdminCompanyContextHOC>
              </AdminServiceContextHOC>
            </Panel>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Admin;
