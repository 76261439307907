import "./index.scss";

import TermsAndConditionsModal from "components/Footer/TermsAndConditionsModal";
import { useState } from "react";
import { useSelector } from "react-redux";

const Footer = () => {
  const [termsAndConditionsOpened, setTermsAndConditionsOpened] = useState(
    false
  );

  const loginUser = useSelector((state) => state.global.data.loginUser);
  return (
    <div className="a-footer-wrapper">
      <TermsAndConditionsModal
        opened={
          termsAndConditionsOpened ||
          loginUser?.termsAndConditionsAccepted === false
        }
        onClose={() => setTermsAndConditionsOpened(false)}
      />
      <div className="a-footer">
        <div className="text">
          © {new Date().getFullYear()} PwC. PwC refers to the PwC network and/or
          one or more of its member firms, each of which is a separate legal
          entity. Please see{" "}
          <a
            href="https://www.pwc.com/structure"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.pwc.com/structure
          </a>{" "}
          for further details.
        </div>
        <div className="actions">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.pwc.lu/en/legal/privacy-statement.html"
          >
            Privacy
          </a>
          <span onClick={() => setTermsAndConditionsOpened(true)}>
            Terms and Conditions
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
