import { getNotificationTypes } from "api/notification/notificationAPI";
import { useEffect, useState } from "react";

type NotificationTypes = Record<number, string>;
export type UseNotificationTypes = NotificationTypes | null;

export default function useNotificationTypes(): UseNotificationTypes {
  const [
    notificationTypes,
    setNotificationTypes,
  ] = useState<UseNotificationTypes>(null);

  useEffect(() => {
    getNotificationTypes().then((notificationTypes) => {
      const byValue = notificationTypes.reduce((acc, n) => {
        acc[n.value] = n.name!;
        return acc;
      }, {} as NotificationTypes);

      setNotificationTypes(byValue);
    });
  }, []);

  return notificationTypes;
}
