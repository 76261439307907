import { ServiceContactReadDTO } from "api/catalog/__generated__/CatalogueAPI";

export interface Person {
  id: string;
  ppid: string;
  name: string;
  email: string;
  grade?: string;
  photo: string | null;
  phoneNumber?: string;
  phoneCountryCode?: number;
}
export function toPersonDTO(user: ServiceContactReadDTO): Person {
  return {
    id: user.id,
    ppid: user.ppid!,
    name: `${user.firstname} ${user.lastname}`,
    photo: user.photoUri,
    email: user.email!,
    grade: user.grade!,
    phoneNumber: user.phoneNumber ?? undefined,
    phoneCountryCode: user.phoneCountryCode ?? undefined,
  };
}

export function toUserReadDTO(person: Person): ServiceContactReadDTO {
  return {
    ppid: person.ppid,
    email: person.email,
    firstname: null,
    lastname: null,
    grade: person.grade,
    id: person.id,
    photoUri: person.photo,
  } as ServiceContactReadDTO;
}
