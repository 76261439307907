import { toDateRangeFilter, toTextFilter } from "api/helpers/filters";

export const toApiFilter = ({
  type,
  territories,
  issuers,
  topics,
  documentTypes,
  published,
  mustRead,
  issuedDateRange,
  freeTextSearch,
  exportAsSubscriptionId,
  publishedDateRange,
  withExtendedInfos,
}) => {
  return {
    type,
    territories,
    issuers,
    topics,
    documentTypes,
    published,
    mustRead,
    issuedDateRange: toDateRangeFilter(issuedDateRange),
    freeTextSearch: toTextFilter(freeTextSearch),
    exportAsSubscriptionId,
    publishedDateRange,
    withExtendedInfos,
  };
};
