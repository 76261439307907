import "./index.scss";

import { Button } from "appkit-react";
import ReactDOM from "react-dom";

const FullPageLoader = (props) => {
  const {
    message = "Processing, please stand by...",
    onCancel = null,
    onCancelLabel = "Cancel",
  } = props;

  return ReactDOM.createPortal(
    <div className="full-page-loader">
      <div className="d-inline-block text-center">
        <div className="a-loading a-primary"></div>
        <div className="a-mt-10 a-h6 a-text-black">{message}</div>
        {onCancel ? (
          <Button kind="secondary" onClick={onCancel}>
            {onCancelLabel}
          </Button>
        ) : null}
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default FullPageLoader;
