import "./index.scss";

import { SubscriptionDetailsReadDTO } from "api/admin/__generated__/UserManagementAPI";
import {
  NotificationFrequency,
  NotificationSettingsReadDTO,
} from "api/notification/__generated__/NotificationAPI";
import { FRENQUENCIES_LABEL } from "api/notification/model";
import { getNotificationsByPpids } from "api/notification/notificationAPI";
import { ModalBody, ModalHeader } from "appkit-react";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import Table from "components/Table";
import useNotificationTypes from "pages/userProfile/hooks/useNotificationTypes";
import { useEffect, useMemo, useState } from "react";

interface Props {
  subscription: SubscriptionDetailsReadDTO | null;
  onClose: () => void;
}

interface UserNotification {
  ppid: string;
  name: string;
  notification: {
    types: string[];
    frequencies: NotificationFrequency[];
  };
}

interface CellProps<T> {
  value: T;
}

const DEFAULT_SORT = [{ id: "name", desc: false }];

const COLUMNS = [
  {
    Header: "Member",
    id: "name",
    accessor: "name",
    filterable: true,
    Filter: () => null,
  },
  {
    Header: "Notifications",
    accessor: "notification.types",
    Cell: ({ value }: CellProps<string[]>) => value.sort().join(", "),
  },
  {
    Header: "Frequency",
    accessor: "notification.frequencies",
    Cell: ({ value }: CellProps<NotificationFrequency[]>) =>
      value
        .sort()
        .map((id) => FRENQUENCIES_LABEL[id])
        .join(", "),
  },
];

export default function SubscriptionDetails({ subscription, onClose }: Props) {
  const [notifications, setNotifications] = useState<
    NotificationSettingsReadDTO[] | null
  >(null);
  const notificationTypes = useNotificationTypes();

  useEffect(() => {
    if (!subscription?.users) {
      setNotifications(null);
      return;
    }

    const usersByPpid = subscription.users.reduce((acc, user) => {
      // @ts-ignore
      acc[user.ppid!] = user;
      return acc;
    }, {});

    getNotificationsByPpids(Object.keys(usersByPpid)).then(setNotifications);
  }, [subscription]);

  const userNotifications: UserNotification[] | null = useMemo(() => {
    if (!(notifications && notificationTypes && subscription?.users)) {
      return null;
    }

    const notificationsByPpid = notifications.reduce((acc, notification) => {
      //@ts-ignore
      acc[notification.userPpid] = notification;
      return acc;
    }, {} as Record<string, NotificationSettingsReadDTO>);

    return subscription.users.map((user) => {
      const notification =
        notificationsByPpid[user.ppid as keyof typeof notificationsByPpid];
      return {
        ppid: user.ppid!,
        name: user.name!,
        notification: {
          frequencies: notification?.frequencies! || [],
          types:
            notification?.types?.map((type) => notificationTypes[type]!) || [],
        },
      };
    });
  }, [subscription?.users, notifications, notificationTypes]);

  return (
    <ResizableAppkitModal
      className="subscription-details-modal"
      visible={!!subscription}
      onCancel={onClose}
    >
      <ModalHeader>Subscription Details</ModalHeader>
      <ModalBody>
        <Table
          // @ts-ignore
          className="subscription-details-table"
          loading={!userNotifications}
          columns={COLUMNS}
          data={userNotifications || []}
          defaultSorted={DEFAULT_SORT}
        />
      </ModalBody>
    </ResizableAppkitModal>
  );
}
