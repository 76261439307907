import { SelectOption } from "appkit-react";
import Select from "components/Select";
import { AdminCompanyContext } from "pages/admin/components/Company";
import { useContext } from "react";
import { localeCompare } from "utils/localeCompare";

const CompanySelect = ({ value, onValueChange, ...selectProps }) => {
  const { items: companies } = useContext(AdminCompanyContext);

  return (
    <Select
      placeholder="Select a Company"
      showSearchOnToggle
      value={value}
      onSelect={onValueChange}
      {...selectProps}
    >
      {companies?.sort(localeCompare("name")).map((company) => (
        <SelectOption value={company.id} key={company.id}>
          {company.name}
        </SelectOption>
      ))}
    </Select>
  );
};

export default CompanySelect;
