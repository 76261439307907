export const TABS = [
  {
    value: "all",
    label: "All",
    icon: "icon-list-view",
  },
  {
    value: "news",
    label: "News",
    icon: "icon-pencil",
  },
  {
    value: "articles",
    label: "Articles ",
    icon: "icon-news",
  },
  {
    value: "calendar",
    label: "Compliance Calendar",
    icon: "icon-calendar",
  },
  {
    value: "videos",
    label: "Videos",
    icon: "icon-play",
  },
  {
    value: "publications",
    label: "Publications",
    icon: "icon-survey",
  },
];
