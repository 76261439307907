import { UseTypedQueryOptions } from "api/UseTypedQueryOptions";
import { UseQueryResult, useQuery } from "react-query";

import {
  ValueChainAllReadDTO,
  ValueChainReadDTO,
} from "../__generated__/CatalogueAPI";
import { getValueChains, getValueChainsAll } from "../catalogApi";

export default function useValueChains(
  props?: UseTypedQueryOptions<ValueChainReadDTO[]>
): UseQueryResult<ValueChainReadDTO[]> {
  return useQuery("valuechains", () => getValueChains(), props);
}

export function useValueChainsAll(
  props?: UseTypedQueryOptions<ValueChainAllReadDTO[]>
): UseQueryResult<ValueChainAllReadDTO[]> {
  return useQuery("valuechains-all", () => getValueChainsAll(), props);
}
