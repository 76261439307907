import "./UnexpectedError.scss";

import { Button } from "appkit-react";
import CategoryPitch from "components/CategoryPitch";
import PageTitle from "components/PageTitle";
import { useEffect } from "react";

import backgroundImage from "../../img/illustrations/undraw_feeling_blue.svg";

const UnexpectedError = ({ error }) => {
  useEffect(() => {
    document.body.classList.add("has-unrecoverable-error");
    return () => {
      document.body.classList.remove("has-unrecoverable-error");
    };
  }, []);

  return (
    <main className="container error-unexpected">
      <PageTitle title="Unexpected error" />
      <CategoryPitch title="Error" image={backgroundImage} maxWidth="30%">
        <p className="subtitle">An unexpected error occurred</p>
      </CategoryPitch>
      <p>
        We're sorry for any inconvenience caused. If this issue persists, do not
        hesitate to contact the support team.
      </p>
      <p>Please reload this page.</p>
      <div className="btn-wrapper">
        <Button
          size="lg"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </Button>
      </div>
      {error?.stack && (
        <details>
          <summary>Error details</summary>
          <pre>{error.stack}</pre>
        </details>
      )}
    </main>
  );
};

export default UnexpectedError;
