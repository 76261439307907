import { ScrollContext } from "App";
import { RefObject, useContext, useEffect, useRef } from "react";

type CloseHandler = () => void;

export default function useClickOutside(
  open: boolean,
  onClose: CloseHandler,
  ousideRef: RefObject<HTMLElement> | null = null
): RefObject<HTMLElement> {
  const rootRef = useContext(ScrollContext);
  const eventRef = ousideRef || rootRef!;
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const handleClickOutside = (e: any) => {
        if (!ref.current?.contains(e.target)) {
          onClose();
        }
      };

      const containerNode = eventRef.current;
      if (!containerNode) {
        return;
      }

      containerNode.addEventListener("mousedown", handleClickOutside);
      return () =>
        containerNode?.removeEventListener("mousedown", handleClickOutside);
    }
  }, [open, onClose, ousideRef, eventRef]);

  return ref;
}
