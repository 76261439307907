import "./index.scss";

import {
  downloadEntityImportTemplate,
  importEntitiesFromExcel,
} from "api/admin/adminApi";
import EntitiesTable from "pages/admin/components/Entity/EntitiesTable";
import UploadImport from "pages/newsAdmin/components/UploadImport";

export default function EntityAdmin(props) {
  return (
    <div className="entity-admin">
      <UploadImport
        onImport={importEntitiesFromExcel}
        onDownloadTemplate={downloadEntityImportTemplate}
        type="Company"
      />
      <EntitiesTable filters={props.filters}/>
    </div>
  );
}
