import "./actions.scss";

import { IconButton, Tooltip } from "@mui/material";

const Actions = ({
  handleEdit = undefined,
  handleDelete = undefined,
  handleEditPwcPanel = undefined,
  handleRefresh = undefined,
  handleNotify = undefined,
}) => {
  return (
    <div className="admin-actions">
      {handleNotify && (
        <Tooltip title="Notify" placement="top" enterDelay={500}>
          <IconButton kind="transparent" onClick={handleNotify}>
            <span className="appkiticon icon-email-fill" />
          </IconButton>
        </Tooltip>
      )}
      {handleRefresh && (
        <Tooltip title="Refresh" placement="top" enterDelay={500}>
          <IconButton kind="transparent" onClick={handleRefresh}>
            <span className="appkiticon icon-refresh-fill" />
          </IconButton>
        </Tooltip>
      )}
      {handleEdit && (
        <Tooltip title="Edit" placement="top" enterDelay={500}>
          <IconButton kind="transparent" onClick={handleEdit}>
            <span className="appkiticon icon-edit-fill" />
          </IconButton>
        </Tooltip>
      )}

      {handleEditPwcPanel &&
        process.env.REACT_APP_NEWS_ENABLE_CLIENT_PANEL === "true" && (
          <Tooltip
            title="Edit PwC Panel Content"
            placement="top"
            enterDelay={500}
          >
            <IconButton kind="transparent" onClick={handleEditPwcPanel}>
              <span className="appkiticon icon-table-data-fill" />
            </IconButton>
          </Tooltip>
        )}

      {handleDelete && (
        <Tooltip title="Delete" placement="top" enterDelay={500}>
          <IconButton kind="transparent" onClick={handleDelete}>
            <span className="appkiticon icon-delete-fill" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default Actions;
