import "./index.scss";

import useCatalogueHomepageDetails from "api/catalog/queries/useCatalogueHomepageDetails";
import Table from "components/Table";
import Actions from "components/Table/actions";
import { useMemo, useRef } from "react";
import Skeleton from "react-loading-skeleton";

import useMainTableStickyHeader from "../../../hooks/useMainTableStickyHeader";
import ServiceTableBadge from "../../Service/components/Table/ServiceTableBadge";

export const COLUMNS = [
  {
    Header: "Service group name",
    id: "name",
    filterable: true,
    Filter: () => null,
  },
];

const DEFAULT_SORT = [
  { id: "company", desc: false },
  { id: "highestLevel", desc: false },
  { id: "name", desc: false },
];

const ServiceGroupsTable = ({
  filters,
  serviceGroups,
  loading,
  onEdit,
  onDelete,
}) => {
  const { data: homepage } = useCatalogueHomepageDetails();

  const { rowFilters = [] } = filters;

  const tableRef = useRef();
  useMainTableStickyHeader(loading, tableRef);

  const columns = useMemo(() => {
    return [
      {
        Header: "Service group name",
        id: "name",
        accessor: "name",
        PivotValue: ({ value }) => value,
        filterable: true,
        Filter: () => null,
        Cell: ({ original }) => (
          <span className="service-item-group-line">
            <span className="service-item-group-name">{original.name}</span>{" "}
            <ServiceTableBadge domain={original.domain} />
          </span>
        ),
      },
      {
        Header: homepage?.secondaryFilter,
        id: "lifeCycle",
        accessor: "lifeCycle",
        PivotValue: ({ value }) => value,
        filterable: true,
        Filter: () => null,
      },
      {
        Header: homepage?.tertiaryFilter,
        id: "valueChain",
        accessor: "valueChain",
        PivotValue: ({ value }) => value,
        filterable: true,
        Filter: () => null,
      },
      {
        Header: "Actions",
        id: "actions",
        sortable: false,
        filterable: false,
        Cell: ({ original }) => (
          <Actions
            handleEdit={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onEdit(original);
            }}
            handleDelete={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onDelete(original);
            }}
          />
        ),
        width: 90,
      },
    ];
  }, [homepage?.secondaryFilter, homepage?.tertiaryFilter, onEdit, onDelete]);

  return !loading ? (
    <>
      <Table
        ref={tableRef}
        filterable={false}
        filtered={rowFilters}
        defaultFilterMethod={({ id, value }, row) => {
          if (row._subRows && row._subRows.length > 0) {
            return true;
          }
          return row[id] !== null
            ? row[id].toUpperCase().indexOf(value.toUpperCase()) >= 0
            : false;
        }}
        className="service-groups-table"
        columns={columns}
        data={serviceGroups}
        defaultSorted={DEFAULT_SORT}
      />
    </>
  ) : (
    <Skeleton height={200} />
  );
};

export default ServiceGroupsTable;
