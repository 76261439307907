import { apiFetch } from "../api";

const PREFIX = process.env.REACT_APP_URL_NEWS_ROOT + "Topic/";

export const getTopics = () => {
  return apiFetch(`${PREFIX}`);
};

export const getTopic = (topicId) => {
  return apiFetch(`${PREFIX}${topicId}`);
};

export const createTopic = (topic) => {
  return apiFetch(`${PREFIX}`, topic, "POST");
};

export const updateTopic = (topic) => {
  return apiFetch(`${PREFIX}${topic.id}`, topic, "PUT");
};

export const deleteTopic = (topicId) => {
  return apiFetch(`${PREFIX}${topicId}`, undefined, "DELETE");
};
