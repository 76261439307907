import { QueryClient, QueryClientProvider } from "react-query";

// React Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        Promise.reject(err);
      },
    },
  },
});

const ReactQueryProvider: React.FC<{}> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryProvider;
