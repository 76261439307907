import { AuthenticationContext } from "@axa-fr/react-oidc-context";
import { getMyProfile } from "api/admin/adminApi";
import { pollCheckCreatingUser } from "api/admin/adminPoller";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router";
import {
  globalInitialize,
  setLoginUserNotFound,
  setSessionExpired,
  upsertLoginUser,
} from "redux/actions/global";
import decodeJWT from "utils/decodeJWT";

const TokenManager = ({ oidcProps }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const token = oidcProps?.oidcUser?.access_token;
  useEffect(() => {
    if (token) {
      getMyProfile()
        .catch((error) => {
          // Back is creating user
          // We wait for creation is done (using long-polling)
          if (error.status === 418) {
            const decoded = decodeJWT(token);
            const ppid = decoded[process.env.REACT_APP_ID_CLAIM];

            const currentLocation = history.location;
            history.replace("/creatingUser");
            return pollCheckCreatingUser(ppid).then((user) => {
              history.replace(currentLocation);
              return user;
            });
          } else if (error.status === 401) {
            dispatch(setSessionExpired());
            throw new Error("Session expired error");
          } else {
            dispatch(setLoginUserNotFound());
            throw new Error("Global init no user error");
          }
        })
        .then((user) => {
          dispatch(upsertLoginUser(user));
          dispatch(globalInitialize());
        });
    }
  }, [token, dispatch, history]);

  return null;
};
const LoginManager = ({ children }) => {
  return (
    <AuthenticationContext.Consumer>
      {(oidcProps) => (
        <TokenManager oidcProps={oidcProps}>{children}</TokenManager>
      )}
    </AuthenticationContext.Consumer>
  );
};

export default withRouter(LoginManager);
