import "./index.scss";

import {
  Badge,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "appkit-react";
import classnames from "classnames";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import { useState } from "react";

const DeleteModal = ({
  children,
  className,
  onConfirm,
  onClose,
  buttonLabel = "Delete",
  title = "Are you sure ?",
  ...modalProps
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleConfirm = async () => {
    setSubmitting(true);
    await onConfirm();
    setSubmitting(false);
    onClose();
  };

  return (
    <ResizableAppkitModal
      className={classnames("delete-modal", className)}
      onCancel={onClose}
      {...modalProps}
    >
      <ModalHeader>
        <Badge type={"warning"}>WARNING</Badge>
        <span className="title">{title}</span>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button kind="transparent" disabled={submitting} onClick={onClose}>
          Cancel
        </Button>
        <Button isLoading={submitting} onClick={handleConfirm}>
          {buttonLabel}
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default DeleteModal;
