import { UseTypedQueryOptions } from "api/UseTypedQueryOptions";
import { UseQueryResult, useQuery } from "react-query";

import {
  LifecycleAllReadDTO,
  LifecycleReadDTO,
} from "../__generated__/CatalogueAPI";
import { getLifeCycles, getLifeCyclesAll } from "../catalogApi";

export default function useLifeCycles(
  props?: UseTypedQueryOptions<LifecycleReadDTO[]>
): UseQueryResult<LifecycleReadDTO[]> {
  return useQuery("lifecycles", () => getLifeCycles(), props);
}

export function useLifeCyclesAll(
  props?: UseTypedQueryOptions<LifecycleAllReadDTO>
): UseQueryResult<LifecycleAllReadDTO> {
  return useQuery("lifecycles-all", () => getLifeCyclesAll(), props);
}
