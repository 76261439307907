function appendValue(formData: FormData, key: string, value: any) {
  formData.append(
    key,
    value instanceof Blob
      ? value
      : typeof value === "object" && value !== null
      ? JSON.stringify(value)
      : `${value}`
  );
}

export function toFormData(object: any) {
  const formData = new FormData();
  for (const [key, value] of Object.entries(object)) {
    if (Array.isArray(value)) {
      for (const arrayValue of value) {
        if (arrayValue) {
          appendValue(formData, key, arrayValue);
        }
      }
    } else if (value) {
      appendValue(formData, key, value);
    }
  }
  return formData;
}
