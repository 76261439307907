import { SelectOption } from "appkit-react";
import Select from "components/Select";
import { AdminServiceContext } from "pages/admin/components/Service/components/AdminServiceContextHOC";
import { useContext } from "react";

interface Props {
  value: string | null;
  onValueChange: (value: string) => void;
  disabled?: boolean;
  hasError?: boolean;
  placeholder?: string;
  className?: string;
}

export default function ServiceSelect({
  value,
  onValueChange,
  ...props
}: Props) {
  const { items: services } = useContext(AdminServiceContext);

  return (
    <Select
      showSearchOnToggle
      value={value}
      onSelect={onValueChange}
      {...props}
    >
      {services
        ?.filter((service) => service.canAddSubscription)
        .map((service) => (
          <SelectOption key={service.id} value={service.id}>
            {service.name}
          </SelectOption>
        ))}
    </Select>
  );
}
