import "./index.scss";

import { Button, Input, List, ListItem } from "appkit-react";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { byId } from "utils/byId";
import { localeCompare } from "utils/localeCompare";

const Item = ({ item, disabled = false, onPrevious, onNext }) => {
  return (
    <ListItem
      className={classNames("multiselect-item", disabled && "disabled")}
    >
      {onPrevious && (
        <Button
          kind="transparent"
          disabled={disabled}
          onClick={() => onPrevious(item)}
        >
          &#x3C;
        </Button>
      )}

      <span className="multiselect-item-label">{item.name}</span>

      {onNext && (
        <Button
          kind="transparent"
          disabled={disabled}
          onClick={() => onNext(item)}
        >
          &#x3E;
        </Button>
      )}
    </ListItem>
  );
};

const MultiSelectColumn = ({ items, disabled, label, onPrevious, onNext }) => {
  const [search, setSearch] = useState("");

  const searchFilter = search.trim().toUpperCase();
  return (
    <div className="multiselect-column">
      <label className="multiselect-column-label">{label}</label>
      <Input
        className="multiselect-column-search"
        value={search}
        onChange={setSearch}
        disabled={disabled}
        placeholder="Search"
        prefix={<span className="appkiticon icon-search-outline" />}
      />

      <List
        className={classNames(
          "multiselect-column-list custom-scrollbar",
          disabled && "disabled"
        )}
      >
        {items
          .filter((item) => item.name.toUpperCase().includes(searchFilter))
          .sort(localeCompare("name"))
          .map((item) => (
            <Item
              key={item.id}
              item={item}
              onPrevious={onPrevious}
              onNext={onNext}
              disabled={disabled}
            />
          ))}
      </List>
    </div>
  );
};

const addTo = (item, array) => {
  return [...array, item];
};

const removeFrom = (item, array) => {
  return array.filter((selected) => selected.id !== item.id);
};

const MultiSelect = ({
  items,
  selection,
  onSelectionChange,
  denied,
  onDeniedChange,
  disabled,
  hasError,
  errorMsg,
}) => {
  const availables = useMemo(() => {
    let othersById = byId(selection);

    if (denied) {
      othersById = {
        ...othersById,
        ...byId(denied),
      };
    }

    return items.filter((item) => !othersById[item.id]);
  }, [items, selection, denied]);

  const handleSelectAll = () => onSelectionChange([...availables]);
  const handleRemoveAll = () => onSelectionChange([]);

  const addToSelection = (item) => onSelectionChange(addTo(item, selection));
  const removeFromSelection = (item) =>
    onSelectionChange(removeFrom(item, selection));

  const handleSelectAllDenied = () => onDeniedChange([...availables]);
  const handleRemoveAllDenied = () => onDeniedChange([]);

  const addToDenied = denied
    ? (item) => onDeniedChange(addTo(item, denied))
    : undefined;
  const removeFromDenied = denied
    ? (item) => onDeniedChange(removeFrom(item, denied))
    : undefined;

  return (
    <div
      className={classNames(
        "multiselect",
        hasError && "errored",
        disabled && "disabled"
      )}
    >
      <div className="multiselect-wrapper">
        {denied && (
          <>
            <MultiSelectColumn
              label="Denied"
              items={denied}
              onNext={removeFromDenied}
              disabled={disabled}
            />

            <div className="multiselect-buttons">
              <Button
                kind="transparent"
                onClick={handleSelectAllDenied}
                disabled={disabled}
              >
                &#x3C;&#x3C;
              </Button>
              <Button
                kind="transparent"
                onClick={handleRemoveAllDenied}
                disabled={disabled}
              >
                &#x3E;&#x3E;
              </Button>
            </div>
          </>
        )}
        <MultiSelectColumn
          label="Available"
          items={availables}
          onPrevious={addToDenied}
          onNext={addToSelection}
          disabled={disabled}
        />

        <div className="multiselect-buttons">
          <Button
            kind="transparent"
            onClick={handleRemoveAll}
            disabled={disabled}
          >
            &#x3C;&#x3C;
          </Button>
          <Button
            kind="transparent"
            onClick={handleSelectAll}
            disabled={disabled}
          >
            &#x3E;&#x3E;
          </Button>
        </div>

        <MultiSelectColumn
          label="Selected"
          items={selection}
          onPrevious={removeFromSelection}
          disabled={disabled}
        />
      </div>

      {hasError && errorMsg && <p className="a-form-error">{errorMsg}</p>}
    </div>
  );
};

export default MultiSelect;
