import "./index.scss";

import { ServiceIllustrationReadDTO } from "api/catalog/__generated__/CatalogueAPI";
import { Avatar, Button, List, ListItem, ListItemText } from "appkit-react";
import classNames from "classnames";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";

interface IIllustrationListProps {
  illustrations: ServiceIllustrationReadDTO[];
  onFilesAddChange: (files: File[]) => void;
  onFilesDeleteChange: (ids: string[]) => void;
}

interface LocalServiceIllustration extends ServiceIllustrationReadDTO {
  file: File;
}

export default function IllustrationList({
  illustrations: currentIllustrations,
  onFilesAddChange,
  onFilesDeleteChange,
}: IIllustrationListProps) {
  const inputFile = useRef<HTMLInputElement>(null);
  const [filesToAdd, setFilesToAdd] = useState<File[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);

  useEffect(() => {
    setFilesToAdd([]);
    setFilesToDelete([]);
  }, [currentIllustrations]);

  const handleClick = () => {
    inputFile?.current?.click();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    var files = event.target.files;
    if (files) {
      setFilesToAdd((prevFiles) => {
        const newFiles = [...prevFiles, ...Array.from(files!)];
        onFilesAddChange(newFiles);
        return newFiles;
      });
    }
  };

  const handleDeleteLocal = (file: LocalServiceIllustration) => {
    setFilesToAdd((prevFiles) => {
      const newFiles = prevFiles.filter((_file) => _file !== file.file);
      onFilesAddChange(newFiles);
      return newFiles;
    });
  };

  const handleDeleteRemote = (file: ServiceIllustrationReadDTO) => {
    setFilesToDelete((prevFiles) => {
      const newFiles = prevFiles.includes(file.id)
        ? prevFiles.filter((id) => id !== file.id)
        : [...prevFiles, file.id];
      onFilesDeleteChange(newFiles);
      return newFiles;
    });
  };

  const illustrations = useMemo<ServiceIllustrationReadDTO[]>(() => {
    return [
      ...currentIllustrations,
      ...filesToAdd.map((file) => ({
        id: file.name,
        contentType: file.type,
        isVideo: file.type.startsWith("video"),
        file,
      })),
    ];
  }, [currentIllustrations, filesToAdd]);

  return (
    <div className="illustration-list">
      <Button onClick={handleClick}>Add illustration</Button>
      <List>
        {illustrations.map((illustration) => (
          <ListItem
            key={illustration.id}
            className={classNames(
              filesToDelete.includes(illustration.id) ? "deleted" : null,
              "file-item"
            )}
          >
            <Button
              className="a-mr-10"
              style={{ padding: 0 }}
              kind="transparent"
              onClick={() => null}
            >
              <span
                className="appkiticon icon-delete-fill"
                onClick={
                  "file" in illustration
                    ? () =>
                        handleDeleteLocal(
                          illustration as LocalServiceIllustration
                        )
                    : () => handleDeleteRemote(illustration)
                }
              />
            </Button>
            <Avatar
              style={{ backgroundColor: "#d04a02" }}
              className="a-mr-10"
              label={illustration.isVideo ? "V" : "P"}
            />
            <ListItemText
              primary={illustration.id}
              secondary={illustration.isVideo ? "video" : "picture"}
            />
          </ListItem>
        ))}
      </List>
      <input
        type="file"
        multiple
        ref={inputFile}
        onChange={onChange}
        style={{ display: "none" }}
        accept="video/*,image/*"
      />
    </div>
  );
}
