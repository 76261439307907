import "./index.scss";

import { getPhoto } from "api/admin/adminApi";
import { Avatar } from "appkit-react";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";

import { getInitialsFromName } from "../../utils/index";

const AvatarWithAsyncPhoto = ({
  photoUri,
  name,
  className = "",
  children = null,
  ...props
}) => {
  const [photo, setPhoto] = useState(undefined);

  useEffect(() => {
    if (photoUri) {
      getPhoto(photoUri).then(setPhoto);
    } else {
      setPhoto(undefined);
    }
  }, [photoUri]);

  const initials = useMemo(() => {
    if (!name) {
      return " ";
    }
    return getInitialsFromName(name);
  }, [name]);

  return (
    <Avatar
      {...props}
      className={classNames("a-avatar-async-photo", className)}
    >
      {photo ? <img src={photo} alt={initials} /> : initials}
      {children && (
        <div className="a-avatar-async-photo-children">{children}</div>
      )}
    </Avatar>
  );
};

export default AvatarWithAsyncPhoto;
