import CatalogueIconFill from "img/icones/icons-outline-app-launcher-fill.svg";
import CatalogueIconOutline from "img/icones/icons-outline-app-launcher.svg";
import NewsIconFill from "img/icones/icons-outline-start-tour-fill.svg";
import NewsIconOutline from "img/icones/icons-outline-start-tour.svg";
// @ts-ignore
import startsWith from "starts-with";

export enum MODULES {
  MYSERVICES = "/myServices",
  MYCLIENTS = "/myClients",
  CATALOGUE = "/",
  NEWS = "/articles",
  NEWS_ADMIN = "/articlesadmin",
  ADMIN = "/admin",
  PROFILE = "/profile",
  CLIENT_VIEW = "/clientView",
}

export const NEWS_MODULE_IS_ENABLED =
  process.env.REACT_APP_FORCE_3W_DISPLAY_MENU === "true" ||
  window.location.host === process.env.REACT_APP_3W_ENABLED_HOST;
export const PSF_PSA_IS_ENABLED =
  process.env.REACT_APP_FORCE_PSF_PSA_DISPLAY === "true" ||
  window.location.host === process.env.REACT_APP_PSF_PSA_ENABLED_HOST;

export type ModuleValues = {
  label: string;
  icon?: string;
  outlineIcon?: any;
  fillIcon?: any;
  match: (path: string) => boolean;
  requireConnexion?: boolean;
  isInternalOnly?: boolean;
  isLuxOnly?: boolean;
};

export const MODULE_VALUES: Record<MODULES, ModuleValues> = {
  [MODULES.MYSERVICES]: {
    label: "My services",
    icon: "icon-grid-view",
    match: (path) => path === MODULES.MYSERVICES,
    requireConnexion: true,
    isLuxOnly: true,
  },
  [MODULES.MYCLIENTS]: {
    label: "My clients",
    icon: "icon-building",
    match: (path) => path === MODULES.MYCLIENTS,
    requireConnexion: true,
    isInternalOnly: true,
    isLuxOnly: true,
  },
  [MODULES.CATALOGUE]: {
    label: "Catalogue",
    outlineIcon: CatalogueIconOutline,
    fillIcon: CatalogueIconFill,
    match: (path) => path === MODULES.CATALOGUE,
    requireConnexion: false,
  },
  [MODULES.NEWS]: {
    label: "News",
    outlineIcon: NewsIconOutline,
    fillIcon: NewsIconFill,
    match: (path) => startsWith(path, MODULES.NEWS),
    requireConnexion: true,
  },
  [MODULES.PROFILE]: {
    match: (path) => startsWith(path, MODULES.PROFILE),
    requireConnexion: true,
    label: "Profile",
  },
  [MODULES.ADMIN]: {
    label: "Admin",
    icon: "icon-setting",
    match: (path) => startsWith(path, MODULES.ADMIN),
    requireConnexion: true,
    isLuxOnly: true,
  },

  [MODULES.NEWS_ADMIN]: {
    label: "News Admin",
    icon: "icon-cogs",
    match: (path) => startsWith(path, MODULES.NEWS_ADMIN),
    requireConnexion: true,
  },

  [MODULES.CLIENT_VIEW]: {
    label: "Client View",
    icon: "icon-building",
    match: (path) => startsWith(path, MODULES.CLIENT_VIEW),
    requireConnexion: true,
    isInternalOnly: true,
    isLuxOnly: true,
  },
};

export const findCurrentModuleKey = (uri: string): MODULES | null => {
  return Object.keys(MODULE_VALUES).find((key) =>
    MODULE_VALUES[key as MODULES].match(uri)
  ) as MODULES | null;
};

export const findCurrentModule = (uri: string) => {
  const key = findCurrentModuleKey(uri);

  return key ? MODULE_VALUES[key] : null;
};
