import { Select, SelectOption } from "appkit-react";

interface Props {
  value: boolean;
  onValueChange: (value: boolean) => void;
}

export default function MyServicesFilter({
  value = false,
  onValueChange,
}: Props) {
  const handleSelect = (value: string) => onValueChange(value === "true");
  return (
    <Select value={value.toString()} onSelect={handleSelect}>
      <SelectOption key={"my-services"} value="true">
        Only my services
      </SelectOption>
      <SelectOption key={"all-services"} value="false">
        All services
      </SelectOption>
    </Select>
  );
}
