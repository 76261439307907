import deepEqual from "deep-equal";
import { useCallback, useEffect, useRef, useState } from "react";

export function hasError<T>(errors: FormErrors<T>) {
  return typeof errors === "boolean"
    ? !errors
    : !!errors && Object.values(errors).findIndex((value) => !!value) >= 0;
}

type Validator<T> = (values: T) => FormErrors<T>;
export type FormErrors<T> = {
  [Property in keyof T]?: FormErrors<any> | string | boolean | null;
};

interface Submitable<T> {
  values: T;
  handleValueChange: (property: keyof T, value: any) => void;
  handleFormChange: (form: T) => void;
  resetForm: (form?: T) => void;
  submitting: boolean;
  formErrors: FormErrors<T>;
  submit: (
    onSubmit: (form: T) => Promise<any>,
    onError?: (errors: any) => FormErrors<T>,
    resetAfterSuccess?: boolean
  ) => void;
}

export default function useSubmitable<T>(
  defaultValues: T,
  validator: Validator<T>
): Submitable<T> {
  const immutableDefaultValues = useRef(defaultValues);

  const [touched, setTouched] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(
    Object.keys(immutableDefaultValues.current as any).reduce(
      (acc, property) => {
        // @ts-ignore
        acc[property] = undefined;
        return acc;
      },
      {}
    )
  );
  const [autoValidate, setAutoValidate] = useState(false);

  useEffect(() => {
    if (autoValidate) {
      setErrors(validator(values));
    }
  }, [values, validator, autoValidate]);

  useEffect(() => {
    setTouched(!deepEqual(immutableDefaultValues.current, values));
  }, [values]);

  useEffect(() => {
    if (touched) {
      const listener = (e: any) => {
        e.preventDefault();
        e.returnValue = "";
      };
      window.addEventListener("beforeunload", listener);
      return () => {
        window.removeEventListener("beforeunload", listener);
      };
    }
  }, [touched]);

  const handleValueChange = useCallback((property, value) => {
    setValues((values) => {
      return {
        ...values,
        [property]: value,
      };
    });
  }, []);

  const resetForm = useCallback((values) => {
    setTouched(false);
    setErrors({});
    setAutoValidate(false);
    setSubmitting(false);
    setValues(values || immutableDefaultValues.current);
  }, []);

  const submit = useCallback(
    (onSubmit, onError, resetAfterSuccess = true) => {
      setAutoValidate(true);

      const errors = validator(values);
      setErrors(errors);

      if (!hasError(errors)) {
        setSubmitting(true);
        return onSubmit(values)
          .then(() => {
            if (resetAfterSuccess) {
              resetForm(values);
            }
          })
          .finally(() => {
            setTouched(false);
            setSubmitting(false);
          });
      } else if (onError) {
        onError(errors);
      }
    },
    [values, validator, resetForm]
  );

  return {
    values,
    handleValueChange,
    handleFormChange: setValues,
    resetForm,
    submitting,
    formErrors: errors,
    submit,
  };
}
