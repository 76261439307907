import { resendUserWelcomeEmails } from "api/admin/adminApi";
import {
  Badge,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
} from "appkit-react";
import classnames from "classnames";
import { useState } from "react";

const NotifyUserModal = ({ user, className, onCancel, ...modalProps }) => {
  const [submitting, setSubmitting] = useState(false);
  const [creationIdentity, setCreationIdentity] = useState("2");
  const [sendAmds, setSendAmds] = useState(false);
  const [send3w, setSend3w] = useState(false);

  const notifyUser = async () => {
    setSubmitting(true);
    try {
      await resendUserWelcomeEmails(
        user.ppid,
        send3w,
        sendAmds,
        creationIdentity
      );
      setCreationIdentity("2");
      setSendAmds(false);
      setSend3w(false);
      onCancel();
    } finally {
      setSubmitting(false);
    }
  };

  const canSubmit = send3w || sendAmds;

  return (
    <Modal
      className={classnames("notify-modal", className)}
      onCancel={onCancel}
      kind="lg"
      visible={!!user}
      {...modalProps}
    >
      <ModalHeader>
        <Badge type={"info"}>Information</Badge>
        <span className="title">Notify user</span>
      </ModalHeader>
      <ModalBody>
        <div>
          {" "}
          <div className="row">
            <div className="col-md-12">
              Resend welcome e-mails to the user{" "}
              <strong>{user?.name ?? ""}</strong>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Checkbox
                checked={send3w}
                onChange={() => {
                  setSend3w(!send3w);
                }}
              >
                Re-Send 3W email
              </Checkbox>
              <br />
              <Checkbox
                checked={sendAmds}
                onChange={() => {
                  setSendAmds(!sendAmds);
                }}
              >
                Re-Send AMDS welcome email
              </Checkbox>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <label className="a-form-label">User type</label>
              <br />
              <RadioGroup
                value={creationIdentity}
                onChange={(event, value) => {
                  setCreationIdentity(value);
                }}
              >
                <Radio value={"1"}>Global</Radio>
                <Radio value={"2"}>Luxembourg</Radio>
              </RadioGroup>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" disabled={submitting} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!canSubmit}
          isLoading={submitting}
          onClick={notifyUser}
        >
          Notify
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NotifyUserModal;
