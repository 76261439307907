import "./AppForm.scss";

import { ApplicationReadDTO } from "api/catalog/__generated__/CatalogueAPI";
import { Button, Input, Switch } from "appkit-react";
import { FormErrors } from "hooks/useSubmitable";
import UserSelect, {
  INTERNAL_ONLY_FILTERS,
} from "pages/admin/components/AddUserToElementModal/UserSelect";
import { Ref } from "react";

interface Props {
  value: ApplicationReadDTO;
  errors?: FormErrors<ApplicationReadDTO> | null;
  onValueChange: (value: ApplicationReadDTO) => void;
  onDelete?: () => void;
  onUnfocus?: () => void;
  clickOutsideRef: Ref<HTMLDivElement | null>;
}
export default function ApplicationForm({
  value: application,
  errors,
  onValueChange,
  onDelete,
  onUnfocus,
  clickOutsideRef,
}: Props) {
  const handleValueChange = (property: keyof ApplicationReadDTO) => (
    value: string
  ) => {
    onValueChange({
      ...application,
      [property]: value,
    });
  };

  return (
    <div className="app-form">
      <div className="app-form-inputs">
        <Input
          value={application.name}
          onChange={handleValueChange("name")}
          hasError={!!errors?.name}
          errMsg={errors?.name || null}
          placeholder="Name"
        />
        <Input
          value={application.link}
          onChange={handleValueChange("link")}
          placeholder="Link"
          onBlur={onUnfocus}
          hasError={!!errors?.link}
          errMsg={errors?.link || null}
        />

        <UserSelect
          clickOutsideRef={clickOutsideRef}
          value={application.itContact}
          onChange={handleValueChange("itContact")}
          placeholder="IT team"
          filters={INTERNAL_ONLY_FILTERS}
        />

        <Switch
          className="switch-showToClient"
          checked={application.showToClient}
          onChange={handleValueChange("showToClient")}
          onText="Yes"
          offText="No"
        />

        <Button kind="transparent" onClick={onDelete} disabled={!onDelete}>
          <span className="appkiticon icon-delete-fill" />
        </Button>
      </div>
    </div>
  );
}
