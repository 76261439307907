import "./index.scss";

import {
  downloadSubscriptionImportTemplate,
  importSubscriptionFromExcel,
} from "api/admin/adminApi";
import EngagementsTable from "pages/admin/components/Engagement/EngagementsTable";
import UploadImport from "pages/newsAdmin/components/UploadImport";

export default function SubscriptionAdmin() {

  return (
    <div className="subscription-admin">
      <UploadImport
        onImport={importSubscriptionFromExcel}
        onDownloadTemplate={downloadSubscriptionImportTemplate}
        type="Subscription"
      />
        <EngagementsTable/>
    </div>
  );
}
