import "./expanded.scss";

import classnames from "classnames";

const Expanded = ({ isExpanded }) => (
  <span
    className={classnames(
      "appkiticon expanded-column",
      isExpanded ? "icon-up-chevron-fill" : "icon-down-chevron-fill"
    )}
  />
);

export default Expanded;
