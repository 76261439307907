import deepEqual from "deep-equal";

import { pollData } from "../apiPoller";
import {
  getThreeWPermissions,
  updateThreeWPermissions,
} from "./engagementAccessApi";

export const pollUpdateThreeWPermissions = async (
  idSubscription,
  permissions,
  oldData
) => {
  if (deepEqual(permissions, oldData)) {
    return Promise.resolve(permissions);
  }

  await updateThreeWPermissions(idSubscription, permissions);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getThreeWPermissions(idSubscription).then((data) => {
          !deepEqual(data, oldData) ? resolve(data) : reject();
        });
      }),
    (err) => err === undefined,
    0,
    oldData
  );
};
