import "./MenuList.scss";

import { ListItem } from "appkit-react";
import MenuList from "components/MenuList";
import { SyntheticEvent } from "react";

interface Props {
  onAddUser: () => void;
  onAddMultipleUsers: () => void;
  onEditEntity: () => void;
  onDeleteEntity: () => void;
}

export default function EntityMenuList({
  onAddUser,
  onAddMultipleUsers,
  onEditEntity,
  onDeleteEntity,
}: Props) {
  return (
    <MenuList
      className="entity-menu-list"
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      <ListItem
        onClick={(event: SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
          onAddUser();
        }}
      >
        <span className="appkiticon a-btn-icon icon-add-user-fill" />
        Add user to company
      </ListItem>
      <ListItem
        onClick={(event: SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
          onAddMultipleUsers();
        }}
      >
        <span className="appkiticon a-btn-icon icon-stewardship-fill" />
        Add multiple new users to company
      </ListItem>
      <ListItem
        onClick={(event: SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
          onEditEntity();
        }}
      >
        <span className="appkiticon a-btn-icon icon-edit-fill" />
        Edit
      </ListItem>

      <ListItem
        onClick={(event: SyntheticEvent) => {
          event.preventDefault();
          event.stopPropagation();
          onDeleteEntity();
        }}
      >
        <span className="appkiticon a-btn-icon icon-delete-fill" />
        Delete
      </ListItem>
    </MenuList>
  );
}
