import { PopBanner } from "appkit-react";
import DeleteModal from "components/DeleteModal";
import { useContext } from "react";

import { AdminEngagementContext } from "..";

const DeleteEngagementModal = ({ engagement, onClose, ...modalProps }) => {
  const { deleteSubscription } = useContext(AdminEngagementContext);
  const submitDeleteEngagement = async () => {
    await deleteSubscription(engagement.id);

    PopBanner({
      content: "The subscription has been successfully deleted.",
      duration: 4000,
      status: "success",
    });

    onClose();
  };

  return (
    <DeleteModal
      visible={!!engagement}
      className="delete-engagement-modal"
      onClose={onClose}
      title="Delete subscription ?"
      onConfirm={submitDeleteEngagement}
      {...modalProps}
    >
      <p>
        You're about to remove the subscription {engagement?.name} along with
        all of its associated information, including users.&nbsp;
        <strong>This action cannot be undone</strong>.
      </p>
    </DeleteModal>
  );
};

export default DeleteEngagementModal;
