import "./UnexpectedError.scss";

import { Button } from "appkit-react";
import classnames from "classnames";
import CategoryPitch from "components/CategoryPitch";
import { handleLogout } from "components/Header";
import PageTitle from "components/PageTitle";
import { useEffect } from "react";

import backgroundImage from "../../img/illustrations/undraw_thoughts_e49y.svg";

const ControlledError = ({
  title,
  subtitle,
  description,
  illustration = backgroundImage,
  showLogoutButton = true,
  showReloadButton = true,
  customButton = undefined,
  dark = false,
}) => {
  useEffect(() => {
    document.body.classList.add("has-unrecoverable-error");
    return () => {
      document.body.classList.remove("has-unrecoverable-error");
    };
  }, []);

  return (
    <main className={classnames("container error-unexpected", dark && "dark")}>
      <PageTitle title={title} />
      <CategoryPitch title={title} image={illustration} maxWidth="30%">
        <p className="subtitle">{subtitle}</p>
      </CategoryPitch>
      <p>{description}</p>
      <div className="btn-wrapper">
        {showLogoutButton && (
          <Button size="lg" kind="secondary" onClick={handleLogout}>
            Logout
          </Button>
        )}
        {showReloadButton && (
          <Button
            size="lg"
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </Button>
        )}
        {customButton}
      </div>
    </main>
  );
};

export default ControlledError;
