import "./index.scss";

import { UserReadDTO } from "api/admin/__generated__/UserManagementAPI";
import {
  Button,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectOption,
  Switch,
  TextArea,
} from "appkit-react";
import ClearableCalendarPicker from "components/ClearableCalendarPicker";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import Select from "components/Select";
import useSubmitable from "hooks/useSubmitable";
import UserSelect, {
  INTERNAL_ONLY_FILTERS,
} from "pages/admin/components/AddUserToElementModal/UserSelect";
import CompanySelect from "pages/admin/components/CompanySelect";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";

import { AdminEntityContext } from "../../Entity";
import { ECModules, ECSModules, toECModules, toECSModules } from "../types";
import {
  SubscriptionForm,
  findFormErrors,
  toSubscriptionForm,
} from "../types/form";
import EntitySelect from "./EntitySelect";
import LinksForm from "./LinksForm";
import ServiceSelect from "./ServiceSelect";

const DEFAULT_FORM: SubscriptionForm = {
  id: null,
  companyId: null,
  entityId: null,
  serviceId: null,
  endDate: null,
  ec: "",
  ecModules: toECModules(),
  hasECLink: false,
  ecs: "",
  ecsModules: toECSModules(),
  hasECSLink: false,
  clientContent: "",
  links: [],
  contacts: [],
  users: [],
};

interface Props {
  subscription: any;
  visible: boolean;
  onClose: () => void;
  onUpsertSubscription: (form: SubscriptionForm) => Promise<any>;
}

const AddEngagementModal = ({
  subscription,
  visible,
  onClose,
  onUpsertSubscription,
}: Props) => {
  const isEditMode = useMemo(() => !!subscription, [subscription]);

  const findFormErrorsWrapper = useCallback(
    (form) => findFormErrors(form, isEditMode),
    [isEditMode]
  );

  const {
    values: form,
    handleValueChange,
    submitting,
    formErrors,
    resetForm,
    submit,
  } = useSubmitable(DEFAULT_FORM, findFormErrorsWrapper);
  const clickOutsideRef = useRef<HTMLDivElement | null>(null);

  const { items: entities } = useContext(AdminEntityContext);

  var users = useMemo(() => {
    const selectedEntity = entities?.find(
      (entity: any) => entity.id === form.entityId
    );
    return !selectedEntity?.users ? null : selectedEntity.users;
  }, [entities, form.entityId]);

  const handleEcsModulesChange = (
    property: keyof ECSModules,
    checked: boolean
  ) => {
    handleValueChange("ecsModules", {
      ...form.ecsModules,
      [property]: checked,
    });
  };

  const handleEcModulesChange = (
    property: keyof ECModules,
    checked: boolean
  ) => {
    handleValueChange("ecModules", {
      ...form.ecModules,
      [property]: checked,
    });
  };

  const handleSubmit = () => {
    submit((form) => {
      return onUpsertSubscription(form).then(() => {
        resetForm();
        onClose();
      });
    });
  };

  useEffect(() => {
    handleValueChange("entityId", null);
  }, [form.companyId, handleValueChange]);

  useEffect(() => {
    if (visible && subscription) {
      resetForm(toSubscriptionForm(subscription));
    } else {
      resetForm();
    }
  }, [visible, subscription, resetForm]);

  const selectedMainContacts = useMemo(() => {
    if (form.users && users) {
      return users.filter((u: any) =>
        form.users.some((u2) => u.ppid === u2.ppid)
      );
    }
  }, [form.users, users]);

  return (
    <ResizableAppkitModal
      visible={visible}
      className="add-engagement-modal"
      onCancel={onClose}
      backdropClosable={false}
    >
      <ModalHeader>
        <div className="modal-title">
          {subscription ? "Edit subscription" : "Create subscription"}
        </div>
      </ModalHeader>
      <ModalBody>
        <div ref={clickOutsideRef}>
          <fieldset>
            <legend>Subscription information</legend>
            <div className="row">
              <div className="col-md-6">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Company group
                </label>
                <CompanySelect
                  value={form.companyId}
                  onValueChange={(companyId: string) =>
                    handleValueChange("companyId", companyId)
                  }
                  placeholder="Company group"
                  disabled={!!subscription}
                  hasError={!!formErrors.companyId}
                />
              </div>
              <div className="col-md-6">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Company
                </label>
                <EntitySelect
                  companyId={form.companyId}
                  companyIdRequired
                  value={form.entityId}
                  onValueChange={(entityId: string) =>
                    handleValueChange("entityId", entityId)
                  }
                  placeholder="Company"
                  disabled={!!subscription}
                  hasError={!!formErrors.entityId}
                  className="create-subscription-entity-select"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Service
                </label>
                <ServiceSelect
                  value={form.serviceId}
                  className="create-subscription-service-select"
                  onValueChange={(serviceId) =>
                    handleValueChange("serviceId", serviceId)
                  }
                  placeholder="Service"
                  disabled={!!subscription}
                  hasError={!!formErrors.serviceId}
                />
              </div>
              <div className="col-md-6">
                <label className="a-form-label">End date</label>
                <ClearableCalendarPicker
                  format="dd/mm/yyyy"
                  navigationLayoutMode="second"
                  placeholder="End date"
                  value={form.endDate}
                  onChange={(newDate: Date) =>
                    handleValueChange("endDate", newDate)
                  }
                  onClearValue={() => handleValueChange("endDate", null)}
                  hasError={!!formErrors.endDate}
                  errMsg={formErrors.endDate as any}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;PwC Contacts
                </label>
                <UserSelect
                  clickOutsideRef={clickOutsideRef}
                  value={form.contacts}
                  onChange={(value: UserReadDTO[]) =>
                    handleValueChange("contacts", value)
                  }
                  placeholder="At least one PWC contact is required"
                  hasError={!!formErrors.contacts}
                  errMsg={formErrors.contacts}
                  multiple
                  filters={INTERNAL_ONLY_FILTERS}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Main
                  contact(s)
                </label>
                {!!users && (
                  <Select
                    multiple
                    value={selectedMainContacts}
                    onSelect={(value: UserReadDTO[]) =>
                      handleValueChange("users", value)
                    }
                    placeholder="At least one contact is required"
                  >
                    {users?.map((user: UserReadDTO) => (
                      <SelectOption key={user.ppid} value={user}>
                        {user.email}
                      </SelectOption>
                    ))}
                  </Select>
                )}
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Engagement Center</legend>
            <div className="row">
              <div className="col-12">
                <label className="a-form-label">
                  Connected to Engagement Center ?
                </label>
              </div>
              <div className="col-2">
                <Switch
                  checked={form.hasECLink}
                  onChange={(value: boolean) =>
                    handleValueChange("hasECLink", value)
                  }
                  onText="Yes"
                  offText="No"
                />
              </div>

              <div className="col-10">
                <Input
                  placeholder="EC Engagement ID"
                  disabled={submitting || !form.hasECLink}
                  value={form.ec}
                  onChange={(value: string) => handleValueChange("ec", value)}
                  hasError={!!formErrors.ec}
                  errMsg={formErrors.ec}
                />
              </div>
            </div>

            {form.hasECLink && (
              <div className="row">
                <div className="col-12">
                  <div className="a-form-label">Your engagement has:</div>
                </div>
                <div className="col-4">
                  <Switch
                    label="Deliverables"
                    checked={form.ecModules.deliverables}
                    onChange={(value: boolean) =>
                      handleEcModulesChange("deliverables", value)
                    }
                    onText="Yes"
                    offText="No"
                    disabled={submitting || !form.hasECLink}
                  />
                </div>
                <div className="col-4">
                  <Switch
                    label="Documents"
                    checked={form.ecModules.documents}
                    onChange={(value: boolean) =>
                      handleEcModulesChange("documents", value)
                    }
                    onText="Yes"
                    offText="No"
                    disabled={submitting || !form.hasECLink}
                  />
                </div>
              </div>
            )}
          </fieldset>

          <fieldset>
            <legend>eCS</legend>

            <div className="row">
              <div className="col-12">
                <label className="a-form-label">Connected to eCS ?</label>
              </div>
              <div className="col-2">
                <Switch
                  checked={form.hasECSLink}
                  onChange={(value: boolean) =>
                    handleValueChange("hasECSLink", value)
                  }
                  onText="Yes"
                  offText="No"
                />
              </div>
              <div className="col-10">
                <Input
                  placeholder="eCS site name"
                  disabled={submitting || !form.hasECSLink}
                  value={form.ecs}
                  onChange={(value: string) => handleValueChange("ecs", value)}
                  hasError={!!formErrors.ecs}
                  errMsg={formErrors.ecs}
                />
              </div>
            </div>

            {form.hasECSLink && (
              <div className="row">
                <div className="col-12">
                  <label className="a-form-label">Your eCS site has:</label>
                </div>
                <div className="col-4">
                  <Switch
                    label="Documents"
                    checked={form.ecsModules.documents}
                    onChange={(value: boolean) =>
                      handleEcsModulesChange("documents", value)
                    }
                    onText="Yes"
                    offText="No"
                    disabled={submitting || !form.hasECSLink}
                  />
                </div>
                <div className="col-4">
                  <Switch
                    label="Links"
                    checked={form.ecsModules.links}
                    onChange={(value: boolean) =>
                      handleEcsModulesChange("links", value)
                    }
                    onText="Yes"
                    offText="No"
                    disabled={submitting || !form.hasECSLink}
                  />
                </div>
                <div className="col-4">
                  <Switch
                    label="Tasks"
                    checked={form.ecsModules.tasks}
                    onChange={(value: boolean) =>
                      handleEcsModulesChange("tasks", value)
                    }
                    onText="Yes"
                    offText="No"
                    disabled={submitting || !form.hasECSLink}
                  />
                </div>
              </div>
            )}
          </fieldset>

          <div className="row">
            <div className="col-12">
              <label className="a-form-label">Message</label>
              <TextArea
                value={form.clientContent}
                onChange={(value: string) =>
                  handleValueChange("clientContent", value)
                }
                placeholder="Description"
                rows="4"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="row" style={{ marginBottom: 0 }}>
                <div className="col-8">
                  <label className="a-form-label">Links</label>
                </div>
                <div className="col-4 text-right">
                  <label className="a-form-label">
                    Display to the client ?
                  </label>
                </div>
              </div>

              <LinksForm
                items={form.links}
                // @ts-ignore
                errors={formErrors.links}
                onItemsChange={(links) => handleValueChange("links", links)}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onClose} disabled={submitting}>
          Cancel
        </Button>
        <Button kind="primary" isLoading={submitting} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default AddEngagementModal;
