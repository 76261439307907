/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CompanyReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;
}

export interface CompanyCreateDTO {
  name: string | null;
}

export interface CompanyUpdateDTO {
  name: string | null;
}

export interface AssignedUserReadDTO {
  ppid: string | null;
  name: string | null;
  email: string | null;
  isMainContact: boolean;
}

export interface ContactReadDTO {
  ppid: string | null;
  name: string | null;
  email: string | null;

  /** @format int32 */
  phoneCountryCode: number | null;
  phoneNumber: string | null;
  photoUri: string | null;
}

export interface EntityDetailsReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;

  /** @format uuid */
  companyId: string;
  companyName: string | null;
  prid: string | null;
  viewInMyServices: boolean;
  users: AssignedUserReadDTO[] | null;
  contacts: ContactReadDTO[] | null;
}

export interface EntityCreateDTO {
  /** @format uuid */
  companyId: string;
  name: string | null;
  prid: string | null;
  viewInMyServices: boolean;
  contacts: string[] | null;
}

export interface EntityUpdateDTO {
  name: string | null;
  prid: string | null;
  viewInMyServices: boolean;
  contacts: string[] | null;
}

export interface EntityContactsReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;
  contacts: ContactReadDTO[] | null;
}

export interface EntityUserAddDTO {
  ppid: string | null;
}

export interface EntityUserRemoveDTO {
  ppid: string | null;
}

export interface EntityReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;

  /** @format uuid */
  companyId: string;
  companyName: string | null;
  prid: string | null;
  viewInMyServices: boolean;
}

export interface OrganizationFilters {
  name: string | null;
}

export interface OrderBy {
  isDescending: boolean | null;
}

export interface OrganizationOrderBy {
  name: OrderBy | null;
}

export interface OrganizationQueryDTO {
  filters: OrganizationFilters | null;
  orderBy: OrganizationOrderBy | null;
}

export interface OrganizationReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;
}

export interface OrganizationReadDTOPagedCollection {
  /** @format int32 */
  currentPage: number;

  /** @format int32 */
  totalPages: number;

  /** @format int32 */
  pageSize: number;

  /** @format int32 */
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
  items: OrganizationReadDTO[] | null;
}

export interface OrganizationFullReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;
  team: string[] | null;
}

export interface ServiceReadForAdminDTO {
  /** @format uuid */
  id: string;
  name: string | null;
  live: boolean;
  isNew: boolean;
  isHighlighted: boolean;
  canAddSubscription: boolean;
  domains: string[];
}

export interface MyServicePwCReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;
}

export interface ApplicationReadDTO {
  name: string | null;
  link: string | null;
  showToClient: boolean;
}

export interface SubscriptionDetailsReadDTO {
  /** @format uuid */
  id: string;

  /** @format uuid */
  companyId: string;
  companyName: string | null;

  /** @format uuid */
  entityId: string;
  entityName: string | null;

  /** @format uuid */
  serviceId: string;
  serviceName: string | null;
  trial: boolean;

  /** @format date-time */
  endDate: string | null;
  ec: string | null;
  ecModules: Record<string, boolean>;
  ecs: string | null;
  ecsModules: Record<string, boolean>;
  clientContent: string | null;
  applications: ApplicationReadDTO[] | null;
  users: AssignedUserReadDTO[] | null;
  contacts: ContactReadDTO[] | null;

  /** @format int32 */
  userCount: number;
}

export interface ApplicationCreateDTO {
  name: string | null;
  link: string | null;
  showToClient: boolean;
}

export interface SubscriptionUpdateDTO {
  /** @format date-time */
  endDate: string | null;
  ec: string | null;
  ecModules: Record<string, boolean>;
  ecs: string | null;
  ecsModules: Record<string, boolean>;
  clientContent: string | null;
  applications: ApplicationCreateDTO[] | null;
  contacts: string[] | null;
}

export interface MyServiceClientReadDTO {
  /** @format uuid */
  serviceId: string;
  serviceName: string | null;
  ec: string | null;
  ecModules: Record<string, boolean>;
  ecs: string | null;
  ecsModules: Record<string, boolean>;
  clientContent: string | null;
  applications: ApplicationReadDTO[] | null;
  contacts: ContactReadDTO[] | null;
  clientContacts: ContactReadDTO[] | null;
}

export interface MyClientReadDTO {
  /** @format uuid */
  id: string;
  name: string | null;
}

export interface MyClientServiceReadDTO {
  /** @format uuid */
  id: string;

  /** @format uuid */
  serviceId: string;
  serviceName: string | null;
  myService: boolean;
  trial: boolean;

  /** @format date-time */
  endDate: string | null;
  clientContacts: ContactReadDTO[] | null;
}

export interface MyClientServicesReadDTO {
  /** @format uuid */
  entityId: string;
  entityName: string | null;
  subscriptions: MyClientServiceReadDTO[] | null;
}

export interface SubscriptionReadDTO {
  /** @format uuid */
  id: string;

  /** @format uuid */
  companyId: string;
  companyName: string | null;

  /** @format uuid */
  entityId: string;
  entityName: string | null;

  /** @format uuid */
  serviceId: string;
  serviceName: string | null;
  trial: boolean;

  /** @format date-time */
  endDate: string | null;
  ec: string | null;
  ecModules: Record<string, boolean>;
  ecs: string | null;
  ecsModules: Record<string, boolean>;
  clientContent: string | null;
  applications: ApplicationReadDTO[] | null;
  contacts: ContactReadDTO[] | null;
  clientContacts: ContactReadDTO[] | null;
}

/**
 * @format int32
 */
export type SubscriptionTrialFilter = 0 | 1 | 2 | 3;

export interface SubscriptionFilters {
  /** @format uuid */
  companyId: string | null;
  companyName: string | null;

  /** @format uuid */
  entityId: string | null;
  entityName: string | null;

  /** @format uuid */
  serviceId: string | null;
  serviceName: string | null;
  memberPpid: string | null;
  memberName: string | null;
  my: boolean | null;
  trial: SubscriptionTrialFilter;
}

export interface SubscriptionOrderBy {
  companyName: OrderBy | null;
  entityName: OrderBy | null;
  serviceName: OrderBy | null;
}

export interface SubscriptionQueryDTO {
  filters: SubscriptionFilters | null;
  orderBy: SubscriptionOrderBy | null;
}

export interface SubscriptionUserAddDTO {
  ppid: string | null;
  isMainContact: boolean;
}

export interface SubscriptionCreateDTO {
  /** @format uuid */
  companyId: string;

  /** @format uuid */
  entityId: string;

  /** @format uuid */
  serviceId: string;

  /** @format date-time */
  endDate: string | null;
  ec: string | null;
  ecModules: Record<string, boolean>;
  ecs: string | null;
  ecsModules: Record<string, boolean>;
  clientContent: string | null;
  applications: ApplicationCreateDTO[] | null;
  contacts: string[] | null;
  users: SubscriptionUserAddDTO[] | null;
}

export interface SubscriptionUserUpdateDTO {
  ppid: string | null;
  isMainContact: boolean;
}

export interface SubscriptionUserRemoveDTO {
  ppid: string | null;
}

/**
 * @format int32
 */
export type UserIdentity = 0 | 1 | 2;

export interface UserReadDTO {
  /** @format uuid */
  id: string;
  ppid: string | null;
  name: string | null;
  lastName: string | null;
  firstName: string | null;
  email: string | null;

  /** @format int32 */
  phoneCountryCode: number | null;
  phoneNumber: string | null;
  grade: string | null;
  photoUri: string | null;
  isInternal: boolean;
  isSuperUser: boolean;
  termsAndConditionsAccepted: boolean;
  isLu: boolean;
  creationIdentity: UserIdentity;
}

export interface UserFilters {
  name: string | null;
  email: string | null;
  grade: string | null;
  isSuperUser: boolean | null;
  isInternal: boolean | null;
  subscriptionId?: string | null;
  ppids: string[] | null;

  /** @format uuid */
  entityId: string | null;
  territory: string | null;
}

export interface UserOrderBy {
  name: OrderBy | null;
  email: OrderBy | null;
  grade: OrderBy | null;
}

export interface UserQueryDTO {
  filters: UserFilters | null;
  orderBy: UserOrderBy | null;
}

export interface UserLookupReadDTO {
  ppid: string | null;
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  pwcEmail: string | null;
  cloudEmail: string | null;
  preferredEmail: string | null;
  territory: string | null;
  isPwcUser: boolean;
  workPhone: string | null;
  mobilePhone: string | null;
  grade: string | null;
  jobTitle: string | null;
  losLevel1: string | null;
  losLevel2: string | null;
  losGlobalLevel1: string | null;
  losGlobalLevel2: string | null;
  costCenter: string | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`
    )}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${
        queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
          ...(requestParams.headers || {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title User Management Services v1
 * @version v1
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Company
     * @name V1CompanyList
     * @summary Get all companies.
     * @request GET:/api/v1/Company
     * @secure
     */
    v1CompanyList: (params: RequestParams = {}) =>
      this.request<CompanyReadDTO[], any>({
        path: `/api/v1/Company`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name V1CompanyCreate
     * @summary Create a company.
     * @request POST:/api/v1/Company
     * @secure
     */
    v1CompanyCreate: (data: CompanyCreateDTO, params: RequestParams = {}) =>
      this.request<CompanyReadDTO, any>({
        path: `/api/v1/Company`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name V1CompanyUpdate
     * @summary Update a company.
     * @request PUT:/api/v1/Company/{companyId}
     * @secure
     */
    v1CompanyUpdate: (
      companyId: string,
      data: CompanyUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Company/${companyId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name V1CompanyDelete
     * @summary Delete a company.
     * @request DELETE:/api/v1/Company/{companyId}
     * @secure
     */
    v1CompanyDelete: (companyId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Company/${companyId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Entity
     * @name V1EntityList
     * @summary Get all entities with users.
     * @request GET:/api/v1/Entity
     * @secure
     */
    v1EntityList: (params: RequestParams = {}) =>
      this.request<EntityDetailsReadDTO[], any>({
        path: `/api/v1/Entity`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Entity
     * @name V1EntityCreate
     * @summary Create an entity.
     * @request POST:/api/v1/Entity
     * @secure
     */
    v1EntityCreate: (data: EntityCreateDTO, params: RequestParams = {}) =>
      this.request<EntityDetailsReadDTO, any>({
        path: `/api/v1/Entity`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Entity
     * @name V1EntityDetail
     * @summary Get all entities with users.
     * @request GET:/api/v1/Entity/{entityId}
     * @secure
     */
    v1EntityDetail: (entityId: string, params: RequestParams = {}) =>
      this.request<EntityDetailsReadDTO, any>({
        path: `/api/v1/Entity/${entityId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Entity
     * @name V1EntityUpdate
     * @summary Update an entity.
     * @request PUT:/api/v1/Entity/{entityId}
     * @secure
     */
    v1EntityUpdate: (
      entityId: string,
      data: EntityUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Entity/${entityId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Entity
     * @name V1EntityDelete
     * @summary Delete an entity.
     * @request DELETE:/api/v1/Entity/{entityId}
     * @secure
     */
    v1EntityDelete: (entityId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Entity/${entityId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Entity
     * @name V1EntityMyContactsList
     * @summary Get available contact the client is member of the entity.
     * @request GET:/api/v1/Entity/my-contacts
     * @secure
     */
    v1EntityMyContactsList: (params: RequestParams = {}) =>
      this.request<EntityContactsReadDTO[], any>({
        path: `/api/v1/Entity/my-contacts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Entity
     * @name V1EntityUsersUpdate
     * @summary Add or update a user in a entity.
     * @request PUT:/api/v1/Entity/{entityId}/users
     * @secure
     */
    v1EntityUsersUpdate: (
      entityId: string,
      data: EntityUserAddDTO[],
      params: RequestParams = {}
    ) =>
      this.request<EntityDetailsReadDTO, any>({
        path: `/api/v1/Entity/${entityId}/users`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Entity
     * @name V1EntityUsersDelete
     * @summary Remove user from an entity.
     * @request DELETE:/api/v1/Entity/{entityId}/users
     * @secure
     */
    v1EntityUsersDelete: (
      entityId: string,
      data: EntityUserRemoveDTO,
      params: RequestParams = {}
    ) =>
      this.request<EntityDetailsReadDTO, any>({
        path: `/api/v1/Entity/${entityId}/users`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Entity
     * @name V1EntityImportList
     * @summary Download the template for the import.
     * @request GET:/api/v1/Entity/import
     * @secure
     */
    v1EntityImportList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Entity/import`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Entity
     * @name V1EntityImportCreate
     * @summary Import users from Excel file.
     * @request POST:/api/v1/Entity/import
     * @secure
     */
    v1EntityImportCreate: (
      data: { importFile?: File },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Entity/import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntityIntegration
     * @name V1IntegrationEntityByUserDetail
     * @summary Get a user.
     * @request GET:/api/v1/Integration/Entity/by-user/{ppid}
     * @secure
     */
    v1IntegrationEntityByUserDetail: (
      ppid: string,
      params: RequestParams = {}
    ) =>
      this.request<EntityReadDTO[], any>({
        path: `/api/v1/Integration/Entity/by-user/${ppid}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Export
     * @name V1ExportUsersPermissionsCreate
     * @summary Export all permissions in Excel file.
     * @request POST:/api/v1/Export/UsersPermissions
     * @secure
     */
    v1ExportUsersPermissionsCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Export/UsersPermissions`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name V1OrganizationPagedCreate
     * @summary Get organizations by page.
     * @request POST:/api/v1/Organization/paged
     * @secure
     */
    v1OrganizationPagedCreate: (
      data: OrganizationQueryDTO,
      query?: { pageSize?: number; pageNumber?: number },
      params: RequestParams = {}
    ) =>
      this.request<OrganizationReadDTOPagedCollection, any>({
        path: `/api/v1/Organization/paged`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags OrganizationIntegration
     * @name V1IntegrationOrganizationByNamesCreate
     * @summary Get organizations by names.
     * @request POST:/api/v1/Integration/Organization/by-names
     * @secure
     */
    v1IntegrationOrganizationByNamesCreate: (
      data: string[],
      params: RequestParams = {}
    ) =>
      this.request<OrganizationFullReadDTO[], any>({
        path: `/api/v1/Integration/Organization/by-names`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceList
     * @summary Get all services.
     * @request GET:/api/v1/Service
     * @secure
     */
    v1ServiceList: (params: RequestParams = {}) =>
      this.request<ServiceReadForAdminDTO[], any>({
        path: `/api/v1/Service`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Service
     * @name V1ServiceMyList
     * @summary Get my services.
     * @request GET:/api/v1/Service/my
     * @secure
     */
    v1ServiceMyList: (params: RequestParams = {}) =>
      this.request<MyServicePwCReadDTO[], any>({
        path: `/api/v1/Service/my`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionDetail
     * @summary Get the specified subscription.
     * @request GET:/api/v1/Subscription/{subscriptionId}
     * @secure
     */
    v1SubscriptionDetail: (
      subscriptionId: string,
      params: RequestParams = {}
    ) =>
      this.request<SubscriptionDetailsReadDTO, any>({
        path: `/api/v1/Subscription/${subscriptionId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionUpdate
     * @summary Update the specified subscription.
     * @request PUT:/api/v1/Subscription/{subscriptionId}
     * @secure
     */
    v1SubscriptionUpdate: (
      subscriptionId: string,
      data: SubscriptionUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Subscription/${subscriptionId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionDelete
     * @summary Delete an subscription.
     * @request DELETE:/api/v1/Subscription/{subscriptionId}
     * @secure
     */
    v1SubscriptionDelete: (
      subscriptionId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Subscription/${subscriptionId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionMyServicesList
     * @summary Get available services the client has a subscription.
     * @request GET:/api/v1/Subscription/my-services
     * @secure
     */
    v1SubscriptionMyServicesList: (params: RequestParams = {}) =>
      this.request<MyServiceClientReadDTO[], any>({
        path: `/api/v1/Subscription/my-services`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionMyClientsList
     * @summary Get services client has a subscription.
     * @request GET:/api/v1/Subscription/my-clients
     * @secure
     */
    v1SubscriptionMyClientsList: (params: RequestParams = {}) =>
      this.request<MyClientReadDTO[], any>({
        path: `/api/v1/Subscription/my-clients`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionMyClientsServicesCreate
     * @summary Get paged my clients with services.
     * @request POST:/api/v1/Subscription/my-clients/services
     * @secure
     */
    v1SubscriptionMyClientsServicesCreate: (
      data: string[],
      query?: { pageSize?: number; pageNumber?: number },
      params: RequestParams = {}
    ) =>
      this.request<MyClientServicesReadDTO[], any>({
        path: `/api/v1/Subscription/my-clients/services`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionByServiceDetail
     * @summary Get subscriptions by service.
     * @request GET:/api/v1/Subscription/by-service/{serviceId}
     * @secure
     */
    v1SubscriptionByServiceDetail: (
      serviceId: string,
      params: RequestParams = {}
    ) =>
      this.request<SubscriptionReadDTO[], any>({
        path: `/api/v1/Subscription/by-service/${serviceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionPagedUsersCreate
     * @summary Get paged subscriptions with client user access.
     * @request POST:/api/v1/Subscription/paged/users
     * @secure
     */
    v1SubscriptionPagedUsersCreate: (
      data: SubscriptionQueryDTO,
      query?: { pageSize?: number; pageNumber?: number },
      params: RequestParams = {}
    ) =>
      this.request<SubscriptionDetailsReadDTO[], any>({
        path: `/api/v1/Subscription/paged/users`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionCreate
     * @summary Create a subscription.
     * @request POST:/api/v1/Subscription
     * @secure
     */
    v1SubscriptionCreate: (
      data: SubscriptionCreateDTO,
      params: RequestParams = {}
    ) =>
      this.request<SubscriptionDetailsReadDTO, any>({
        path: `/api/v1/Subscription`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionUsersUpdate
     * @summary Add or update a user in a subscription.
     * @request PUT:/api/v1/Subscription/{subscriptionId}/users
     * @secure
     */
    v1SubscriptionUsersUpdate: (
      subscriptionId: string,
      data: SubscriptionUserAddDTO[],
      params: RequestParams = {}
    ) =>
      this.request<SubscriptionDetailsReadDTO, any>({
        path: `/api/v1/Subscription/${subscriptionId}/users`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionUsersPartialUpdate
     * @summary Update user from an subscription.
     * @request PATCH:/api/v1/Subscription/{subscriptionId}/users
     * @secure
     */
    v1SubscriptionUsersPartialUpdate: (
      subscriptionId: string,
      data: SubscriptionUserUpdateDTO,
      params: RequestParams = {}
    ) =>
      this.request<SubscriptionDetailsReadDTO, any>({
        path: `/api/v1/Subscription/${subscriptionId}/users`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionUsersDelete
     * @summary Remove user from an subscription.
     * @request DELETE:/api/v1/Subscription/{subscriptionId}/users
     * @secure
     */
    v1SubscriptionUsersDelete: (
      subscriptionId: string,
      data: SubscriptionUserRemoveDTO,
      params: RequestParams = {}
    ) =>
      this.request<SubscriptionDetailsReadDTO, any>({
        path: `/api/v1/Subscription/${subscriptionId}/users`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionImportList
     * @summary Download the template for the import.
     * @request GET:/api/v1/Subscription/import
     * @secure
     */
    v1SubscriptionImportList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/Subscription/import`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Subscription
     * @name V1SubscriptionImportCreate
     * @summary Import users from Excel file.
     * @request POST:/api/v1/Subscription/import
     * @secure
     */
    v1SubscriptionImportCreate: (
      data: { importFile?: File },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/Subscription/import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserCreate
     * @summary Create the user (internal or external).
     * @request POST:/api/v1/User
     * @secure
     */
    v1UserCreate: (
      data: {
        LastName?: string;
        FirstName?: string;
        Email?: string;
        PhoneCountryCode?: number;
        PhoneNumber?: string;
        Grade?: string;
        Photo?: File;
        IsSuperUser?: boolean;
        Entities?: string[];
        CreationIdentity?: UserIdentity;
        Send3WEmail?: boolean;
        SendAmdsEmail?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<UserReadDTO, any>({
        path: `/api/v1/User`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserUpdate
     * @summary Update the user.
     * @request PUT:/api/v1/User/{ppid}
     * @secure
     */
    v1UserUpdate: (
      ppid: string,
      data: {
        LastName?: string;
        FirstName?: string;
        PhoneCountryCode?: number;
        PhoneNumber?: string;
        Grade?: string;
        Photo?: File;
        IsSuperUser?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/User/${ppid}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserDelete
     * @summary Delete the user.
     * @request DELETE:/api/v1/User/{ppid}
     * @secure
     */
    v1UserDelete: (ppid: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/User/${ppid}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserDetail
     * @summary Get a user.
     * @request GET:/api/v1/User/{ppid}
     * @secure
     */
    v1UserDetail: (ppid: string, params: RequestParams = {}) =>
      this.request<UserReadDTO, any>({
        path: `/api/v1/User/${ppid}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserMyProfileUpdate
     * @summary Update own user profile.
     * @request PUT:/api/v1/User/MyProfile
     * @secure
     */
    v1UserMyProfileUpdate: (
      data: {
        LastName?: string;
        FirstName?: string;
        PhoneCountryCode?: number;
        PhoneNumber?: string;
        Photo?: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/User/MyProfile`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description If return code is 418: user is creating during the call.
     *
     * @tags User
     * @name V1UserMyProfileList
     * @summary Get own user profile.
     * @request GET:/api/v1/User/MyProfile
     * @secure
     */
    v1UserMyProfileList: (params: RequestParams = {}) =>
      this.request<UserReadDTO, any>({
        path: `/api/v1/User/MyProfile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserTermsAndConditionsAcceptedPartialUpdate
     * @summary Accept the Terms and Conditions.
     * @request PATCH:/api/v1/User/TermsAndConditionsAccepted
     * @secure
     */
    v1UserTermsAndConditionsAcceptedPartialUpdate: (
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/User/TermsAndConditionsAccepted`,
        method: "PATCH",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserSuperuserPartialUpdate
     * @summary Set the user status "SuperUser".
     * @request PATCH:/api/v1/User/{ppid}/superuser/{isSuperUser}
     * @secure
     */
    v1UserSuperuserPartialUpdate: (
      ppid: string,
      isSuperUser: boolean,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/User/${ppid}/superuser/${isSuperUser}`,
        method: "PATCH",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserServicesDetail
     * @summary get user's services.
     * @request GET:/api/v1/User/{ppid}/services
     * @secure
     */
    v1UserServicesDetail: (ppid: string, params: RequestParams = {}) =>
      this.request<SubscriptionReadDTO[], any>({
        path: `/api/v1/User/${ppid}/services`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserPagedCreate
     * @summary Get filtered users.
     * @request POST:/api/v1/User/paged
     * @secure
     */
    v1UserPagedCreate: (
      query: { pageSize: number; pageNumber: number },
      data: UserQueryDTO,
      params: RequestParams = {}
    ) =>
      this.request<UserReadDTO[], any>({
        path: `/api/v1/User/paged`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserDetail2
     * @summary Get a user photo.
     * @request GET:/api/v1/User/{ppid}/{id}
     * @originalName v1UserDetail
     * @duplicate
     * @secure
     */
    v1UserDetail2: (ppid: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/User/${ppid}/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserExistingUserList
     * @summary Check if external user exists.
     * @request GET:/api/v1/User/ExistingUser
     * @secure
     */
    v1UserExistingUserList: (
      query: { email: string },
      params: RequestParams = {}
    ) =>
      this.request<UserReadDTO, any>({
        path: `/api/v1/User/ExistingUser`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserEntitiesContactsDetail
     * @summary Get contacts of entities the user is linked to.
     * @request GET:/api/v1/User/{ppid}/entities/contacts
     * @secure
     */
    v1UserEntitiesContactsDetail: (ppid: string, params: RequestParams = {}) =>
      this.request<EntityContactsReadDTO[], any>({
        path: `/api/v1/User/${ppid}/entities/contacts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserImportList
     * @summary Download the template for the import.
     * @request GET:/api/v1/User/import
     * @secure
     */
    v1UserImportList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/User/import`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name V1UserImportCreate
     * @summary Import users from Excel file.
     * @request POST:/api/v1/User/import
     * @secure
     */
    v1UserImportCreate: (
      data: { importFile?: File },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/User/import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserIntegration
     * @name V1IntegrationUserDetail
     * @summary Get a user.
     * @request GET:/api/v1/Integration/User/{ppid}
     * @secure
     */
    v1IntegrationUserDetail: (ppid: string, params: RequestParams = {}) =>
      this.request<UserReadDTO, any>({
        path: `/api/v1/Integration/User/${ppid}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserIntegration
     * @name V1IntegrationUserByPpidsCreate
     * @summary Get users.
     * @request POST:/api/v1/Integration/User/by-ppids
     * @secure
     */
    v1IntegrationUserByPpidsCreate: (
      data: string[],
      params: RequestParams = {}
    ) =>
      this.request<UserReadDTO[], any>({
        path: `/api/v1/Integration/User/by-ppids`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserIntegration
     * @name V1IntegrationUserByEmailsCreate
     * @summary Get users.
     * @request POST:/api/v1/Integration/User/by-emails
     * @secure
     */
    v1IntegrationUserByEmailsCreate: (
      data: string[],
      params: RequestParams = {}
    ) =>
      this.request<UserReadDTO[], any>({
        path: `/api/v1/Integration/User/by-emails`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserIntegration
     * @name IntegrationUser
     * @summary Create the user (internal or external).
     * @request POST:/api/v1/Integration/User
     * @secure
     */
    integrationUser: (
      data: {
        LastName?: string;
        FirstName?: string;
        Email?: string;
        PhoneCountryCode?: number;
        PhoneNumber?: string;
        Grade?: string;
        Photo?: File;
        IsSuperUser?: boolean;
        Entities?: string[];
        CreationIdentity?: UserIdentity;
        Send3WEmail?: boolean;
        SendAmdsEmail?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<UserReadDTO, any>({
        path: `/api/v1/Integration/User`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserLookupIntegration
     * @name V1IntegrationUserLookupByEmailList
     * @summary Get a user info based on his email.
     * @request GET:/api/v1/Integration/UserLookup/by-email
     * @secure
     */
    v1IntegrationUserLookupByEmailList: (
      query: { email: string },
      params: RequestParams = {}
    ) =>
      this.request<UserLookupReadDTO[], any>({
        path: `/api/v1/Integration/UserLookup/by-email`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserLookupIntegration
     * @name V1IntegrationUserLookupByPpidList
     * @summary Get a user info based on his Ppid.
     * @request GET:/api/v1/Integration/UserLookup/by-ppid
     * @secure
     */
    v1IntegrationUserLookupByPpidList: (
      query: { ppid: string },
      params: RequestParams = {}
    ) =>
      this.request<UserLookupReadDTO[], any>({
        path: `/api/v1/Integration/UserLookup/by-ppid`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserLookupIntegration
     * @name V1IntegrationUserLookupByAttributeList
     * @summary Get a user info based on a specific attribute.
     * @request GET:/api/v1/Integration/UserLookup/by-attribute
     * @secure
     */
    v1IntegrationUserLookupByAttributeList: (
      query: { attribute: string; value: string },
      params: RequestParams = {}
    ) =>
      this.request<UserLookupReadDTO[], any>({
        path: `/api/v1/Integration/UserLookup/by-attribute`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
