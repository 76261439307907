import { PopBanner } from "appkit-react";
import UserSelectLegacy, {
  EXTERNAL_ONLY_FILTERS,
} from "pages/admin/components/AddUserToElementModal/UserSelect";
import { useContext } from "react";

import AddUserModal from "../../AddUserToElementModal/Modal";
import { AdminEntityContext } from "..";

const AddUserToEntityModal = ({ entity, onClose, ...modalProps }) => {
  const { addUserToEntity } = useContext(AdminEntityContext);

  const submitAddUser = (form) => {
    const users = form.users;

    return addUserToEntity(
      entity.id,
      users.map((user) => user.ppid)
    ).then(() => {
      PopBanner({
        content:
          users.length > 1
            ? `The users have been successfully added to the entity.`
            : `The user has been successfully added to the entity.`,
        duration: 4000,
        status: "success",
      });

      onClose();
    });
  };

  return (
    <AddUserModal
      visible={!!entity}
      onCancel={onClose}
      onSubmit={submitAddUser}
      Render={({ hasError, value, handleValueChange, clickOutsideRef }) => (
        <UserSelectLegacy
          placeholder="Select user(s) to add here"
          multiple
          clickOutsideRef={clickOutsideRef}
          onChange={handleValueChange}
          showSearchOnList={true}
          value={value}
          hasError={hasError}
          filters={EXTERNAL_ONLY_FILTERS}
          alreadyAdded={entity?.users}
        />
      )}
      {...modalProps}
    />
  );
};

export default AddUserToEntityModal;
