import "./index.scss";

import { TextArea } from "appkit-react";
import classnames from "classnames";

const ValidationTextarea = ({
  errMsg = "",
  hasError = false,
  className = undefined,
  ...textareaProps
}) => {
  return (
    <>
      <TextArea
        className={classnames(
          hasError ? "a-textarea-error" : "",
          className ? className : ""
        )}
        {...textareaProps}
      />
      {hasError && <p className="a-form-error">{errMsg}</p>}
    </>
  );
};

export default ValidationTextarea;
