import { ApplicationCreateDTO } from "api/catalog/__generated__/CatalogueAPI";

export enum ServiceType {
  RECURRING = 1,
  CONSULTING = 2,
  DATA_DRIVEN = 3,
}

export enum ServiceTag {
  MANCO = 1,
  FUND = 2,
  INVESTORS = 3,
  INTERMEDIARY_VEHICLES = 4,
  INVESTMENTS = 5,
  DISTRIBUTION = 6,
  THIRD_PARTIES = 7,
  REPORTING = 8,
  CORPORATE_SECRETARY = 9,
  LIQUIDATION = 10,

  PORTFOLIO_MANAGEMENT = 11,
  RISK_MANAGEMENT = 12,
  VALUATION = 13,
  COMPLIANCE = 14,
  DELEGATES = 15,
  REPORTING_MANAGEMENT = 16,
  FINANCE_ACCOUNTING_TAX = 17,
  INTERNAL_AUDIT = 18,
  DISTRIBUTION_MANAGEMENT = 19,
  IT_DATA_MANAGEMENT = 20,
}

export interface CatalogueTags {
  industries: string[];
}

// ServiceCreateDTO and ServiceUpdateDTO was previously automatically generated in CalatogueAPI.ts, but once the body type has been switched from json to form data, theses types are not generated anymore
export interface ServiceUpdateDTO {
  TeamPpids?: string[];
  ResponsiblesPpids?: string[];
  Organizations?: string[];
  Applications?: ApplicationCreateDTO[];
  Industries?: string[];
  IllustrationsToAdd?: File[];
  IllustrationsToRemove?: string[];
  RelatedServices?: string[];
  Name?: string;
  Description?: string;
  VideoLink?: string;
  ItContactPpid?: string;
  Live?: boolean;
  IsHighlighted?: boolean;
  IsNew?: boolean;
  IsPSF?: boolean;
  IsPSA?: boolean;
  LifeCycles?: string[];
  ValueChains?: string[];
  SmallDescription?: string;
  GeographicalInformation?: string;
  TargetProfile?: string;
  MainAddedValue?: string;
  SecondaryAddedValue?: string;
  AvailableApplication?: string;
  Deliverable?: string;
  ServiceGroupId?: string;
  ViewersPpids?: string[];
  FriendlyUrl?: string;
  VisibleInClientView?: boolean;
  UkViewersPpids?: string[];
  FrViewersPpids?: string[];
}

export interface ServiceCreateDTO {
  TeamPpids?: string[];
  ResponsiblesPpids?: string[];
  Organizations?: string[];
  Applications?: ApplicationCreateDTO[];
  Industries?: string[];
  RelatedServices?: string[];
  Illustrations?: File[];
  Name?: string;
  Description?: string;
  VideoLink?: string;
  ItContactPpid?: string;
  Live?: boolean;
  IsHighlighted?: boolean;
  IsNew?: boolean;
  IsPSA?: boolean;
  IsPSF?: boolean;
  LifeCycles?: string[];
  ValueChains?: string[];
  SmallDescription?: string;
  GeographicalInformation?: string;
  TargetProfile?: string;
  MainAddedValue?: string;
  SecondaryAddedValue?: string;
  AvailableApplication?: string;
  Deliverable?: string;
  ServiceGroupId?: string;
  ViewersPpids?: string[];
  UkViewersPpids?: string[];
  FrViewersPpids?: string[];
  Domains?: string[];
  Territories?: string[];
  FriendlyUrl?: string;
  VisibleInClientView?: boolean;
  [key: string]: any;
}
