import "./addMultipleUsersToEntityModal.scss";

import { Tooltip } from "@mui/material";
import { pollAddUserToEntity, pollCreateUser } from "api/admin/adminPoller";
import {
  Button,
  Checkbox,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopBanner,
  Radio,
  RadioGroup,
} from "appkit-react";
import PhoneInput from "components/PhoneInput";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import { useMemo, useState } from "react";

const _defaultUser = {
  email: "",
  photo: "",
  firstName: "",
  lastName: "",
  phone: {
    code: null,
    number: "",
  },
  grade: "",
  isSuperUser: false,
  entities: [],
  send3WEmail: false,
  sendAmdsEmail: false,
  creationIdentity: "2",
};

const AddMultipleUsersToEntityModal = ({ entity, onClose: _onClose }) => {
  const defaultUser = useMemo(() => {
    return { ..._defaultUser, entities: [entity?.id] };
  }, [entity]);
  const [summaryStatus, setSummaryStatus] = useState(null);
  const [usersToAdd, _setUsersToAdd] = useState([]);
  const [userToAdd, _setUserToAdd] = useState({ ...defaultUser });
  const [submitting, setSubmitting] = useState(false);

  const addUser = () => {
    _setUsersToAdd((usersToAdd) => [
      ...usersToAdd,
      {
        ...userToAdd,
        phoneCountryCode: userToAdd.code,
        phoneNumber: userToAdd.number,
      },
    ]);
    _setUserToAdd(_defaultUser);
  };

  const removeUser = (index) => {
    const newUsersToAdd = [...usersToAdd];
    newUsersToAdd.splice(index, 1);
    _setUsersToAdd(newUsersToAdd);
  };

  const setUserValue = (key, value) => {
    const newUser = { ...userToAdd };
    newUser[key] = value;
    _setUserToAdd(newUser);
  };
  const handleSubmit = () => {
    setSubmitting(true);
    const promises = usersToAdd.map(async (userToAdd) => {
      try {
        const user = await pollCreateUser(userToAdd);
        return await pollAddUserToEntity(entity.id, [user.ppid]);
      } catch (error) {
        const errorData = await error.json();
        throw errorData;
      }
    });
    Promise.allSettled(promises)
      .then((results) => {
        const status = results.map((result) =>
          result.status === "fulfilled" ? true : result.reason
        );
        setSummaryStatus(status);
        const failures = status.reduce((acc, res) => {
          if (res !== true) {
            return acc + 1;
          }
          return acc;
        }, 0);
        if (failures === 0) {
          PopBanner({
            content: <span>All users successfully added</span>,
            closeable: true,
            status: "success",
          });
        } else if (failures < promises.length) {
          PopBanner({
            content: (
              <span>
                Failed to add {failures} users. Please check the summary below
                for details
              </span>
            ),
            closeable: true,
            status: "warning",
          });
        } else {
          PopBanner({
            content: (
              <span>
                No users were added. Please check the summary below for details
              </span>
            ),
            closeable: true,
            status: "negative",
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onClose = () => {
    setSummaryStatus(null);
    _setUsersToAdd([]);
    _setUserToAdd(defaultUser);
    if (!submitting) {
      _onClose();
    }
  };

  const getErrorMessage = (index) => {
    const DEFAULT_MESSAGE = "Failed to add this user";
    try {
      return summaryStatus[index][0].errorMessage ?? DEFAULT_MESSAGE;
    } catch (e) {
      return DEFAULT_MESSAGE;
    }
  };

  return (
    <ResizableAppkitModal
      visible={entity !== null}
      className="add-multiple-users-to-entity-modal create-user-modal"
      backdropClosable={false}
      onCancel={onClose}
    >
      <ModalHeader>
        <div className="title">Add multiple users to company</div>
      </ModalHeader>
      <ModalBody>
        <strong>Users to add to the company "{entity?.name}"</strong>
        <table className="adding-users">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>E-Mail</th>
              <th>Phone</th>
              <th>Type</th>
              <th>Notifications</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {usersToAdd.length === 0 ? (
              <tr>
                <td colSpan={6}>
                  <em>No users added yet</em>
                </td>
              </tr>
            ) : (
              <>
                {usersToAdd.map((user, index) => (
                  <tr
                    key={user.email}
                    style={{
                      backgroundColor:
                        summaryStatus !== null && summaryStatus[index] === true
                          ? "#D3EBD5"
                          : summaryStatus !== null &&
                            summaryStatus[index] !== true
                          ? "#F3D4D1"
                          : undefined,
                    }}
                  >
                    <td>
                      {summaryStatus !== null && (
                        <span>
                          {summaryStatus[index] === true ? (
                            <span className="appkiticon icon-circle-checkmark-fill" />
                          ) : (
                            <Tooltip
                              title={getErrorMessage(index)}
                              arrow
                              placement="right"
                            >
                              <div>
                                <span className="appkiticon icon-circle-delete-fill" />
                              </div>
                            </Tooltip>
                          )}
                        </span>
                      )}
                    </td>
                    <td>
                      {user.firstName} {user.lastName}
                    </td>
                    <td>{user.email}</td>
                    <td>
                      +{user.phone.code} {user.phone.number}
                    </td>
                    <td>
                      {user.creationIdentity === "1" ? "Global" : "Luxembourg"}
                    </td>
                    <td>
                      <ul>
                        {user.send3WEmail && <li>Send 3W E-Mail</li>}
                        {user.sendAmdsEmail && <li>Send AMDS welcome email</li>}
                        {!user.send3WEmail && !user.sendAmdsEmail && (
                          <li>
                            <i>No notifications</i>
                          </li>
                        )}
                      </ul>
                    </td>
                    <td>
                      {summaryStatus === null && !submitting && (
                        <div className="actions">
                          <span
                            onClick={() => removeUser(index)}
                            className="appkiticon icon-circle-delete-outline"
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        {!submitting && summaryStatus === null && (
          <form className="form">
            <div className="fields-wrapper">
              <div>
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;E-Mail
                </label>
                <Input
                  autoFocus
                  placeholder="E-Mail"
                  value={userToAdd.email}
                  onChange={(value) => setUserValue("email", value)}
                />
              </div>
              <div>
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;First Name
                </label>
                <Input
                  placeholder="First Name"
                  value={userToAdd.firstName}
                  onChange={(value) => setUserValue("firstName", value)}
                />
              </div>
              <div>
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Last Name
                </label>
                <Input
                  placeholder="Last Name"
                  value={userToAdd.lastName}
                  onChange={(value) => setUserValue("lastName", value)}
                />
              </div>
              <div>
                <label className="a-form-label">Phone number</label>
                <PhoneInput
                  value={userToAdd.phone}
                  onChange={(value) => setUserValue("phone", value)}
                />
              </div>
              <div>
                <label className="a-form-label">Companies</label>
                <Input
                  style={{ display: "inline" }}
                  placeholder="Company"
                  disabled
                  value={entity?.companyName}
                />
              </div>
              <div className="welcome-checkboxes">
                <Checkbox
                  checked={userToAdd.send3WEmail}
                  onChange={() =>
                    setUserValue("send3WEmail", !userToAdd.send3WEmail)
                  }
                >
                  Send 3W email
                </Checkbox>
                <Checkbox
                  checked={userToAdd.sendAmdsEmail}
                  onChange={() =>
                    setUserValue("sendAmdsEmail", !userToAdd.sendAmdsEmail)
                  }
                >
                  Send AMDS welcome email
                </Checkbox>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="a-form-label">User type</label>
                  <br />
                  <RadioGroup
                    value={userToAdd.creationIdentity}
                    onChange={(event, value) => {
                      setUserValue("creationIdentity", value);
                    }}
                  >
                    <Radio value={"1"}>Global</Radio>
                    <Radio value={"2"}>Luxembourg</Radio>
                  </RadioGroup>
                </div>
              </div>
            </div>
            <div>
              <Button onClick={addUser}>Add user</Button>
            </div>
          </form>
        )}
      </ModalBody>
      <ModalFooter>
        {summaryStatus === null ? (
          <>
            <Button kind="transparent" onClick={onClose} disabled={submitting}>
              Cancel
            </Button>
            <Button
              loading
              kind="primary"
              onClick={handleSubmit}
              isLoading={submitting}
              disabled={usersToAdd.length === 0}
            >
              Submit
            </Button>
          </>
        ) : (
          <Button kind="transparent" onClick={onClose} disabled={submitting}>
            Close
          </Button>
        )}
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default AddMultipleUsersToEntityModal;
