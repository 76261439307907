import { ApplicationCreateDTO } from "api/admin/__generated__/UserManagementAPI";
import {
  ApplicationReadDTO,
  ServiceContactReadDTO,
  ServiceIllustrationReadDTO,
  ServiceReadDTO,
} from "api/catalog/__generated__/CatalogueAPI";
import { ServiceCreateDTO, ServiceUpdateDTO } from "api/catalog/type";
import { FormErrors, hasError } from "hooks/useSubmitable";
import { validateURL } from "pages/news/utils/videoMatcher";
import { isValidUrl } from "utils";

export interface ServiceForm {
  id?: string;
  name: string;
  description: string;
  live: boolean;
  applications: ApplicationReadDTO[];
  team: ServiceContactReadDTO[];
  responsibles: ServiceContactReadDTO[];
  videoLink: string;
  itContact: ServiceContactReadDTO | null;
  organizations: string[];
  industries: string[];
  isHighlighted: boolean;
  isNew: boolean;
  isPSF: boolean;
  isPSA: boolean;
  lifeCycles: string[];
  valueChains: string[];
  smallDescription: string;
  geographicalInformation: string;
  targetProfile: string;
  availableApplication: string;
  deliverable: string;
  mainAddedValue: string;
  secondaryAddedValue: string;
  relatedServices: string[];
  illustrations: ServiceIllustrationReadDTO[];
  illustrationsToAdd: File[];
  illustrationsToRemove: string[];
  serviceGroupId?: string | null;
  viewers: ServiceContactReadDTO[];
  domains: string[];
  territories: string[];
  friendlyUrl: string;
  visibleInClientView: boolean;
  ukViewers: ServiceContactReadDTO[];
  frViewers: ServiceContactReadDTO[];
}

type ServiceFormErrors = {
  [Property in keyof ServiceForm]?: FormErrors<any> | string | boolean | null;
};

interface ApplicationForm {
  name: string | null;
  link: string | null;
}
export function findFormErrors(form: ServiceForm): ServiceFormErrors {
  return {
    name: form.name.trim().length <= 0 && "Please provide a valid name",
    description:
      form.description.trim().length <= 0
        ? "Please provide a valid description"
        : null,
    smallDescription:
      form.smallDescription.trim().length <= 0 ||
      form.smallDescription.trim().length > 160
        ? "Please provide a valid short description (max 160 characters)"
        : null,
    responsibles:
      form.responsibles.length > 3 && "A maximum of 3 responsibles is allowed.",
    industries:
      form.industries.length === 0 && "Please choose at least one tag",
    videoLink: form.videoLink.trim().length > 0 && validateURL(form.videoLink),
    // @ts-ignore
    applications: findApplicationErrors(form.applications),
    geographicalInformation:
      form.geographicalInformation.trim().length <= 0 ||
      form.geographicalInformation.trim().length > 160
        ? "Please provide a valid geographical information (max 160 characters)"
        : null,
    targetProfile:
      form.targetProfile.trim().length <= 0 ||
      form.targetProfile.trim().length > 160
        ? "Please provide a valid target profile (max 160 characters)"
        : null,
    mainAddedValue:
      form.mainAddedValue.trim().length <= 0 ||
      form.mainAddedValue.trim().length > 160
        ? "Please provide a valid main added value (max 160 characters)"
        : null,
    secondaryAddedValue:
      form.secondaryAddedValue.trim().length <= 0 ||
      form.secondaryAddedValue.trim().length > 160
        ? "Please provide a valid secondary added value (max 160 characters)"
        : null,
    deliverable:
      form.deliverable.trim().length <= 0 ||
      form.deliverable.trim().length > 160
        ? "Please provide a valid deliverable (max 160 characters)"
        : null,
    availableApplication:
      form.availableApplication.trim().length <= 0 ||
      form.availableApplication.trim().length > 160
        ? "Please provide a valid available application (max 160 characters)"
        : null,
    lifeCycles:
      form.lifeCycles.length <= 0 ? "Please provide a life cycle" : null,
    valueChains:
      form.valueChains.length <= 0 ? "Please provide a value chain" : null,
  };
}

export function computeApplicationError(item: ApplicationForm) {
  const link = item.link?.trim() || "";
  const name = item.name?.trim() || "";

  const errors = {
    name:
      link.length > 0 && name.length === 0
        ? "Please provide a valid name"
        : null,
    link:
      name.length > 0 && !isValidUrl(link)
        ? "Please provide a valid URL"
        : null,
  };

  return hasError(errors) ? errors : null;
}

export function findApplicationErrors(
  applications: ApplicationForm[]
): FormErrors<ApplicationForm[]> | null {
  const errors = applications.reduce((errors, application, index) => {
    const error = computeApplicationError(application);
    if (!!error) {
      // @ts-ignore
      errors[index] = error;
    }
    return errors;
  }, {});
  return Object.keys(errors).length > 0
    ? (errors as FormErrors<ApplicationForm[]>)
    : null;
}

const toPascalCase = (s: string) => `${s[0].toUpperCase()}${s.slice(1)}`;

export function toServiceCreateDTO(form: ServiceForm): ServiceCreateDTO {
  const videoLink = form.videoLink.trim();
  const applications = form.applications
    .filter((app) => app.name!.trim().length > 0 && app.link!.trim().length > 0)
    .map((app) => {
      return {
        name: app.name!.trim(),
        link: app.link!.trim(),
        itContactPpid: app.itContact?.ppid || null,
        showToClient: app.showToClient,
      };
    });
  const ret: ServiceCreateDTO = {
    Name: form.name.trim(),
    Description: form.description.trim(),
    Live: form.live,
    ResponsiblesPpids: form.responsibles.map((p) => p.ppid!),
    TeamPpids: form.team.map((p) => p.ppid!),
    VideoLink: videoLink.length ? videoLink : undefined,
    // TODO JGE Check that
    // Tags: [...form.lifeCycleTags, ...form.companyManagementTags],
    Applications: applications,
    ItContactPpid: form.itContact?.ppid ?? undefined,
    Organizations: form.organizations,
    Industries: form.industries,
    RelatedServices: form.relatedServices ?? [],
    IsHighlighted: form.isHighlighted,
    IsNew: form.isNew,
    IsPSA: form.isPSA,
    IsPSF: form.isPSF,
    SmallDescription: form.smallDescription,
    LifeCycles: form.lifeCycles,
    ValueChains: form.valueChains,
    MainAddedValue: form.mainAddedValue,
    SecondaryAddedValue: form.secondaryAddedValue,
    GeographicalInformation: form.geographicalInformation,
    AvailableApplication: form.availableApplication,
    Deliverable: form.deliverable,
    TargetProfile: form.targetProfile,
    Illustrations: form.illustrationsToAdd,
    ServiceGroupId: form.serviceGroupId ?? undefined,
    ViewersPpids: form.viewers.map((item) => item.ppid!),
    UkViewersPpids: form.ukViewers.map((item) => item.ppid!),
    FrViewersPpids: form.frViewers.map((item) => item.ppid!),
    Domains: form.domains,
    Territories: form.territories,
    FriendlyUrl: form.friendlyUrl ?? "",
    VisibleInClientView: form.visibleInClientView ?? true,
  };
  processApplications(ret, applications);
  return ret;
}

const processApplications = (
  input: ServiceCreateDTO,
  applications: ApplicationCreateDTO[]
) => {
  applications.forEach((app, index) => {
    Object.entries(app).forEach(([key, value]) => {
      input[`Applications[${index}].${toPascalCase(key)}`] = value;
    });
  });
  delete input.Applications;
};

export function toServiceUpdateDTO(form: ServiceForm): ServiceUpdateDTO {
  const { Illustrations, ...service } = toServiceCreateDTO(form);
  return {
    ...service,
    IllustrationsToAdd: form.illustrationsToAdd,
    IllustrationsToRemove: form.illustrationsToRemove,
  };
}

export function toServiceForm(service: ServiceReadDTO): ServiceForm {
  return {
    ...service,
    name: service.name || "",
    description: service.description || "",
    applications: service.applications || [],
    responsibles: service.responsibles || [],
    team: service.team || [],
    videoLink: service.videoLink || "",
    itContact: service.itContact,
    organizations: service.organizations || [],
    industries: service.industries ? (service.industries as string[]) : [],
    isHighlighted: service.isHighlighted,
    isNew: service.isNew,
    lifeCycles: service.lifeCycles ?? [],
    valueChains: service.valueChains ?? [],
    smallDescription: service.smallDescription ?? "",
    geographicalInformation: service.geographicalInformation ?? "",
    targetProfile: service.targetProfile ?? "",
    availableApplication: service.availableApplication ?? "",
    deliverable: service.deliverable ?? "",
    mainAddedValue: service.mainAddedValue ?? "",
    secondaryAddedValue: service.secondaryAddedValue ?? "",
    relatedServices:
      service.relatedServices?.map((service) => service.id) ?? [],
    illustrations: service.illustrations ?? [],
    illustrationsToAdd: [],
    illustrationsToRemove: [],
    serviceGroupId: service.serviceGroupId,
    viewers: service.viewers ?? [],
    domains: service.domains ?? [],
    territories: service.territories?.map((territory) => territory.id) ?? [],
    friendlyUrl: service.friendlyUrl ?? "",
    visibleInClientView: service.visibleInClientView ?? true,
    ukViewers: service.ukViewers ?? [],
    frViewers: service.frViewers ?? [],
  };
}
