export const MIME_PDF = "application/pdf";
export const MIME_DOC = "application/msword";
export const MIME_DOCX =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const MIME_XLS = "application/vnd.ms-excel";
export const MIME_XLSX =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const MIME_PPT = "application/vnd.ms-powerpoint";
export const MIME_PPTX =
  "application/vnd.openxmlformats-officedocument.presentationml.presentation";
export const MIME_PNG = "image/png";
export const MIME_JPEG = "image/jpeg";
