import { useEffect } from "react";

export const ADMIN_TABLE_OFFSET = 234;
export const DELIVERABLES_TABLE_OFFSET = 263;

const useStickyHeader = (
  scrollableElementRef,
  stickyElementRef,
  offset = 0
) => {
  useEffect(() => {
    if (!scrollableElementRef || !stickyElementRef) {
      return;
    }
    const onScrollEvent = (event) => {
      stickyElementRef.style.transform = `translateY(${Math.max(
        0,
        event.target.scrollTop - offset
      )}px)`;
    };
    scrollableElementRef.addEventListener("scroll", onScrollEvent);
    return () => {
      scrollableElementRef.removeEventListener("scroll", onScrollEvent);
    };
  }, [scrollableElementRef, stickyElementRef, offset]);
};

export default useStickyHeader;
