import "./index.scss";

import { Tooltip } from "@mui/material";
import { getUsers } from "api/admin/adminApi";
import { pollUpdateSuperUserStatus } from "api/admin/adminPoller";
import { PopBanner } from "appkit-react";
import classNames from "classnames";
import AvatarWithAsyncPhoto from "components/AvatarWithAsyncPhoto";
import FloatingActionButtonPortal from "components/FloatingActionButton";
import SwitchWithLoader from "components/SwitchWithLoader";
import Table, { Actions } from "components/Table";
import useData from "hooks/useData";
import { USERS_CONTEXTS } from "pages/admin/constants";
import useMainTableStickyHeader from "pages/admin/hooks/useMainTableStickyHeader";
import { createSuccessBanner } from "pages/admin/utils";
import { useCallback, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

import AddUserModal from "../AddUserModal";
import DeleteUserModal from "../DeleteUserModal";
import NotifyUserModal from "../NotifyUserModal";
import RefreshUserModal from "../RefreshUserModal";

export const COLUMNS = [
  {
    Header: "",
    id: "avatar",
    Cell: ({ original }) => {
      return (
        <AvatarWithAsyncPhoto
          name={original.name}
          photoUri={original.photoUri}
        />
      );
    },
    sortable: false,
    filterable: false,
    width: 64,
  },
  {
    Header: "Member",
    accessor: "name",
    id: "name",
    filterable: true,
    Filter: () => null,
  },
  {
    Header: "Email",
    accessor: "email",
    id: "email",
    filterable: true,
    Filter: () => null,
  },
  {
    Header: "Telephone",
    accessor: "phone",
    id: "phone",
    filterable: false,
    sortable: false,
    Filter: () => null,
    Cell: ({ original }) => {
      return original.phoneNumber
        ? `+${original.phoneCountryCode} ${original.phoneNumber.replaceAll(
            " ",
            ""
          )}`
        : "";
    },
  },
  {
    Header: "Grade",
    accessor: "grade",
    id: "grade",
    filterable: false,
    Filter: () => null,
    sortable: false,
  },
  {
    Header: "Terms accepted",
    accessor: "termsAndConditionsAccepted",
    id: "termsAndConditionsAccepted",
    filterable: false,
    sortable: false,
    width: 120,
    className: "td-center",
    Cell: ({ value }) => (
      <span
        className={classNames(
          "appkiticon",
          !!value ? "icon-check-mark-outline" : "icon-close-outline"
        )}
      />
    ),
  },
  {
    Header: "User Type",
    accessor: "creationIdentity",
    id: "creationIdentity",
    filterable: false,
    sortable: true,
    width: 78,
    className: "td-center",
    Cell: ({ value }) => {
      const tooltip =
        value === 0
          ? "PwC Staff User"
          : value === 1
          ? "PwC Global Account"
          : "PwC Luxembourg Account";
      return (
        <Tooltip title={tooltip} placement="left" arrow>
          <span
            className={classNames(
              "appkiticon",
              value === 0
                ? "icon-building-outline"
                : value === 1
                ? "icon-globe-map-outline"
                : "icon-home-outline"
            )}
          />
        </Tooltip>
      );
    },
  },
];

const DEFAULT_SORT = [{ id: "name", desc: false }];

const UsersTable = ({ filters }) => {
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToRefresh, setUserToRefresh] = useState(null);
  const [userToNotify, setUserToNotify] = useState(null);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [superUserUpdating, setSuperUserUpdating] = useState([]);
  const user = useSelector((state) => state.global.data.loginUser);

  const apiFilters = useMemo(() => {
    const res = {
      isSuperUser:
        filters.context === USERS_CONTEXTS.SUPERADMIN ? true : undefined,
      isInternal:
        filters.context === USERS_CONTEXTS.SUPERADMIN ||
        filters.context === USERS_CONTEXTS.PWC ||
        filters.context === USERS_CONTEXTS.LUXEMBOURG_STAFF
          ? true
          : filters.context === USERS_CONTEXTS.CLIENT
          ? false
          : undefined,
      ...(filters.context === USERS_CONTEXTS.LUXEMBOURG_STAFF
        ? { territory: "LU", isInternal: true }
        : {}),
    };

    return filters.rowFilters.reduce((acc, rowFilter) => {
      acc[rowFilter.id] = rowFilter.value;
      return acc;
    }, res);
  }, [filters]);

  const {
    loading,
    items: users,
    fetchData,
    optimisticChange,
    ...tableReducer
  } = useData(apiFilters, getUsers, DEFAULT_SORT);

  const setSuperUser = useCallback(
    (ppid, newValue) => {
      setSuperUserUpdating([...superUserUpdating, ppid]);
      pollUpdateSuperUserStatus(ppid, newValue)
        .then(() => {
          const user = (users[
            users.findIndex((user) => user.ppid === ppid)
          ].isSuperUser = newValue);

          optimisticChange(user);
        })
        .finally(() => {
          let newSuperUserUpdating = [...superUserUpdating];
          newSuperUserUpdating.splice(newSuperUserUpdating.indexOf(ppid), 1);
          setSuperUserUpdating(newSuperUserUpdating);
        });
    },
    [superUserUpdating, users, optimisticChange]
  );

  const tableRef = useRef();
  useMainTableStickyHeader(loading, tableRef, 310);

  const columns = useMemo(() => {
    return [
      ...COLUMNS,
      {
        Header: "Admin",
        accessor: "isSuperUser",
        id: "isSuperUser",
        filterable: false,
        sortable: false,
        width: 104,
        Filter: () => null,
        Cell: (row) => {
          return row.original.isInternal ? (
            <SwitchWithLoader
              checked={row.original.isSuperUser}
              loading={superUserUpdating.indexOf(row.original.ppid) !== -1}
              onChange={(newValue) => {
                setSuperUser(row.original.ppid, newValue);
              }}
            />
          ) : null;
        },
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <Actions
            handleRefresh={() => setUserToRefresh(row._original)}
            handleNotify={() => {
              setUserToNotify(row._original);
            }}
            handleEdit={() => {
              setEditedUser(row._original);
              setShowCreateUserModal(true);
            }}
            handleDelete={
              user?.isSuperUser
                ? () => {
                    setUserToDelete(row._original);
                  }
                : undefined
            }
          />
        ),
        id: "actions",
        sortable: false,
        filterable: false,
        width: 180,
      },
    ];
  }, [setSuperUser, superUserUpdating, user.isSuperUser]);

  const updateUser = (updatedUser) => {
    fetchData();

    PopBanner({
      content: `The user ${updatedUser.name} has been successfully updated.`,
      duration: 4000,
      status: "success",
    });
  };

  const onDelete = (deletedUserPpid) => {
    let newUsers = [...users];
    newUsers.splice(
      newUsers.findIndex((user) => user.ppid === deletedUserPpid),
      1
    );
    fetchData();
  };

  return (
    <>
      <AddUserModal
        visible={showCreateUserModal}
        onClose={() => {
          setShowCreateUserModal(false);
        }}
        initialUser={editedUser}
        onCreate={createSuccessBanner}
        onUpdate={updateUser}
      />
      <FloatingActionButtonPortal
        name="icon-plus-fill"
        onClick={() => {
          setEditedUser(null);
          setShowCreateUserModal(true);
        }}
        tooltip="Create a new user"
      />
      <RefreshUserModal
        user={userToRefresh}
        onCancel={() => setUserToRefresh(null)}
      />
      <DeleteUserModal
        user={userToDelete}
        onCancel={() => setUserToDelete(null)}
        onDelete={onDelete}
      />
      <NotifyUserModal
        className={""}
        onCancel={() => setUserToNotify(false)}
        user={userToNotify}
      />
      {!loading && !!users ? (
        <Table
          manual
          columns={columns}
          data={users}
          loading={loading}
          ref={tableRef}
          className="userstable"
          defaultSorted={DEFAULT_SORT}
          {...tableReducer}
        />
      ) : (
        <Skeleton height={200} />
      )}
    </>
  );
};

export default UsersTable;
