import { isValidUrl } from "utils";

export const VIDEO_TYPES = {
  YOUTUBE: "YOUTUBE",
  VIMEO: "VIMEO",
};

const matchers = [
  (url) => {
    const matches =
      /https:\/\/www.youtube.com\/watch\?v=([a-zA-Z0-9_\-?]+)/g.exec(url);
    return matches && matches[1]
      ? {
          type: VIDEO_TYPES.YOUTUBE,
          id: matches[1],
        }
      : null;
  },

  (url) => {
    const matches = /https:\/\/youtu.be\/([a-zA-Z0-9_\-?]+)/g.exec(url);
    return matches && matches[1]
      ? {
          type: VIDEO_TYPES.YOUTUBE,
          id: matches[1],
        }
      : null;
  },

  (url) => {
    const matches = /https:\/\/vimeo.com\/(([a-zA-Z0-9_\-?]+\/?)+)/g.exec(url);
    if (!matches || !matches[1]) {
      return null;
    }
    const splittedId = matches[1].split("/");
    return matches && matches[1]
      ? {
          type: VIDEO_TYPES.VIMEO,
          id: `${splittedId.shift()}${
            (splittedId.length > 0 && `?h=${splittedId.join("&h=")}`) || ""
          }`,
        }
      : null;
  },
];

export const findFirstMatcher = (url) => {
  for (const matcher of matchers) {
    const res = matcher(url);
    if (!!res) {
      return res;
    }
  }
  return null;
};

export const validateURL = (url) =>
  !isValidUrl(url)
    ? "Please provide a valid URL"
    : findFirstMatcher(url) === null &&
      "Please provide a URL from Youtube or Vimeo";
