import { byId } from "utils/byId";

import { Item, State } from "./type";
import {
  ADMIN_FETCHLIST,
  ADMIN_FETCHLIST_SUCCESS,
  ADMIN_ITEM_ADDED,
  ADMIN_ITEM_DELETED,
  ADMIN_ITEM_UPDATED,
  Action,
} from ".";

export const initialState: State<any> = {
  items: null,
  itemsById: null,
  loading: false,
};

export default function adminListReducer<T extends Item>(
  state: State<T>,
  action: Action<T>
): State<T> {
  switch (action.type) {
    case ADMIN_FETCHLIST:
      return {
        ...state,
        loading: true,
      };

    case ADMIN_FETCHLIST_SUCCESS:
      return {
        ...state,
        items: action.payload,
        itemsById: byId(action.payload),
        loading: false,
      };

    case ADMIN_ITEM_ADDED:
      const listAdded = [
        ...(state.items || []),
        {
          users: [],
          ...action.payload,
        },
      ];
      return {
        ...state,
        items: listAdded,
        itemsById: byId(listAdded),
      };

    case ADMIN_ITEM_UPDATED:
      const listUpdated = state.items!.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

      return {
        ...state,
        items: listUpdated,
        itemsById: byId(listUpdated),
      };

    case ADMIN_ITEM_DELETED:
      let listDeleted = state.items!.filter(
        (item) => item.id !== action.payload.id
      );

      return {
        ...state,
        items: listDeleted,
        itemsById: byId(listDeleted),
      };

    default:
      return state;
  }
}
