import { SelectOption } from "appkit-react";
import Select from "components/Select";
import { useContext, useEffect, useState } from "react";

import { AdminEntityContext } from "../../../Entity";

const EntitySelect = ({
  companyId,
  disabled,
  value,
  onValueChange,
  companyIdRequired,
  ...selectProps
}) => {
  const [values, setValues] = useState(null);
  const { items: entities } = useContext(AdminEntityContext);

  useEffect(() => {
    if (companyId && entities?.length) {
      const newValues = entities
        .filter((entity) => entity.companyId === companyId)
        .map((entity) => ({
          id: entity.id,
          name: entity.name,
          companyId: entity.companyId,
        }));

      newValues.sort((a, b) => a.name.localeCompare(b.name));

      setValues(newValues);
    } else {
      if (entities && !companyIdRequired) {
        const newValues = [...entities];
        newValues.sort((a, b) => a.name.localeCompare(b.name));
        setValues(newValues);
      }
    }
  }, [companyId, entities, companyIdRequired]);

  return (
    <Select
      showSearchOnToggle
      value={value}
      onSelect={onValueChange}
      disabled={disabled !== undefined ? disabled : !companyId}
      {...selectProps}
    >
      {values?.map((entity) => (
        <SelectOption key={entity.id} value={entity.id}>
          {entity.name}
        </SelectOption>
      ))}
    </Select>
  );
};

export default EntitySelect;
