import { UseTypedQueryOptions } from "api/UseTypedQueryOptions";
import { UseQueryResult, useQuery } from "react-query";

import { ServiceGroupReadDTO } from "../__generated__/CatalogueAPI";
import { getServiceGroupById } from "../catalogApi";

interface IUseServiceGroupByIdProps {
  serviceGroupId: string;
  queryOptions?: UseTypedQueryOptions<ServiceGroupReadDTO>;
}

export default function useServiceGroupById(
  props: IUseServiceGroupByIdProps
): UseQueryResult<ServiceGroupReadDTO> {
  return useQuery(
    "serviceGroup/" + props.serviceGroupId,
    () => getServiceGroupById(props.serviceGroupId),
    props.queryOptions
  );
}
