export function fromUserModel(user) {
  const { phoneCountryCode, phoneNumber, ...data } = user;
  data.phone = { code: phoneCountryCode || "", number: phoneNumber || "" };

  return data;
}

export function toUserModel(view) {
  const { phone, ...data } = view;
  if (phone.number?.trim().length > 0) {
    data.phoneCountryCode = phone.code;
    data.phoneNumber = phone.number.replaceAll(" ", "");
  }

  return data;
}
