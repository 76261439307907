import { useCallback, useEffect } from "react";

interface Namable {
  name: string | null;
}

type OnChange<T> = (items: T[]) => void;

export interface UseMultilineForm<T> {
  handleChange: (value: T, index: number) => void;
  handleDelete: (index: number) => void;
}

export default function useMultilineForm<T extends Namable>(
  items: T[],
  onItemsChange: OnChange<T>,
  defaultItem: T
): UseMultilineForm<T> {
  const handleChange = useCallback(
    (newApp: T, index: number) => {
      let newApplications;
      if (index >= items.length) {
        newApplications = [...items, newApp];
      } else {
        newApplications = items.map((oldApp, i) =>
          i === index ? newApp : oldApp
        );
      }

      onItemsChange(newApplications);
    },
    [items, onItemsChange]
  );

  const handleDelete = useCallback(
    (index: number) => {
      if (index !== items.length - 1) {
        onItemsChange(items.filter((_, i) => i !== index));
      } else {
        onItemsChange(
          items.map((app, i) => (i !== index ? app : { ...defaultItem }))
        );
      }
    },
    [defaultItem, onItemsChange, items]
  );

  useEffect(() => {
    if (
      items.length === 0 ||
      // @ts-ignore
      items[items.length - 1]?.name?.length > 0
    ) {
      onItemsChange([...items, { ...defaultItem }]);
    }
  }, [items, onItemsChange, defaultItem]);

  return { handleChange, handleDelete };
}
