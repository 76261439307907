import { toUTCISODateString } from "api/helpers";

import { blankRawDraft } from "../../utils/draftjs";
import {
  toDateRangeFilter,
  toSafeNumberFilter,
  toTextFilter,
} from "../helpers/filters";

export const adaptArticleFromBackEnd = (oldData) => {
  let newData = { ...oldData };
  // Adapt dates
  if (newData.publishDate) {
    newData.publishDate = new Date(newData.publishDate);
  }
  if (newData.creationDate) {
    newData.creationDate = new Date(newData.creationDate);
  }
  if (newData.lastUpdateDate) {
    newData.lastUpdateDate = new Date(newData.lastUpdateDate);
  }
  if (newData.startDate) {
    newData.startDate = new Date(newData.startDate);
  }
  if (newData.endDate) {
    newData.endDate = new Date(newData.endDate);
  }

  newData.businessId = oldData.businessId.toString();

  // Adapt draftjs content
  newData.description = Object.entries(oldData.description).reduce(
    (acc, [key, content]) => {
      try {
        acc[key] = JSON.parse(content);
      } catch (e) {
        console.error(
          `Error while parsing content for article (field: '${key}')`,
          e
        );
        acc[key] = { ...blankRawDraft };
      }

      return acc;
    },
    {}
  );

  return newData;
};

export const adaptArticleToBackEnd = (oldData) => {
  let newData = { ...oldData };
  // Adapt dates
  if (newData.publishDate) {
    newData.publishDate = toUTCISODateString(newData.publishDate);
  }

  newData.businessId = parseInt(oldData.businessId);

  newData.description = Object.entries(oldData.description).reduce(
    (acc, [key, content]) => {
      acc[key] = JSON.stringify(content);
      return acc;
    },
    {}
  );
  return newData;
};

export const toApiFilter = (filters) => {
  const {
    type,
    territories,
    issuers,
    topics,
    documentTypes,
    published,
    publishDateRange,
    linkedNewsDateRange,
    freeTextSearch,
    businessId,
    checkedEventDates,
  } = filters;

  const businessIdFilters = parseInt(businessId);

  return {
    type,
    territories,
    issuers,
    topics,
    documentTypes,
    published,
    checkedEventDates,
    publishDateRange: toDateRangeFilter(publishDateRange),
    linkedNewsDateRange: toDateRangeFilter(linkedNewsDateRange),
    freeTextSearch: toTextFilter(freeTextSearch),
    businessId: toSafeNumberFilter(businessIdFilters),
    clientNoteFilter: filters.clientNoteFilter,
    pwcNoteFilter: filters.pwcNoteFilter,
  };
};
