export const INITIALIZE = {
  FALSE: 0,
  WIP: 1,
  TRUE: 2,
};

export const GLOBAL_ERRORS = {
  NO_ERROR: 0,
  UNKNOWN: 1,
  NO_COMPANY: 2,
  NO_USER: 3,
  SESSION_EXPIRED: 4,
  NO_TENANT: 5,
};
