import "./index.scss";

import { getPhoto, getUserFromEmail } from "api/admin/adminApi";
import {
  pollCreateUser,
  pollUpdateProfile,
  pollUpdateUser,
} from "api/admin/adminPoller";
import {
  Banner,
  Button,
  Checkbox,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
} from "appkit-react";
import ImageUploadToBase64 from "components/ImageUploadToBase64";
import PhoneInput, { validatePhone } from "components/PhoneInput";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import useSubmitable from "hooks/useSubmitable";
import { fromUserModel, toUserModel } from "pages/admin/utils/model";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";

import EntitySelect from "../../Engagement/AddEngagementModal/EntitySelect";

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isInternalEmail = (email) => {
  const regexp = /^.*@([a-z]+\.)*pwc\.[a-z]{2,3}$/g;
  return regexp.test(email);
};

const defaultUser = {
  email: "",
  photo: "",
  firstName: "",
  lastName: "",
  phone: {
    code: null,
    number: "",
  },
  grade: "",
  isSuperUser: false,
  entities: [],
  send3WEmail: false,
  sendAmdsEmail: false,
  creationIdentity: "2",
};

const focusInputElement = (element) => {
  setTimeout(() => {
    const ref = ReactDOM.findDOMNode(element);
    if (ref) {
      const input = ref.querySelector("input");
      if (input) {
        input.focus();
      }
    }
  }, 0);
};

const findFormErrors = (user) => {
  return {
    email: !isValidEmail(user.email),
    firstName: !user.firstName,
    lastName: !user.lastName,
    phone: user.phone?.number?.trim().length > 0 && validatePhone(user.phone),
    grade: isInternalEmail(user.email) && user.grade.trim().length === 0,
  };
};

const AddUserModal = ({
  visible = false,
  onClose,
  initialUser = null,
  onCreate,
  onUpdate,
}) => {
  const loginUser = useSelector((state) => state.global.data.loginUser);
  const ownProfile =
    initialUser && loginUser && initialUser.ppid === loginUser.ppid;

  const {
    values: user,
    handleValueChange,
    handleFormChange,
    resetForm,
    submitting,
    formErrors,
    submit,
  } = useSubmitable(defaultUser, findFormErrors);

  const [fieldsAvailable, setFieldsAvailable] = useState(false);
  const [searchingUser, setSearchingUser] = useState(false);
  const [bannerContent, setBannerContent] = useState("");
  const [isCropping, setIsCropping] = useState(false);
  const emailRef = useRef();
  const firstNameRef = useRef();

  const [oldPhoto, setOldPhoto] = useState(null);

  const isInternalUser = useMemo(
    () => isInternalEmail(user.email),
    [user.email]
  );

  useEffect(() => {
    if (user.photoUri) {
      getPhoto(user.photoUri).then(setOldPhoto);
    } else {
      setOldPhoto(null);
    }
  }, [user.photoUri]);

  useEffect(() => {
    if (visible && emailRef && emailRef.current) {
      focusInputElement(emailRef.current);
    }
  }, [visible, emailRef]);

  useEffect(() => {
    if (visible) {
      setBannerContent("");
      if (initialUser !== null) {
        const data = fromUserModel(initialUser);

        handleFormChange(data);
        setFieldsAvailable(true);
      } else {
        resetForm();
        setFieldsAvailable(false);
      }
    } else {
      setTimeout(() => {
        handleFormChange(defaultUser);
        setFieldsAvailable(false);
        setBannerContent("");
      }, 300);
    }
  }, [initialUser, handleFormChange, resetForm, visible]);

  const getModalTitle = () => {
    if (!initialUser) {
      return "Create new user";
    }
    return !ownProfile ? "Edit user" : "Edit profile";
  };

  const searchUser = () => {
    if (!isValidEmail(user.email)) {
      setBannerContent(
        "The specified e-mail is not valid. Please check your input and try again"
      );
      return;
    }
    setSearchingUser(true);
    setFieldsAvailable(false);

    getUserFromEmail(user.email)
      .then((data) => {
        const phone = {
          code: data?.phoneCountryCode || null,
          number: data?.phoneNumber || "",
        };

        handleFormChange({ ...user, ...data, phone, grade: data?.grade || "" });
        setFieldsAvailable(true);
        setSearchingUser(false);
        setBannerContent("");
      })
      .catch((data) => {
        setSearchingUser(false);
        if (data.status === 404) {
          setBannerContent(
            "No user found with the specified E-Mail. Please fill the fields manually"
          );
          focusInputElement(firstNameRef.current);
          setFieldsAvailable(true);
        } else {
          throw data;
        }
      });
  };

  const handleSubmit = () => {
    submit(() => {
      const data = toUserModel(user);

      if (!initialUser) {
        return pollCreateUser(data).then((data) => {
          onClose();
          onCreate(data);
        });
      } else {
        const promise =
          !loginUser.isSuperUser && ownProfile
            ? pollUpdateProfile(data, initialUser)
            : pollUpdateUser(data, initialUser);

        return promise.then((newUser) => {
          onClose();
          newUser.name = `${user.firstName} ${user.lastName}`;
          onUpdate(newUser);
        });
      }
    });
  };

  const photoToDisplay = user.photo || oldPhoto;

  return (
    <ResizableAppkitModal
      backdropClosable={false}
      visible={visible && !isCropping}
      className="create-user-modal"
      onCancel={onClose}
    >
      <ModalHeader>
        <div className="modal-title">{getModalTitle()}</div>
      </ModalHeader>
      <ModalBody>
        {!!bannerContent ? (
          <Banner content={bannerContent} status="warning" />
        ) : null}
        <div className="form">
          <div className="photo-wrapper">
            <div
              className="photo"
              style={{ backgroundImage: `url(${photoToDisplay})` }}
            >
              <ImageUploadToBase64
                openCropAfterSelect
                cropRatio={1 / 1}
                onChange={(newValue) => {
                  handleValueChange("photo", newValue);
                  setIsCropping(false);
                }}
                onCropOpened={() => setIsCropping(true)}
                onCropCanceled={() => setIsCropping(false)}
              />
              <span
                hidden={!!photoToDisplay}
                className="appkiticon icon-camera-outline"
              ></span>
            </div>
          </div>
          <div className="fields-wrapper">
            <div>
              <label className="a-form-label">
                <span className="a-color-primary">*</span>&nbsp;E-Mail
              </label>
              <Input
                className={!!initialUser ? "" : ""}
                autoFocus
                ref={emailRef}
                placeholder="E-Mail"
                value={user.email}
                hasError={formErrors.email}
                onChange={(value) => handleValueChange("email", value)}
                onKeyPress={(event) => {
                  if (event.charCode === 13 /*ENTER*/) {
                    searchUser();
                  }
                }}
                disabled={searchingUser || !!initialUser}
              />
            </div>
            {!initialUser ? (
              <Button
                className="search-user"
                kind="primary"
                disabled={searchingUser || !!initialUser}
                onClick={searchUser}
              >
                Search{" "}
                {searchingUser ? (
                  <div className="a-loading a-primary a-m-10" />
                ) : null}
              </Button>
            ) : (
              <div />
            )}
            <div>
              <label className="a-form-label">
                <span className="a-color-primary">*</span>&nbsp;First Name
              </label>
              <Input
                placeholder="First Name"
                value={user.firstName}
                onChange={(value) => handleValueChange("firstName", value)}
                disabled={!fieldsAvailable}
                ref={firstNameRef}
                hasError={formErrors.firstName}
              />
            </div>
            <div>
              <label className="a-form-label">
                <span className="a-color-primary">*</span>&nbsp;Last Name
              </label>
              <Input
                placeholder="Last Name"
                value={user.lastName}
                onChange={(value) => handleValueChange("lastName", value)}
                disabled={!fieldsAvailable}
                hasError={formErrors.lastName}
              />
            </div>
            <div>
              <label className="a-form-label">Phone number</label>
              <PhoneInput
                value={user.phone}
                onChange={(value) => handleValueChange("phone", value)}
                disabled={!fieldsAvailable}
                hasError={formErrors.phone}
              />
            </div>
            {isInternalUser ? (
              <>
                <div>
                  <label className="a-form-label">
                    <span className="a-color-primary">*</span>&nbsp;Grade
                  </label>
                  <Input
                    placeholder="Grade"
                    value={user.grade}
                    onChange={(value) => handleValueChange("grade", value)}
                    disabled={!fieldsAvailable}
                    hasError={formErrors.grade}
                  />
                </div>

                <Checkbox
                  disabled={!fieldsAvailable}
                  checked={user.isSuperUser}
                  onChange={() => {
                    handleValueChange("isSuperUser", !user.isSuperUser);
                  }}
                >
                  Admin
                </Checkbox>
              </>
            ) : (
              // As back support entities for user creation only
              !initialUser && (
                <>
                  <div>
                    <label className="a-form-label">Companies</label>
                    <EntitySelect
                      style={{ display: "inline" }}
                      disabled={!fieldsAvailable}
                      multiple
                      value={user.entities}
                      onValueChange={(value) =>
                        handleValueChange("entities", value)
                      }
                      placeholder="Companies"
                    />
                  </div>
                  <Checkbox
                    disabled={!fieldsAvailable}
                    checked={user.send3WEmail}
                    onChange={() => {
                      handleValueChange("send3WEmail", !user.send3WEmail);
                    }}
                  >
                    Send 3W email
                  </Checkbox>
                  <Checkbox
                    disabled={!fieldsAvailable}
                    checked={user.sendAmdsEmail}
                    onChange={() => {
                      handleValueChange("sendAmdsEmail", !user.sendAmdsEmail);
                    }}
                  >
                    Send AMDS welcome email
                  </Checkbox>
                </>
              )
            )}
            {!initialUser && (
              <div className="row">
                <div className="col-md-12">
                  <label className="a-form-label">User type</label>
                  <br />
                  <RadioGroup
                    value={user.creationIdentity}
                    onChange={(event, value) => {
                      handleValueChange("creationIdentity", value);
                    }}
                  >
                    <Radio value={"1"} disabled={!fieldsAvailable}>
                      Global
                    </Radio>
                    <Radio value={"2"} disabled={!fieldsAvailable}>
                      Luxembourg
                    </Radio>
                  </RadioGroup>
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onClose} disabled={submitting}>
          Cancel
        </Button>
        <Button kind="primary" isLoading={submitting} onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default AddUserModal;
