export const toDateRangeFilter = (range) => {
  if (!range) {
    return null;
  }
  return toDateFilter(
    range.start ? new Date(range.start) : null,
    range.end ? new Date(range.end) : null
  );
};

export const toDateFilter = (dateFrom, dateTo) => {
  const start = dateFrom
    ? new Date(
        Date.UTC(
          dateFrom.getFullYear(),
          dateFrom.getMonth(),
          dateFrom.getDate(),
          0,
          0,
          0,
          0
        )
      ).toISOString()
    : undefined;

  const end = dateTo
    ? new Date(
        Date.UTC(
          dateTo.getFullYear(),
          dateTo.getMonth(),
          dateTo.getDate() + 1,
          0,
          0,
          -1
        )
      ).toISOString()
    : undefined;

  return {
    start,
    end,
  };
};

export const toTextFilter = (text) =>
  text?.trim().length > 0 ? text.trim() : null;

export const toSafeNumberFilter = (number) => (!isNaN(number) ? number : null);
