import { refreshAllUsers } from "api/admin/adminApi";
import {
  Badge,
  Button,
  Checkbox,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopBanner,
} from "appkit-react";
import classnames from "classnames";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import { useState } from "react";

const RefreshUsersModal = ({
  user,
  className,
  onCancel,
  onDelete,
  ...modalProps
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const [confirmRead, setConfirmRead] = useState(false);

  const onClose = () => {
    if (!refreshing) {
      onCancel();
    }
  };

  const refresh = () => {
    setRefreshing(true);
    refreshAllUsers()
      .then(() => {
        PopBanner({
          content:
            "All of the users are currently being refreshed. This operation will take about 15 minutes to complete.",
          closeable: true,
          className: "a-banner-success",
        });
        setConfirmRead(false);
        onCancel();
      })
      .finally(() => setRefreshing(false));
  };

  return (
    <ResizableAppkitModal
      className={classnames("delete-modal", className)}
      onCancel={onClose}
      {...modalProps}
    >
      <ModalHeader>
        <Badge type={"warning"}>INFORMATION</Badge>
        <span className="title">Refresh all user data</span>
      </ModalHeader>
      <ModalBody>
        <p>
          This action will refresh all user information from the users in this
          application, which includes names, grades and phone numbers. This
          action will take some time and{" "}
          <strong>could slow down the application</strong>. Therefore, this
          action <strong>should only be executed when required</strong> and will
          be logged. Are you sure you wish to proceed ?
        </p>
        <Checkbox
          checked={confirmRead}
          onChange={() => setConfirmRead(!confirmRead)}
          disabled={refreshing}
        >
          I aknowledge that I have read and understood the notice above
        </Checkbox>
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onClose} disabled={refreshing}>
          Cancel
        </Button>
        <Button
          onClick={refresh}
          isLoading={refreshing}
          disabled={!confirmRead}
        >
          Refresh all data
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default RefreshUsersModal;
