import useLiveService from "api/catalog/queries/useLiveServices";
import { SelectOption } from "appkit-react";
import Select from "components/Select";
import { localeCompare } from "utils/localeCompare";

interface IServiceSelectProps {
  value: string[];
  onValueChange: (value: string[]) => void;
  serviceIdExclude?: string | null;
}

const ServiceSelect = ({
  value,
  onValueChange,
  serviceIdExclude,
  ...selectProps
}: IServiceSelectProps) => {
  const { data: services = [] } = useLiveService(
    serviceIdExclude
      ? {
          select: (services) =>
            services.filter((service) => service.id !== serviceIdExclude),
        }
      : {}
  );

  return (
    <Select
      placeholder="Select some services"
      showSearchOnToggle
      value={value}
      onSelect={onValueChange}
      multiple
      {...selectProps}
    >
      {services?.sort(localeCompare("name")).map((service) => (
        <SelectOption value={service.id} key={service.id}>
          {service.name}
        </SelectOption>
      ))}
    </Select>
  );
};

export default ServiceSelect;
