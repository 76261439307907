import { imageFromBlob } from "api/utils";
import { downloadBlob } from "utils";

import {
  RET_RAW,
  apiFetch,
  apiFetchFormData,
  appendIEHeaders,
  appendTokenHeaders,
} from "../api";
import {
  Api,
  CompanyCreateDTO,
  CompanyReadDTO,
  EntityCreateDTO,
  EntityReadDTO,
  MyClientReadDTO,
  MyClientServicesReadDTO,
  MyServiceClientReadDTO,
  MyServicePwCReadDTO,
  OrganizationQueryDTO,
  OrganizationReadDTOPagedCollection,
  ServiceReadForAdminDTO,
  SubscriptionCreateDTO,
  SubscriptionDetailsReadDTO,
  SubscriptionQueryDTO,
  SubscriptionReadDTO,
  UserFilters,
  UserOrderBy,
  UserQueryDTO,
} from "./__generated__/UserManagementAPI";
import { adminAdaptUserToBackEnd } from "./adminDataManipulators";
import { Company } from "./model/Company";
import { EntityUpdate } from "./model/Entity";
import { SubscriptionUpdate } from "./model/Subscription";

const PREFIX = process.env.REACT_APP_URL_USERMANAGEMENT;

const DEFAULT_PAGINATION = {
  pageSize: 20,
  pageNumber: 1,
};

let _clientSingleton: Api<any> | null = null;
const client = (): Api<any> => {
  if (!_clientSingleton) {
    _clientSingleton = new Api({
      baseUrl: PREFIX,
      baseApiParams: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...appendTokenHeaders(),
          ...appendIEHeaders(),
        },
      },
    });
  }
  return _clientSingleton;
};

function toData<T>(res: { data: T }) {
  return res.data;
}

export const getMyProfile = () => {
  return client().api.v1UserMyProfileList().then(toData);
};

export const getUsers = (
  filters: UserQueryDTO,
  pagination = DEFAULT_PAGINATION
) => {
  return client().api.v1UserPagedCreate(pagination, filters).then(toData);
};

export const getUsersByPpids = (ppids: string[]) => {
  return getUsers(
    {
      filters: {
        ppids,
      } as UserFilters,
      orderBy: {} as UserOrderBy,
    },
    {
      pageNumber: 1,
      pageSize: ppids.length,
    }
  );
};

export const getUsersFromIdArray = (ids: string[]) => {
  return apiFetch(`${PREFIX}/api/v1/User/fullnames`, ids, "POST");
};
export const getOrganizationsFromIdArray = (ids: string[]) => {
  return apiFetch(`${PREFIX}/api/v1/Organization/names`, ids, "POST");
};

export const getUserDetails = (ppid: string) => {
  return client().api.v1UserDetail(ppid).then(toData);
};

export const deleteUser = (id: string) => {
  return client().api.v1UserDelete(id).then(toData);
};

export const getSubscriptionsUsersPaged = (
  filters: SubscriptionQueryDTO,
  pagination: any
): Promise<SubscriptionDetailsReadDTO[]> => {
  return client()
    .api.v1SubscriptionPagedUsersCreate(filters, pagination)
    .then(toData);
};

export const getSubscriptionDetails = (id: string) => {
  return client().api.v1SubscriptionDetail(id).then(toData);
};

function upsertSubscriptionCatch(exception: any) {
  if (
    exception?.error?.length === 1 &&
    exception.error[0].message?.startsWith("[Duplicate]")
  ) {
    exception.error[0].message =
      "A subscription already exists for the selection";
  }
}

export const addEngagement = (
  engagement: SubscriptionCreateDTO
): Promise<SubscriptionDetailsReadDTO> => {
  const _engagement = {
    ...engagement,
    users: engagement.users!.map((user) => ({
      ppid: user.ppid,
      isMainContact: true, // all users added are main contact
    })),
  };
  return client()
    .api.v1SubscriptionCreate(_engagement)
    .then(toData)
    .catch((exception) => {
      upsertSubscriptionCatch(exception);
      throw exception;
    });
};

export const updateEngagement = ({
  subscriptionId,
  ...engagement
}: SubscriptionUpdate): Promise<void> => {
  return client()
    .api.v1SubscriptionUpdate(subscriptionId, engagement)
    .then(toData)
    .catch((exception) => {
      upsertSubscriptionCatch(exception);
      throw exception;
    });
};

export const getUserFromEmail = (email: string) => {
  return client()
    .api.v1UserExistingUserList({ email })
    .then((response) => {
      if (response.status === 204) {
        // server returns 204 in case user does not exist, treat it like a 404
        // eslint-disable-next-line
        throw { ...response, status: 404 };
      } else return toData(response);
    });
};

export const createUser = (user: any) => {
  const data = adminAdaptUserToBackEnd(user);
  return apiFetchFormData(`${PREFIX}/api/v1/User`, data, "POST");
};

export const updateUser = (user: any) => {
  const data = adminAdaptUserToBackEnd(user);
  return apiFetchFormData(`${PREFIX}/api/v1/User/${user.ppid}`, data, "PUT");
};

export const downloadUserImportTemplate = () => {
  return apiFetch(`${PREFIX}/api/v1/User/import`, undefined, "GET", RET_RAW)
    .then((res: any) => res.blob())
    .then((blob) => downloadBlob(blob, "import_users_template.xlsx"));
};

export const importUsersFromExcel = (file: File) => {
  return apiFetchFormData(
    `${PREFIX}/api/v1/User/import`,
    { importFile: file },
    "POST"
  );
};

export const downloadEntityImportTemplate = () => {
  return apiFetch(`${PREFIX}/api/v1/Entity/import`, undefined, "GET", RET_RAW)
    .then((res: any) => res.blob())
    .then((blob) => downloadBlob(blob, "import_companies_template.xlsx"));
};

export const importEntitiesFromExcel = (file: File) => {
  return apiFetchFormData(
    `${PREFIX}/api/v1/Entity/import`,
    { importFile: file },
    "POST"
  );
};

export const updateProfile = (user: any) => {
  const data = adminAdaptUserToBackEnd(user);
  return apiFetchFormData(`${PREFIX}/api/v1/User/MyProfile`, data, "PUT");
};

export const addUserToEngagement = (
  subscirptionId: string,
  ppids: string[]
) => {
  return client()
    .api.v1SubscriptionUsersUpdate(
      subscirptionId,
      ppids.map((ppid) => ({ ppid, isMainContact: false }))
    )
    .then(toData);
};

export const updateUserEngagement = (
  subscriptionId: string,
  userPpid: string,
  isMainContact: boolean
) => {
  return client()
    .api.v1SubscriptionUsersPartialUpdate(subscriptionId, {
      ppid: userPpid,
      isMainContact,
    })
    .then(toData);
};

export const deleteUserFromEngagement = (
  engagementId: string,
  ppid: string
) => {
  return client()
    .api.v1SubscriptionUsersDelete(engagementId, { ppid })
    .then(toData);
};

export const deleteEngagement = (engagementId: string) => {
  return client().api.v1SubscriptionDelete(engagementId).then(toData);
};

export const downloadSubscriptionImportTemplate = () => {
  return apiFetch(
    `${PREFIX}/api/v1/Subscription/import`,
    undefined,
    "GET",
    RET_RAW
  )
    .then((res: any) => res.blob())
    .then((blob) => downloadBlob(blob, "import_subscriptions_template.xlsx"));
};

export const getSubscriptionNamesFromIds = (subscriptionIds: string[]) => {
  return apiFetch(
    `${PREFIX}/api/v1/Subscription/fullnames`,
    subscriptionIds,
    "POST"
  );
};

export const importSubscriptionFromExcel = (file: File) => {
  return apiFetchFormData(
    `${PREFIX}/api/v1/Subscription/import`,
    { importFile: file },
    "POST"
  );
};

export const downloadServiceImportTemplate = () => {
  return apiFetch(
    `${process.env.REACT_APP_URL_CATALOG}/api/v1/Service/import`,
    undefined,
    "GET",
    RET_RAW
  )
    .then((res: any) => res.blob())
    .then((blob) => downloadBlob(blob, "import_services_template.xlsx"));
};

export const downloadServiceExport = () => {
  return apiFetch(
    `${process.env.REACT_APP_URL_CATALOG}/api/v1/Service/export/excel`,
    undefined,
    "POST",
    RET_RAW
  )
    .then((res: any) => res.blob())
    .then((blob) => downloadBlob(blob, "export_services.xlsx"));
};

export const downloadUserPermissions = () => {
  return apiFetch(
    `${process.env.REACT_APP_URL_USERMANAGEMENT}/api/v1/Export/UsersPermissions`,
    undefined,
    "POST",
    RET_RAW
  )
    .then((res: any) => res.blob())
    .then((blob) => downloadBlob(blob, "export_user_permissions.xlsx"));
};

export const importServiceFromExcel = (file: File) => {
  return apiFetchFormData(
    `${process.env.REACT_APP_URL_CATALOG}/api/v1/Service/import`,
    { importFile: file },
    "POST"
  );
};

export const updateSuperUserStatus = (ppid: string, isSuperUser: boolean) => {
  return client()
    .api.v1UserSuperuserPartialUpdate(ppid, isSuperUser)
    .then(toData);
};

export const getPhoto = (photoUri: string) => {
  return apiFetch(
    `${PREFIX}/api/v1/User/${photoUri}`,
    undefined,
    "GET",
    RET_RAW,
    { "Cache-Control": "max-age=9999" }
  )
    .then((data: any) => data.blob())
    .then(imageFromBlob)
    .catch(() => null);
};

export const resendUserWelcomeEmails = (
  ppid: string,
  send3w: boolean,
  sendAmds: boolean,
  creationIdentity: string
) => {
  return apiFetchFormData(
    `${PREFIX}/api/v1/User/${ppid}/resend`,
    {
      Send3WEmail: send3w,
      SendAmdsEmail: sendAmds,
      CreationIdentity: creationIdentity,
    },
    "POST"
  );
};

export const getCompanies = (): Promise<CompanyReadDTO[]> => {
  return client().api.v1CompanyList().then(toData);
};

export const createCompany = (company: CompanyCreateDTO) => {
  return client().api.v1CompanyCreate(company).then(toData);
};

export const updateCompany = (company: Company) => {
  const { id, ...data } = company;
  return client().api.v1CompanyUpdate(id, data);
};

export const acceptTermsAndConditions = () => {
  return client()
    .api.v1UserTermsAndConditionsAcceptedPartialUpdate()
    .then(toData);
};

export const getAllEntities = () => {
  return client().api.v1EntityList().then(toData);
};
export const createEntity = (dto: EntityCreateDTO) => {
  return client().api.v1EntityCreate(dto).then(toData);
};

export const updateEntity = ({ entityId, ...data }: EntityUpdate) => {
  return client().api.v1EntityUpdate(entityId, data).then(toData);
};

export const getMyContacts = () => {
  return client().api.v1EntityMyContactsList().then(toData);
};

export const getUserEntitiesContacs = (entityId: string) => {
  return client().api.v1UserEntitiesContactsDetail(entityId).then(toData);
};

export const deleteEntity = (entityId: string) => {
  return client().api.v1EntityDelete(entityId).then(toData);
};

export const addUserToEntity = (
  entityId: string,
  ppids: string[]
): Promise<EntityReadDTO> => {
  return client()
    .api.v1EntityUsersUpdate(
      entityId,
      ppids.map((ppid) => ({ ppid }))
    )
    .then(toData);
};

export const removeUserFromEntity = (
  entityId: string,
  ppid: string
): Promise<EntityReadDTO> => {
  return client().api.v1EntityUsersDelete(entityId, { ppid }).then(toData);
};

export const getAdminCatalogue = (): Promise<ServiceReadForAdminDTO[]> => {
  return client().api.v1ServiceList().then(toData);
};

export const getUserSubscriptions = (): Promise<MyServiceClientReadDTO[]> => {
  return client().api.v1SubscriptionMyServicesList().then(toData);
};

export const getMyClientNames = (): Promise<MyClientReadDTO[]> => {
  return client().api.v1SubscriptionMyClientsList().then(toData);
};

export const getMyServices = (): Promise<MyServicePwCReadDTO[]> => {
  return client().api.v1ServiceMyList().then(toData);
};

export const getMyClientSubscriptions = (
  clientIds: string[],
  pagination = DEFAULT_PAGINATION
): Promise<MyClientServicesReadDTO[]> => {
  return client()
    .api.v1SubscriptionMyClientsServicesCreate(clientIds, pagination)
    .then(toData);
};

export const getOrganizations = (
  filters: OrganizationQueryDTO,
  pagination = DEFAULT_PAGINATION
): Promise<OrganizationReadDTOPagedCollection> => {
  return client()
    .api.v1OrganizationPagedCreate(filters, pagination)
    .then(toData);
};

export function getSubscriptionsByService(
  serviceId: string
): Promise<SubscriptionReadDTO[]> {
  return client().api.v1SubscriptionByServiceDetail(serviceId).then(toData);
}

export function getSubscriptionsByUser(
  userId: string
): Promise<SubscriptionReadDTO[]> {
  return client().api.v1UserServicesDetail(userId).then(toData);
}

export const getUserContacts = (subscriptionId: string) => {
  return client()
    .api.v1UserPagedCreate(
      { pageSize: 9999, pageNumber: 1 },
      {
        filters: {
          isInternal: false,
          subscriptionId: subscriptionId,
        } as any,
        orderBy: {
          name: {
            isDescending: false,
          },
        } as any,
      }
    )
    .then(toData);
};

export const refreshUser = (userId: string) => {
  return apiFetch(`${PREFIX}/api/v1/User/refresh`, [userId], "PUT");
};

export const refreshAllUsers = () => {
  //if (!!1) return new Promise((resolve) => setTimeout(resolve, 5000));
  return apiFetch(`${PREFIX}/api/v1/User/refreshAll`, undefined, "PUT");
};
