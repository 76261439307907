import ControlledError from "pages/error/ControlledError";
import UnexpectedError from "pages/error/UnexpectedError";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { GLOBAL_ERRORS } from "redux/constants";

import RefreshSessionButton from "./refreshSessionButton";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const error =
      this.state.error ||
      (this.props.hasError && this.props.hasError !== GLOBAL_ERRORS.NO_ERROR);
    if (error && prevProps.location.pathname !== this.props.location.pathname) {
      window.location.replace(this.props.location.pathname);
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  render() {
    if (
      this.props.hasError !== undefined &&
      this.props.hasError !== GLOBAL_ERRORS.NO_ERROR
    ) {
      switch (this.props.hasError) {
        case GLOBAL_ERRORS.NO_COMPANY:
          return (
            <ControlledError
              title="Your profile is not ready yet"
              subtitle="Please try again later"
              description="We're currently setting up your profile in the Managed Services Hub platform. Please try again later. If you think you're seeing this message by mistake, please reach out to us and we'll be happy to help."
            />
          );
        case GLOBAL_ERRORS.NO_USER:
          return (
            <ControlledError
              title="Your profile doesn't exist"
              description="Your profile hasn't been created in the Managed Services Hub platform yet. Please contact us for more information."
              showReloadButton={false}
            />
          );
        case GLOBAL_ERRORS.SESSION_EXPIRED:
          return (
            <ControlledError
              title="You lost your connection"
              description="Please refresh the page and reconnect"
              showReloadButton={false}
              showLogoutButton={false}
              customButton={<RefreshSessionButton />}
            />
          );
        case GLOBAL_ERRORS.NO_TENANT:
          return (
            <ControlledError
              title="Failed to get tenant information"
              description="We couldn't load the application correctly. Please try again later."
              showReloadButton={true}
              showLogoutButton={false}
            />
          );
        case GLOBAL_ERRORS.UNKNOWN:
        default:
          return <UnexpectedError />;
      }
    }
    const error = this.state.error || this.props.hasError;
    if (error) {
      // You can render any custom fallback UI
      return <UnexpectedError error={error} />;
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    hasError: state.global.hasError,
  };
};

export default withRouter(connect(mapStateToProps)(ErrorBoundary));
