import useTableReducer from "hooks/useTableReducer";
import { useCallback, useEffect, useState } from "react";

const useData = (filters, apiCall, initialSorted, appendOrderWithName) => {
  const [data, setData] = useState({
    loading: true,
    items: null,
    totalCount: 0,
  });

  const tableReducer = useTableReducer(initialSorted);
  const { state, onPageChange } = tableReducer;

  const fetchData = useCallback(() => {
    setData((data) => ({
      ...data,
      loading: true,
    }));

    apiCall(
      {
        filters,
        orderBy: state.sorted.reduce((acc, sorted) => {
          acc[sorted.id + (appendOrderWithName ? "Name" : "")] = {
            isDescending: !!sorted.desc,
          };
          return acc;
        }, {}),
      },
      {
        pageNumber: state.page + 1,
        pageSize: state.pageSize,
      }
    ).then((data) => {
      if (Array.isArray(data)) {
        setData({
          loading: false,
          items: data,
          totalCount: data.length,
          pages:
            data?.length < state.pageSize ? state.page + 1 : Number.MAX_VALUE,
        });
      } else {
        const totalPages = Math.max(data.totalPages, 1);
        setData({
          ...data,
          totalPages,
          loading: false,
        });

        if (totalPages < state.page) {
          onPageChange(totalPages - 1);
        }
      }
    });
  }, [filters, state, onPageChange, apiCall, appendOrderWithName]);

  const optimisticChange = useCallback((newItem) => {
    setData((data) => {
      return {
        ...data,
        items: data.items.map((item) =>
          item.id === newItem.id ? newItem : item
        ),
      };
    });
  }, []);

  const setItems = useCallback((items) => {
    setData((prevData) => ({ ...prevData, items }));
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    ...tableReducer,
    ...tableReducer.state,
    loading: data.loading,
    items: data.items,
    pages: data.totalPages ?? data.pages,
    setItems,
    fetchData,
    optimisticChange,
  };
};

export default useData;
