import { MIME_PNG } from "../../constants";
import { dataURItoBlob } from "../helpers";

export const adminAdaptUserToBackEnd = (oldData: any): any => {
  const newData = {
    Email: oldData.email,
    FirstName: oldData.firstName,
    Grade: oldData.grade,
    IsSuperUser: oldData.isSuperUser,
    LastName: oldData.lastName,
    PhoneCountryCode: oldData.phoneCountryCode,
    PhoneNumber: oldData.phoneNumber,
    Photo: null as any,
    Entities: oldData.entities,
    Send3WEmail: oldData.send3WEmail,
    SendAmdsEmail: oldData.sendAmdsEmail,
    CreationIdentity: oldData.creationIdentity,
  };

  if (oldData.photo) {
    newData.Photo = dataURItoBlob(oldData.photo, MIME_PNG);
  }

  return newData;
};
