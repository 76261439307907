import { downloadBlob } from "utils/";

import { RET_RAW, apiFetch, apiFetchFormData } from "../api";
import {
  adaptArticleFromBackEnd,
  adaptArticleToBackEnd,
  toApiFilter,
} from "./articleDataManipulators";

const PREFIX = process.env.REACT_APP_URL_NEWS_ROOT;

const PREFIX_3W = PREFIX + "Article3W/";

const PREFIX_GLOBAL = PREFIX + "ArticleGlobal/";

export const ARTICLE_TYPES = {
  "3W": 0,
  GLOBAL: 1,
};

const getPrefixFromType = (type) => {
  switch (type) {
    case ARTICLE_TYPES["3W"]:
      return PREFIX_3W;
    case ARTICLE_TYPES.GLOBAL:
      return PREFIX_GLOBAL;
    default:
      throw new Error("No valid type specified");
  }
};

export const genericGetList = (
  filters,
  { pageNumber = 1, pageSize = 6 },
  newsType
) => {
  return apiFetch(
    `${getPrefixFromType(
      newsType
    )}paged?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    { filters: toApiFilter(filters.filters), orderBy: filters.orderBy },
    "POST"
  );
};

export const genericDeleteArticle = (id, newsType) => {
  return apiFetch(`${getPrefixFromType(newsType)}${id}`, undefined, "DELETE");
};

export const genericGetArticle = (articleId, newsType) => {
  return apiFetch(`${getPrefixFromType(newsType)}${articleId}`).then((data) =>
    adaptArticleFromBackEnd(data)
  );
};

export const genericUpdateArticle = async (article, newsType) => {
  const adaptedArticle = adaptArticleToBackEnd(article);
  return await apiFetch(
    `${getPrefixFromType(newsType)}/${article.id}`,
    adaptedArticle,
    "PUT"
  );
};

export const genericCreateArticle = async (article, newsType) => {
  const data = await apiFetch(
    `${getPrefixFromType(newsType)}`,
    adaptArticleToBackEnd(article),
    "POST"
  );
  return adaptArticleFromBackEnd(data);
};

export const genericPublishArticle = async (id, publish, newsType) => {
  return apiFetch(
    `${getPrefixFromType(newsType)}${id}/publish`,
    publish.toString(),
    "PATCH"
  );
};

export const genericGetBusinessId = (type, newsType) => {
  return apiFetch(`${getPrefixFromType(newsType)}nextBusinessId/${type}`);
};

export const getNewsPermissions = () => {
  return apiFetch(`${PREFIX}Metadata/types`);
};

export const getUsedMetadataForType = (type) => {
  return apiFetch(`${PREFIX}Metadata/used/${type}`);
};

export const downloadTemplate = () => {
  return apiFetch(`${PREFIX_3W}import`, undefined, "GET", RET_RAW)
    .then((res) => res.blob())
    .then((blob) => downloadBlob(blob, "import_article_template.xlsx"));
};

export const importArticlesFromExcel = (file) => {
  return apiFetchFormData(`${PREFIX_3W}import`, { importFile: file }, "POST");
};

export const getClientNote = (articleId) => {
  return apiFetch(`${PREFIX_3W}${articleId}/ClientNote`);
};

export const getClientNoteFromSubscription = (articleId, subscriptionId) => {
  return apiFetch(`${PREFIX_3W}${articleId}/ClientNote/${subscriptionId}`);
};

export const updateClientNote = (articleId, clientNoteContent) => {
  return apiFetch(
    `${PREFIX_3W}${articleId}/ClientNote`,
    clientNoteContent,
    "PUT"
  );
};

export const updateClientNotePersonal = (articleId, note, assignToMe) => {
  return apiFetch(
    `${PREFIX_3W}${articleId}/ClientNote/Personal`,
    {
      assignedToMe: assignToMe,
      personalComments: note,
    },
    "PUT"
  );
};

export const getPwcNote = (articleId) => {
  return apiFetch(`${PREFIX_3W}${articleId}/PwCNote`);
};

export const updatePwcNote = (articleId, content) => {
  return apiFetch(`${PREFIX_3W}${articleId}/PwCNote`, content, "PUT");
};

export const getAllPwcNotes = (articleId) => {
  return apiFetch(`${PREFIX_3W}${articleId}/PwCNote/All`);
};
