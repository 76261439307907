import "./index.scss";

const SuspenseLoader = ({ text = "Loading page..." }) => {
  return (
    <div className="suspense-loader">
      <div className="d-inline-block text-center">
        <div className="a-loading a-primary"></div>
        <div className="a-mt-10 a-h6 a-text-black">{text}</div>
      </div>
    </div>
  );
};

export default SuspenseLoader;
