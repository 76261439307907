import { getAllEntities } from "api/admin/adminApi";
import {
  pollAddEntity,
  pollAddUserToEntity,
  pollDeleteEntity,
  pollRemoveUserFromEntity,
  pollUpdateEntity,
} from "api/admin/adminPoller";
import {
  fetchList,
  fetchListSuccess,
  itemAdded,
  itemDeleted,
  itemUpdated,
} from "pages/admin/actions";
import adminReducer, { initialState } from "pages/admin/actions/reducer";
import { useCallback, useEffect, useReducer } from "react";

export default function useEntityReducer() {
  const [state, dispatch] = useReducer(adminReducer, initialState);

  const fetchEntityList = useCallback(async () => {
    dispatch(fetchList());

    const entities = await getAllEntities();
    dispatch(fetchListSuccess(entities));
  }, []);

  const setEntityList = useCallback((entities) => {
    dispatch(fetchListSuccess(entities));
  }, []);

  const addEntity = useCallback((form) => {
    return pollAddEntity(form).then((entity) => {
      dispatch(itemAdded(entity));
      return entity;
    });
  }, []);

  const updateEntity = useCallback((form) => {
    return pollUpdateEntity(form).then((entity) => {
      dispatch(itemUpdated(entity));
      return entity;
    });
  }, []);

  const deleteEntity = useCallback((entity) => {
    return pollDeleteEntity(entity.id).then(() => {
      dispatch(itemDeleted(entity));
    });
  }, []);

  const addUserToEntity = useCallback((entityId, ppids) => {
    return pollAddUserToEntity(entityId, ppids).then((entity) => {
      dispatch(itemUpdated(entity));
      return entity;
    });
  }, []);

  const removeUserFromEntity = useCallback((entityId, ppid) => {
    return pollRemoveUserFromEntity(entityId, ppid).then((entity) => {
      dispatch(itemUpdated(entity));
      return entity;
    });
  }, []);

  useEffect(() => {
    fetchEntityList();
  }, [fetchEntityList]);

  return {
    ...state,
    fetchEntityList,
    setEntityList,
    addEntity,
    updateEntity,
    deleteEntity,
    addUserToEntity,
    removeUserFromEntity,
  };
}
