import "./index.scss";

import FloatingActionButtonPortal from "components/FloatingActionButton";
import Table from "components/Table";
import { useContext, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

import useMainTableStickyHeader from "../../../hooks/useMainTableStickyHeader";
import UsersTable from "../../UserTable";
import AddEntityModal from "../AddEntityModal";
import DeleteEntityModal from "../DeleteEntityModal";
import AddMultipleUsersToEntityModal from "./addMultipleUsersToEntityModal";
import AddUserToEntityModal from "./addUserToEntityModal";
import DeleteUserFromEntityModal from "./deleteUserFromEntityModal";
import EntityMenuList from "./MenuList";
import { AdminEntityContext } from "..";

export const COLUMNS = [
  {
    Header: "Company name",
    id: "entity",
    filterable: true,
    sortable: true,
  },
  {
    Header: "Company group name",
    id: "company",
    filterable: true,
    sortable: true,
  },
  {
    Header: "PRID",
    id: "prid",
    accessor: "prid",
    filterable: true,
    Filter: () => null,
    sortable: false,
  },
  {
    Header: "Show Services",
    id: "viewInMyServices",
    accessor: "viewInMyServices",
    filterable: false,
    Filter: () => null,
    sortable: false,
  },
  {
    Header: "Member",
    id: "name",
    accessor: "users",
    filterable: true,
    sortable: false,
  },
];

const DEFAULT_SORT = [
  { id: "entity", desc: false },
  { id: "highestLevel", desc: false },
];

const EntitiesTable = ({ filters }) => {
  const loginUser = useSelector((state) => state.global.data.loginUser);

  const { rowFilters } = filters;
  const { loading, items: entities } = useContext(AdminEntityContext);

  const tableRef = useRef();
  useMainTableStickyHeader(loading, tableRef, 310);

  const [showCreateEntityModal, setShowCreateEntityModal] = useState(false);
  const [editedEntity, setEditedEntity] = useState(null);
  const [entityToDelete, setEntityToDelete] = useState(null);

  const [addUser, setAddUser] = useState(null);
  const [addMultipleUsers, setAddMultipleUsers] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);

  const [expanded, setExpanded] = useState([]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Company name",
        id: "entity",
        accessor: "name",
        filterable: true,
        Filter: () => null,
      },
      {
        Header: "Company group name",
        id: "company",
        accessor: "companyName",
        filterable: true,
        Filter: () => null,
      },
      {
        Header: "PRID",
        id: "prid",
        accessor: "prid",
        filterable: true,
        Filter: () => null,
        sortable: false,
      },
      {
        Header: "Show Services",
        id: "viewInMyServices",
        accessor: "viewInMyServices",
        filterable: false,
        Filter: () => null,
        sortable: false,
        Cell: ({ value }) => (value ? "Yes" : "No"),
      },
      {
        Header: "# users",
        id: "name",
        accessor: "users",
        filterable: true,
        sortable: false,
        Filter: () => null,
        filterMethod: (rowFilter, row) => {
          // row.name is actually row.users (?!)
          // id "name" is required to allow to keep the filter through admin tabs
          // "name" is the id of user column
          return row.name.some((user) =>
            user.name.toLowerCase().includes(rowFilter.value.toLowerCase())
          );
        },
        Cell: ({ value }) => value.length,
        width: 90,
      },
      {
        Header: "Actions",
        id: "actions",
        sortable: false,
        filterable: false,
        className: "overflow-td",
        Cell: ({ original }) => {
          return (
            <EntityMenuList
              entity={original}
              onAddUser={() => setAddUser(original)}
              onAddMultipleUsers={() => setAddMultipleUsers(original)}
              onEditEntity={() => {
                setShowCreateEntityModal(true);
                setEditedEntity(original);
              }}
              onDeleteEntity={() => setEntityToDelete(original)}
            />
          );
        },
        width: 90,
      },
    ];
  }, []);

  return !loading && !!entities ? (
    <>
      <AddEntityModal
        visible={showCreateEntityModal}
        onClose={() => setShowCreateEntityModal(false)}
        entity={editedEntity}
      />

      <DeleteEntityModal
        onClose={() => setEntityToDelete(null)}
        entity={entityToDelete}
      />

      <AddUserToEntityModal
        entity={addUser || null}
        onClose={() => setAddUser(null)}
      />

      <AddMultipleUsersToEntityModal
        entity={addMultipleUsers || null}
        onClose={() => setAddMultipleUsers(null)}
      />

      <DeleteUserFromEntityModal
        user={userToDelete}
        onClose={() => setUserToDelete(null)}
      />

      <FloatingActionButtonPortal
        name="icon-plus-fill"
        onClick={() => {
          setEditedEntity(null);
          setShowCreateEntityModal(true);
        }}
        tooltip="Create a new entity"
      />

      <Table
        ref={tableRef}
        filterable={false}
        filtered={rowFilters}
        defaultFilterMethod={({ id, value }, row) => {
          if (row._subRows && row._subRows.length > 0) {
            return true;
          }
          return row[id] !== null
            ? row[id].toUpperCase().indexOf(value.toUpperCase()) >= 0
            : false;
        }}
        className="entitiestable"
        columns={columns}
        data={entities}
        defaultSorted={DEFAULT_SORT}
        expanded={expanded}
        onExpandedChange={setExpanded}
        SubComponent={({ original }) => {
          return (
            <UsersTable
              data={original.users}
              parentId={original.id}
              onAddUser={setAddUser}
              onDeleteUser={loginUser.isSuperUser ? setUserToDelete : null}
            />
          );
        }}
      />
    </>
  ) : (
    <Skeleton height={200} />
  );
};

export default EntitiesTable;
