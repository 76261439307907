import {
  MatomoProvider,
  createInstance,
  useMatomo,
} from "@datapunt/matomo-tracker-react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const instance = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_URLBASE!,
  siteId: Number.parseInt(process.env.REACT_APP_MATOMO_SITEID!),
  disabled: process.env.REACT_APP_MATOMO_ENABLED !== "true",
  linkTracking: false,
});
const MatomoHOC: React.FC<{}> = ({ children }) => {
  return (
    <MatomoProvider value={instance}>
      <MatomoTracker>{children}</MatomoTracker>
    </MatomoProvider>
  );
};

const MatomoTracker: React.FC<{}> = ({ children }) => {
  const { enableLinkTracking, trackPageView, pushInstruction } = useMatomo();
  const loginUser = useSelector(
    (state) => (state as any).global.data.loginUser
  );
  const location = useLocation();
  enableLinkTracking();

  useEffect(() => {
    if (loginUser !== null) {
      pushInstruction("setUserId", loginUser.ppid);
      pushInstruction(
        "setCustomVariable",
        4,
        "Is Internal User",
        loginUser.isInternal
      );
      trackPageView({ href: location.pathname });
    }
  }, [location, trackPageView, loginUser, pushInstruction]);

  return <>{children}</>;
};

export default MatomoHOC;
