import "./index.scss";

import FloatingActionButtonPortal from "components/FloatingActionButton";
import Table from "components/Table";
import Actions from "components/Table/actions";
import { useContext, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import useMainTableStickyHeader from "../../../hooks/useMainTableStickyHeader";
import AddCompanyModal from "../AddCompanyModal";
import { AdminCompanyContext } from "..";

export const COLUMNS = [
  {
    Header: "Company group name",
    id: "company",
    filterable: true,
    Filter: () => null,
  },
];

const DEFAULT_SORT = [
  { id: "company", desc: false },
  { id: "highestLevel", desc: false },
  { id: "name", desc: false },
];

const CompaniesTable = ({ filters }) => {
  const { items: companies, loading } = useContext(AdminCompanyContext);

  const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);
  const [editedCompany, setEditedCompany] = useState(null);

  const { rowFilters = [] } = filters;

  const tableRef = useRef();
  useMainTableStickyHeader(loading, tableRef);

  const columns = useMemo(() => {
    return [
      {
        Header: "Company group name",
        id: "company",
        accessor: "name",
        PivotValue: ({ value }) => value,
        filterable: true,
        Filter: () => null,
      },
      {
        Header: "Actions",
        id: "actions",
        sortable: false,
        filterable: false,
        Cell: ({ original }) => (
          <Actions
            handleEdit={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setShowCreateCompanyModal(true);
              setEditedCompany(original);
            }}
          />
        ),
        width: 90,
      },
    ];
  }, []);

  return !loading && !!companies ? (
    <>
      <AddCompanyModal
        visible={showCreateCompanyModal}
        onClose={() => setShowCreateCompanyModal(false)}
        company={editedCompany}
      />
      <FloatingActionButtonPortal
        name="icon-plus-fill"
        onClick={() => {
          setEditedCompany(null);
          setShowCreateCompanyModal(true);
        }}
        tooltip="Create a new company"
      />

      <Table
        ref={tableRef}
        filterable={false}
        filtered={rowFilters}
        defaultFilterMethod={({ id, value }, row) => {
          if (row._subRows && row._subRows.length > 0) {
            return true;
          }
          return row[id] !== null
            ? row[id].toUpperCase().indexOf(value.toUpperCase()) >= 0
            : false;
        }}
        className="companiestable"
        columns={columns}
        data={companies}
        defaultSorted={DEFAULT_SORT}
      />
    </>
  ) : (
    <Skeleton height={200} />
  );
};

export default CompaniesTable;
