import "./index.scss";

import { acceptTermsAndConditions } from "api/admin/adminApi";
import { Button, ModalBody, ModalFooter, ModalHeader } from "appkit-react";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminAcceptTermsAndConditions } from "redux/actions/global";

const TermsAndConditionsModal = ({ opened, onClose }) => {
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.global.data.loginUser);
  const [acceptingTermsAndConditions, setAcceptingTermsAndConditions] =
    useState(false);

  const handleClose = () => {
    if (
      !loginUser ||
      !loginUser.termsAndConditionsAccepted ||
      acceptingTermsAndConditions
    ) {
      return;
    }
    onClose();
  };

  const acceptTerms = () => {
    setAcceptingTermsAndConditions(true);
    acceptTermsAndConditions()
      .then(() => {
        dispatch(adminAcceptTermsAndConditions());
      })
      .finally(() => {
        setAcceptingTermsAndConditions(false);
      });
  };

  const refuseTerms = () => {
    window.location.replace("https://www.google.com/");
  };

  return (
    <ResizableAppkitModal
      visible={opened}
      className="terms-and-conditions-modal"
      onCancel={handleClose}
      width={1400}
    >
      <ModalHeader>
        <div className="title">Terms and Conditions</div>
      </ModalHeader>
      <ModalBody>
        <div className="terms-of-use__body__container">
          <p>
            This statement describes terms and conditions of use (the{" "}
            <strong>"Terms of Use"</strong> or the <strong>"Statement"</strong>)
            under which PricewaterhouseCoopers, Société coopérative (
            <strong>"PwC"</strong> or <strong>"We"</strong> or{" "}
            <strong>"Us"</strong>), a Luxembourg company whose principal place
            of business is at 2, rue Gerhart Mercator, L-2182 Luxembourg, allows
            users (<strong>"You"</strong> or the <strong>"User(s)"</strong>), be
            they individuals and/or the business entities they represent (the{" "}
            <strong>"Company(ies)"</strong>), to access and use online the
            Managed Services Platform (the{" "}
            <strong>"Managed Services Hub Platform"</strong>) as well as its
            related services (the <strong>"Services"</strong>). By accessing and
            using the Managed Services Hub Platform and its related AM Digital
            Solutions Platform Services, You manifest your express consent to
            the acceptance of the Terms of Use set out below, including all
            notices displayed on the Asset Management Digital Solutions
            Platform.
          </p>

          <h1>1. ACCESS</h1>

          <h2>1.1. Eligibility</h2>

          <p>
            The Managed Services Hub Platform aims at supporting collaboration
            and exchange of information and documents (the "Information")
            between Users and may only be used in the framework of (i) the
            project supported by the Platform and (ii) of a contract (the
            "Contract") concluded between the Company(ies) and PwC.
          </p>
          <p>
            In application of the Contract, PwC (or the Company or one of the
            Companies (as designated in the Contract) acting as the AM Digital
            Solutions Platform Administrator, if any – the "Client
            Administrator") attributes a password and a username (the
            "Identifications") to each person designated as authorized User by
            the Company(ies). As such, You have a limited, non-exclusive and
            non-transferable authorization to access and navigate the AM Digital
            Solutions Platform and its related Asset Management Digital
            Solutions Platform Services for strictly professional purposes in
            accordance with the tasks entrusted to You by the Company(ies). Any
            non-authorized person having accessed, navigated or used the Asset
            Management Digital Solutions Platform and/or its related Asset
            Management Digital Solutions Platform Services is subject to the
            same obligations and responsibilities listed in this Statement as
            that of an authorized User.
          </p>
          <p>
            For purposes of maintenance and efficiency access, PwC may modify
            the Identifications from time to time.
          </p>
          <h2>1.2. Your Identification and Security</h2>
          <p>
            The Identifications provided to You are strictly personal and
            non-transferable. You are fully responsible for maintaining the
            confidentiality of your Identification as well as all utilization
            thereof. You commit to inform PwC, the Client Administrator if any,
            and the Company(ies), of any suspected or actual non-authorized
            utilization of your Identifications or of any other suspected or
            actual security threat to the Managed Services Hub Platform and/or
            its related Managed Services Hub Platform Services.
          </p>
          <p>
            PwC cannot be held liable for any damage that may occur due to the
            utilization of your Identification by a third party, whether You
            knew of this utilization or not. However, You may be held liable if
            PwC or a third party incurs damage due to the utilization of your
            Identification by a third party.
          </p>
          <h2>1.3. Supply of the Managed Services Hub Platform</h2>
          <p>
            The Managed Services Hub Platform is expected to be available
            24*7*365, on a reasonable-efforts basis.
          </p>
          <p>
            However, PwC cannot guarantee (i) continuous or uninterrupted access
            to the Managed Services Hub Platform and its related AM Digital
            Solutions Platform Services or the reliability, availability,
            performance and other aspects of the Internet, nor (ii) that the
            Information will not be captured or monitored by one or more third
            parties.
          </p>
          <p>
            PwC shall provide service support as regard the Asset Management
            Digital Solutions Platform during Luxembourg working hours from
            08:30 CET to 18:00 CET only. Additionally, the Asset Management
            Digital Solutions Platform might be temporarily unavailable for
            maintenance purposes. For example, in that case, if possible, a
            message stating the estimated time of its unavailability will be
            posted on the Managed Services Hub Platform homepage in advance
            where possible.
          </p>
          <p>
            PwC will use standard, commercially-available virus-checking
            software in relation to any Information accessible using the AM
            Digital Solutions Platform in order to prevent such unauthorized
            access. However, and unless otherwise provided in the Contract, PwC
            does not represent or warrant that the Asset Management Digital
            Solutions Platform or any Information will be error-free, free from
            viruses or other harmful components. You warrant that any
            Information that You supply, post or otherwise make available via
            the AM Digital Solutions Platform, does not contain any viruses or
            other computer programming routines which may damage or interfere
            with the operations of the Managed Services Hub Platform.
          </p>
          <p>
            PwC’s responsibility with regard to the transmission and reception
            of data is limited to the interface between the server of PwC and
            the Users’ network.{" "}
          </p>
          <p>
            In no event PwC can be held liable for damages resulting from
            interferences, interruptions or limitation of the functioning of the
            Managed Services Hub Platform and its related Asset Management
            Digital Solutions Platform Services for technical reasons nor for
            any costs incurred by You in securing, obtaining or maintaining an
            appropriate internet connection.
          </p>
          <h2>1.4. Storage</h2>

          <p>
            The Information posted by You on the Asset Management Digital
            Solutions Platform will be stored on a server located in PwC
            premises and maintained under reasonably appropriate technical and
            organizational security measures. PwC cannot be held liable for any
            kind of damages in case of unforeseeable events as long as such
            security standards have been respected. In this context, You must
            ensure that You stored all Information appropriately and maintain
            appropriate back-up.
          </p>

          <h1>2. CONTENT</h1>
          <p>
            The Information, including all material, data, documents and files
            contained in, and/or provided through, the Asset Management Digital
            Solutions Platform and its related Asset Management Digital
            Solutions Platform Services, is for general guidance on matters of
            interest only, unless otherwise provided in the Contract.
          </p>
          <p>
            The Managed Services Hub Platform aims at supporting collaboration
            and exchange of Information between the Users only and not at giving
            any legal value of any Information exchanged, posted or downloaded
            from the Managed Services Hub Platform. Therefore, You acknowledge
            that all Information, which is necessarily dematerialized, cannot be
            considered as original.{" "}
          </p>
          <p>
            Given the changing nature of the applicable laws, rules and
            regulations, the Managed Services Hub Platform, its related AM
            Digital Solutions Platform Services and/or the Information are
            therefore provided "as is", and, to the extent permitted by
            applicable law, PwC, any other entity linked to PwC, and their
            respective partners, agents, representatives or employees, or
            licensors, distributors or suppliers (the{" "}
            <strong>"PwC Parties"</strong>), disclaim all guarantees of
            representation, reliability, timeliness or of the results obtained
            from the use of, the Managed Services Hub Platform, its related AM
            Digital Solutions Platform Services and/or the Information, as well
            as all warranties of any kind, express, implied, or statutory,
            including, without limitation, any implied warranties of
            merchantability, fitness for a particular purpose, accuracy,
            completeness, non-infringement, non-interference, error-free and
            uninterrupted service.
          </p>
          <p>
            The functionalities ‘request job’ and ‘chat’ of the AM Digital
            Solutions Platform are not designed for and shall not be used for
            answering and advising You (and/or the Company(ies)) on any
            technical and/or material matters. Any dialogue, answers and/or
            exchanges which may take place between PwC and You (and/or the
            Company(ies)) there trough, do not constitute, final opinions of PwC
            and You (and/or the Companies) shall neither act nor refrain from
            acting on the basis of such exchanges until they have been
            officially confirmed and issued by PwC in their final form as
            deliverables to the Company(ies).
          </p>
          <p>
            PwC declines all responsibility and You recognize that, in no
            circumstances, can the PwC Parties be held liable towards You or any
            other person for:
          </p>
          <ul>
            <li>
              the Information or any other information omitted in the AM Digital
              Solutions Platform, unless otherwise provided in the Contract;
            </li>
            <li>
              the consequences of all decisions, taken or not, by Yourself or
              any other person, with regards to the Information provided by/on
              the Managed Services Hub Platform or resulting from the use of the
              Managed Services Hub Platform or its related Managed Services Hub
              Platform Services;
            </li>
            <li>
              any kind of damages (notably the damages resulting from the loss
              of benefits, interruption of activity, loss of data or any other
              kind of financial loss) resulting from the use or impossibility of
              use of the Information, the Managed Services Hub Platform or its
              related Managed Services Hub Platform Services, even if PwC was
              previously informed of the possibility of such damages.
            </li>
          </ul>
          <p>
            You agree to reimburse the PwC Parties for all damages, settlements,
            losses and expenses (including legal fees) relating to all actions,
            proceedings and claims brought or threatened by a third party in
            connection with Your access and use of the Information, the AM
            Digital Solutions Platform and/or its related Asset Management
            Digital Solutions Platform Services.
          </p>
          <h1>3. YOUR OBLIGATIONS</h1>
          <p>
            You can only access, navigate or use the Asset Management Digital
            Solutions Platform and its related Asset Management Digital
            Solutions Platform Services on the condition that You guarantee that
            You will not use it to conduct any illicit activity, which is
            prohibited by or would violate any applicable law or these Terms of
            Use. You may not use the AM Digital Solutions Platform or its
            related Managed Services Hub Platform Services in any way that may
            harm, hinder, impair or overload the performance of the Managed
            Services Hub Platform and/or its related Asset Management Digital
            Solutions Platform Services.
          </p>
          <p>You are not authorized to: </p>
          <ol type="i">
            <li>
              unless as expressly foreseen in the Contract, modify, copy,
              distribute, transfer, diffuse, represent, reproduce, create
              derivative obligations, transfer or sell in whole or in part,
              directly or indirectly, free or at a cost, to non-authorized third
              parties, by physical or electronic means, any Information or
              service obtained from the Managed Services Hub Platform and/or its
              related Managed Services Hub Platform Services; nor
            </li>
            <li>
              manipulate or use the Managed Services Hub Platform or its related
              Managed Services Hub Platform Services to create products or
              services which are likely to compete with or to hinder, directly
              or indirectly the Asset Management Digital Solutions Platform or
              AM Digital Solutions Platform Services delivered or to be
              delivered by PwC.
            </li>
          </ol>
          <p>
            Before making all necessary and sufficient Information available,
            You shall verify that it is accurate and comprehensive.{" "}
          </p>
          <p>
            You commit to use the Managed Services Hub Platform, its related AM
            Digital Solutions Platform Services and the Information for the
            internal use of the Company(ies) only and in order to attain the
            objectives that are defined in the Contract only.
          </p>
          <p>
            The abusive use of the Managed Services Hub Platform and its related
            Managed Services Hub Platform Services may expose You to the
            suspension and/or termination of Your access to the AM Digital
            Solutions Platform and its related Services (as well as the access
            of the Company(ies)), and may expose You (as well as the
            Company(ies)) to legal actions.
          </p>
          <p>
            Your obligations resulting from this Statement towards the AM
            Digital Solutions Platform, its related Asset Management Digital
            Solutions Platform Services and the Information, do not cease at the
            end of your utilization or access of the Asset Management Digital
            Solutions Platform and of its related Asset Management Digital
            Solutions Platform Services.
          </p>
          <h1>4. SUSPENSION/TERMINATION OF ACCESS</h1>
          <p>
            In case of termination, for any reason, of Your relationship with
            the Company(ies), You shall immediately inform PwC (or the Client
            Administrator if any), of the effective date of such termination and
            cease using Your Identifications up from this date.
          </p>
          <p>
            PwC reserves the right to control at any moment and without any
            notice the use that You make of this Asset Management Digital
            Solutions Platform and its related Services. If PwC suspects an
            abuse concerning Your use of the Managed Services Hub Platform or
            any use contrary to the terms and conditions of the present
            Statement, your right of access will automatically be suspended
            without any notice.{" "}
          </p>
          <p>
            PwC may suspend Your access to the Asset Management Digital
            Solutions Platform and to the utilization of the related Asset
            Management Digital Solutions Platform Services without any notice:
          </p>
          <ul>
            <li>
              in order to repair, maintain or improve the Asset Management
              Digital Solutions Platform and its related Asset Management
              Digital Solutions Platform Services;
            </li>
            <li>
              on the written or verbal demand of the Company(ies) (or the Client
              Administrator if any);
            </li>
            <li>
              in order to comply with an order, an instruction or demand from a
              tribunal, government or other competent administrative, legal or
              statutory authority.
            </li>
          </ul>
          <h1>5. INTELLECTUAL PROPERTY</h1>
          <p>
            The Managed Services Hub Platform, the Information and all the
            elements constituting the Managed Services Hub Platform and its
            related Managed Services Hub Platform Services (for example and
            without limitation: general structure, database and software, texts,
            animated or non-animated images, titles, documentation, arrangement
            and form of the presentation) are the exclusive property of PwC and
            are protected by copyrights. Any program, publication, design,
            product, process, software, technology, information, know-how, or
            idea described in, or related to, the Managed Services Hub Platform
            and its related Managed Services Hub Platform Services, may be
            subject to other rights, including other intellectual property
            rights, which are owned by PwC or other interested parties and are
            not licensed in any way to you hereunder.
          </p>
          <p>
            The present Statement hereby grants You only a professional and
            individual permission to use the Managed Services Hub Platform and
            its related Managed Services Hub Platform Services. Any interference
            with the stipulation of this clause such as notably the
            reproduction, diffusion of any kind of process, commercial or
            otherwise, free or at a cost, constitutes a counterfeiting
            punishable according to the applicable law.
          </p>
          <p>
            All rights not expressly conceded in the present Statement are
            reserved.
          </p>
          <h1>6. DATA PROTECTION</h1>
          <p>
            Information about how PwC collects, processes, protects and disposes
            of Users’ personal data and how data subjects can exercise their
            rights is available on PwC’s dedicated page:
            www.pwc.lu/privacy-statement.
          </p>
          <p>
            Should you supply PwC with personal data of a third party, PwC
            assumes, and by providing the personal data to us, You confirm,
            that:
          </p>
          <p>
            - the personal data You provide to PwC are accurate, adequate,
            relevant and limited to what is necessary for the specific purpose
            for which they are disclosed ;
          </p>
          <p>
            - You comply with all applicable laws (including the EU General Data
            Protection Regulation) in connection with PwC’s processing of the
            personal data (including the lawfulness of the data provision and,
            where applicable, collecting and managing the data subject’s consent
            accordingly);
          </p>
          <p>
            - the data subjects are informed of the conditions and modalities of
            the processing of their personal data in the form required by all
            applicable laws (including the EU General Data Protection
            Regulation), in particular about the processing of their personal
            data by PwC as described above; and
          </p>
          <p>
            - You will immediately inform PwC if any of the conditions above
            ceases to be met.{" "}
          </p>
          <h1>7. MISCELLANEOUS </h1>
          <p>
            All complaints regarding the application, lack or violation of the
            obligations or the rights relating to the present Terms of Use will
            be subject exclusively to Luxembourg Law. By accepting this
            Statement, You agree irrevocably that any litigation caused by or
            linked to the access, the navigation, the connection or all other
            use of the Managed Services Hub Platform or its related AM Digital
            Solutions Platform Services will come under the exclusive competency
            of the courts of Luxembourg-Ville.
          </p>
          <p>
            Notwithstanding the above provisions dismissing any liability of PwC
            toward the User(s) and third parties to the extent permitted by
            applicable law, all actions brought against PwC and/or its employees
            shall be statute-barred after i) a period of one (1) year from the
            date of access or use of the Managed Services Hub Platform or its
            related Managed Services Hub Platform Services linked to the damage,
            or ii) after a period of one (1) year from the date on which You
            should reasonably have become aware of the damage, whichever comes
            first.
          </p>
          <p>
            PwC reserves the right to modify these Terms of Use at any time.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        {loginUser?.termsAndConditionsAccepted && (
          <>
            <div className="text">
              You have already accepted the terms and conditions
            </div>
            <Button kind="transparent" onClick={handleClose}>
              Close
            </Button>
          </>
        )}
        {!loginUser?.termsAndConditionsAccepted && (
          <>
            <div className="text">
              You must read and accept the terms and conditions before using
              this application
            </div>
            <Button
              kind="transparent"
              onClick={refuseTerms}
              disabled={acceptingTermsAndConditions}
            >
              Refuse
            </Button>
            <Button
              onClick={acceptTerms}
              isLoading={acceptingTermsAndConditions}
            >
              Accept
            </Button>
          </>
        )}
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default TermsAndConditionsModal;
