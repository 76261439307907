import { Switch } from "appkit-react";
import Table from "components/Table";
import Actions from "components/Table/actions";
import { NO_USER_ROW } from "pages/admin/utils";
import { useMemo } from "react";

interface User {
  ppid: number;
  name: string;
  isMainContact: boolean;
}

interface Props {
  data: User[];
  parentId: string;
  onDeleteUser:
    | (({ ppid, parentId }: { ppid: number; parentId: string }) => void)
    | null;
  onMainContactChanged: (
    subscriptionId: string,
    userPpid: string,
    isMainContact: boolean
  ) => void;
}

export const COLUMNS = [
  {
    Cell: () => null,
    width: 35,
  },
  {
    Header: "Member",
    id: "name",
    accessor: "name",
    filterable: true,
    Filter: () => null,
  },
  {
    Header: "",
    Cell: () => null,
  },
  {
    Header: "",
    Cell: () => null,
  },
];

const DEFAULT_SORT = [{ id: "name", desc: false }];

const UserEngagementTable = ({
  data,
  parentId,
  onDeleteUser,
  onMainContactChanged,
}: Props) => {
  const columns = useMemo(() => {
    return [
      ...COLUMNS,
      ...(data?.length && onMainContactChanged
        ? [
            {
              Header: "Is main contact ?",
              id: "actions",
              sortable: false,
              filterable: false,
              width: 120,
              Cell: ({ original }: { original: User }) => {
                return (
                  <Switch
                    checked={original.isMainContact}
                    onText="On"
                    offText="Off"
                    onChange={(value: boolean) => {
                      onMainContactChanged(
                        parentId,
                        original.ppid.toString(),
                        value
                      );
                    }}
                  />
                );
              },
            },
          ]
        : []),
      {
        Header: "",
        Cell: ({ original }: { original: User }) => (
          <Actions
            handleDelete={
              onDeleteUser && original.ppid !== -1
                ? () =>
                    onDeleteUser({
                      ppid: original.ppid,
                      parentId,
                    })
                : null
            }
          />
        ),
        id: "actions",
        sortable: false,
        filterable: false,
        width: 80,
      },
    ];
  }, [parentId, onDeleteUser, onMainContactChanged, data]);

  const displayedData = useMemo(() => {
    return data && data.length > 0 ? data : [{ ...NO_USER_ROW }];
  }, [data]);

  return (
    <Table
      // @ts-ignore
      className="engagement-user-table"
      columns={columns}
      data={displayedData}
      defaultSorted={DEFAULT_SORT}
      showPagination={false}
      pageSize={displayedData.length}
      getTheadTrProps={() => ({
        className: "-padRow",
      })}
    />
  );
};

export default UserEngagementTable;
