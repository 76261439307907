import "./index.scss";

import { getPhoto, getUsers, getUsersFromIdArray } from "api/admin/adminApi";
import classNames from "classnames";
import AsyncSelect from "components/AsyncSelect";
import { useCallback, useState } from "react";

const DEFAULT_FILTERS = {};

export const EXTERNAL_ONLY_FILTERS = {
  isInternal: false,
};

export const INTERNAL_ONLY_FILTERS = {
  isInternal: true,
};

const UserSelect = ({
  className = undefined,
  filters = DEFAULT_FILTERS,
  alreadyAdded = null,
  ...selectProps
}) => {
  const [contactImages, setContactImages] = useState({});

  const loadUsers = useCallback(
    async (pageNumber, email) => {
      const items = await getUsers(
        {
          filters: { email, ...filters },
          orderBy: {
            email: {
              isDescending: false,
            },
          },
        },
        { pageNumber, pageSize: 20 }
      );

      const users = { items };

      if (alreadyAdded) {
        const alreadyAddedByPpid = alreadyAdded.reduce((acc, user) => {
          acc[user.ppid] = user;
          return acc;
        }, {});

        users.items = users.items.filter(
          (user) => !alreadyAddedByPpid[user.ppid]
        );
      }

      users.items.forEach((user) => {
        if (user.photoUri) {
          getPhoto(user.photoUri).then((photo) =>
            setContactImages((contactImages) => {
              return {
                ...contactImages,
                [user.photoUri]: photo,
              };
            })
          );
        }
      });

      return users.items;
    },
    [filters, alreadyAdded]
  );
  return (
    <AsyncSelect
      {...selectProps}
      clearable
      className={classNames("contact-select", className)}
      loadItems={loadUsers}
      isEqual={(a, b) => a.ppid === b.ppid}
      getTooltip={
        selectProps.multiple
          ? () => getUsersFromIdArray(selectProps.value.map((x) => x.id))
          : undefined
      }
      render={(contact) => (
        <>
          <div
            className="select-item-image"
            style={{
              backgroundImage: contactImages[contact.photoUri]
                ? `url(${contactImages[contact.photoUri]})`
                : "none",
            }}
          />
          {contact.email ?? contact.name}
        </>
      )}
    />
  );
};

export default UserSelect;
