const delays = [100, 150, 200, 300, 400, 500, 600, 750];
const delayTotal = Math.floor(
  delays.reduce((acc, delay) => acc + delay, 0) / 1000
);

export const getDelay = (attemptNumber) => {
  return delays[attemptNumber - 1];
};

export const pollData = (
  pollingPromise,
  checkShouldPoll,
  attemptNumber = 0,
  timeoutResult
) => {
  return new Promise((resolve, reject) => {
    pollingPromise()
      .then(resolve)
      .catch((error) => {
        if (checkShouldPoll(error)) {
          attemptNumber++;
          if (attemptNumber > delays.length) {
            process.env.REACT_APP_BUILD_ENV !== "prod" &&
              console.warn(
                `API Poller Timeout after ${delayTotal} seconds. Resolving with timeoutResult`,
                timeoutResult
              );
            return resolve(timeoutResult); // resolve promise with default data if timeout has been reached
          }
          setTimeout(() => {
            pollData(
              pollingPromise,
              checkShouldPoll,
              attemptNumber,
              timeoutResult
            ).then(resolve);
          }, getDelay(attemptNumber));
        } else {
          reject(error);
        }
      });
  });
};

export const pollerCheck404 = (error) => error.status === 404;
export const pollerCheck204 = (error) => error.status === 204;
export const pollerCheck4XX = (error) => Math.floor(error.status / 100) === 4;
export const pollerCheck2XX = (error) => Math.floor(error.status / 100) === 2;
export const pollerCheckUndefined = (error) => error === undefined;
