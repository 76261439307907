import {
  SubscriptionCreateDTO,
  SubscriptionFilters,
  SubscriptionTrialFilter,
} from "api/admin/__generated__/UserManagementAPI";
import { getSubscriptionsUsersPaged } from "api/admin/adminApi";
import {
  pollAddEngagement,
  pollAddUserToEngagement,
  pollDeleteEngagement,
  pollDeleteUserFromEngagement,
  pollUpdateEngagement,
  pollUpdateUserEngagement,
} from "api/admin/adminPoller";
import { SubscriptionUpdate } from "api/admin/model/Subscription";
import useData from "hooks/useData";
import { useCallback, useMemo } from "react";

import { SubscriptionForm, toSubscriptionCreateDTO } from "../types/form";

export interface UseSubscriptions {
  data: any; // FIXME JGE
  upsertSubscription: (form: SubscriptionForm) => Promise<void>;
  deleteSubscription: (SubscriptionId: string) => Promise<void>;
  addUserToSubscription: (
    subscriptionId: string,
    ppid: string[]
  ) => Promise<any[]>;
  updateUserSubscription: (
    subscriptionId: string,
    userPpid: string,
    isMainContact: boolean
  ) => Promise<any>;
  removeUserFromSubscription: (
    subscriptionId: string,
    ppid: string
  ) => Promise<void>;
}

interface Filter {
  id: string;
  value: any;
}

export const DEFAULT_SORT = [{ id: "service", desc: false }];

// test-only-export
export function toSubscriptionApiFilter(
  filters: Filter[]
): SubscriptionFilters {
  return filters.reduce((acc, filter) => {
    const value =
      typeof filter.value === "string" ? filter.value.trim() : filter.value;

    switch (filter.id) {
      case "service":
        acc.serviceName = value;
        break;
      case "company":
        acc.companyName = value;
        break;
      case "entity":
        acc.entityName = value;
        break;
      case "name":
        acc.memberName = value;
        break;
      case "myServices":
        acc.my = value;
        break;
      case "trial":
        acc.trial = value;
        break;
      default:
        break;
    }

    return acc;
  }, {} as SubscriptionFilters);
}

interface InputFilters {
  rowFilters: Filter[];
  myServices?: boolean;
  trial: SubscriptionTrialFilter;
}

export default function useSubscriptions(
  filters: InputFilters
): UseSubscriptions {
  const apiFilters = useMemo(() => {
    const filtersArray = [...filters.rowFilters];

    filtersArray.push({ id: "trial", value: filters.trial });

    if (!!filters.myServices) {
      filtersArray.push({ id: "myServices", value: true });
    }

    return toSubscriptionApiFilter(filtersArray);
  }, [filters]);
  const data = useData(
    apiFilters,
    getSubscriptionsUsersPaged,
    DEFAULT_SORT,
    true
  );
  const fetchData = data.fetchData;

  const upsertSubscription = useCallback(
    (form: SubscriptionForm) => {
      let promise;

      if (form.id) {
        const subscription: SubscriptionUpdate = {
          ...toSubscriptionCreateDTO(form),
          subscriptionId: form.id,
          contacts: form.contacts.map((contact) => contact.ppid!),
        };

        promise = pollUpdateEngagement(subscription);
      } else {
        const subscription: SubscriptionCreateDTO =
          toSubscriptionCreateDTO(form);

        promise = pollAddEngagement(subscription);
      }

      return promise.then(fetchData);
    },
    [fetchData]
  );

  const deleteSubscription = useCallback(
    (subscriptionId: string) => {
      return pollDeleteEngagement(subscriptionId).then(fetchData);
    },
    [fetchData]
  );

  const addUserToSubscription = useCallback(
    (subscriptionId: string, ppids: string[]) => {
      return pollAddUserToEngagement(subscriptionId, ppids).then(fetchData);
    },
    [fetchData]
  );

  const updateUserSubscription = useCallback(
    (subscriptionId: string, userPpid: string, isMainContact: boolean) => {
      return pollUpdateUserEngagement(
        subscriptionId,
        userPpid,
        isMainContact
      ).then(fetchData);
    },
    [fetchData]
  );

  const removeUserFromSubscription = useCallback(
    (subscriptionId: string, ppid: string) => {
      return pollDeleteUserFromEngagement(subscriptionId, ppid).then(fetchData);
    },
    [fetchData]
  );

  return {
    data,
    upsertSubscription,
    updateUserSubscription,
    deleteSubscription,
    addUserToSubscription,
    removeUserFromSubscription,
  };
}
