import { useMemo } from "react";

import {
  MIME_DOC,
  MIME_DOCX,
  MIME_JPEG,
  MIME_PDF,
  MIME_PNG,
  MIME_PPT,
  MIME_PPTX,
  MIME_XLS,
  MIME_XLSX,
} from "../../../../constants";

const FileIcon = ({ mimeType, type = "outline" }) => {
  const icon = useMemo(() => {
    switch (mimeType) {
      case MIME_PDF:
        return "pdf";
      case MIME_DOC:
      case MIME_DOCX:
        return "doc";
      case MIME_XLS:
      case MIME_XLSX:
        return "xls";
      case MIME_PPT:
      case MIME_PPTX:
        return "ppt";
      case MIME_PNG:
      case MIME_JPEG:
        return "image";
      default:
        return "file";
    }
  }, [mimeType]);

  return <span className={`appkiticon file-icon icon-${icon}-${type}`} />;
};

export default FileIcon;
