import { useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  articlesInitNewsAdmin,
  articlesSetPermissions,
} from "redux/actions/articles";
import { INITIALIZE } from "redux/constants";

const useNewsAdminInitialize = (triggerInit = true) => {
  const dispatch = useDispatch();
  const newsAdminInitialized = useSelector(
    (state) => state.articles.data.newsAdminInitialized
  );
  const store = useStore();

  useEffect(() => {
    if (
      store.getState().articles.data.newsAdminInitialized ===
        INITIALIZE.FALSE &&
      triggerInit
    ) {
      dispatch(articlesInitNewsAdmin());
      dispatch(articlesSetPermissions());
    }
  }, [store, dispatch, triggerInit]);

  return newsAdminInitialized === INITIALIZE.TRUE;
};

export default useNewsAdminInitialize;
