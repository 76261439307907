import "./index.scss";

import SwitchWithLoader from "components/SwitchWithLoader";
import Table from "components/Table";
import Actions from "components/Table/actions";
import useMainTableStickyHeader from "pages/admin/hooks/useMainTableStickyHeader";
import { useContext, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";

import { ServiceItem } from "../../type";
import { AdminServiceContext } from "../AdminServiceContextHOC";
import ServiceTableBadge from "./ServiceTableBadge";

const DEFAULT_SORT = [{ id: "name", desc: false }];

interface Props {
  filters: any;
  onEditService: (service: ServiceItem) => void;
  onDeleteService: (service: ServiceItem) => void;
}

interface Submitting {
  [serviceId: string]: boolean;
}
const emptyFilter = () => null;

export const COLUMNS = [
  {
    Header: "Service Name",
    id: "service",
    filterable: true,
    Filter: emptyFilter,
  },
  {
    Header: "Domains",
    id: "domains",
    filterable: true,
    Filter: emptyFilter,
  },
];

export default function ServicesTable({
  filters,
  onEditService,
  onDeleteService,
}: Props) {
  const {
    items: services,
    loading,
    patchDisplayed,
    patchIsNew,
    patchIsHighlighted,
  } = useContext(AdminServiceContext);

  const tenants = useSelector((state) => (state as any).global.data.tenants);

  const { rowFilters = [] } = filters;

  var { isSuperUser } = useSelector(
    (state: any) => state.global.data.loginUser
  );

  const [submitting, setSubmitting] = useState<Submitting>({});

  const tableRef = useRef();
  useMainTableStickyHeader(loading, tableRef, 310);

  const columns = useMemo(() => {
    const handleDisplayedChange = (
      service: ServiceItem,
      displayed: boolean
    ) => {
      setSubmitting((submitting) => {
        return {
          ...submitting,
          [service.id]: true,
        };
      });
      patchDisplayed(service, displayed).then(() => {
        setSubmitting((submitting) => {
          return {
            ...submitting,
            [service.id]: false,
          };
        });
      });
    };

    const handleIsNewChange = (service: ServiceItem, isNew: boolean) => {
      setSubmitting((submitting) => {
        return {
          ...submitting,
          [service.id]: true,
        };
      });
      patchIsNew(service, isNew).then(() => {
        setSubmitting((submitting) => {
          return {
            ...submitting,
            [service.id]: false,
          };
        });
      });
    };

    const handleIsHighlightedChange = (
      service: ServiceItem,
      isHighlighted: boolean
    ) => {
      setSubmitting((submitting) => {
        return {
          ...submitting,
          [service.id]: true,
        };
      });
      patchIsHighlighted(service, isHighlighted).then(() => {
        setSubmitting((submitting) => {
          return {
            ...submitting,
            [service.id]: false,
          };
        });
      });
    };

    let cols = [
      {
        Header: "Service Name",
        id: "service",
        accessor: "name",
        filterable: true,
        Filter: emptyFilter,
        Cell: ({ original }: { original: ServiceItem }) => {
          return (
            <span className="service-item-line">
              <span className="service-item-name">{original.name}</span>{" "}
              {original.domains.map((domain) => (
                <ServiceTableBadge key={domain} domain={domain} />
              ))}
            </span>
          );
        },
      },
      {
        Header: "",
        id: "domains",
        accessor: "domains",
        filterable: true,
        Filter: emptyFilter,
        filterMethod: (
          filterQuery: { id: string; Header: string; value: string },
          original: ServiceItem
        ) => {
          const originalDomainNames = original.domains.map(
            (domain) =>
              tenants.find(
                (tenant: any) =>
                  tenant.domain.toUpperCase() === domain.toUpperCase()
              )?.name
          );
          return originalDomainNames.some((domain: any) => {
            return domain
              ?.toUpperCase()
              .includes(filterQuery.value.toUpperCase());
          });
        },
        Cell: () => null,
        width: 0,
        className: "hidden",
      },
      {
        Header: "Is New",
        id: "isNew",
        accessor: "isNew",
        Cell: ({ original }: { original: ServiceItem }) => {
          return (
            <SwitchWithLoader
              onText="Yes"
              offText="No"
              loading={!!submitting[original.id]}
              checked={original.isNew}
              onChange={(isNew: boolean) => {
                handleIsNewChange(original, isNew);
              }}
            />
          );
        },
        filterable: true,
        Filter: emptyFilter,
        width: 90,
      },
      {
        Header: "Is Highlighted",
        id: "isHighlighted",
        accessor: "isHighlighted",
        Cell: ({ original }: { original: ServiceItem }) => {
          return (
            <SwitchWithLoader
              onText="Yes"
              offText="No"
              loading={!!submitting[original.id]}
              checked={original.isHighlighted}
              onChange={(isHighlighted: boolean) => {
                handleIsHighlightedChange(original, isHighlighted);
              }}
            />
          );
        },
        filterable: true,
        Filter: emptyFilter,
        width: 110,
      },
      {
        Header: "Visible",
        id: "displayed",
        accessor: "displayed",
        Cell: ({ original }: { original: ServiceItem }) => {
          return (
            <SwitchWithLoader
              onText="Yes"
              offText="No"
              loading={!!submitting[original.id]}
              checked={original.live}
              onChange={(displayed: boolean) => {
                handleDisplayedChange(original, displayed);
              }}
            />
          );
        },
        filterable: true,
        Filter: emptyFilter,
        width: 90,
      },
      {
        Header: "Actions",
        Cell: ({ original }: { original: ServiceItem }) => (
          <>
            <Actions
              handleEdit={
                original?.canAddSubscription && (() => onEditService(original))
              }
              handleDelete={isSuperUser && (() => onDeleteService(original))}
            />
          </>
        ),
        id: "actions",
        sortable: false,
        filterable: false,
        width: 90,
      },
    ];

    return cols;
  }, [
    isSuperUser,
    patchDisplayed,
    submitting,
    onEditService,
    onDeleteService,
    patchIsHighlighted,
    patchIsNew,
    tenants,
  ]);

  return !loading && !!services ? (
    <Table
      ref={tableRef}
      // @ts-ignore
      filtered={rowFilters}
      className="servicestable"
      columns={columns}
      data={services}
      defaultFilterMethod={({ id, value }: any, row: any) =>
        row[id]?.toUpperCase().indexOf(value.toUpperCase()) >= 0
      }
      defaultSorted={DEFAULT_SORT}
    />
  ) : (
    <Skeleton height={200} />
  );
}
