import { apiFetch } from "../api";

const PREFIX = process.env.REACT_APP_URL_NEWS_ROOT + "DocumentType/";

export const getDocumentTypes = () => {
  return apiFetch(`${PREFIX}`);
};

export const getDocumentType = (documentTypeId) => {
  return apiFetch(`${PREFIX}${documentTypeId}`);
};

export const createDocumentType = (documentType) => {
  return apiFetch(`${PREFIX}`, documentType, "POST");
};

export const updateDocumentType = (documentType) => {
  return apiFetch(`${PREFIX}${documentType.id}`, documentType, "PUT");
};

export const deleteDocumentType = (documentTypeId) => {
  return apiFetch(`${PREFIX}${documentTypeId}`, undefined, "DELETE");
};
