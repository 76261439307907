export const dataURItoBlob = (dataURI, dataTYPE) => {
  const binary = atob(dataURI.split(",")[1]);
  const array = [];

  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  const res = new Blob([new Uint8Array(array)], {
    type: dataTYPE,
  });

  return res;
};

export function toUTCISODateString(date) {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
  ).toISOString();
}
