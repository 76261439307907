import "./index.scss";

import { ApplicationReadDTO } from "api/catalog/__generated__/CatalogueAPI";
import { FormErrors } from "hooks/useSubmitable";
import useMultilineForm from "pages/admin/hooks/useMultilineForm";
import { Ref } from "react";

import ApplicationForm from "./AppForm";

export const DEFAULT_APPLICATION = {
  name: "",
  link: "",
  itContact: null,
  showToClient: true,
};

interface Props {
  applications: ApplicationReadDTO[];
  errors?: FormErrors<ApplicationReadDTO>;
  onApplicationsChange: (applications: ApplicationReadDTO[]) => void;
  clickOutsideRef: Ref<HTMLDivElement | null>;
}

export default function ApplicationsForm({
  applications,
  errors,
  onApplicationsChange,
  clickOutsideRef,
}: Props) {
  const { handleChange, handleDelete } = useMultilineForm(
    applications,
    onApplicationsChange,
    DEFAULT_APPLICATION
  );
  return (
    <>
      {applications.map((app, index) => (
        <ApplicationForm
          clickOutsideRef={clickOutsideRef}
          key={index}
          value={app}
          // @ts-ignore
          errors={errors ? errors[index] : null}
          onValueChange={(app) => handleChange(app, index)}
          onDelete={() => handleDelete(index)}
        />
      ))}

      <div className="row applications-label">
        <div className="col-9 a-form-label">
          (Leave blank to avoid adding a new application)
        </div>
        <div className="col-3 a-form-label">Display to the client ?</div>
      </div>
    </>
  );
}
