export function scrollTo(domNodeRef, params) {
  if (!domNodeRef.scrollTo) {
    console.warn("browser doesn't support scrollTo");
    return;
  }

  if (!("scrollBehavior" in document.documentElement.style)) {
    domNodeRef.scrollTo(params.left || 0, params.top || 0); // Partial support : no smooth scroll
    return;
  }

  domNodeRef.scrollTo(params);
}

export function scrollToBottom(domNodeRef, params = {}) {
  const scrollHeight = domNodeRef.scrollHeight;
  const height = domNodeRef.clientHeight;
  const top = scrollHeight - height;

  if (top > 0) {
    scrollTo(domNodeRef, {
      ...params,
      top,
    });
  }
}
