import { apiFetch } from "../api";

const PREFIX = process.env.REACT_APP_URL_NEWS_ROOT + "SubscriptionAccess/";

export const getThreeWPermissions = (idSubscription) => {
  return apiFetch(`${PREFIX}${idSubscription}`);
};

export const updateThreeWPermissions = (idSubscription, permissions) => {
  return apiFetch(`${PREFIX}${idSubscription}`, permissions, "PUT");
};

export const getAllSubscriptionsFromType = (type) => {
  return apiFetch(`${PREFIX}${type}`);
};
