import { IndustryReadDTO } from "api/catalog/__generated__/CatalogueAPI";
import { useIndustriesAll } from "api/catalog/queries/useIndustries";
import { SelectGroupTitle, SelectOption } from "appkit-react";
import Select from "components/Select";

interface Props {
  value: string[];
  onValueChange: (value: string[]) => void;
  placeholder?: string;
  hasError?: boolean;
  errMsg?: string | boolean | null;
  tenant: string;
}
export default function ServiceIndustryTagSelect({
  value,
  onValueChange,
  ...props
}: Props) {
  const { data: industriesAll = {} } = useIndustriesAll();
  const industries: IndustryReadDTO[] =
    industriesAll[props.tenant] ??
    industriesAll[`HTTP://${props.tenant}/`] ??
    [];
  return (
    <Select
      clearable
      value={value}
      onSelect={onValueChange}
      placeholder="Industry tags"
      multiple
      {...props}
    >
      {industries
        .filter((industry) => industry.subIndustries?.length)
        .flatMap((industry) => [
          <SelectGroupTitle>{industry.name}</SelectGroupTitle>,
          ...industry.subIndustries!.map((subIndustry) => (
            <SelectOption key={subIndustry.id} value={subIndustry.name}>
              {subIndustry.name}
            </SelectOption>
          )),
        ])}
      <SelectGroupTitle>Others</SelectGroupTitle>
      {industries
        .filter((industry) => !industry.subIndustries?.length)
        .map((industry) => (
          <SelectOption key={industry.id} value={industry.name}>
            {industry.name}
          </SelectOption>
        ))}
    </Select>
  );
}
