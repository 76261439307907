import { apiFetch } from "api/api";

const PREFIX = `${process.env.REACT_APP_URL_CATALOG}/api/v1/`;

export function getTenant() {
  return apiFetch(`${PREFIX}Tenant/current`).catch(() =>
    JSON.parse(`{
    "id": "719c8f26-848f-423a-8c87-9494d4ca283a",
    "name": "Managed Service Hub",
    "url": "https://amdigitalsolutions.pwc.lu/",
    "domain": "amdigitalsolutions.pwc.lu"
}`)
  );
}

export function adminGetTenants() {
  return apiFetch(`${PREFIX}Tenant`);
}
