import "./LoadingComponent.scss";

const LoadingComponent = () => {
  return (
    <div className="loading-component">
      <div className="a-loading a-primary a-m-10" />
      <div>Loading data...</div>
    </div>
  );
};

export default LoadingComponent;
