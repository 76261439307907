import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: [
      '"PwC Helvetica Neue"',
      '"Helvetica Neue"',
      '"sans-serif"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#D04A02",
    },
    secondary: {
      main: "#EB8C00",
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: "1rem",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          height: "2.25rem",
          width: "2.25rem",
          fontSize: "0.875rem",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: { marginBottom: "1rem" },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: "1rem",
          marginRight: "1rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0",
          boxShadow: "none",
          fontWeight: 600,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "#D04A02",
          fontFamily: ['"PwC ITC Charter"', '"ITC Charter"', "serif"].join(","),
          fontSize: "1.4rem",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          positionStart: {
            marginRight: "0",
            marginLeft: "0.8rem",
          },
        },
      },
    },
  },
});

const MuiTheme: React.FC<{}> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default MuiTheme;
