import {
  ApplicationReadDTO,
  ContactReadDTO,
} from "api/admin/__generated__/UserManagementAPI";
import { getUserSubscriptions } from "api/admin/adminApi";
import { getServiceByIds } from "api/catalog/catalogApi";
import {
  ECModules,
  ECSModules,
  toECModules,
  toECSModules,
} from "pages/admin/components/Engagement/types";
import { CatalogueService, toCatalogueService } from "pages/catalogue/types";
import { useEffect, useState } from "react";
import { byId } from "utils/byId";

export interface UserService {
  ec: string | null;
  ecs: string | null;
  clientContent: string | null;
  ecModules: ECModules | null;
  ecsModules: ECSModules | null;
  links: ApplicationReadDTO[];
  service: CatalogueService;
  contacts: ContactReadDTO[];
  clientContacts: ContactReadDTO[];
}

export type UserServices = UserService[] | null;

export default function useUserServices(): UserServices {
  const [userServices, setUserServices] = useState<UserServices>(null);

  useEffect(() => {
    getUserSubscriptions().then((subscriptions) => {
      getServiceByIds(subscriptions.map((s) => s.serviceId)).then(
        (services) => {
          const serviceById = byId(services.map(toCatalogueService));

          const userServices: UserService[] = subscriptions
            .filter((subscription) => serviceById[subscription.serviceId])
            .map((subscription) => ({
              ec: subscription.ec,
              ecs: subscription.ecs,
              clientContent: subscription.clientContent,
              ecModules: toECModules(subscription.ecModules),
              ecsModules: toECSModules(subscription.ecsModules),
              links: subscription.applications || [],
              service: {
                ...serviceById[subscription.serviceId],
                subscribed: true,
              },
              contacts: subscription.contacts ?? [],
              clientContacts: subscription.clientContacts ?? [],
            }));

          setUserServices(userServices);
        }
      );
    });
  }, []);

  return userServices;
}
