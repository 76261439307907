import "./index.scss";

import {
  downloadUserImportTemplate,
  importUsersFromExcel,
} from "api/admin/adminApi";
import FloatingActionButtonPortal from "components/FloatingActionButton";
import UsersTable from "pages/admin/components/User/UsersTable";
import UploadImport from "pages/newsAdmin/components/UploadImport";
import { useState } from "react";

import RefreshUsersModal from "./refreshUsersModal";

export default function UserAdmin(props) {
  const [openRefreshUsersModal, setOpenRefreshUsersModal] = useState(false);
  return (
    <div className="user-admin">
      <UploadImport
        onImport={(file) =>
          new Promise((resolve, reject) => {
            importUsersFromExcel(file);
            resolve(true);
          })
        }
        onDownloadTemplate={downloadUserImportTemplate}
        type="User"
      />
      <UsersTable filters={props.filters} />
      <RefreshUsersModal
        visible={openRefreshUsersModal}
        onCancel={() => setOpenRefreshUsersModal(false)}
      />
      <FloatingActionButtonPortal
        tooltip="Refresh All User Data"
        name="icon-refresh-fill"
        kind="secondary"
        className="refresh-users-fab"
        right={84}
        onClick={() => setOpenRefreshUsersModal(true)}
      />
    </div>
  );
}
