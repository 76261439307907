import "./index.scss";

import { Tooltip } from "@mui/material";
import { Button, Checkbox, List, ListItem } from "appkit-react";
import classNames from "classnames";
import SuspenseLoader from "components/SuspenseLoader";
import useClickOutside from "hooks/useClickOutside";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import InfiniteScroll from "react-infinite-scroller";

const DEBOUNCE_TIME = 500;

const DEFAULT_EQUALS = (a, b) => a === b;

const AsyncSelect = ({
  className,
  placeholder,
  loadItems,
  pageSize = 20,
  render,
  value,
  multiple,
  onChange,
  disabled = false,
  clickOutsideRef = null,
  isEqual = DEFAULT_EQUALS,
  hasError = false,
  errMsg = undefined,
  clearable = false,
  selectedLabel = "items selected",
  getTooltip = null,
}) => {
  const [open, setOpen] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [items, setItems] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [tooltipContent, setTooltipContent] = useState("");

  const inputRef = useRef();
  const scrollWrapper = useRef();

  const loadMore = (pageNumber) => {
    loadItems(pageNumber, textSearch).then((data) => {
      setHasMore(data.hasNext ?? data.length === pageSize);
      setItems([...items, ...(data.items ?? data)]);
    });
  };

  const reload = useCallback(
    (textSearch) => {
      setItems(null);

      loadItems(1, textSearch).then((data) => {
        setHasMore(data.hasNext ?? data.length === pageSize);
        setItems(data.items ?? data);
      });
    },
    [loadItems, pageSize]
  );

  const isEmptySelection = !value || (multiple && !value.length);

  const toggleText = () => {
    if (isEmptySelection) {
      return placeholder;
    }

    if (!multiple) {
      return render(value);
    }

    return value.length === 1
      ? render(value[0])
      : `${value.length} ${selectedLabel}`;
  };

  useEffect(() => {
    if (open) {
      const debouncer = setTimeout(() => {
        reload(textSearch);
      }, DEBOUNCE_TIME);
      return () => {
        window.clearInterval(debouncer);
      };
    }
  }, [open, textSearch, reload]);

  useEffect(() => {
    if (!open) {
      setTextSearch("");
      setItems(null);
    }
  }, [open, reload]);

  useLayoutEffect(() => {
    if (open && !!inputRef.current) {
      ReactDOM.findDOMNode(inputRef.current).focus();
    }
  }, [open]);

  const close = useCallback(() => setOpen(false), []);
  const selectRef = useClickOutside(open, close, clickOutsideRef);

  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  const handleSelect = (item) => {
    if (!multiple) {
      onChange(item);
      setOpen(false);
    } else {
      onChange(
        value.findIndex((v) => isEqual(item, v)) >= 0
          ? value.filter((v) => !isEqual(item, v))
          : [...value, item]
      );
    }
  };

  return (
    <div ref={selectRef} className="async-select a-select-wrapper">
      <div
        className={classNames(
          "a-dropdown a-dropdown-default",
          open && "open",
          disabled && "disabled",
          hasError && "errored",
          className
        )}
      >
        <div
          className="select-toggle"
          onClick={!disabled && (() => setOpen(true))}
        >
          {!open ? (
            <>
              <span className="select-toggle-icon appkiticon arrow-icon icon-down-chevron-fill apply-opacity-in-closed-toggle" />
              <div
                className={classNames(
                  "select-toggle-text-wrapper",
                  isEmptySelection &&
                    "select-toggle-place-holder apply-opacity-in-closed-toggle"
                )}
              >
                {getTooltip !== null ? (
                  <Tooltip
                    title={tooltipContent}
                    onOpen={() => {
                      setTooltipContent(
                        <div className="a-loading a-loading-16 a-primary"></div>
                      );
                      getTooltip()
                        .then((items) =>
                          items.map((item) => item.value).join(", ")
                        )
                        .then(setTooltipContent);
                    }}
                  >
                    <span>{toggleText()}</span>
                  </Tooltip>
                ) : (
                  <>{toggleText()}</>
                )}
              </div>

              {clearable && !isEmptySelection && (
                <Button
                  className="amds-select-clear-button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    onChange(multiple ? [] : null);
                  }}
                >
                  <span className="appkiticon icon-circle-delete-outline clear-button" />
                </Button>
              )}
            </>
          ) : (
            <input
              value={textSearch}
              onChange={(e) => setTextSearch(e.target.value)}
              ref={inputRef}
              className="select-toggle-input"
            />
          )}
        </div>
        <div
          className="a-select-list-out"
          style={{
            display: open ? "block" : "none",
            bottom: "auto",
            top: 34,
            marginTop: 10,
          }}
        >
          <div
            className="a-select-list-scroll-wrap"
            style={{ maxHeight: 204 }}
            ref={scrollWrapper}
          >
            <List>
              {items ? (
                <InfiniteScroll
                  hasMore={hasMore}
                  loadMore={loadMore}
                  initialLoad={false}
                  useWindow={false}
                  pageStart={1}
                  getScrollParent={() => scrollWrapper.current}
                >
                  {items.map((item) => (
                    <ListItem
                      key={item.id}
                      onClick={!multiple ? () => handleSelect(item) : null}
                    >
                      {multiple ? (
                        <Checkbox
                          checked={
                            value.findIndex((v) => isEqual(v, item)) >= 0
                          }
                          onChange={(e) => {
                            e.stopPropagation();
                            handleSelect(item);
                          }}
                        >
                          {render(item)}
                        </Checkbox>
                      ) : (
                        render(item)
                      )}
                    </ListItem>
                  ))}
                </InfiniteScroll>
              ) : (
                <SuspenseLoader text="" />
              )}
            </List>
          </div>
        </div>
      </div>
      {hasError && errMsg && typeof errMsg === "string" ? (
        <p className="a-form-error">{errMsg}</p>
      ) : null}
    </div>
  );
};

export default AsyncSelect;
