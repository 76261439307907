import "./index.scss";

import {
  Button,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "appkit-react";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import { useContext, useEffect } from "react";

import useSubmitable from "../../../../../hooks/useSubmitable";
import { AdminCompanyContext } from "..";

const DEFAULT_FORM = {
  companyId: null,
  name: "",
};

const findFormErrors = (form) => {
  return {
    name: form.name.trim().length === 0,
  };
};

const AddCompanyModal = ({ company, visible, onClose }) => {
  const { addCompany, updateCompany } = useContext(AdminCompanyContext);

  const {
    values: form,
    handleValueChange,
    submitting,
    formErrors,
    resetForm,
    submit,
  } = useSubmitable(DEFAULT_FORM, findFormErrors);

  useEffect(() => {
    resetForm(company || DEFAULT_FORM);
  }, [company, resetForm]);

  const handleSubmit = () => {
    submit((form) => {
      const promise = !company ? addCompany : updateCompany;

      return promise(form).then(onClose);
    });
  };

  useEffect(() => {
    if (!visible) {
      resetForm();
    }
  }, [visible, resetForm]);

  return (
    <ResizableAppkitModal
      visible={visible}
      className="add-company-modal"
      onCancel={onClose}
      backdropClosable={false}
    >
      <ModalHeader>
        <div className="modal-title">
          {company ? "Edit company group" : "Create company group"}
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-md-12">
            <label className="a-form-label">
              <span className="a-color-primary">*</span>&nbsp;Company group name
            </label>
            <Input
              value={form.name}
              onChange={(value) => handleValueChange("name", value)}
              placeholder="Company group name"
              hasError={!!formErrors.name}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onClose} disabled={submitting}>
          Cancel
        </Button>
        <Button kind="primary" isLoading={submitting} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default AddCompanyModal;
