const { apiFetch, RET_RAW } = require("api/api");
const { imageFromBlob } = require("components/ImageUploadToBase64");

const PREFIX = process.env.REACT_APP_URL_DATA;

export const getFile = (uri) => {
  return apiFetch(`${PREFIX}${uri}`, undefined, "GET", RET_RAW, {
    "Access-Control-Request-Headers":
      "Content-Disposition, Content-Type, x-requested-with",
  });
};

export const getData = (uri) => {
  return getFile(uri)
    .then((data) => data.blob())
    .then(imageFromBlob);
};
