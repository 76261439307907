import {
  ApplicationReadDTO,
  ServiceReadDTO,
} from "api/catalog/__generated__/CatalogueAPI";
import { ServiceType } from "api/catalog/type";
import {
  Person,
  toPersonDTO,
} from "pages/admin/components/Service/type/person";

export interface CatalogueService {
  id: string;
  name: string;
  description: string;
  responsibles: Person[];
  subscribed: boolean;
  applications: ApplicationReadDTO[];
  itContact: Person | null;
  team: Person[];
  industries: string[];
}

export interface CatalogueServiceDetails extends CatalogueService {
  applications: ApplicationReadDTO[];
  videoLink?: string | null;
}

export type CatalogueServices = CatalogueService[] | null;

export function toCatalogueService(dto: ServiceReadDTO): CatalogueService {
  return {
    id: dto.id,
    subscribed: false,
    description: dto.description!,
    name: dto.name!,
    // @ts-ignore
    type: ServiceType[ServiceType[dto.type]],
    responsibles: dto.responsibles?.map(toPersonDTO) || [],
    applications: dto.applications || [],
    itContact: dto.itContact ? toPersonDTO(dto.itContact) : null,
    team: dto.team?.map(toPersonDTO) || [],
    industries: dto.industries ?? [],
  };
}

export function toCatalogueServiceDetails(
  dto: ServiceReadDTO
): CatalogueServiceDetails {
  return {
    ...toCatalogueService(dto),
    applications: dto.applications || [],
    videoLink: dto.videoLink,
  };
}

export function isServiceDetails(
  service: CatalogueService | CatalogueServiceDetails
): service is CatalogueServiceDetails {
  if (!service) {
    return false;
  }
  const serviceDetails = service as CatalogueServiceDetails;
  return (
    serviceDetails.applications !== undefined &&
    serviceDetails.videoLink !== undefined
  );
}
