import { getOrganizations } from "api/admin/adminApi";
import AsyncSelect from "components/AsyncSelect";
import { Ref, useCallback } from "react";

interface Props {
  value: string[];
  onChange: (value: string[]) => void;
  clickOutsideRef?: Ref<HTMLDivElement>;
}

export default function OrganizationSelect({
  value,
  onChange,
  clickOutsideRef,
}: Props) {
  const loadItems = useCallback(async (pageNumber, name) => {
    const response = await getOrganizations(
      {
        filters: { name },
        orderBy: {
          name: {
            isDescending: false,
          },
        },
      },
      { pageNumber, pageSize: 20 }
    );

    return {
      ...response,
      items: response.items?.map((organization) => organization.name) || [],
    };
  }, []);

  return (
    <AsyncSelect
      getTooltip={
        (() => Promise.resolve(value.map((x) => ({ id: x, value: x })))) as any
      }
      clearable
      className="organization-select"
      loadItems={loadItems}
      placeholder="Organizations"
      multiple
      render={(name: string) => name}
      value={value}
      onChange={onChange}
      clickOutsideRef={clickOutsideRef as any}
    />
  );
}
