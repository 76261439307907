import { Chip } from "@mui/material";
import { useSelector } from "react-redux";

const ServiceTableBadge: React.FC<{ domain: string }> = (props) => {
  const tenants = useSelector((state) => (state as any).global.data.tenants);
  const tenant = tenants?.find(
    (tenant: any) => tenant.domain.toUpperCase() === props.domain
  );
  return (
    <Chip
      size="small"
      label={tenant?.name ?? "Unknown Tenant"}
      style={{ backgroundColor: tenant?.color, color: "#FFFFFF" }}
    />
  );
};
export default ServiceTableBadge;
