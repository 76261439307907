import { DEFAULT_OIDC_KEY } from "api/auth/config";
import { Button } from "appkit-react";

function RefreshSessionButton() {
  return (
    <Button
      size="lg"
      onClick={() => {
        sessionStorage.removeItem(DEFAULT_OIDC_KEY);
        window.location.reload();
      }}
    >
      Refresh session
    </Button>
  );
}

export default RefreshSessionButton;
