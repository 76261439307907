import "./index.scss";

import classNames from "classnames";
import { Link } from "react-router-dom";

const AppLink = ({
  children,
  className = undefined,
  external = false,
  transparent = true,
  ...otherProps
}) => {
  const allClassNames = classNames(
    "app-link",
    transparent && "transparent",
    className
  );

  if (external) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        {...otherProps}
        className={allClassNames}
      >
        {children}
      </a>
    );
  }
  return (
    <Link {...otherProps} className={allClassNames}>
      {children}
    </Link>
  );
};

export default AppLink;
