import "./index.scss";

import { Button, PopBanner } from "appkit-react";
import { Upload } from "appkit-react-upload";
import FullPageLoader from "components/FullPageLoader";
import { useState } from "react";

import { MIME_XLSX } from "../../../../constants";
import NewsExcelUploadModal from "../NewsExcelUploadModal";

const UploadImport = ({ onImport, type = "News", onDownloadTemplate }) => {
  const [fileToUpload, setFileToUpload] = useState(null);
  const [uploadingExcel, setUploadingExcel] = useState(false);

  const onUploadChange = (file) => {
    if (file.type !== MIME_XLSX) {
      PopBanner({
        content: <span>Only .xlsx files are supported</span>,
        duration: 4000,
        status: "warning",
      });
    } else {
      setFileToUpload(file.originFile);
    }
  };

  const handleExcelUpload = () => {
    setUploadingExcel(true);
    onImport(fileToUpload)
      .then((data) => {
        PopBanner({
          content: (
            <span>
              {`${type[0].toUpperCase()}${type.substring(
                1
              )} items successfully imported. They will be available shortly`}
            </span>
          ),
          duration: 4000,
          status: "success",
        });
      })
      .finally(() => {
        setUploadingExcel(false);
      });
    setFileToUpload(null);
  };

  const handleDownloadTemplate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDownloadTemplate();
  };

  return (
    <>
      <NewsExcelUploadModal
        handleConfirm={handleExcelUpload}
        onClose={() => setFileToUpload(null)}
        uploadedFile={fileToUpload}
        type={type}
      />
      {uploadingExcel && (
        <FullPageLoader message="Importing excel file. Please stand by..." />
      )}
      <Upload
        showFileList={false}
        onChange={onUploadChange}
        autoUpload={false}
        className="admin-news-upload-block"
        action="#"
      >
        <div className="upload-drop-small a-apply-theme-background-gray-level-2">
          <div>
            <span className="upload-drop-span">Drag and drop or</span>
            <span className="upload-browse-span">&nbsp;choose your file</span>
          </div>
          <Button
            className="template-button"
            kind="transparent"
            onClick={handleDownloadTemplate}
          >
            <span className="appkiticon icon-download-outline" />
            Download empty import template file
          </Button>
        </div>
      </Upload>
    </>
  );
};

export default UploadImport;
