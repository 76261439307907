import "./index.scss";

import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { UserReadDTO } from "api/admin/__generated__/UserManagementAPI";
import { getServiceDetails, getTerritories } from "api/catalog/catalogApi";
import useCatalogueHomepageDetails from "api/catalog/queries/useCatalogueHomepageDetails";
import {
  Button,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopBanner,
  SelectOption,
  Switch,
} from "appkit-react";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import Select from "components/Select";
import ValidationTextarea from "components/Textarea";
import useSubmitable from "hooks/useSubmitable";
import UserSelect, {
  INTERNAL_ONLY_FILTERS,
} from "pages/admin/components/AddUserToElementModal/UserSelect";
import is3wService from "pages/admin/utils/is3wService";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { localeCompare } from "utils/localeCompare";

import { ServiceForm, findFormErrors, toServiceForm } from "../../type/form";
import { AdminServiceContext } from "../AdminServiceContextHOC";
import ApplicationsForm, { DEFAULT_APPLICATION } from "./ApplicationsForm";
import IllustrationList from "./IllustrationList";
import LifeCycleSelect from "./LifeCycleSelect";
import OrganizationSelect from "./OrganizationSelect";
import ServiceGroupSelect from "./ServiceGroupSelect";
import ServiceIndustryTagSelect from "./ServiceIndustryTagSelect";
import ServiceSelect from "./ServiceSelect";
import ValueChainSelect from "./ValueChainSelect/ValueChainSelect";

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

interface Props {
  visible: boolean;
  onClose: () => void;
  serviceId: string | null;
}

const DEFAULT_FORM: ServiceForm = {
  applications: [{ ...DEFAULT_APPLICATION }],
  description: "",
  live: false,
  name: "",
  responsibles: [],
  team: [],
  videoLink: "",
  itContact: null,
  organizations: [],
  industries: [],
  isHighlighted: false,
  isNew: false,
  isPSF: false,
  isPSA: false,
  lifeCycles: [],
  valueChains: [],
  smallDescription: "",
  geographicalInformation: "",
  targetProfile: "",
  mainAddedValue: "",
  secondaryAddedValue: "",
  relatedServices: [],
  illustrations: [],
  illustrationsToAdd: [],
  illustrationsToRemove: [],
  availableApplication: "",
  deliverable: "",
  viewers: [],
  domains: [],
  territories: [],
  friendlyUrl: "",
  visibleInClientView: true,
  ukViewers: [],
  frViewers: [],
};

export default function AddServiceModal({
  serviceId,
  visible,
  onClose,
}: Props) {
  const { upsertService } = useContext(AdminServiceContext);
  const { data: homepage } = useCatalogueHomepageDetails();
  const tenants = useSelector((state) => (state as any).global.data.tenants);
  const { data: territories, isLoading: loadingTerritories } = useQuery(
    ["service-territories"],
    {
      queryFn: () => getTerritories(),
      staleTime: 1000 * 60,
      enabled: process.env.REACT_APP_ENABLE_SERVICE_TERRITORIES === "true",
    }
  );

  const {
    values: form,
    handleValueChange,
    submitting,
    formErrors,
    resetForm,
    submit,
  } = useSubmitable(DEFAULT_FORM, findFormErrors);

  useEffect(() => {
    if (!visible || serviceId === null) {
      resetForm(DEFAULT_FORM);
    } else {
      getServiceDetails(serviceId).then((service) =>
        resetForm(toServiceForm(service))
      );
    }
  }, [visible, serviceId, resetForm]);

  const handleSubmit = () => {
    submit(() => upsertService(form).then(onClose));
  };

  const clickOutsideRef = useRef<HTMLDivElement | null>(null);

  const hasErrors = useMemo(
    () => Object.values(formErrors).some((value) => !!value),
    [formErrors]
  );

  const previousHasErrors = usePrevious(hasErrors);

  useEffect(() => {
    if (hasErrors && !previousHasErrors) {
      PopBanner({
        content: <span>Errors exist in the form. Please fix them.</span>,
        duration: 6000,
        status: "warning",
      });
    }
  }, [hasErrors, previousHasErrors]);

  const handleDomainChange = (domain: string, newValue: boolean) => {
    if (newValue) {
      handleValueChange("domains", [...form.domains, domain]);
    } else {
      handleValueChange(
        "domains",
        form.domains.filter((formDomain) => formDomain !== domain)
      );
    }
  };

  const formatFriendlyUrl = (value: string) => {
    return value
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/([^ ]) $/, "$1-")
      .trim()
      .replaceAll(" ", "-")
      .replaceAll(/[^a-z-_]/gm, "");
  };

  return (
    <ResizableAppkitModal
      visible={visible}
      className="add-service-modal"
      onCancel={onClose}
      backdropClosable={false}
      width={800}
    >
      <ModalHeader>
        <div className="modal-title">
          {serviceId ? "Edit service" : "Create service"}
        </div>
      </ModalHeader>
      <ModalBody>
        <div ref={clickOutsideRef}>
          <fieldset>
            <legend>Service information</legend>
            <div className="row">
              <div className="col-md-6">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Service name
                </label>
                <Input
                  value={form.name}
                  onChange={(value: string) => handleValueChange("name", value)}
                  placeholder="Service name"
                  hasError={!!formErrors.name}
                  errMsg={formErrors.name || undefined}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Service
                  description
                </label>
                <ValidationTextarea
                  rows={4}
                  value={form.description}
                  onChange={(value: string) =>
                    handleValueChange("description", value)
                  }
                  placeholder="Service description"
                  hasError={!!formErrors.description}
                  errMsg={
                    typeof formErrors.description === "string"
                      ? formErrors.description
                      : undefined
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Service short
                  description
                </label>
                <ValidationTextarea
                  rows={4}
                  value={form.smallDescription}
                  onChange={(value: string) =>
                    handleValueChange("smallDescription", value)
                  }
                  placeholder="Service short description"
                  hasError={!!formErrors.smallDescription}
                  errMsg={
                    typeof formErrors.smallDescription === "string"
                      ? formErrors.smallDescription
                      : undefined
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label className="a-form-label">Service Friendly URL</label>
                <Input
                  className="service-friendly-url"
                  addonBefore={<span>{process.env.REACT_APP_URL}service/</span>}
                  value={form.friendlyUrl}
                  onChange={(value: string) =>
                    handleValueChange("friendlyUrl", formatFriendlyUrl(value))
                  }
                  hasError={!!formErrors.friendlyUrl}
                  errMsg={formErrors.friendlyUrl || undefined}
                />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>Service Scope</legend>
            <div className="row">
              <div className="col-md-12 service-scope">
                <FormGroup>
                  {tenants?.map((tenant: any) => {
                    const tenantDomain = tenant.domain.toUpperCase();
                    return (
                      <FormControlLabel
                        key={tenant.id}
                        control={
                          <Checkbox
                            checked={form.domains.some(
                              (domain) => domain === tenantDomain
                            )}
                            onChange={(_event, newValue) =>
                              handleDomainChange(tenantDomain, newValue)
                            }
                          />
                        }
                        label={tenant.name}
                      />
                    );
                  })}
                </FormGroup>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>Service Details</legend>
            {tenants &&
              tenants
                .filter((tenant: any) =>
                  form.domains.includes(tenant.domain.toUpperCase())
                )
                .map((tenant: any) => {
                  return (
                    <div className="row" key={tenant.domain}>
                      <span className="tenant-name">{tenant.name}</span>
                      <div className="col-md-12">
                        <label className="a-form-label">
                          {homepage?.mainFilter}
                        </label>
                        {!!homepage && (
                          <ServiceIndustryTagSelect
                            tenant={tenant.domain.toUpperCase()}
                            value={form.industries}
                            onValueChange={(value: string[]) =>
                              handleValueChange("industries", value)
                            }
                            placeholder={homepage?.mainFilter + " tags"}
                            hasError={!!formErrors.industries}
                            // @ts-ignore
                            errMsg={formErrors.industries}
                          />
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="a-form-label">
                          <span className="a-color-primary">*</span>&nbsp;
                          {homepage?.secondaryFilter}
                        </label>
                        {!!homepage && (
                          <LifeCycleSelect
                            tenant={tenant.domain.toUpperCase()}
                            value={form.lifeCycles}
                            onValueChange={(value: string[]) =>
                              handleValueChange("lifeCycles", value)
                            }
                            hasError={!!formErrors.lifeCycles}
                            placeholder={
                              "Select a " + homepage?.secondaryFilter
                            }
                            errMsg={
                              typeof formErrors.lifeCycles === "string"
                                ? formErrors.lifeCycles
                                : undefined
                            }
                          />
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="a-form-label">
                          <span className="a-color-primary">*</span>&nbsp;
                          {homepage?.tertiaryFilter}
                        </label>
                        {!!homepage && (
                          <ValueChainSelect
                            tenant={tenant.domain.toUpperCase()}
                            value={form.valueChains}
                            onValueChange={(value: string[]) =>
                              handleValueChange("valueChains", value)
                            }
                            placeholder={"Select a " + homepage?.tertiaryFilter}
                            hasError={!!formErrors.valueChains}
                            errMsg={
                              typeof formErrors.valueChains === "string"
                                ? formErrors.valueChains
                                : undefined
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                })}

            <div className="row">
              <div className="col-md-6">
                <div>
                  <label className="a-form-label">Is New?</label>
                </div>
                <div>
                  <Switch
                    onText="Yes"
                    offText="No"
                    checked={form.isNew}
                    onChange={(isNew: boolean) =>
                      handleValueChange("isNew", isNew)
                    }
                    label="New"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <label className="a-form-label">Is Highlighted?</label>
                </div>
                <div>
                  <Switch
                    onText="Yes"
                    offText="No"
                    checked={form.isHighlighted}
                    onChange={(isHighlighted: boolean) =>
                      handleValueChange("isHighlighted", isHighlighted)
                    }
                    label="Highlighted"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div>
                  <label className="a-form-label">Is PSF?</label>
                </div>
                <div>
                  <Switch
                    onText="Yes"
                    offText="No"
                    checked={form.isPSF}
                    onChange={(isPSF: boolean) =>
                      handleValueChange("isPSF", isPSF)
                    }
                    label="PSF"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <label className="a-form-label">Is PSA?</label>
                </div>
                <div>
                  <Switch
                    onText="Yes"
                    offText="No"
                    checked={form.isPSA}
                    onChange={(isPSA: boolean) =>
                      handleValueChange("isPSA", isPSA)
                    }
                    label="PSA"
                  />
                </div>
              </div>
            </div>
            {process.env.REACT_APP_ENABLE_SERVICE_TERRITORIES === "true" && (
              <>
                <div className="row">
                  <div className="col-12">
                    {!loadingTerritories ? (
                      <>
                        <label className="a-form-label">Territories</label>
                        <Select
                          placeholder="Service Territories"
                          showSearchOnToggle
                          value={form.territories}
                          onSelect={(values: string[]) => {
                            handleValueChange("territories", values);
                          }}
                          multiple
                        >
                          {territories
                            ?.sort(localeCompare("name"))
                            .map((territory) => (
                              <SelectOption
                                value={territory.id}
                                key={territory.id}
                              >
                                {territory.name}
                              </SelectOption>
                            ))}
                        </Select>
                      </>
                    ) : (
                      <div className="a-loading a-primary" />
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <div className="col-md-6">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Geographical
                  information
                </label>
                <Input
                  value={form.geographicalInformation}
                  onChange={(value: string) =>
                    handleValueChange("geographicalInformation", value)
                  }
                  placeholder="Geographical information"
                  hasError={!!formErrors.geographicalInformation}
                  errMsg={formErrors.geographicalInformation || undefined}
                />
              </div>
              <div className="col-md-6">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Target profile
                </label>
                <Input
                  value={form.targetProfile}
                  onChange={(value: string) =>
                    handleValueChange("targetProfile", value)
                  }
                  placeholder="Target profile"
                  hasError={!!formErrors.targetProfile}
                  errMsg={formErrors.targetProfile || undefined}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Available
                  Applications
                </label>
                <Input
                  value={form.availableApplication}
                  onChange={(value: string) =>
                    handleValueChange("availableApplication", value)
                  }
                  placeholder="Available Applications"
                  hasError={!!formErrors.availableApplication}
                  errMsg={formErrors.availableApplication || undefined}
                />
              </div>
              <div className="col-md-6">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Deliverable
                </label>
                <Input
                  value={form.deliverable}
                  onChange={(value: string) =>
                    handleValueChange("deliverable", value)
                  }
                  placeholder="Deliverable"
                  hasError={!!formErrors.deliverable}
                  errMsg={formErrors.deliverable || undefined}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Main added
                  value
                </label>
                <Input
                  value={form.mainAddedValue}
                  onChange={(value: string) =>
                    handleValueChange("mainAddedValue", value)
                  }
                  placeholder="Main added value"
                  hasError={!!formErrors.mainAddedValue}
                  errMsg={formErrors.mainAddedValue || undefined}
                />
              </div>
              <div className="col-md-6">
                <label className="a-form-label">
                  <span className="a-color-primary">*</span>&nbsp;Secondary
                  added value
                </label>
                <Input
                  value={form.secondaryAddedValue}
                  onChange={(value: string) =>
                    handleValueChange("secondaryAddedValue", value)
                  }
                  placeholder="Secondary added value"
                  hasError={!!formErrors.secondaryAddedValue}
                  errMsg={formErrors.secondaryAddedValue || undefined}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="a-form-label">Related services</label>
                <ServiceSelect
                  value={form.relatedServices}
                  onValueChange={(value: string[]) =>
                    handleValueChange("relatedServices", value)
                  }
                  serviceIdExclude={serviceId}
                />
              </div>
              <div className="col-md-6">
                <label className="a-form-label">Service group</label>
                <ServiceGroupSelect
                  value={form.serviceGroupId ?? null}
                  onValueChange={(value: string) =>
                    handleValueChange("serviceGroupId", value)
                  }
                />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>Illustrations</legend>
            <div className="row">
              <div className="col-md-12">
                <IllustrationList
                  onFilesAddChange={(value: File[]) =>
                    handleValueChange("illustrationsToAdd", value)
                  }
                  onFilesDeleteChange={(value: string[]) =>
                    handleValueChange("illustrationsToRemove", value)
                  }
                  illustrations={form.illustrations}
                />
              </div>
            </div>
            {formErrors.illustrations ? (
              <p>{formErrors.illustrations}</p>
            ) : null}
          </fieldset>
          <fieldset>
            <legend>Applications</legend>
            <div className="row">
              <div className="col-md-12">
                <ApplicationsForm
                  clickOutsideRef={clickOutsideRef}
                  applications={form.applications}
                  // @ts-ignore
                  errors={formErrors.applications}
                  onApplicationsChange={(applications) =>
                    handleValueChange("applications", applications)
                  }
                />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>Teams</legend>
            <div className="row">
              <div className="col-md-6">
                <label className="a-form-label">Service Responsibles</label>
                <UserSelect
                  clickOutsideRef={clickOutsideRef}
                  value={form.responsibles}
                  onChange={(value: UserReadDTO[]) =>
                    handleValueChange("responsibles", value)
                  }
                  placeholder="Service responsibles"
                  hasError={!!formErrors.responsibles}
                  errMsg={formErrors.responsibles}
                  multiple
                  filters={INTERNAL_ONLY_FILTERS}
                />
              </div>
              <div className="col-md-6">
                <label className="a-form-label">IT team</label>
                <UserSelect
                  clickOutsideRef={clickOutsideRef}
                  value={form.itContact}
                  onChange={(value: UserReadDTO[]) =>
                    handleValueChange("itContact", value)
                  }
                  placeholder="IT team"
                  filters={INTERNAL_ONLY_FILTERS}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <label className="a-form-label">Service team</label>
                <UserSelect
                  clickOutsideRef={clickOutsideRef}
                  value={form.team}
                  onChange={(value: UserReadDTO[]) =>
                    handleValueChange("team", value)
                  }
                  placeholder="Service team"
                  multiple
                  filters={INTERNAL_ONLY_FILTERS}
                />
              </div>

              <div className="col-md-6">
                <label className="a-form-label">Organizations</label>
                <OrganizationSelect
                  clickOutsideRef={clickOutsideRef}
                  value={form.organizations}
                  onChange={(value: string[]) =>
                    handleValueChange("organizations", value)
                  }
                />
              </div>
            </div>
            {is3wService(serviceId) && (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <label className="a-form-label">PwC viewers</label>
                    <UserSelect
                      clickOutsideRef={clickOutsideRef}
                      value={form.viewers}
                      onChange={(value: UserReadDTO[]) =>
                        handleValueChange("viewers", value)
                      }
                      placeholder="PwC viewers"
                      hasError={!!formErrors.viewers}
                      errMsg={formErrors.viewers}
                      multiple
                      filters={INTERNAL_ONLY_FILTERS}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="a-form-label">UK</label>
                    <UserSelect
                      clickOutsideRef={clickOutsideRef}
                      value={form.ukViewers}
                      onChange={(value: UserReadDTO[]) =>
                        handleValueChange("ukViewers", value)
                      }
                      placeholder="UK"
                      hasError={!!formErrors.ukViewers}
                      errMsg={formErrors.ukViewers}
                      multiple
                      filters={INTERNAL_ONLY_FILTERS}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label className="a-form-label">FR</label>
                    <UserSelect
                      clickOutsideRef={clickOutsideRef}
                      value={form.frViewers}
                      onChange={(value: UserReadDTO[]) =>
                        handleValueChange("frViewers", value)
                      }
                      placeholder="FR"
                      hasError={!!formErrors.frViewers}
                      errMsg={formErrors.frViewers}
                      multiple
                      filters={INTERNAL_ONLY_FILTERS}
                    />
                  </div>
                </div>
              </>
            )}
          </fieldset>

          <div className="row">
            <div className="col-md-12">
              <label className="a-form-label">Video URL</label>
              <Input
                autoFocus
                placeholder="Video URL"
                value={form.videoLink}
                onChange={(value: string) =>
                  handleValueChange("videoLink", value)
                }
                hasError={!!formErrors.videoLink}
                errMsg={formErrors.videoLink}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label className="a-form-label">Visible in catalogue ?</label>
              <br />
              <Switch
                onText="Yes"
                offText="No"
                checked={form.live}
                onChange={(live: boolean) => handleValueChange("live", live)}
                label="Visible"
              />
            </div>
            <div className="col">
              <label className="a-form-label">Visible in client view ?</label>
              <br />
              <Switch
                onText="Yes"
                offText="No"
                checked={form.visibleInClientView}
                onChange={(visibleInClientView: boolean) =>
                  handleValueChange("visibleInClientView", visibleInClientView)
                }
                label="Visible"
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onClose} disabled={submitting}>
          Cancel
        </Button>
        <Button kind="primary" isLoading={submitting} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
}
