import { PopBanner } from "appkit-react";

import { USERS_CONTEXTS } from "../constants";

const FILTER_CONTEXT_RULES = {
  [USERS_CONTEXTS.ALL]: () => true,
  [USERS_CONTEXTS.SUPERADMIN]: (user) => !!user.isSuperUser,
  [USERS_CONTEXTS.PWC]: (user) => !!user.isInternal,
  [USERS_CONTEXTS.CLIENT]: (user) => !user.isInternal,
};

export const NO_USER_ROW = {
  ppid: -1,
  name: "No user",
  lastName: "",
  firstName: "",
  isInternal: null,
  role: "",
  highestLevel: "",
};

export const filterContext = (users, context) => {
  const res =
    context === null ? users : users.filter(FILTER_CONTEXT_RULES[context]);

  if (res.length === 0) {
    res.push({ ...NO_USER_ROW });
  }

  return res;
};

export const filterPriorities = (users, highestLevel) => {
  let counts = {};
  let newUsers = [...users];
  newUsers.forEach((user) => {
    counts[user.ppid] = (counts[user.ppid] || 0) + 1;
  });
  for (let ppid in counts) {
    if (counts[ppid] > 1) {
      newUsers = newUsers.filter((user) => {
        return (
          user.ppid !== ppid ||
          (user.ppid === ppid && user.highestLevel === highestLevel)
        );
      });
    }
  }
  return newUsers;
};

export const getPwcClientCellString = (value) => {
  if (typeof value === "boolean") {
    return value ? "PwC" : "Client";
  } else {
    return "";
  }
};

export const createSuccessBanner = () => {
  PopBanner({
    content:
      "Your action has been taken into account and will be treated in a few moments.",
    duration: 4000,
    status: "success",
  });
};
