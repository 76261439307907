import "./NewsletterExport.scss";

import { exportAllToExcel, exportAllToWord } from "api/news/exportNewsApi";
import FullPageLoader from "components/FullPageLoader";
import UnexpectedError from "pages/error/UnexpectedError";
import { defaultFilename } from "pages/news/helpers/filename";
import useQueryParams from "pages/news/hooks/useQueryParams";
import { useEffect, useState } from "react";
import { downloadFromResponse } from "utils";

export default function NewsletterExport() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  let query = useQueryParams();
  useEffect(() => {
    const type = query.get("Type");
    const publishDateStart = query.get("PublishDateStart");
    const publishDateEnd = query.get("PublishDateEnd");

    let call =
      type === "Excel"
        ? exportAllToExcel
        : type === "Word"
        ? exportAllToWord
        : null;
    if (type && call) {
      setLoading(true);
      call({
        published: true,
        publishedDateRange: { start: publishDateStart, end: publishDateEnd },
      })
        .then((data) =>
          downloadFromResponse(data, defaultFilename(type.toLocaleLowerCase()))
        )
        .finally(() => {
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [query]);

  return loading ? (
    <FullPageLoader />
  ) : error ? (
    <UnexpectedError error={error} />
  ) : (
    <div className="file-downloaded">
      File has been downloaded. You can close this browser tab.
    </div>
  );
}
