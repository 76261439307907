export const threeWServiceIds = [
  process.env.REACT_APP_3WDISTIB_SERVICEID,
  process.env.REACT_APP_3WREG_SERVICEID,
  process.env.REACT_APP_3WTAX_SERVICEID,
  process.env.REACT_APP_3WAWM_SERVICEID,
];

function is3wService(id) {
  return threeWServiceIds.includes(id);
}

export default is3wService;
