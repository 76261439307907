import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import striptags from "striptags";

export const draftContentIsEmpty = (draft) => {
  if (!draft) {
    return true;
  }
  return striptags(draftRawToHtml(draft)).trim().length === 0;
};

export const blankRawDraft = convertToRaw(
  EditorState.createEmpty().getCurrentContent()
);

export const draftRawToHtml = (raw) => {
  try {
    return draftToHtml(raw);
  } catch (error) {
    console.error("Error while converting draft raw to HTML", error);
    return "";
  }
};
