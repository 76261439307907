import {
  downloadServiceExport,
  downloadUserPermissions,
} from "api/admin/adminApi";
import { Button } from "appkit-react";
import { ContextsSearchbar } from "components/SearchBar";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { ADMIN_TABS } from "../../index";
import ContextToggle from "./ContextToggle";
import MyServicesFilter from "./MyServices";
import TrialFilter from "./TrialFilter";

const Filters = ({ filters, onFiltersChange, activeTab }) => {
  const loginUser = useSelector((state) => state.global.data.loginUser);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const filterColumns = useMemo(() => {
    return activeTab.columns.filter((column) => !!column.filterable);
  }, [activeTab]);

  const handleChange = (property, value) => {
    onFiltersChange({
      ...filters,
      [property]: value,
    });
  };

  const needsContextToggle = useMemo(
    () => activeTab === ADMIN_TABS.USERS,
    [activeTab]
  );

  const serviceTab = useMemo(
    () => activeTab === ADMIN_TABS.SERVICES,
    [activeTab]
  );

  const usersTab = useMemo(() => activeTab === ADMIN_TABS.USERS, [activeTab]);

  const needsMyServices = useMemo(
    () => activeTab === ADMIN_TABS.ENGAGEMENTS && !!loginUser?.isSuperUser,
    [activeTab, loginUser]
  );

  const needsTrialFilter = useMemo(
    () => activeTab === ADMIN_TABS.ENGAGEMENTS,
    [activeTab]
  );

  return (
    <div className="row">
      <div
        className={
          needsMyServices || serviceTab || usersTab ? "col-6" : "col-9"
        }
      >
        <ContextsSearchbar
          filters={filters.rowFilters}
          onFiltersChange={(value) => handleChange("rowFilters", value)}
          columns={filterColumns}
        />
      </div>

      {needsContextToggle ? (
        <div className="col-md-2">
          <ContextToggle
            className="search-bar-style"
            context={filters.context}
            onContextChange={(value) => handleChange("context", value)}
            superAdmin={activeTab === ADMIN_TABS.USERS}
          />
        </div>
      ) : needsTrialFilter ? (
        <>
          {needsMyServices && (
            <div className="col-md-2">
              <MyServicesFilter
                value={filters.myServices}
                onValueChange={(value) => handleChange("myServices", value)}
              />
            </div>
          )}
          <div className="col-md-2">
            <TrialFilter
              value={filters.trial}
              onValueChange={(value) => handleChange("trial", value)}
            />
          </div>
        </>
      ) : serviceTab ? (
        <div className="col-md-2">
          <Button
            style={{ height: "100%" }}
            className="btn-export"
            onClick={() => {
              setDownloadLoading(true);
              downloadServiceExport().finally(() => setDownloadLoading(false));
            }}
            {...(downloadLoading ? { disabled: true } : {})}
          >
            {downloadLoading ? "Loading" : "Export all services"}
          </Button>
        </div>
      ) : null}
      {usersTab && (
        <div className="col-md-2">
          <Button
            style={{ height: "100%" }}
            className="btn-export"
            onClick={() => {
              setDownloadLoading(true);
              downloadUserPermissions().finally(() =>
                setDownloadLoading(false)
              );
            }}
            {...(downloadLoading ? { disabled: true } : {})}
          >
            {downloadLoading ? "Loading" : "Export All users"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Filters;
