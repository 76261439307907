import { PopBanner, SelectOption } from "appkit-react";
import Select from "components/Select";
import { useContext, useMemo } from "react";
import { localeCompare } from "utils/localeCompare";

import AddUserModal from "../../AddUserToElementModal/Modal";
import { AdminEntityContext } from "../../Entity";
import { AdminEngagementContext } from "..";

const UserSelect = ({ entityId, subscription, ...props }) => {
  const { items: entities } = useContext(AdminEntityContext);
  const users = useMemo(() => {
    if (!(entityId && subscription)) {
      return null;
    }

    const selectedEntity = entities.find((entity) => entity.id === entityId);
    if (!selectedEntity?.users) {
      return null;
    }

    const subscriptionPpid = subscription.users.reduce((acc, user) => {
      acc[user.ppid] = user;
      return acc;
    }, {});

    return selectedEntity.users
      .filter((user) => !subscriptionPpid[user.ppid])
      .sort(localeCompare("name"));
  }, [entities, entityId, subscription]);

  return (
    <Select showSearchOnList {...props} multiple>
      {users?.map((user) => (
        <SelectOption key={user.ppid} value={user}>
          {user.email}
        </SelectOption>
      ))}
    </Select>
  );
};
const AddUserToEngagementModal = ({ subscription, onClose, ...modalProps }) => {
  const { addUserToSubscription } = useContext(AdminEngagementContext);

  const submitAddUser = (form) => {
    const users = form.users;

    return addUserToSubscription(
      subscription.id,
      users.map((user) => user.ppid)
    ).then(() => {
      PopBanner({
        content:
          users.length > 1
            ? `The users have been successfully added to the engagement.`
            : `The user has been successfully added to the engagement.`,
        duration: 4000,
        status: "success",
      });

      onClose();
    });
  };

  return (
    <AddUserModal
      visible={!!subscription}
      onCancel={onClose}
      onSubmit={submitAddUser}
      Render={({ hasError, value, handleValueChange }) => (
        <UserSelect
          entityId={subscription?.entityId}
          subscription={subscription}
          placeholder="Select user to add here"
          onSelect={handleValueChange}
          showSearchOnList={true}
          value={value}
          hasError={hasError}
        />
      )}
      {...modalProps}
    />
  );
};

export default AddUserToEngagementModal;
