import "./LinkForm.scss";

import { Button, Input, Switch } from "appkit-react";
import { FormErrors } from "hooks/useSubmitable";

import { SubscriptionLinkForm } from "../../types/form";

interface Props {
  value: SubscriptionLinkForm;
  errors?: FormErrors<SubscriptionLinkForm> | null;
  onValueChange: (value: SubscriptionLinkForm) => void;
  onDelete?: () => void;
  onUnfocus?: () => void;
}
export default function LinkForm({
  value: items,
  errors,
  onValueChange,
  onDelete,
  onUnfocus,
}: Props) {
  const handleValueChange = (property: keyof SubscriptionLinkForm) => (
    value: string
  ) => {
    onValueChange({
      ...items,
      [property]: value,
    });
  };

  return (
    <div className="link-form">
      <div className="link-form-inputs">
        <Input
          value={items.name}
          onChange={handleValueChange("name")}
          hasError={!!errors?.name}
          errMsg={errors?.name || null}
          placeholder="Name"
        />
        <Input
          value={items.link}
          onChange={handleValueChange("link")}
          placeholder="Link"
          onBlur={onUnfocus}
          hasError={!!errors?.link}
          errMsg={errors?.link || null}
        />

        <Switch
          className="switch-showToClient"
          checked={items.showToClient}
          onChange={handleValueChange("showToClient")}
          onText="Yes"
          offText="No"
        />

        <Button kind="transparent" onClick={onDelete} disabled={!onDelete}>
          <span className="appkiticon icon-delete-fill" />
        </Button>
      </div>
    </div>
  );
}
