import {
  MODULES,
  MODULE_VALUES,
  NEWS_MODULE_IS_ENABLED,
} from "constants/module";
import { findCurrentModule } from "constants/module";
import { DOWNLOAD_PUBLICATION_BASEURL } from "constants/routes";

import { withOidcSecure } from "@axa-fr/react-oidc-context";
import IFramePage from "components/IFramePage";
import Downloader from "pages/download";
import NewsletterExport from "pages/newsletter/NewsletterExport";
import { Suspense, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { INITIALIZE } from "redux/constants";

import RestoreScroll from "./components/RestoreScroll";
import SuspenseLoader from "./components/SuspenseLoader";
import { AdminWithInitializer } from "./pages/admin";

const Error404 = lazy(() => import("./pages/error/404"));
const UnexpectedError = lazy(() => import("./pages/error/UnexpectedError"));
const LogoutSuccess = lazy(() => import("./pages/logoutSuccess"));
const ArticlesLandingPage = lazy(() => import("./pages/news"));
const ShowArticle = lazy(() => import("./pages/news/showArticle"));
const ArticleEditor = lazy(() => import("./pages/news/editArticle"));
const NewsAdmin = lazy(() => import("./pages/newsAdmin"));
const Admin = lazy(() => import("./pages/admin"));
const UserProfile = lazy(() => import("./pages/userProfile"));
const CreatingUser = lazy(() => import("./pages/creatingUser"));
const Catalogue2 = lazy(() => import("./pages/catalogue2/Catalogue2"));
const CatalogueServiceDetails = lazy(() =>
  import("./pages/catalogue2/CatalogueServiceDetails")
);
const MyServices = lazy(() => import("./pages/myServices"));
const PWCServices = lazy(() => import("./pages/pwcServices"));
const MyClients = lazy(() => import("./pages/myClients"));
const ClientView = lazy(() => import("./pages/ClientView"));

const ModuleSecure = ({ params, children }) => {
  const initialized = useSelector((state) => state.global.initialized);
  const loginUser = useSelector((state) => state.global.data.loginUser);

  const menus = Object.values(MODULES);

  useEffect(() => {
    if (initialized === INITIALIZE.TRUE) {
      const selectedKey = Object.keys(MODULE_VALUES).find((key) =>
        MODULE_VALUES[key].match(params.location.pathname)
      );

      if (!selectedKey) {
        params.history.push("/404");
        return;
      }
    }
  }, [initialized, menus, params]);

  const hasModulePermission = (routeModule) => {
    if (initialized !== INITIALIZE.TRUE || !routeModule) {
      return false;
    }
    return !routeModule.isInternalOnly || !!loginUser?.isInternal;
  };

  return hasModulePermission(findCurrentModule(params.location.pathname))
    ? children
    : null;
};

const Routes = () => {
  const loginUser = useSelector((state) => state.global.data.loginUser);

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Switch>
        <Route
          exact
          path="/logoutsuccess"
          component={() => <LogoutSuccess />}
        />

        <Route exact path="/creatingUser" component={() => <CreatingUser />} />
        <Route exact path="/error" component={() => <UnexpectedError />} />
        <Route exact path="/404" component={() => <Error404 />} />
        {process.env.REACT_APP_ENABLE_ECS_MENU_ITEM === "true" && (
          <Route
            exact
            path="/ecs"
            component={() => (
              <IFramePage
                title="ECS"
                src="https://ecs.pwc.lu/sites/eCSDemonstration11/SitePages/Dashboard.aspx"
              />
            )}
          />
        )}

        <Route
          exact
          path={MODULES.CATALOGUE}
          component={(params) => <Catalogue2 {...params} />}
        />
        <Route
          exact
          path={MODULES.CATALOGUE + "service/:id"}
          component={(params) => <CatalogueServiceDetails {...params} />}
        />

        <Route
          component={withOidcSecure((oidcParams) => (
            <ModuleSecure params={oidcParams}>
              <Switch>
                <Route
                  exact
                  path={MODULES.MYSERVICES}
                  component={() =>
                    process.env.REACT_APP_HIDE_MODULES_NON_LUX !== "1" ||
                    (process.env.REACT_APP_HIDE_MODULES_NON_LUX === "1" &&
                      loginUser?.isLu) ? (
                      loginUser?.isInternal ? (
                        <PWCServices />
                      ) : (
                        <MyServices />
                      )
                    ) : null
                  }
                />

                <Route
                  exact
                  path={MODULES.MYCLIENTS}
                  component={() =>
                    process.env.REACT_APP_HIDE_MODULES_NON_LUX !== "1" ||
                    (process.env.REACT_APP_HIDE_MODULES_NON_LUX === "1" &&
                      loginUser?.isLu) ? (
                      <MyClients />
                    ) : null
                  }
                />

                <Route
                  exact
                  path={MODULES.CLIENT_VIEW}
                  component={() =>
                    process.env.REACT_APP_HIDE_MODULES_NON_LUX !== "1" ||
                    (process.env.REACT_APP_HIDE_MODULES_NON_LUX === "1" &&
                      loginUser?.isLu) ? (
                      <ClientView />
                    ) : null
                  }
                />

                <Route
                  exact
                  path={`${DOWNLOAD_PUBLICATION_BASEURL}:id`}
                  component={(params) => (
                    <Downloader publicationId={params.match.params.id} />
                  )}
                />
                {NEWS_MODULE_IS_ENABLED && (
                  <Route
                    exact
                    path={`${MODULES.NEWS}/view/:id`}
                    component={(params) => (
                      <ShowArticle articleId={params.match.params.id} />
                    )}
                  />
                )}

                {NEWS_MODULE_IS_ENABLED && (
                  <Route
                    exact
                    path={`${MODULES.NEWS}/add/:type`}
                    component={(params) => (
                      <ArticleEditor
                        type={parseInt(params.match.params.type)}
                      />
                    )}
                  />
                )}
                {NEWS_MODULE_IS_ENABLED && (
                  <Route
                    exact
                    path={`${MODULES.NEWS}/export`}
                    component={(params) => <NewsletterExport {...params} />}
                  />
                )}
                {NEWS_MODULE_IS_ENABLED && (
                  <Route
                    exact
                    path={`${MODULES.NEWS}/edit/:articleId`}
                    component={(params) => (
                      <ArticleEditor
                        articleId={params.match.params.articleId}
                      />
                    )}
                  />
                )}
                <RestoreScroll>
                  <Switch>
                    <Route
                      exact
                      path="/logoutsuccess"
                      component={() => <LogoutSuccess />}
                    />

                    <Route
                      exact
                      path={MODULES.ADMIN}
                      component={() =>
                        process.env.REACT_APP_HIDE_MODULES_NON_LUX !== "1" ||
                        (process.env.REACT_APP_HIDE_MODULES_NON_LUX === "1" &&
                          loginUser?.isLu) ? (
                          <Admin />
                        ) : null
                      }
                    />
                    <Route
                      path={`${MODULES.ADMIN}/:service/:id`}
                      component={AdminWithInitializer}
                    />

                    {NEWS_MODULE_IS_ENABLED && (
                      <Route
                        exact
                        path={MODULES.NEWS}
                        component={() => <ArticlesLandingPage />}
                      />
                    )}
                    {NEWS_MODULE_IS_ENABLED && (
                      <Route
                        exact
                        path={MODULES.NEWS_ADMIN}
                        component={() => <NewsAdmin />}
                      />
                    )}

                    <Route
                      exact
                      path={MODULES.PROFILE}
                      component={() => <UserProfile />}
                    />
                  </Switch>
                </RestoreScroll>
              </Switch>
            </ModuleSecure>
          ))}
        />
      </Switch>
    </Suspense>
  );
};

export default Routes;
