import "./index.scss";

import { Panel } from "appkit-react";
import classnames from "classnames";

const CustomPanel = (props) => {
  const classes = classnames([
    "a-custom-panel",
    props.className ? props.className : "",
  ]);

  return (
    <Panel {...props} className={classes}>
      {props.children}
    </Panel>
  );
};

export default CustomPanel;
