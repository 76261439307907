export const localeCompare = (property) => (a, b) => {
  let first = a,
    second = b;
  if (!!property) {
    first = a[property];
    second = b[property];
  }

  return first.trim().localeCompare(second.trim());
};
