import "./index.scss";

import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

const CategoryPitch = ({
  title = "",
  image,
  className,
  rightChildren = null,
  children,
  marginBottomOffset = null,
  titleMarginBottom = null,
}) => {
  return (
    <div
      className={classNames("category-pitch", className)}
      style={{
        marginBottom:
          marginBottomOffset !== null ? -1 * marginBottomOffset : null,
      }}
    >
      <div className="background-illustration-container">
        <div
          className="background-illustration"
          style={{
            backgroundImage: image ? `url(${image})` : "none",
          }}
        />
      </div>
      <div
        className="container category-pitch-container"
        style={{ marginBottom: titleMarginBottom }}
      >
        <div className="row title-container">
          <div className="col">
            <h2 className="from-category-pitch">
              {title === null ? <Skeleton /> : title}
            </h2>
          </div>
          <div className="col-md-6 category-pitch-right-children-container">
            {rightChildren}
          </div>
        </div>
      </div>

      <div className="container with-margin category-pitch-children-container">
        {children}
      </div>
    </div>
  );
};

export default CategoryPitch;
