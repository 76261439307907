import "./index.scss";

import { Tab, Tabs } from "appkit-react";
import { useDispatch, useSelector } from "react-redux";
import { newsAdminChangeTab } from "redux/actions/newsAdmin";

export const TABS = {
  NEWS: "NEWS",
  ARTICLES: "ARTICLES",
  EVENTS: "EVENTS",
  VIDEOS: "VIDEOS",
  PUBLICATIONS: "PUBLICATIONS",
  TOPICS: "TOPICS",
  ISSUERS: "ISSUERS",
  DOCUMENTTYPES: "DOCUMENTTYPES",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
};

const OPTIONS = {
  [TABS.NEWS]: {
    label: "News",
    icon: "icon-pencil",
  },
  [TABS.ARTICLES]: {
    label: "Articles",
    icon: "icon-news",
  },
  [TABS.EVENTS]: {
    label: "Compliance Calendar",
    icon: "icon-calendar",
  },
  [TABS.VIDEOS]: {
    label: "Videos",
    icon: "icon-play",
  },
  [TABS.PUBLICATIONS]: {
    label: "Publications",
    icon: "icon-survey",
  },
  [TABS.TOPICS]: {
    label: "Topics",
    icon: "icon-tag",
  },
  [TABS.ISSUERS]: {
    label: "Issuers",
    icon: "icon-leader",
  },
  [TABS.DOCUMENTTYPES]: {
    label: "Document types",
    icon: "icon-management",
  },
  [TABS.SUBSCRIPTIONS]: {
    label: "Subscriptions",
    icon: "icon-growth",
  },
};

const TableToggle = () => {
  const selected = useSelector((state) => state.newsAdmin.tab);
  const dispatch = useDispatch();

  const onSelectedChange = (tab) => dispatch(newsAdminChangeTab(tab));

  return (
    <Tabs
      className="a-category-tabs"
      size="lg"
      value={selected}
      onChange={(_, selected) => onSelectedChange(selected)}
    >
      {Object.entries(OPTIONS).map(([key, tab]) => (
        <Tab
          key={tab.label}
          value={key}
          icon={
            <>
              <span
                className={`appkiticon ${tab.icon}-${
                  selected === key ? "fill" : "outline"
                }`}
              />
              &nbsp;{tab.label}
            </>
          }
        />
      ))}
    </Tabs>
  );
};

export default TableToggle;
