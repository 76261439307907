import {
  SubscriptionDetailsReadDTO,
  SubscriptionReadDTO,
} from "api/admin/__generated__/UserManagementAPI";
import FloatingActionButtonPortal from "components/FloatingActionButton";
import React, { useContext, useState } from "react";

import ThreeWPermissionsModal from "../../ThreeWPermissionsModal";
import AddEngagementModal from "../AddEngagementModal";
import SubscriptionDetails from "../SubscriptionDetails";
import SubscriptionTable from "../SubscriptionTable";
import AddUserToEngagementModal from "./addUserToEngagementModal";
import DeleteEngagementModal from "./deleteEngagementModal";
import DeleteUserFromEngagementModal from "./deleteUserFromEngagementModal";
import { AdminEngagementContext } from "..";

const AdminEngagements = () => {
  const { upsertSubscription, updateUserSubscription } = useContext(
    AdminEngagementContext
  );
  const [addUser, setAddUser] = useState<any>(null);
  const [userToDelete, setUserToDelete] = useState(null);

  const [subscriptionDetails, setSubscriptionDetails] =
    useState<SubscriptionDetailsReadDTO | null>(null);
  const [engagementToDelete, setEngagementToDelete] =
    useState<SubscriptionReadDTO | null>(null);
  const [threeWPermissionsEngagement, setThreeWPermissionsEngagement] =
    useState(null);

  const [showCreateEngagementModal, setShowCreateEngagementModal] =
    useState(false);
  const [editedEngagement, setEditedEngagement] = useState<any>(null);

  const handleEditEngagement = (engagement: any) => {
    setEditedEngagement(engagement);
    setShowCreateEngagementModal(true);
  };

  const handleMainContactChanged = (
    subscriptionId: string,
    userPpid: string,
    isMainContact: boolean
  ) => {
    updateUserSubscription(subscriptionId, userPpid, isMainContact);
  };

  return (
    <>
      <ThreeWPermissionsModal
        engagement={threeWPermissionsEngagement}
        onClose={() => setThreeWPermissionsEngagement(null)}
      />

      <DeleteEngagementModal
        engagement={engagementToDelete}
        onClose={() => setEngagementToDelete(null)}
      />

      <AddEngagementModal
        subscription={editedEngagement}
        visible={!!showCreateEngagementModal}
        onUpsertSubscription={upsertSubscription}
        onClose={() => {
          setEditedEngagement(null);
          setShowCreateEngagementModal(false);
        }}
      />

      <SubscriptionDetails
        onClose={() => setSubscriptionDetails(null)}
        subscription={subscriptionDetails}
      />
      <AddUserToEngagementModal
        subscription={addUser || null}
        onClose={() => setAddUser(null)}
      />
      <DeleteUserFromEngagementModal
        user={userToDelete}
        onClose={() => setUserToDelete(null)}
      />

      <SubscriptionTable
        onEditSubscription={handleEditEngagement}
        onNewsAccessRight={setThreeWPermissionsEngagement}
        onDeleteSubscription={setEngagementToDelete}
        onAddUser={setAddUser}
        onDeleteUser={setUserToDelete}
        onDisplayDetails={setSubscriptionDetails}
        onMainContactChanged={handleMainContactChanged}
      />

      <FloatingActionButtonPortal
        name="icon-plus-fill"
        onClick={() => {
          setEditedEngagement(null);
          setShowCreateEngagementModal(true);
        }}
        tooltip="Create a new subscription"
      />
    </>
  );
};

export default AdminEngagements;
