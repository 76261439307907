export const ARTICLE_TYPES = {
  THREEW_FUND_DISTRI: 0,
  THREEW_REG: 1,
  THREEW_TAX: 2,
  THREEW_AWM: 3,
};

export const ARTICLE_CLASSNAME = {
  [ARTICLE_TYPES.THREEW_FUND_DISTRI]: "threew-funddistri",
  [ARTICLE_TYPES.THREEW_REG]: "threew-reg",
  [ARTICLE_TYPES.THREEW_TAX]: "threew-tax",
  [ARTICLE_TYPES.THREEW_AWM]: "threew-awm",
};

export const ARTICLE_SERVICE = {
  [ARTICLE_TYPES.THREEW_FUND_DISTRI]: process.env.REACT_APP_3WDISTIB_SERVICEID,
  [ARTICLE_TYPES.THREEW_REG]: process.env.REACT_APP_3WREG_SERVICEID,
  [ARTICLE_TYPES.THREEW_TAX]: process.env.REACT_APP_3WTAX_SERVICEID,
  [ARTICLE_TYPES.THREEW_AWM]: null,
};

export const ARTICLES_LABELS = {
  [ARTICLE_TYPES.THREEW_FUND_DISTRI]: "3W Fund Distribution",
  [ARTICLE_TYPES.THREEW_REG]: "3W Regulatory",
  [ARTICLE_TYPES.THREEW_TAX]: "3W Tax",
  [ARTICLE_TYPES.THREEW_AWM]: "3W Tax - PwC Internal Database",
};

export const SECTION_TITLES = {
  background: "Background",
  whatsNew: "What's new?",
  whatsNext: "What's next?",
  freeContent: "",
};

export const PERMISSION_TYPES = {
  READ: "READ",
  WRITE: "WRITE",
  DELETE: "DELETE",
};

export const DEFAULT_PAGINATION = { pageNumber: 1, pageSize: 15 };
