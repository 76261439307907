import {
  getNewsPermissions,
  getUsedMetadataForType,
} from "api/news/articleApi";
import { getDocumentTypes } from "api/news/articleDocumentTypeApi";
import { getTopics } from "api/news/articleTopicApi";
import { getIssuers } from "api/news/issuerApi";
import { getList as fetchNews } from "api/news/newsApi";
import { getTerritories } from "api/news/territoryApi";

// ARTICLES ACTIONS
export const ARTICLES_INITIALIZE = "ARTICLES_INITIALIZE";
export const ARTICLES_INITIALIZE_SUCCESS = "ARTICLES_INITIALIZE_SUCCESS";

export const articleInit = (type) => async (dispatch) => {
  await articlesGetMetadataForType(type)(dispatch);
};

export const NEWS_ADMIN_INITIALIZE = "NEWS_ADMIN_INITIALIZE";
export const NEWS_ADMIN_INITIALIZE_SUCCESS = "NEWS_ADMIN_INITIALIZE_SUCCESS";

export const articlesInitNewsAdmin = () => async (dispatch) => {
  dispatch({ type: NEWS_ADMIN_INITIALIZE });
  await Promise.all([
    articlesSetTerritories(dispatch),
    articlesSetTopics(dispatch),
    articlesSetDocumentTypes(dispatch),
    articlesSetIssuers(dispatch),
  ]);
  dispatch({ type: NEWS_ADMIN_INITIALIZE_SUCCESS });
};

export const ARTICLES_CHANGE_FILTERS = "ARTICLES_CHANGE_FILTERS";

export const articlesChangeFilters = (newFilters) => {
  return { type: ARTICLES_CHANGE_FILTERS, newFilters: newFilters };
};

export const ARTICLES_CHANGE_TAB = "ARTICLES_CHANGE_TAB";
export const articlesChangeLandingTab = (tab) => {
  return { type: ARTICLES_CHANGE_TAB, tab };
};

export const ARTICLES_RESET_FILTERS = "ARTICLES_RESET_FILTERS";

export const articlesResetFilters =
  (selectedType = null) =>
  (dispatch) => {
    dispatch({ type: ARTICLES_RESET_FILTERS, selectedType: selectedType });
  };

export const ARTICLES_SET_TERRITORIES = "ARTICLES_SET_TERRITORIES";

const articlesSetTerritories = async (dispath) => {
  const newData = await getTerritories();
  dispath({ type: ARTICLES_SET_TERRITORIES, newData });
};

export const ARTICLES_SET_METADATA_TYPE = "ARTICLES_SET_METADATA_TYPE";

const articlesGetMetadataForType = (type) => async (dispath) => {
  const newData = await getUsedMetadataForType(type);

  dispath({
    type: ARTICLES_SET_METADATA_TYPE,
    payload: {
      type,
      newData,
    },
  });
};

export const ARTICLES_SET_PERMISSIONS = "ARTICLES_SET_PERMISSIONS";
export const articlesSetPermissions = () => async (dispatch) => {
  const newData = await getNewsPermissions();
  dispatch({ type: ARTICLES_SET_PERMISSIONS, newData });
};

export const ARTICLES_SET_ISSUERS = "ARTICLES_SET_ISSUERS";
const articlesSetIssuers = async (dispath) => {
  const newData = await getIssuers();
  dispath({ type: ARTICLES_SET_ISSUERS, newData });
};

export const ARTICLES_MANUAL_CREATE_ISSUER = "ARTICLES_MANUAL_CREATE_ISSUER";

export const articlesManualCreateIssuer = (issuer) => {
  return {
    type: ARTICLES_MANUAL_CREATE_ISSUER,
    payload: issuer,
  };
};

export const ARTICLES_MANUAL_UPDATE_ISSUER = "ARTICLES_MANUAL_UPDATE_ISSUER";

export const articlesManualUpdateIssuer = (issuer) => {
  return {
    type: ARTICLES_MANUAL_UPDATE_ISSUER,
    payload: issuer,
  };
};

export const ARTICLES_MANUAL_DELETE_ISSUER = "ARTICLES_MANUAL_DELETE_ISSUER";

export const articlesManualDeleteIssuer = (issuer) => {
  return {
    type: ARTICLES_MANUAL_DELETE_ISSUER,
    payload: issuer,
  };
};

export const ARTICLES_SET_ARTICLE = "ARTICLES_SET_ARTICLE";

export const articlesSetArticle = (newData) => {
  return { type: ARTICLES_SET_ARTICLE, newData: newData };
};

export const ARTICLES_SET_ARTICLE_FIELD = "ARTICLES_SET_ARTICLE_FIELD";

export const articlesSetArticleField = (payload) => {
  return { type: ARTICLES_SET_ARTICLE_FIELD, payload: payload };
};

export const NEWS_CHANGE_PAGE = "NEWS_CHANGE_PAGE";
export const NEWS_CHANGE_PAGE_SUCCESS = "NEWS_CHANGE_PAGE_SUCCESS";

const newsDefaultFilters = {
  published: true,
  type: null,
  territories: [],
  issuers: [],
  topics: [],
  documentTypes: [],
  effectiveDateRange: {},
  freeTextSearch: "",
};

const newsDefaultOrderBy = {
  issueDate: {
    isDescending: true,
  },
};

export const newsChangePage = (pageNumber) => async (dispatch) => {
  dispatch({ type: NEWS_CHANGE_PAGE, newData: { pageNumber } });

  const newData = await fetchNews(
    { filters: newsDefaultFilters, orderBy: newsDefaultOrderBy },
    { pageNumber }
  );

  dispatch({
    type: NEWS_CHANGE_PAGE_SUCCESS,
    newData: {
      totalCount: newData.totalCount,
      items: newData.items,
      hasNext: newData.hasNext,
    },
  });
};

export const ARTICLES_SET_TOPICS = "ARTICLES_SET_TOPICS";

const articlesSetTopics = async (dispath) => {
  const newData = await getTopics();
  dispath({ type: ARTICLES_SET_TOPICS, newData });
};

export const ARTICLES_MANUAL_CREATE_TOPIC = "ARTICLES_MANUAL_CREATE_TOPIC";

export const articlesManualCreateTopic = (topic) => {
  return {
    type: ARTICLES_MANUAL_CREATE_TOPIC,
    payload: topic,
  };
};

export const ARTICLES_MANUAL_UPDATE_TOPIC = "ARTICLES_MANUAL_UPDATE_TOPIC";

export const articlesManualUpdateTopic = (topic) => {
  return {
    type: ARTICLES_MANUAL_UPDATE_TOPIC,
    payload: topic,
  };
};

export const ARTICLES_MANUAL_DELETE_TOPIC = "ARTICLES_MANUAL_DELETE_TOPIC";

export const articlesManualDeleteTopic = (topic) => {
  return {
    type: ARTICLES_MANUAL_DELETE_TOPIC,
    payload: topic,
  };
};

export const ARTICLES_SET_DOCUMENT_TYPES = "ARTICLES_SET_DOCUMENT_TYPES";

const articlesSetDocumentTypes = async (dispath) => {
  const newData = await getDocumentTypes();
  dispath({ type: ARTICLES_SET_DOCUMENT_TYPES, newData });
};

export const ARTICLES_MANUAL_CREATE_DOCUMENT_TYPE =
  "ARTICLES_MANUAL_CREATE_DOCUMENT_TYPE";

export const articlesManualCreateDocumentType = (documenttype) => {
  return {
    type: ARTICLES_MANUAL_CREATE_DOCUMENT_TYPE,
    payload: documenttype,
  };
};

export const ARTICLES_MANUAL_UPDATE_DOCUMENT_TYPE =
  "ARTICLES_MANUAL_UPDATE_DOCUMENT_TYPE";

export const articlesManualUpdateDocumentType = (documenttype) => {
  return {
    type: ARTICLES_MANUAL_UPDATE_DOCUMENT_TYPE,
    payload: documenttype,
  };
};

export const ARTICLES_MANUAL_DELETE_DOCUMENT_TYPE =
  "ARTICLES_MANUAL_DELETE_DOCUMENT_TYPE";

export const articlesManualDeleteDocumentType = (documenttype) => {
  return {
    type: ARTICLES_MANUAL_DELETE_DOCUMENT_TYPE,
    payload: documenttype,
  };
};

export const CALENDAR_SET_FOCUS_DATE = "CALENDAR_SET_FOCUS_DATE";

export const calendarSetFocusDate = (date) => {
  return {
    type: CALENDAR_SET_FOCUS_DATE,
    payload: date,
  };
};
