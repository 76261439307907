const OID_CONFIGURATION = {
  client_id: "spa",
  redirect_uri: `${window.location.origin}${process.env.REACT_APP_OID_REDIRECT_URI_SUFFIX}`,
  response_type: "id_token token",
  post_logout_redirect_uri: `${window.location.origin}${process.env.REACT_APP_OID_POST_LOGOUT_REDIRECT_URI_SUFFIX}`,
  scope: "openid offline_access",
  authority: process.env.REACT_APP_OID_AUTHORITY,
  silent_redirect_uri: `${window.location.origin}${process.env.REACT_APP_OID_SILENT_REDIRECT_URI_SUFFIX}`,
  automaticSilentRenew: true,
  loadUserInfo: true,
  ...(process.env.REACT_APP_AUTOMATIC_SSO_REDIRECT === "true" && {
    acr_values: "idp:openAm",
  }),
};

export const DEFAULT_OIDC_KEY = `oidc.user:${OID_CONFIGURATION.authority}:${OID_CONFIGURATION.client_id}`;

export default OID_CONFIGURATION;
