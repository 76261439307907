import { UseTypedQueryOptions } from "api/UseTypedQueryOptions";
import { UseQueryResult, useQuery } from "react-query";

import { ServiceGroupReadListDTO } from "../__generated__/CatalogueAPI";
import { getServiceGroups } from "../catalogApi";

interface IUseServiceGroupsProps {
  queryOptions?: UseTypedQueryOptions<ServiceGroupReadListDTO[]>;
}

export default function useServiceGroups(
  props?: IUseServiceGroupsProps
): UseQueryResult<ServiceGroupReadListDTO[]> {
  return useQuery(
    "serviceGroups",
    () => getServiceGroups(),
    props?.queryOptions
  );
}
