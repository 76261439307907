export const byId = (array, property = "id") => {
  return array.reduce((acc, item) => {
    acc[item[property]] = item;
    return acc;
  }, {});
};

export const recursiveById = (
  array,
  property = "id",
  children = "children"
) => {
  const rec = (acc, array) =>
    array.reduce((acc, item) => {
      acc[item[property]] = item;
      item[children] && rec(acc, item[children]);
      return acc;
    }, acc);

  return rec({}, array);
};
