import { pollData, pollerCheck404, pollerCheckUndefined } from "api/apiPoller";
import {
  IndustryCreateDTO,
  IndustryReadDTO,
  IndustryUpdateDTO,
  LifecycleCreateDTO,
  LifecycleReadDTO,
  LifecycleUpdateDTO,
  PageFieldsUpdateDTO,
  PageOptionsUpdateDTO,
  PageReadDTO,
  ServiceGroupCreateDTO,
  ServiceGroupReadDTO,
  ServiceGroupUpdateDTO,
  ServiceReadDTO,
  SubIndustryCreateDTO,
  SubIndustryReadDTO,
  SubIndustryUpdateDTO,
  ValueChainCreateDTO,
  ValueChainReadDTO,
  ValueChainUpdateDTO,
} from "api/catalog/__generated__/CatalogueAPI";
import { isEqual } from "lodash";
import { localeCompare } from "utils/localeCompare";

import {
  addIndustry,
  addLifeCycle,
  addServiceGroup,
  addSubIndustry,
  addTerritory,
  addValueChain,
  createService,
  deleteIndustry,
  deleteLifeCycle,
  deleteServiceGroup,
  deleteSubIndustry,
  deleteTerritory,
  deleteValueChain,
  editIndustry,
  editLifeCycle,
  editLifeCycleOrder,
  editServiceGroup,
  editSubIndustry,
  editTerritory,
  editValueChain,
  editValueChainOrder,
  getCatalogueHomepageDetails,
  getIndustryById,
  getLifeCycleById,
  getServiceDetails,
  getServiceGroupById,
  getSubIndustryById,
  getTerritoryById,
  getValueChainById,
  removeService,
  updateCatalogueHomepageDetails,
  updateCatalogueOptions,
  updateDisplayStatus,
  updateIsHighlighted,
  updateIsNew,
  updateService,
} from "./catalogApi";
import { ServiceCreateDTO, ServiceUpdateDTO } from "./type";

export async function pollCreateService(
  service: ServiceCreateDTO
): Promise<ServiceReadDTO> {
  const addedService = await createService(service);

  return await pollData(
    () => getServiceDetails(addedService.id),
    pollerCheck404,
    0,
    addedService
  );
}

export async function pollUpdateService(
  service: ServiceUpdateDTO,
  id: string
): Promise<ServiceReadDTO> {
  await updateService(service, id);

  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const serviceDetails = await getServiceDetails(id);
        return compare(serviceDetails, service)
          ? resolve(serviceDetails)
          : reject();
      }),
    pollerCheckUndefined,
    0,
    service
  );
}

export async function pollUpdateIndustry(
  id: string,
  industry: IndustryUpdateDTO
): Promise<IndustryReadDTO> {
  await editIndustry(id, industry);

  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const industryDetails = await getIndustryById(id);
        return compareFilter(industryDetails, industry)
          ? resolve(industryDetails)
          : reject();
      }),
    pollerCheckUndefined,
    0,
    industry
  );
}

export async function pollAddIndustry(
  industry: IndustryCreateDTO
): Promise<ServiceReadDTO> {
  const addedIndustry = await addIndustry(industry);

  return await pollData(
    () => getIndustryById(addedIndustry.id),
    pollerCheck404,
    0,
    addedIndustry
  );
}

export async function pollAddLifeCycle(
  lifecycle: LifecycleCreateDTO
): Promise<LifecycleReadDTO> {
  const addedLifeCycle = await addLifeCycle(lifecycle);

  return await pollData(
    () => getLifeCycleById(addedLifeCycle.id),
    pollerCheck404,
    0,
    addedLifeCycle
  );
}

export async function pollRemoveLifeCycle(id: string): Promise<void> {
  await deleteLifeCycle(id);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getLifeCycleById(id)
          .then(reject)
          .catch((error) => {
            if (pollerCheck404(error)) {
              return resolve(undefined);
            }
            return reject(error);
          });
      }),
    () => true
  );
}

export async function pollUpdateLifeCycle(
  id: string,
  lifeCycle: LifecycleUpdateDTO
): Promise<LifecycleReadDTO> {
  await editLifeCycle(id, lifeCycle);

  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const lifeCycleDetails = await getLifeCycleById(id);
        return compareFilter(lifeCycleDetails, lifeCycle)
          ? resolve(lifeCycleDetails)
          : reject();
      }),
    pollerCheckUndefined,
    0,
    lifeCycle
  );
}

export async function pollUpdateLifeCycleOrder(
  id: string,
  order: number
): Promise<number> {
  await editLifeCycleOrder(id, order);

  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const lifeCycleDetails = await getLifeCycleById(id);
        return order === lifeCycleDetails.order ? resolve(order) : reject();
      }),
    pollerCheckUndefined,
    0,
    order
  );
}

export async function pollAddValueChain(
  valueChain: ValueChainCreateDTO
): Promise<ValueChainReadDTO> {
  const addedValueChain = await addValueChain(valueChain);

  return await pollData(
    () => getValueChainById(addedValueChain.id),
    pollerCheck404,
    0,
    addedValueChain
  );
}

export async function pollRemoveValueChain(id: string): Promise<void> {
  await deleteValueChain(id);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getValueChainById(id)
          .then(reject)
          .catch((error) => {
            if (pollerCheck404(error)) {
              return resolve(undefined);
            }
            return reject(error);
          });
      }),
    () => true
  );
}

export async function pollUpdateValueChain(
  id: string,
  valueChain: ValueChainUpdateDTO
): Promise<ValueChainReadDTO> {
  await editValueChain(id, valueChain);

  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const valueChainDetails = await getValueChainById(id);
        return compareFilter(valueChainDetails, valueChain)
          ? resolve(valueChainDetails)
          : reject();
      }),
    pollerCheckUndefined,
    0,
    valueChain
  );
}

export async function pollUpdateValueChainOrder(
  id: string,
  order: number
): Promise<number> {
  await editValueChainOrder(id, order);

  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const valueChainDetails = await getValueChainById(id);
        return valueChainDetails.order === order ? resolve(order) : reject();
      }),
    pollerCheckUndefined,
    0,
    order
  );
}

export async function pollUpdateSubIndustry(
  parentId: string,
  id: string,
  industry: SubIndustryUpdateDTO
): Promise<SubIndustryReadDTO> {
  await editSubIndustry(parentId, id, industry);

  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const industryDetails = await getSubIndustryById(parentId, id);
        return compareFilter(industryDetails, industry)
          ? resolve(industryDetails)
          : reject();
      }),
    pollerCheckUndefined,
    0,
    industry
  );
}

export async function pollAddSubIndustry(
  parentId: string,
  industry: SubIndustryCreateDTO
): Promise<ServiceReadDTO> {
  const addedIndustry = await addSubIndustry(parentId, industry);

  return await pollData(
    () => getSubIndustryById(parentId, addedIndustry.id),
    pollerCheck404,
    0,
    addedIndustry
  );
}

export async function pollRemoveIndustry(id: string): Promise<void> {
  await deleteIndustry(id);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getIndustryById(id)
          .then(reject)
          .catch((error) => {
            if (pollerCheck404(error)) {
              return resolve(undefined);
            }
            return reject(error);
          });
      }),
    () => true
  );
}

export async function pollRemoveSubIndustry(
  parentId: string,
  id: string
): Promise<void> {
  await deleteSubIndustry(parentId, id);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getSubIndustryById(parentId, id)
          .then(reject)
          .catch((error) => {
            if (pollerCheck404(error)) {
              return resolve(undefined);
            }
            return reject(error);
          });
      }),
    () => true
  );
}

export async function pollRemoveService(id: string): Promise<void> {
  await removeService(id);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getServiceDetails(id)
          .then(reject)
          .catch((error) => {
            if (pollerCheck404(error)) {
              return resolve(undefined);
            }
            return reject(error);
          });
      }),
    () => true
  );
}

export async function pollUpdateDisplayStatus(
  serviceId: string,
  live: boolean
): Promise<ServiceReadDTO> {
  await updateDisplayStatus(serviceId, live);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getServiceDetails(serviceId).then((serviceDetails) =>
          serviceDetails.live === live ? resolve(undefined) : reject()
        );
      }),
    pollerCheckUndefined
  );
}

export async function pollUpdateIsNew(
  serviceId: string,
  isNew: boolean
): Promise<ServiceReadDTO> {
  await updateIsNew(serviceId, isNew);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getServiceDetails(serviceId).then((serviceDetails) =>
          serviceDetails.isNew === isNew ? resolve(undefined) : reject()
        );
      }),
    pollerCheckUndefined
  );
}

export async function pollAddServiceGroup(serviceGroup: ServiceGroupCreateDTO) {
  const addedServiceGroup = await addServiceGroup(serviceGroup);
  return await pollData(
    () => getServiceGroupById(addedServiceGroup.id),
    pollerCheck404,
    0,
    addedServiceGroup
  );
}

export async function pollEditServiceGroup(
  id: string,
  serviceGroup: ServiceGroupUpdateDTO
) {
  await editServiceGroup(id, serviceGroup);
  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const serviceGroupDetails = await getServiceGroupById(id);
        return compareServiceGroup(serviceGroup, serviceGroupDetails)
          ? resolve(serviceGroupDetails)
          : reject();
      }),
    pollerCheckUndefined,
    0,
    serviceGroup
  );
}

export async function pollDeleteServiceGroup(id: string) {
  await deleteServiceGroup(id);
  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getServiceGroupById(id)
          .then(reject)
          .catch((error) => {
            if (pollerCheck404(error)) {
              return resolve(undefined);
            }
            return reject(error);
          });
      }),
    () => true
  );
}

export async function pollAddTerritory(territory: any) {
  const addedServiceGroup = await addTerritory(territory);
  return await pollData(
    () => getTerritoryById(addedServiceGroup.id),
    pollerCheck404,
    0,
    addedServiceGroup
  );
}

export async function pollEditTerritory(id: string, territory: any) {
  await editTerritory(id, territory);
  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const territoryDetails = await getTerritoryById(id);
        return territoryDetails.name !== territory.name
          ? reject()
          : resolve(territoryDetails);
      }),
    pollerCheckUndefined,
    0,
    territory
  );
}

export async function pollDeleteTerritory(id: string) {
  await deleteTerritory(id);
  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getTerritoryById(id)
          .then(reject)
          .catch((error) => {
            if (pollerCheck404(error)) {
              return resolve(undefined);
            }
            return reject(error);
          });
      }),
    () => true
  );
}

export async function pollUpdateIsHighlighted(
  serviceId: string,
  isHighlighted: boolean
): Promise<ServiceReadDTO> {
  await updateIsHighlighted(serviceId, isHighlighted);

  return pollData(
    () =>
      new Promise((resolve, reject) => {
        getServiceDetails(serviceId).then((serviceDetails) =>
          serviceDetails.isHighlighted === isHighlighted
            ? resolve(undefined)
            : reject()
        );
      }),
    pollerCheckUndefined
  );
}

function compare(service: ServiceReadDTO, modified: ServiceUpdateDTO) {
  return (
    JSON.stringify([
      service.applications?.sort(localeCompare("name")).map((app) => ({
        name: app.name,
        link: app.link,
        ppid: app.itContact?.ppid || null,
      })) ?? [],
      service.description?.replace(/[^a-zA-Z0-9]/g, ""),
      service.live,
      service.name,
      service.responsibles?.map((p) => p.ppid).sort() ?? [],
      service.team?.map((p) => p.ppid).sort() ?? [],
      service.videoLink,
      service.itContact?.ppid || null,
      service.organizations && service.organizations.length > 0
        ? service.organizations.sort((a, b) => a.localeCompare(b))
        : [],
      service.isNew,
      service.isPSA,
      service.isPSF,
      service.isHighlighted,
      service.smallDescription?.replace(/[^a-zA-Z0-9]/g, ""),
      service.valueChains,
      service.lifeCycles,
      service.relatedServices?.map((service) => service.id).sort() ?? [],
      service.mainAddedValue,
      service.secondaryAddedValue,
      service.geographicalInformation,
      service.targetProfile,
      service.availableApplication,
      service.deliverable,
      service.industries?.sort((a, b) => a.localeCompare(b)) ?? [],
      service.serviceGroupId,
      service.viewers?.map((viewer) => viewer.ppid).sort() ?? [],
      service.ukViewers?.map((viewer) => viewer.ppid).sort() ?? [],
      service.frViewers?.map((viewer) => viewer.ppid).sort() ?? [],
    ]) ===
    JSON.stringify([
      modified.Applications?.sort(localeCompare("name")).map((app) => ({
        name: app.name,
        link: app.link,
        ppid: app.itContactPpid,
      })) ?? [],
      modified.Description?.replace(/[^a-zA-Z0-9]/g, "") ?? null,
      modified.Live ?? false,
      modified.Name ?? null,
      modified.ResponsiblesPpids?.sort() ?? [],
      modified.TeamPpids?.sort() ?? [],
      modified.VideoLink ?? null,
      modified.ItContactPpid || null,
      modified.Organizations && modified.Organizations.length > 0
        ? modified.Organizations.sort((a, b) => a.localeCompare(b))
        : [],
      modified.IsNew ?? false,
      modified.IsPSA ?? false,
      modified.IsPSF ?? false,
      modified.IsHighlighted ?? false,
      modified.SmallDescription?.replace(/[^a-zA-Z0-9]/g, "") ?? null,
      modified.ValueChains ?? null,
      modified.LifeCycles ?? null,
      modified.RelatedServices?.sort() ?? [],
      modified.MainAddedValue ?? null,
      modified.SecondaryAddedValue ?? null,
      modified.GeographicalInformation ?? null,
      modified.TargetProfile ?? null,
      modified.AvailableApplication ?? null,
      modified.Deliverable ?? null,
      modified.Industries?.sort((a, b) => a.localeCompare(b)) ?? [],
      modified.ServiceGroupId,
      modified.ViewersPpids?.sort() ?? [],
      modified.UkViewersPpids?.sort() ?? [],
      modified.FrViewersPpids?.sort() ?? [],
    ])
  );
}

function compareFilter(
  industry: { name: string | null },
  modified: { name: string | null }
) {
  return industry.name === modified.name;
}

function compareServiceGroup(
  serviceGroup1: ServiceGroupUpdateDTO,
  serviceGroup2: ServiceGroupReadDTO
) {
  return isEqual(
    [
      serviceGroup1.name,
      serviceGroup1.smallDescription,
      serviceGroup1.lifeCycles,
      serviceGroup1.valueChains,
      (serviceGroup1.services ?? []).sort(),
    ],
    [
      serviceGroup2.name,
      serviceGroup2.smallDescription,
      serviceGroup2.lifeCycles,
      serviceGroup2.valueChains,
      (serviceGroup2.services ?? []).map((service) => service.id).sort(),
    ]
  );
}

export async function pollUpdateHomepage(
  modified: PageFieldsUpdateDTO
): Promise<PageReadDTO> {
  await updateCatalogueHomepageDetails(modified);

  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const homepage = await getCatalogueHomepageDetails();
        return compareHomepage(homepage, modified)
          ? resolve(homepage)
          : reject();
      }),
    pollerCheckUndefined,
    0,
    modified
  );
}

export async function pollUpdateHomepageFilterFields(
  modified: PageOptionsUpdateDTO
): Promise<PageReadDTO> {
  await updateCatalogueOptions(modified);

  return await pollData(
    () =>
      new Promise(async (resolve, reject) => {
        const homepage = await getCatalogueHomepageDetails();
        return compareHomepageFilterFields(homepage, modified)
          ? resolve(homepage)
          : reject();
      }),
    pollerCheckUndefined,
    0,
    modified
  );
}

function compareHomepage(homepage: PageReadDTO, modified: PageFieldsUpdateDTO) {
  return isEqual(
    [
      homepage.title,
      homepage.subtitle,
      homepage.description,
      homepage.value1Title,
      homepage.value1Description,
      homepage.value2Title,
      homepage.value2Description,
      homepage.contacts?.map((contact) => contact.ppid).sort(),
    ],
    [
      modified.title,
      modified.subtitle,
      modified.description,
      modified.value1Title,
      modified.value1Description,
      modified.value2Title,
      modified.value2Description,
      modified.contacts?.sort(),
    ]
  );
}

function compareHomepageFilterFields(
  homepage: PageReadDTO,
  modified: PageOptionsUpdateDTO
) {
  return isEqual(
    [homepage.mainFilter, homepage.secondaryFilter, homepage.tertiaryFilter],
    [modified.mainFilter, modified.secondaryFilter, modified.tertiaryFilter]
  );
}
