export const FREQUENCIES = {
  // DAILY: 1,
  WEEKLY: 2,
  // MONTHLY: 3,
};

export const FRENQUENCIES_LABEL = {
  // [FREQUENCIES.DAILY]: "Daily",
  [FREQUENCIES.WEEKLY]: "Weekly",
  // [FREQUENCIES.MONTHLY]: "Monthly",
};
