import { useCallback, useReducer } from "react";

const SORT_CHANGE = "SORT_CHANGE";
const PAGE_NUMBER_CHANGE = "PAGE_NUMBER_CHANGE";
const PAGE_SIZE_CHANGE = "PAGE_SIZE_CHANGE";

function reducer(state, action) {
  switch (action.type) {
    case SORT_CHANGE:
      return {
        ...state,
        sorted: action.payload,
      };
    case PAGE_NUMBER_CHANGE:
      return {
        ...state,
        page: action.payload,
      };
    case PAGE_SIZE_CHANGE:
      return {
        ...state,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
      };
    default:
      return state;
  }
}

const pageChange = (page) => {
  return {
    type: PAGE_NUMBER_CHANGE,
    payload: page,
  };
};

const pageSizeChange = (pageSize, page) => {
  return {
    type: PAGE_SIZE_CHANGE,
    payload: { pageSize, page },
  };
};

const sortChange = (sort) => {
  return {
    type: SORT_CHANGE,
    payload: sort,
  };
};

function useTableReducer(sorted = [], page = 0, pageSize = 20) {
  const [state, dispatch] = useReducer(reducer, {
    sorted,
    page,
    pageSize,
  });

  const onPageSizeChange = useCallback(
    (pageSize, page) => dispatch(pageSizeChange(pageSize, page)),
    []
  );
  const onPageChange = useCallback((page) => dispatch(pageChange(page)), []);
  const onSortedChange = useCallback((sort) => dispatch(sortChange(sort)), []);

  return {
    onPageSizeChange,
    onPageChange,
    onSortedChange,
    state,
  };
}

export default useTableReducer;
