import "./index.scss";

import classNames from "classnames";
import Table from "components/Table";
import React, { useContext, useMemo, useState } from "react";
import { atMidnight, formatDate } from "utils/date";

import UserEngagementTable from "../../UserTable";
import { DEFAULT_SORT } from "../hooks/useSubscriptions";
import EditList from "./MenuList";
import { AdminEngagementContext } from "..";

const TABLE_COLUMNS = [
  {
    Header: "Service Name",
    id: "service",
    accessor: "serviceName",
    filterable: true,
    Filter: () => null,
  },
  {
    Header: "Company group name",
    id: "company",
    accessor: "companyName",
    filterable: true,
    Filter: () => null,
  },
  {
    Header: "Company name",
    id: "entity",
    accessor: "entityName",
    filterable: true,
    Filter: () => null,
  },

  {
    Header: "# users",
    id: "users",
    accessor: "users",
    filterable: false,
    sortable: false,
    Filter: () => null,
    Cell: ({ value }: { value: any[] }) => value.length,
    width: 90,
  },
];

export const COLUMNS = [
  ...TABLE_COLUMNS,
  {
    Header: "Member",
    id: "name",
    accessor: "name",
    filterable: true,
    Filter: () => null,
  },
];

interface Props {
  onEditSubscription: (subscription: any) => void;
  onNewsAccessRight: (subscription: any) => void;
  onDeleteSubscription: (subscription: any) => void;
  onAddUser: (subscription: any) => void;
  onDeleteUser: (subscription: any) => void;
  onDisplayDetails: (subscription: any) => void;
  onMainContactChanged: (
    subscriptionId: string,
    userPpid: string,
    isMainContact: boolean
  ) => void;
}

export default function SubscriptionTable({
  onEditSubscription,
  onNewsAccessRight,
  onDeleteSubscription,
  onAddUser,
  onDeleteUser,
  onDisplayDetails,
  onMainContactChanged,
}: Props) {
  const { data } = useContext(AdminEngagementContext);

  const {
    loading,
    items: subscriptions,
    fetchData,
    optimisticChange,
    ...tableReducer
  } = data;

  const [expanded, setExpanded] = useState([]);

  const columns = useMemo(() => {
    let cols = [
      ...TABLE_COLUMNS,
      {
        Header: "End date",
        sortable: false,
        accessor: "endDate",
        width: 100,
        Cell: ({ value }: { value: string }) => {
          return value ? (
            <div
              className={classNames(
                new Date(value).getTime() < atMidnight(new Date()).getTime() &&
                  "expired-date"
              )}
            >
              {formatDate(value)}
            </div>
          ) : null;
        },
      },
      {
        className: "overflow-td",
        Header: "Actions",
        id: "actions",
        sortable: false,
        filterable: false,
        width: 80,
        Cell: ({ original }: any) => {
          return (
            <EditList
              subscription={original}
              onAddUser={onAddUser}
              onDeleteSubscription={onDeleteSubscription}
              onEditSubscription={onEditSubscription}
              onNewsAccessRight={onNewsAccessRight}
              onDisplayDetails={onDisplayDetails}
            />
          );
        },
      },
    ];

    return cols;
  }, [
    onAddUser,
    onDeleteSubscription,
    onEditSubscription,
    onNewsAccessRight,
    onDisplayDetails,
  ]);

  return (
    <Table
      className="subscriptions-table"
      manual
      columns={columns}
      data={subscriptions || []}
      loading={loading}
      defaultSorted={DEFAULT_SORT}
      expanded={expanded}
      onExpandedChange={setExpanded}
      SubComponent={({ original }: any) => {
        return (
          <UserEngagementTable
            data={original.users}
            parentId={original.id}
            onDeleteUser={onDeleteUser}
            onMainContactChanged={onMainContactChanged}
          />
        );
      }}
      {...tableReducer}
    />
  );
}
