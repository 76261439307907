import { getFile } from "api/data";
import { getPublication } from "api/news/publicationsApi";
import { useEffect } from "react";
import { downloadFromResponse } from "utils";

const Downloader = ({ publicationId }) => {
  useEffect(() => {
    if (publicationId) {
      getPublication(publicationId).then((publication) => {
        return getFile(publication.fileUrl).then((data) => {
          downloadFromResponse(data, publication.title);
        });
      });
    }
  }, [publicationId]);

  return null;
};

export default Downloader;
