import useServiceGroups from "api/catalog/queries/useServiceGroups";
import { SelectOption } from "appkit-react";
import Select from "components/Select";
import { localeCompare } from "utils/localeCompare";

interface IServiceGroupSelect {
  value: string | null;
  onValueChange: (value: string) => void;
}

const ServiceGroupSelect = ({
  value,
  onValueChange,
  ...selectProps
}: IServiceGroupSelect) => {
  const { data: serviceGroups = [] } = useServiceGroups();

  return (
    <Select
      placeholder="Select a service group"
      showSearchOnToggle
      value={value}
      onSelect={onValueChange}
      {...selectProps}
    >
      {serviceGroups?.sort(localeCompare("name")).map((service) => (
        <SelectOption value={service.id} key={service.id}>
          {service.name}
        </SelectOption>
      ))}
    </Select>
  );
};

export default ServiceGroupSelect;
