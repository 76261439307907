import { UseTypedQueryOptions } from "api/UseTypedQueryOptions";
import { UseQueryResult, useQuery } from "react-query";

import {
  IndustryAllReadDTO,
  IndustryReadDTO,
} from "../__generated__/CatalogueAPI";
import { getIndustries, getIndustriesAll } from "../catalogApi";

export default function useIndustries(
  props?: UseTypedQueryOptions<IndustryReadDTO[]>
): UseQueryResult<IndustryReadDTO[]> {
  return useQuery("industries", () => getIndustries(), props);
}

export function useIndustriesAll(
  props?: UseTypedQueryOptions<IndustryAllReadDTO>
): UseQueryResult<IndustryAllReadDTO> {
  return useQuery("industries-all", () => getIndustriesAll(), props);
}
