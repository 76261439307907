import moment from "moment";

const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
const DEFAULT_DATETIME_FORMAT = "DD/MM/YYYY HH:mm";

export const formatDate = (date, pattern = DEFAULT_DATE_FORMAT) =>
  moment(date).format(pattern);

export const formatDateTime = (date, pattern = DEFAULT_DATETIME_FORMAT) =>
  formatDate(date, pattern);

export const computeCurrentMonth = () => {
  const today = new Date();
  today.setDate(1);
  today.setUTCHours(0, 0, 0, 0);
  return today;
};

export const getEntireMonthRange = (date, numberOfMonths = 1) => {
  const start = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
  const end = new Date(
    date.getFullYear(),
    date.getMonth() + numberOfMonths,
    0,
    23,
    59,
    59,
    999
  );
  return { start, end };
};

export const getEntireYear = (year) => {
  const start = new Date(`${year}-01-01T00:00:00.000Z`);
  const end = new Date(`${year}-12-31T23:59:59.999Z`);
  return { start, end };
};

export const atMidnight = (date) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
};

export const toISODateStringIgnoreTime = (date) => {
  return `${date.getFullYear().toString().padStart(2, "0")}-${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}T00:00:00.000Z`;
};
