import "./index.scss";

import { PopBanner } from "appkit-react";
import { useEffect, useRef, useState } from "react";

import { trimMime as applyTrimMime } from "../../utils/base64image";
import { formatBytes } from "../../utils/index";
import ImageCropperModal from "./ImageCropperModal";

let timeout = false;

const availableMIMETypes = ["image/bmp", "image/jpeg", "image/png"];
const INVALID_IMAGE_ERROR =
  "The selected file doesn't seem to be a valid image.";

const isValidImage = (imageSrc) => {
  return new Promise((resolve) => {
    var image = new Image();
    image.onload = function () {
      resolve(true);
    };
    image.onerror = function () {
      resolve(false);
    };
    image.src = imageSrc;
  });
};

const defaultInvalidImageAction = (message) => {
  PopBanner({
    content: <span>{message}</span>,
    duration: 4000,
    status: "warning",
  });
};

const defaultSizeExceededAction = (size, maxSize) => {
  PopBanner({
    content: (
      <span>{`Your file is too heavy. Please submit a file smaller than ${formatBytes(
        maxSize
      )} (Your file was ${formatBytes(size)})`}</span>
    ),
    duration: 4000,
    status: "warning",
  });
};

const ImageUploadToBase64 = ({
  onChange,
  onError = defaultInvalidImageAction,
  trimMime = false,
  maxSize = null,
  onSizeExceeded = defaultSizeExceededAction,
  openCropAfterSelect = false,
  cropRatio = null,
  onCropOpened = () => {},
  onCropCanceled = () => {},
}) => {
  const inputRef = useRef();

  const [isDragging, setIsDragging] = useState(false);
  const [isCropping, setIsCropping] = useState(null);

  const addDragListener = () => {
    window.addEventListener("dragover", onDragOver);
  };

  const addDragEndListener = () => {
    window.addEventListener("dragleave", onDragOut);
    window.addEventListener("drop", onDragOut);
  };

  const removeDragListener = () => {
    window.removeEventListener("dragover", onDragOver);
  };

  const removeDragEndListener = () => {
    window.removeEventListener("dragleave", onDragOut);
    window.removeEventListener("drop", onDragOut);
  };

  const onDragOver = () => {
    timeout = true;
    setIsDragging(true);
    removeDragListener();
    addDragEndListener();
  };

  const onDragOut = () => {
    timeout = false;
    setTimeout(() => {
      if (!timeout) {
        setIsDragging(false);
      }
    }, 50);
    removeDragEndListener();
    addDragListener();
  };

  useEffect(() => {
    addDragListener();
    // eslint-disable-next-line
  }, []);

  const onBase64Change = (newValue) => {
    if (trimMime) {
      newValue = applyTrimMime(newValue);
    }
    if (openCropAfterSelect) {
      setIsCropping(newValue);
      onCropOpened();
    } else {
      onChange(newValue);
    }
  };

  const onChangeInput = (event) => {
    if (event.target && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (maxSize !== null && file.size > maxSize) {
        onSizeExceeded(file.size, maxSize);
        inputRef.current.value = null;
        return;
      }
      if (!availableMIMETypes.includes(file.type)) {
        onError(INVALID_IMAGE_ERROR);
        return;
      }

      const reader = new FileReader();

      const eventFunction = () => {
        isValidImage(reader.result).then((result) => {
          if (!result) {
            onError(INVALID_IMAGE_ERROR);
            inputRef.current.value = null;
            return;
          }
          onBase64Change(reader.result);
          reader.removeEventListener("load", eventFunction);
        });
      };

      reader.addEventListener("load", eventFunction, false);

      reader.readAsDataURL(file);
      inputRef.current.value = null;
    }
  };

  return (
    <>
      {openCropAfterSelect && (
        <ImageCropperModal
          visible={isCropping !== null}
          image={isCropping}
          onCancel={() => {
            setIsCropping(null);
            onCropCanceled();
          }}
          onValidate={(newImage) => {
            setIsCropping(null);
            onChange(newImage);
          }}
          aspect={cropRatio}
        />
      )}

      <label className="image-upload-base64-label">
        <input
          ref={inputRef}
          className="image-upload-base64-input"
          type="file"
          accept="image/*"
          onChange={onChangeInput}
        />
        {isDragging ? <div className="on-drag">Drop file here</div> : null}
      </label>
    </>
  );
};

export default ImageUploadToBase64;
