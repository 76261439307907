export const trimMime = (b64) => {
  let splitedValue = b64.split(",");
  if (splitedValue.length > 1) {
    return splitedValue[1];
  }
  return b64;
};

export const getBase64ImageWeight = (b64) => {
  let result = trimMime(b64);
  let trailingEquals = 0;
  for (trailingEquals; trailingEquals < result.length; trailingEquals++) {
    if (result[result.length - 1 - trailingEquals] !== "=") {
      break;
    }
  }
  return result.length * (3 / 4) - trailingEquals;
};
