import {
  apiFetchFormDataCustom,
  appendIEHeaders,
  appendTokenHeaders,
} from "api/api";
import {
  Api,
  CaptchaReadDTO,
  IndustryCreateDTO,
  IndustryReadDTO,
  IndustryUpdateDTO,
  LifecycleCreateDTO,
  LifecycleReadDTO,
  LifecycleUpdateDTO,
  PageFieldsUpdateDTO,
  PageOptionsUpdateDTO,
  PageReadDTO,
  ServiceContactDTO,
  ServiceGroupCreateDTO,
  ServiceGroupReadDTO,
  ServiceGroupReadListDTO,
  ServiceGroupUpdateDTO,
  ServiceReadDTO,
  SubIndustryCreateDTO,
  SubIndustryReadDTO,
  SubIndustryUpdateDTO,
  ValueChainCreateDTO,
  ValueChainReadDTO,
  ValueChainUpdateDTO,
} from "api/catalog/__generated__/CatalogueAPI";
import { HttpResponse } from "api/notification/__generated__/NotificationAPI";

import { ServiceCreateDTO } from "./type";

const PREFIX = process.env.REACT_APP_URL_CATALOG;

let _clientSingleton: Api<any> | null = null;
const client = (): Api<any> => {
  if (!_clientSingleton) {
    _clientSingleton = new Api({
      baseUrl: PREFIX,
      baseApiParams: {
        headers: {
          Accept: "application/json",
          ...appendTokenHeaders(),
          ...appendIEHeaders(),
        },
      },
    });
  }
  return _clientSingleton;
};

function toData<T>(res: { data: T }) {
  return res.data;
}

export function getServiceDetails(serviceId: string): Promise<ServiceReadDTO> {
  if (
    serviceId.match(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    ) !== null
  ) {
    return client().api.v1ServiceDetail(serviceId).then(toData);
  }
  return client().api.v1ServiceDetailFriendly(serviceId).then(toData);
}

export function createService(
  service: ServiceCreateDTO
): Promise<ServiceReadDTO> {
  // we don't use the generated api file here because FormData serialization for Array is incompatible with our .Net backend
  return apiFetchFormDataCustom(
    `${PREFIX}/api/v1/Service`,
    service,
    "POST"
  ) as Promise<ServiceReadDTO>;
  // return client().api.v1ServiceCreate(service).then(toData);
}

export function updateService(
  service: ServiceCreateDTO,
  id: string
): Promise<void> {
  // we don't use the generated api file here because FormData serialization for Array is incompatible with our .Net backend
  return apiFetchFormDataCustom(
    `${PREFIX}/api/v1/Service/${id}`,
    service,
    "PUT"
  ).then(toData) as Promise<void>;
  // return client().api.v1ServiceUpdate(id, service).then(toData);
}

export function removeService(id: string): Promise<void> {
  return client().api.v1ServiceDelete(id).then(toData);
}

export function updateDisplayStatus(id: string, live: boolean): Promise<void> {
  return client().api.v1ServiceLivePartialUpdate(id, { live }).then(toData);
}

export function updateIsNew(id: string, isNew: boolean): Promise<void> {
  return client().api.v1ServiceNewPartialUpdate(id, { isNew }).then(toData);
}

export function updateIsHighlighted(
  id: string,
  isHighlighted: boolean
): Promise<void> {
  return client()
    .api.v1ServiceHighlightedPartialUpdate(id, { isHighlighted })
    .then(toData);
}

export function getCatalogue(): Promise<ServiceReadDTO[]> {
  return client().api.v1ServiceLiveList().then(toData);
}

export function getCatalogueServiceDetails(
  serviceId: string
): Promise<ServiceReadDTO> {
  return getServiceDetails(serviceId); // TODO discuss with back-end : should we accept another DTO for client-view ?
}

export function getCatalogueHomepageDetails(): Promise<PageReadDTO> {
  return client().api.v1PageList().then(toData);
}

export function updateCatalogueHomepageDetails(
  homepage: PageFieldsUpdateDTO
): Promise<void> {
  return client().api.v1PageFieldsUpdate(homepage).then(toData);
}

export function updateCatalogueOptions(
  homepage: PageOptionsUpdateDTO
): Promise<void> {
  return client().api.v1PageOptionsUpdate(homepage).then(toData);
}

export function getCaptcha(serviceId: string): Promise<CaptchaReadDTO> {
  return client().api.v1CaptchaCreate({ resourceId: serviceId }).then(toData);
}

export function sendContactForm(
  serviceId: string,
  form: ServiceContactDTO
): Promise<HttpResponse<void, any>> {
  return client().api.v1ServiceContactCreate(serviceId, form);
}

export function getLifeCycles() {
  return client().api.v1LifecycleList().then(toData);
}
export function getLifeCyclesAll() {
  return client().api.v1LifecycleAllList().then(toData);
}
export function addLifeCycle(
  lifeCycle: LifecycleCreateDTO
): Promise<LifecycleReadDTO> {
  return client().api.v1LifecycleCreate(lifeCycle).then(toData);
}

export function deleteLifeCycle(id: string) {
  return client().api.v1LifecycleDelete(id);
}

export function editLifeCycle(id: string, lifeCycle: LifecycleUpdateDTO) {
  return client().api.v1LifecycleUpdate(id, lifeCycle);
}

export function editLifeCycleOrder(id: string, order: number) {
  return client().api.v1LifecycleOrderUpdate(id, order);
}

export function getLifeCycleById(id: string): Promise<LifecycleReadDTO> {
  return client().api.v1LifecycleDetail(id).then(toData);
}

export function getIndustries() {
  return client().api.v1IndustryList().then(toData);
}

export function getIndustriesAll() {
  return client().api.v1IndustryAllList().then(toData);
}

export function getIndustryById(id: string): Promise<IndustryReadDTO> {
  return client().api.v1IndustryDetail(id).then(toData);
}

export function getSubIndustryById(
  parentId: string,
  id: string
): Promise<SubIndustryReadDTO> {
  return client().api.v1IndustryDetail2(parentId, id).then(toData);
}

export function addIndustry(
  industry: IndustryCreateDTO
): Promise<IndustryReadDTO> {
  return client().api.v1IndustryCreate(industry).then(toData);
}

export function addSubIndustry(
  parentId: string,
  industry: SubIndustryCreateDTO
): Promise<SubIndustryReadDTO> {
  return client().api.v1IndustryCreate2(parentId, industry).then(toData);
}

export function deleteIndustry(id: string) {
  return client().api.v1IndustryDelete(id);
}

export function deleteSubIndustry(parentId: string, id: string) {
  return client().api.v1IndustryDelete2(parentId, id);
}

export function editIndustry(id: string, industry: IndustryUpdateDTO) {
  return client().api.v1IndustryUpdate(id, industry);
}

export function editSubIndustry(
  parentId: string,
  id: string,
  industry: SubIndustryUpdateDTO
) {
  return client().api.v1IndustryUpdate2(parentId, id, industry);
}

export function getValueChains() {
  return client().api.v1ValueChainList().then(toData);
}
export function getValueChainsAll() {
  return client().api.v1ValueChainAllList().then(toData);
}

export function addValueChain(
  valueChain: ValueChainCreateDTO
): Promise<ValueChainReadDTO> {
  return client().api.v1ValueChainCreate(valueChain).then(toData);
}

export function deleteValueChain(id: string) {
  return client().api.v1ValueChainDelete(id);
}

export function editValueChain(id: string, valueChain: ValueChainUpdateDTO) {
  return client().api.v1ValueChainUpdate(id, valueChain);
}

export function editValueChainOrder(id: string, order: number): Promise<any> {
  return client().api.v1ValueChainOrderUpdate(id, order);
}

export function getValueChainById(id: string): Promise<ValueChainReadDTO> {
  return client().api.v1ValueChainDetail(id).then(toData);
}

export function getServiceGroups(): Promise<ServiceGroupReadListDTO[]> {
  return client().api.v1ServiceGroupList().then(toData);
}

export function getTerritories(): Promise<any[]> {
  return client().api.v1TerritoriesList().then(toData);
}

export function addServiceGroup(
  serviceGroup: ServiceGroupCreateDTO
): Promise<ServiceGroupReadDTO> {
  return client().api.v1ServiceGroupCreate(serviceGroup).then(toData);
}

export function editServiceGroup(
  id: string,
  serviceGroup: ServiceGroupUpdateDTO
): Promise<void> {
  return client().api.v1ServiceGroupUpdate(id, serviceGroup).then(toData);
}

export function getServiceGroupById(id: string): Promise<ServiceGroupReadDTO> {
  return client().api.v1ServiceGroupDetail(id).then(toData);
}

export function deleteServiceGroup(id: string) {
  return client().api.v1ServiceGroupDelete(id);
}

export function addTerritory(territory: any): Promise<any> {
  return client().api.v1TerritoryCreate(territory).then(toData);
}

export function editTerritory(id: string, territory: any): Promise<void> {
  return client().api.v1TerritoryUpdate(id, territory).then(toData);
}

export function deleteTerritory(id: string) {
  return client().api.v1TerritoryDelete(id);
}

export function getTerritoryById(id: string): Promise<any> {
  return client().api.v1TerritoryGet(id).then(toData);
}

export function getServiceByIds(ids: Array<string>): Promise<ServiceReadDTO[]> {
  return client().api.v1ServiceByidsCreate(ids).then(toData);
}
