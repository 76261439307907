import "./index.scss";

import {
  Button,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Switch,
} from "appkit-react";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import UserSelect, {
  INTERNAL_ONLY_FILTERS,
} from "pages/admin/components/AddUserToElementModal/UserSelect";
import CompanySelect from "pages/admin/components/CompanySelect";
import { useContext, useEffect, useRef } from "react";

import useSubmitable from "../../../../../hooks/useSubmitable";
import { AdminEntityContext } from "..";

const DEFAULT_FORM = {
  companyId: null,
  entityId: null,
  name: "",
  prid: "",
  viewInMyServices: false,
  contacts: [],
};

const findFormErrors = (form) => {
  return {
    name: form.name.trim().length === 0,
    companyId: form.companyId === null || form.companyId === undefined,
  };
};

const AddEntityModal = ({ entity, visible, onClose }) => {
  const { addEntity, updateEntity } = useContext(AdminEntityContext);
  const {
    values: form,
    handleValueChange,
    submitting,
    formErrors,
    resetForm,
    submit,
  } = useSubmitable(DEFAULT_FORM, findFormErrors);
  const clickOutsideRef = useRef();

  useEffect(() => {
    resetForm(
      entity
        ? {
            name: entity.name,
            companyId: entity.companyId,
            entityId: entity.id,
            prid: entity.prid,
            contacts: entity.contacts,
            viewInMyServices: entity.viewInMyServices,
          }
        : DEFAULT_FORM
    );
  }, [entity, resetForm]);

  const handleSubmit = () => {
    submit((form) => {
      const promise = !entity ? addEntity : updateEntity;
      form = {
        ...form,
        contacts: form.contacts.map((contact) => contact.ppid ?? contact),
      };
      return promise(form).then(onClose);
    });
  };

  useEffect(() => {
    if (!visible) {
      resetForm();
    }
  }, [visible, resetForm]);

  return (
    <ResizableAppkitModal
      visible={visible}
      className="add-entity-modal"
      onCancel={onClose}
      backdropClosable={false}
    >
      <ModalHeader>
        <div className="modal-title">
          {entity ? "Edit company" : "Create company"}
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="row" ref={clickOutsideRef}>
          <div className="col-md-12">
            <label className="a-form-label">
              <span className="a-color-primary">*</span>&nbsp;Company group
            </label>
            <CompanySelect
              disabled={!!entity}
              value={form.companyId}
              onValueChange={(value) => handleValueChange("companyId", value)}
              placeholder="Company group"
              hasError={!!formErrors.companyId}
            />
          </div>
          <div className="col-md-12">
            <label className="a-form-label">
              <span className="a-color-primary">*</span>&nbsp;Company name
            </label>
            <Input
              value={form.name}
              onChange={(value) => handleValueChange("name", value)}
              placeholder="Company name"
              hasError={!!formErrors.name}
            />
          </div>
          <div className="col-md-12">
            <label className="a-form-label">PRID</label>
            <Input
              value={form.prid}
              onChange={(value) => handleValueChange("prid", value)}
              placeholder="PRID"
            />
          </div>
          <div className="col-md-12">
            <label className="a-form-label">PwC Contacts</label>
            <UserSelect
              clickOutsideRef={clickOutsideRef}
              value={form.contacts}
              onChange={(value) => handleValueChange("contacts", value)}
              placeholder="PwC Contacts"
              hasError={!!formErrors.contacts}
              errMsg={formErrors.contacts}
              multiple
              filters={INTERNAL_ONLY_FILTERS}
            />
          </div>
          <div className="col-md-12 visible-myservices-field">
            <label className="a-form-label">Show Services</label>
            <Switch
              onText="Yes"
              offText="No"
              checked={form.viewInMyServices}
              onChange={(value) => handleValueChange("viewInMyServices", value)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onClose} disabled={submitting}>
          Cancel
        </Button>
        <Button kind="primary" isLoading={submitting} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default AddEntityModal;
