import { SubscriptionTrialFilter } from "api/admin/__generated__/UserManagementAPI";
import { SelectOption } from "appkit-react";
import Select from "components/Select";

interface Props {
  value: SubscriptionTrialFilter;
  onValueChange: (value: SubscriptionTrialFilter) => void;
}

const SubscriptionTrialFilterValues = [
  {
    value: 0,
    label: "All trial kind",
  },
  {
    value: 1,
    label: "Trial only",
  },
  {
    value: 2,
    label: "Expired trial only",
  },
  { value: 3, label: "Non-trial only" },
];

export default function TrialFilter({ value, onValueChange }: Props) {
  const handleFilter = (stringValue: string) =>
    onValueChange(parseInt(stringValue) as SubscriptionTrialFilter);
  return (
    <Select value={value.toString()} onSelect={handleFilter}>
      {SubscriptionTrialFilterValues.map((filterValue) => (
        <SelectOption
          key={filterValue.value}
          value={filterValue.value.toString()}
        >
          {filterValue.label}
        </SelectOption>
      ))}
    </Select>
  );
}
