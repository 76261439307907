import { apiFetch } from "../api";
const PREFIX = process.env.REACT_APP_URL_NEWS_ROOT + "Issuer/";

export const getIssuers = () => {
  return apiFetch(`${PREFIX}`);
};

export const getIssuer = (issuerId) => {
  return apiFetch(`${PREFIX}${issuerId}`);
};

export const createIssuer = (issuer) => {
  return apiFetch(`${PREFIX}`, issuer, "POST");
};

export const updateIssuer = (issuer) => {
  return apiFetch(`${PREFIX}${issuer.id}`, issuer, "PUT");
};

export const deleteIssuer = (issuerId) => {
  return apiFetch(`${PREFIX}${issuerId}`, undefined, "DELETE");
};
