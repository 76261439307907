import "./index.scss";

import { Button, ModalBody, ModalFooter, ModalHeader } from "appkit-react";
import ResizableAppkitModal from "components/ResizableAppkitModal";
import FileIcon from "pages/newsAdmin/components/FileIcon";

const NewsExcelUploadModal = ({
  uploadedFile = null,
  onClose,
  handleConfirm,
  type = "News",
}) => {
  return (
    <ResizableAppkitModal
      backdropClosable={false}
      visible={uploadedFile}
      className="news-excel-upload-modal"
      onCancel={onClose}
    >
      <ModalHeader>Bulk import {type}</ModalHeader>
      <ModalBody>
        <div className="file-preview">
          <FileIcon mimeType={uploadedFile?.type} />
          <span className="filename">{uploadedFile?.name}</span>
        </div>
        <p>
          {`This file's content will be read and imported into the ${type} list. Are
          you sure you wish to proceed?`}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button kind="transparent" onClick={onClose}>
          Cancel
        </Button>
        <Button kind="primary" onClick={handleConfirm}>
          Import
        </Button>
      </ModalFooter>
    </ResizableAppkitModal>
  );
};

export default NewsExcelUploadModal;
